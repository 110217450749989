export const loadStorage = (key = 'arl') => {
  try {
    const storage = window.localStorage.getItem(key)
    return storage ? JSON.parse(storage) : null
  } catch (error) {
    return null
  }
}

export const saveStorage = (state, key = 'arl') => {
  try {
    let dataToSave = state
    if (typeof dataToSave === 'object') dataToSave = JSON.stringify(dataToSave)
    window.localStorage.setItem(key, dataToSave)
  } catch (error) {
    throw new Error(`Error saving storage: ${error}`)
  }
}

export const removeStorage = (key) => {
  try {
    window.localStorage.removeItem(key)
  } catch (error) {
    throw new Error(`Error removing storage: ${error}`)
  }
}

export const clearStorage = () => {
  try {
    window.localStorage.clear()
  } catch (error) {
    throw new Error(`Error clearing storage: ${error}`)
  }
}
