import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  data: [],
}

export const { saveServices, clearSpecialServices, updateSpecialService } = createActions({
  SAVE_SERVICES: (data) => ({
    ...defaultState,
    data,
  }),
  CLEAR_SPECIAL_SERVICES: () => ({
    ...defaultState,
  }),
  UPDATE_SPECIAL_SERVICE: (data) => ({
    ...defaultState,
    data,
  }),
})

const saveSpecialServices = handleActions(
  {
    [saveServices]: produce((draft, { payload: { data } }) => {
      draft.data = data
    }),
    [clearSpecialServices]: produce((draft, { payload: { data } }) => {
      draft.data = data
    }),
    [updateSpecialService]: produce((draft, { payload: { data } }) => {
      draft.data = data
    }),
  },
  defaultState
)

export default saveSpecialServices
