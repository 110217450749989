import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: {},
  editUser: false,
  userSelected: {},
}

export const {
  requestCreateUser,
  successCreateUser,
  failureCreateUser,
  resetCreateUser,
  loadUser,
  unloadUser,
} = createActions({
  REQUEST_CREATE_USER: (data) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    data,
  }),
  SUCCESS_CREATE_USER: (data) => ({
    data,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_CREATE_USER: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  RESET_CREATE_USER: () => ({
    ...defaultState,
  }),
  LOAD_USER: (data) => ({
    editUser: true,
    userSelected: data,
  }),
  UNLOAD_USER: () => ({
    editUser: false,
    userSelected: defaultState.userSelected,
  }),
})

const createUser = handleActions(
  {
    [requestCreateUser]: produce((draft, { payload: { fetching, data, error, success } }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successCreateUser]: produce((draft, { payload: { message, fetching, success, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = false
      draft.data = data
    }),
    [failureCreateUser]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = false
    }),
    [resetCreateUser]: produce((draft, { payload: { fetching, data, error, success } }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [loadUser]: produce((draft, { payload: { editUser, userSelected } }) => {
      draft.editUser = editUser
      draft.userSelected = userSelected
    }),
    [unloadUser]: produce((draft, { payload: { editUser } }) => {
      draft.editUser = editUser
    }),
  },
  defaultState
)

export default createUser
