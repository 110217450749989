import { put, takeLatest, call } from 'redux-saga/effects'
import { users } from 'services'
import { requestUserList, successUserList, failureUserList } from 'store/duck/getUsers.duck'

function* getUsersRequestAsync(data) {
  try {
    const response = yield call(users.getUsers, data.payload.params)
    yield put(successUserList(response))
  } catch (error) {
    yield put(failureUserList(error.response.data.message))
  }
}

function* watchGetUsersAsync() {
  yield takeLatest(requestUserList, getUsersRequestAsync)
}

export default watchGetUsersAsync
