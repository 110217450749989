/* eslint-disable react/jsx-props-no-spreading */
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import React, { useCallback, useEffect, useState } from 'react'
import Col from 'antd/lib/col'
import Swal from 'sweetalert2'
import Form from 'antd/lib/form'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import notify from 'utils/notify'
import StyledItem from 'components/Form/StyledItem/Index'
import StyledOptions from 'components/Form/StyledOptions'
import { ArrowDownIcon } from 'assets/svg'
import { MdBlock } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { requestGetTruckTypeList } from 'store/duck/getTruckTypeList.duck'
import { clearMessageSnackBar } from 'store/duck/messageSnackBar.duck'
import { clearUpdateTruckType, requestUpdateTruckType } from 'store/duck/updateTruckType.duck'
import { requestDeleteTruckType, clearDeleteTruckType } from 'store/duck/deleteTruckType.duck'
import Table from 'components/Table'
import StyledSearch from 'components/Input/Search'
import FloatLabel from 'components/Label/FloatLabel'
import TruckType from 'containers/settings/TruckType'
import columns from './utils/TableColumns'

const TruckTypeList = () => {
  const [form] = Form.useForm()
  const { xl } = useBreakpoint()
  const dispatch = useDispatch()
  const { success, error, trucktypes, totalRecords } = useSelector((state) => state.getTruckTypeList)
  const { success: successUpdate, error: errorUpdate, message: msmUpdate } = useSelector(
    (state) => state.updateTruckType
  )
  const [visible, setVisible] = useState(false)
  const [params, setParams] = useState({ text: '', page: 1, active: '' })
  const { message: msgSnack } = useSelector((state) => state.messageSnackBar)
  const { message: msgError, error: errorDelete, successDelete } = useSelector((state) => state.deleteTruckType)
  const tableColumns = columns(dispatch, setVisible)

  useEffect(() => {
    if (!visible) dispatch(requestGetTruckTypeList(params))
  }, [visible, params, dispatch])

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error getting truck types!',
      })
    }
  }, [error])

  useEffect(() => {
    if (successUpdate && msmUpdate === '') {
      notify('success', msgSnack)
      dispatch(clearMessageSnackBar())
      dispatch(clearUpdateTruckType())
      dispatch(requestGetTruckTypeList(params))
    }
    if (errorUpdate && msmUpdate !== '') {
      notify('error', `Error update: ${msmUpdate}`)
      dispatch(clearMessageSnackBar())
      dispatch(clearUpdateTruckType())
    }
  }, [errorUpdate, msmUpdate, successUpdate, dispatch, params, msgSnack])

  useEffect(() => {
    if (successDelete) {
      notify('success', 'Truck type has been deleted')
      dispatch(clearMessageSnackBar())
      dispatch(clearDeleteTruckType())
      dispatch(requestGetTruckTypeList(params))
    }
    if (errorDelete) {
      notify('error', `Error deleting special service: ${msgError}`)
      dispatch(clearDeleteTruckType())
    }
  }, [dispatch, successDelete, errorDelete, msgError, params])

  const handleValuesChange = (values) => {
    const active = values.active ?? params.active
    setParams({ ...params, text: values.text ?? params.text, active: active !== 'All' ? active : undefined })
  }

  const handleTableChange = useCallback((pagination) => {
    setParams((prevParams) => ({
      ...prevParams,
      page: pagination.current ?? prevParams.page,
      limit: pagination.limit ?? prevParams.limit,
    }))
  }, [])

  return (
    <div style={{ width: '96%', margin: '10px auto 15px' }}>
      <Form
        layout="vertical"
        name="filter-customer-list"
        style={{ width: '100%', margin: '0px', padding: '0px' }}
        form={form}
        initialValues={{
          active: 'All',
        }}
        onValuesChange={handleValuesChange}
        hideRequiredMark
      >
        <Row gutter={[24, 16]} justify="end">
          <Col flex={xl ? 8 : undefined} xs={!xl ? 24 : undefined}>
            <Form.Item name="text">
              <StyledSearch />
            </Form.Item>
          </Col>
          <Col flex="auto">
            <FloatLabel label="Status" show>
              <StyledItem name="active">
                <Select suffixIcon={<ArrowDownIcon />} placeholder="Select" size="large">
                  <StyledOptions key="All" value="All">
                    All
                  </StyledOptions>
                  <StyledOptions key="Active" value="true">
                    Activated
                  </StyledOptions>
                  <StyledOptions key="Blocked" value="false">
                    Blocked
                  </StyledOptions>
                </Select>
              </StyledItem>
            </FloatLabel>
          </Col>
        </Row>
      </Form>
      <TruckType visible={visible} onCloseDrawer={setVisible} />
      <Table
        width="100%"
        padding="4px 0px"
        margin="0px"
        columns={tableColumns}
        data={trucktypes}
        loading={!success}
        rowKey="_id"
        pagination={{ total: totalRecords, pageSize: 10, current: params.page }}
        updatedPagination
        onChange={handleTableChange}
        limitPerPage={12}
        rowSelection
        dispatch={dispatch}
        avoidNotify
        entityNameForBulk="Equipment Type"
        pauseProps={{
          label: 'Block',
          labelSuccess: 'blocked',
          icon: (props) => <MdBlock {...props} />,
          newState: { active: false },
        }}
        pauseRequest={requestUpdateTruckType}
        deleteRequest={requestDeleteTruckType}
        deleteProps={{
          hidden: true,
        }}
      />
    </div>
  )
}

export default TruckTypeList
