import styled from 'styled-components'

const CardShadow = styled.div`
  width: 100%;
  height: auto;
  padding: 16px;
  margin-bottom: 17px;
  overflow-x: auto;
  border-radius: 2px;
  box-shadow: 0px 10px 20px rgba(217, 217, 217, 0.2);
`

export default CardShadow
