import { put, takeLatest, call } from 'redux-saga/effects'
import { users } from 'services'
import { requestEditUser, successEditUser, failureEditUser } from 'store/duck/editUser.duck'

function* editUserRequestAsync(data) {
  try {
    const response = yield call(users.editUser, data.payload.data, data.payload.userId)
    yield put(successEditUser(response, 'success'))
  } catch (error) {
    yield put(failureEditUser(error.response.data.message))
  }
}

function* watchEditUserAsync() {
  yield takeLatest(requestEditUser, editUserRequestAsync)
}

export default watchEditUserAsync
