import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
}

export const { requestMessageSnackBar, clearMessageSnackBar } = createActions({
  REQUEST_MESSAGE_SNACK_BAR: (message) => ({
    message,
  }),
  CLEAR_MESSAGE_SNACK_BAR: () => ({
    ...defaultState,
  }),
})

const messageSnackBar = handleActions(
  {
    [requestMessageSnackBar]: produce((draft, { payload: { message } }) => {
      draft.message = message
    }),
    [clearMessageSnackBar]: produce((draft, { payload: { message } }) => {
      draft.message = message
    }),
  },
  defaultState
)

export default messageSnackBar
