import styled from 'styled-components'
import Col from 'antd/lib/col'
import Modal from 'antd/lib/modal'

export const StyledCol = styled(Col)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const StyledModal = styled(Modal)`
  .ant-modal-close-x {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
  }

  .ant-modal-header {
    border-bottom-color: ${(props) => props?.theme?.colors?.light_grey?.default };
    background-color: ${(props) => props?.theme?.colors?.modal_white?.default};
  }
  .ant-modal-body {
    overflow: auto;
    height: ${(props) => props.height};
  }

  .ant-modal-content {
    background: #f9fafe;
    border-radius: 4px 4px 0 0;
    border-width: 4px;
    border-style: none none solid;
    border-color: #3594ff;
  }

  .ant-modal-footer {
    border: none;
    padding-left: 24px;
    padding-right: 24px;
  }

  .ant-select-selector,
  .ant-input,
  .ant-input-affix-wrapper {
    border-radius: 4px !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${(props) => props.theme.colors.dark_blue.default};
    border-color: ${(props) => props.theme.colors.dark_blue.default};
  }
`

export const StyledFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`
