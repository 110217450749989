import Menu from 'antd/lib/menu'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { requestMessageSnackBar } from 'store/duck/messageSnackBar.duck'
import MoreDropdown from 'components/MoreDropdown'
import ModalConfirmation from 'components/ModalConfirmation'
import { loadRuleset } from 'store/duck/createRuleSet.duck'
import { getStatus, EnumStatus } from 'utils/getStatus'
import { AiOutlineCheck } from 'react-icons/ai'
import { MdBlock, MdDelete, MdModeEdit, MdPauseCircleFilled } from 'react-icons/md'
import { requestDeleteRuleset } from 'store/duck/deleteRuleset.duck'
import { requestUpdateRuleset } from 'store/duck/updateRuleset.duck'
import { StyledCol, StyledRow } from './styles'

const sizeIcon = '20'
const paddingItem = { padding: '0px 8px 0px 8px' }

const ActionsDropdown = ({ record, openDrawer, status }) => {
  const dispatch = useDispatch()

  const [propsModal, setPropsModal] = useState({
    visible: false,
    message: 'Are you sure you want to delete this RuleSet?',
    // eslint-disable-next-line react/jsx-props-no-spreading
    icon: (props) => <MdDelete {...props} />,
    textButon: 'Delete',
    textSuccessfull: 'RuleSet has been deleted',
    actionConfirm: (some) => some,
  })

  const loadRuleSelected = (data) => {
    dispatch(loadRuleset(data))
    openDrawer(true)
  }
  const handleDeleteRule = async (id) => {
    setPropsModal({
      visible: true,
      message: 'Are you sure you want to delete this RuleSet?',
      // eslint-disable-next-line react/jsx-props-no-spreading
      icon: (props) => <MdDelete {...props} />,
      textButon: 'Delete',
      textSuccessfull: 'RuleSet has been deleted',
      actionConfirm: () => {
        dispatch(requestDeleteRuleset(id))
        setPropsModal({
          visible: false,
          message: ' ',
          icon: (props) => props,
          textButon: '',
          textSuccessfull: '',
          actionConfirm: (props) => props,
        })
      },
    })
  }

  const messageModal = {
    [EnumStatus.Activated]: 'activated',
    [EnumStatus.Paused]: 'paused',
    [EnumStatus.Deactivated]: 'blocked',
  }

  const handleUpdateStatus = async (id = '', newStatus = '') => {
    const data = {
      id,
      status: newStatus,
    }
    setPropsModal({
      visible: true,
      message: `Are you sure you want to ${messageModal[newStatus]} this RuleSet?`,
      // eslint-disable-next-line react/jsx-props-no-spreading
      icon: (props) => <AiOutlineCheck {...props} />,
      textButon: messageModal[newStatus].charAt(0).toUpperCase() + messageModal[newStatus].slice(1),
      textSuccessfull: `RuleSet has been ${newStatus.toLowerCase()}`,
      actionConfirm: () => {
        dispatch(requestMessageSnackBar(`RuleSet has been ${newStatus.toLowerCase()}`))
        dispatch(requestUpdateRuleset(data))
        setPropsModal({
          visible: false,
          message: ' ',
          icon: (props) => props,
          textButon: '',
          textSuccessfull: '',
          actionConfirm: (props) => props,
        })
      },
    })
  }

  const assets = {
    [EnumStatus.Activated]: {
      icon: <AiOutlineCheck size={sizeIcon} />,
      text: <span>Activate RuleSet</span>,
      onClick: (id) => handleUpdateStatus(id, EnumStatus.Activated),
    },
    [EnumStatus.Paused]: {
      icon: <MdPauseCircleFilled size={sizeIcon} />,
      text: <span>Pause RuleSet</span>,
      onClick: (id) => handleUpdateStatus(id, EnumStatus.Paused),
    },
    [EnumStatus.Deactivated]: {
      icon: <MdBlock size={sizeIcon} />,
      text: <span>Block RuleSet</span>,
      onClick: (id) => handleUpdateStatus(id, EnumStatus.Deactivated),
    },
  }

  const displayedStatusModifiers = (id) => {
    return Object.keys(EnumStatus)
      .filter((item) => item !== status)
      .map((row, index) => (
        <Menu.Item key={index} style={paddingItem} onClick={() => assets[row].onClick(id)}>
          <StyledRow>
            <StyledCol>{assets[row].icon}</StyledCol>
            <StyledCol>
              <span>{assets[row].text}</span>
            </StyledCol>
          </StyledRow>
        </Menu.Item>
      ))
  }

  return (
    <MoreDropdown 
      menu={
        <Menu>
          <Menu.Item style={paddingItem} onClick={() => loadRuleSelected(record)}>
            <StyledRow>
              <StyledCol>
                <MdModeEdit size={sizeIcon} />
              </StyledCol>
              <StyledCol>
                <span>Edit RuleSet</span>
              </StyledCol>
            </StyledRow>
          </Menu.Item>
          {displayedStatusModifiers(record._id)}
          <Menu.Item style={paddingItem} onClick={() => handleDeleteRule(record._id)}>
            <StyledRow>
              <StyledCol>
                <MdDelete size={sizeIcon} />
              </StyledCol>
              <StyledCol>
                <span>Delete RuleSet</span>
              </StyledCol>
            </StyledRow>
          </Menu.Item>
          <ModalConfirmation
            actionConfirm={propsModal.actionConfirm}
            textSuccessfull={propsModal.textSuccessfull}
            textButton={propsModal.textButon}
            close={() => {
              setPropsModal({
                visible: false,
                message: ' ',
                icon: (props) => props,
                textButon: '',
                textSuccessfull: '',
                actionConfirm: (props) => props,
              })
            }}
            icon={propsModal.icon}
            message={propsModal.message}
            visible={propsModal.visible}
            height="296px"
            width={350}
            centered
            avoidNotify
          />
        </Menu>
      }
    />
  )
}

const actionsDropdown = (_, openDrawer) => ({
  title: <div style={{ fontSize: '16px' }}>Actions</div>,
  dataIndex: 'actions',
  key: 'actions',
  textWrap: 'word-break',
  render: (edit, record) => (
    <ActionsDropdown record={record} openDrawer={openDrawer} status={getStatus(record.status, record.active)} />
  ),
  width: 100,
  align: 'center',
})

export default actionsDropdown
