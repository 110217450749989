import { put, takeLatest, call } from 'redux-saga/effects'
import { users } from 'services'
import { requestResendInvite, successResendInvite, failureResendInvite } from 'store/duck/resendInvite.duck'

function* resendInviteRequestAsync(data) {
  try {
    const response = yield call(users.resendInvite, data.payload.data)
    yield put(successResendInvite(response))
  } catch (error) {
    yield put(failureResendInvite(error.response.data.message))
  }
}

function* watchResendInviteAsync() {
  yield takeLatest(requestResendInvite, resendInviteRequestAsync)
}

export default watchResendInviteAsync