import React from 'react'
import Tooltip from 'antd/lib/tooltip'
import { StyledDeleteIcon, StyledButton } from './styles'

const DeleteIcon = ({ onClick, title }) => {
  return (
    <Tooltip title={title}>
      <span>
        <StyledButton onClick={onClick}>
          <StyledDeleteIcon />
        </StyledButton>
      </span>
    </Tooltip>
  )
}

export default DeleteIcon
