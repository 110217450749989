import styled from 'styled-components' 
import Tabs from 'antd/lib/tabs' 

export const StyledDiv = styled.div`
  width: 96%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: auto;
  margin-top: 30px;
`


export const StyledTabs = styled(Tabs)`
  width: ${(props) => (props.width ? props.width : '100%')};
  .ant-tabs-tab {
    padding-bottom: 2px;
    &.ant-tabs-tab-active .ant-tabs-tab-btn {
      font-weight: 700;
    }
  }
`