import { put, debounce, call } from 'redux-saga/effects'
import { integrations } from 'services'
import { formatResultsZipCode } from 'utils/formatValues'
import {
  successGetCityFromZip,
  requestGetCityFromZip,
  failureGetCityFromZip,
} from 'store/duck/getCityFromZip.duck'

function* getCityFromZip(data) {
  try {
    const response = yield call(integrations.getCityFromZip, data.payload.entry)
    yield put(successGetCityFromZip(formatResultsZipCode(response.data)))
  } catch (error) {
    yield put(failureGetCityFromZip(error))
  }
}

function* watchGetCityFromZip() {
  yield debounce(150, requestGetCityFromZip, getCityFromZip)
}

export default watchGetCityFromZip
