import { put, takeLatest, call } from 'redux-saga/effects'
import { customers } from 'services'
import { requestEditCustomer, successEditCustomer, failureEditCustomer } from 'store/duck/editCustomer.duck'

function* editCustomerRequestAsync(data) {
  try {
    const response = yield call(customers.editCustomer, data.payload.data, data.payload.customerId)
    yield put(successEditCustomer(response, 'success'))
  } catch (error) {
    yield put(failureEditCustomer(error.response.data.message))
  }
}

function* watchEditUserAsync() {
  yield takeLatest(requestEditCustomer, editCustomerRequestAsync)
}

export default watchEditUserAsync
