import React from 'react'
import Input from 'antd/lib/input'
import DrawerItem from 'components/Form/DrawerItem'
import ControlInput from 'components/Form/ControlInput'
import InputWithLabel from 'components/Form/InputWithLabel'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/all'
import colors from 'providers/theme/config/colorPallete'

const PasswordInput = ({ label, ...props }) => (
  <InputWithLabel label={label}>
    {(hasValue) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <DrawerItem style={{ marginBottom: 0 }} {...props}>
        <ControlInput hasValue={hasValue}>
          {({ value, onChange }) => (
            <Input.Password
              size="large"
              value={value}
              onChange={onChange}
              iconRender={(visible) =>
                visible ? (
                  <AiFillEye size={24} color={colors.dark_blue.default} />
                ) : (
                  <AiFillEyeInvisible size={24} color={colors.dark_blue.default} />
                )
              }
            />
          )}
        </ControlInput>
      </DrawerItem>
    )}
  </InputWithLabel>
)

export default PasswordInput
