import PropTypes from 'prop-types';
import ThemeProvider from './ThemeProvider';

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

ThemeProvider.defaultProps = {
  children: null,
};

export default ThemeProvider;
