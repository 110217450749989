import { put, takeLatest, call } from 'redux-saga/effects'
import { rules } from 'services'
import {
  requestDeleteMultiStep,
  successDeleteMultiStep,
  failureDeleteMultiStep,
} from '../duck/deleteMultiStep.duck'

function* deleteMultiStepAsync(data) {
  try {
    const response = yield call(rules.deleteMultiStep, data.payload.data)
    yield put(successDeleteMultiStep(response))
  } catch (error) {
    yield put(failureDeleteMultiStep(error.response.data.message))
  }
}

function* watchDeleteMultiStepAsync() {
  yield takeLatest(requestDeleteMultiStep, deleteMultiStepAsync)
}

export default watchDeleteMultiStepAsync
