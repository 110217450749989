import { put, takeLatest, call } from 'redux-saga/effects'
import { rules } from 'services'
import { requestDeleteBetaRules, successDeleteBetaRules, failureDeleteBetaRules } from '../duck/deleteBetaRules.duck'

function* deleteBetaRulesAsync() {
  try {
    const response = yield call(rules.deleteBetaRules)
    yield put(successDeleteBetaRules(response))
  } catch (error) {
    yield put(failureDeleteBetaRules(error))
  }
}

function* watchDeleteBetaRulesAsync() {
  yield takeLatest(requestDeleteBetaRules, deleteBetaRulesAsync)
}

export default watchDeleteBetaRulesAsync
