import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'
import { chooseBetweenZipCodes } from 'utils/filters'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: [],
  zipCodes: []
}

export const {
  requestGetZipAutoComplete,
  successGetZipAutoComplete,
  failureGetZipAutoComplete,
  clearGetZipAutoComplete,
} = createActions({
  REQUEST_GET_ZIP_AUTO_COMPLETE: (entry) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    entry,
  }),
  SUCCESS_GET_ZIP_AUTO_COMPLETE: (data) => ({
    data,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_ZIP_AUTO_COMPLETE: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  CLEAR_GET_ZIP_AUTO_COMPLETE: () => ({
    ...defaultState,
  })
})

const getZipAutoComplete = handleActions(
  {
    [requestGetZipAutoComplete]: produce((draft, { payload: { fetching, data, error, success } }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successGetZipAutoComplete]: produce((draft, { payload: { message, fetching, success, data, error } }) => {
      const zip = chooseBetweenZipCodes(data)
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.zipCodes = zip
    }),
    [failureGetZipAutoComplete]: produce((draft, { payload: { message, fetching, error, success } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [clearGetZipAutoComplete]: produce((draft, { payload: { message, fetching, success, data, error, zipCodes } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success      
      draft.data = data
      draft.zipCodes = zipCodes
    }),
  },
  defaultState
)

export default getZipAutoComplete
