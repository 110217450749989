import styled from 'styled-components'
import Typography from 'antd/lib/typography'

const { Text } = Typography

const TitleLabel = styled(Text)`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.dark_blue.default};
`

export default TitleLabel
