import { RULE_GEOGRAPHIC, RULE_SIMPLE, RULE_DATE_RANGE, RULE_LENGTH } from 'utils/constants'

export const parseUsersData = (usersResp) => {
  const totalUsers = []
  usersResp.map((user, index) => {
    totalUsers.push({
      key: index + 1,
      userId: user._id,
      name: user.name,
      lastname: user.lastName,
      phone: user.phone,
      avatar: user.avatar,
      role: user.roleId ? user.roleId.name : '',
      roleId: user.roleId ? user.roleId._id : '',
      email: user.email,
      status: user.active,
    })
    return totalUsers
  })
  return totalUsers
}

export const parseRulesetData = (data) => {
  const ruleset = { ...data }
  const { rules } = data
  const rulesId = []
  const customersId = []
  const totalRules = {
    geographic: {
      inboundState: [],
      inboundZip: [],
      outboundState: [],
      outboundZip: [],
    },
    dateRange: {
      pickup: [],
      delivery: [],
    },
    simple: {
      pickup: [],
      delivery: [],
    },
    lengthRule: [],
  }
  rules.map((rule) => {
    rulesId.push(rule._id)
    switch (rule.ruleModel) {
      case RULE_GEOGRAPHIC: {
        const reference = rule.rule.typeReferenceGeographicId.name
        const shipping = rule.rule.typeShippingId.name
        if (reference === 'State' && shipping === 'inbound') totalRules.geographic.inboundState.push(rule)
        if (reference === 'Zipcode' && shipping === 'inbound') totalRules.geographic.inboundZip.push(rule)
        if (reference === 'State' && shipping === 'outbound') totalRules.geographic.outboundState.push(rule)
        if (reference === 'Zipcode' && shipping === 'outbound') totalRules.geographic.outboundZip.push(rule)
        break
      }
      case RULE_SIMPLE: {
        const shipping = rule.rule.typeShippingId.name
        if (shipping === 'inbound') totalRules.simple.delivery.push(rule)
        if (shipping === 'outbound') totalRules.simple.pickup.push(rule)
        break
      }
      case RULE_DATE_RANGE: {
        const shipping = rule.rule.typeShippingId.name
        if (shipping === 'inbound') totalRules.dateRange.delivery.push(rule)
        if (shipping === 'outbound') totalRules.dateRange.pickup.push(rule)
        break
      }
      case RULE_LENGTH: {
        totalRules.lengthRule.push(rule)
        break
      }
      default:
        break
    }
    return totalRules
  })
  data.customers.map((customer) => {
    customersId.push(customer._id)
    return customersId
  })
  ruleset.rulesId = rulesId
  ruleset.totalRules = totalRules
  ruleset.customersId = customersId
  return ruleset
}

export const parseRulesData = (rules) => {
  const totalRules = []
  rules.map((rule) => {
    const addRule = {
      id: rule._id,
      key: rule._id,
      value: rule.name,
    }
    totalRules.push(addRule)
    return totalRules
  })
  return totalRules
}

export const parseCustomersData = (customers) => {
  const totalCustomers = []
  customers.map((item) => {
    const addCustomer = {
      id: item.customer._id,
      key: item.customer._id,
      value: item.customer.name,
      flat: item.customer.flat,
      margin: item.customer.margin,
      email: item.customer.email,
      consider: item.customer.considerCustomFuelPrice,
      fuelPrice: item.customer.customFuelPrice,
    }
    totalCustomers.push(addCustomer)
    return totalCustomers
  })
  return totalCustomers
}

export const parseSpecialServicesData = (services) => {
  const totalSpecialServices = []
  services.map((item) => {
    const addService = {
      name: item.name,
      description: item.description,
      price: item.price,
      stops: item.stops,
      id: item._id,
      key: item._id,
      value: item.name,
      active: item.active,
    }
    totalSpecialServices.push(addService)
    return totalSpecialServices
  })
  return totalSpecialServices
}

export const parseRulesType = (types) => {
  const typesId = {}
  types.map((type) => {
    switch (type.name) {
      case 'Geographic':
        typesId.typeGeographic = type._id
        break
      case 'Length':
        typesId.typeLength = type._id
        break
      case 'Date':
        typesId.typeDate = type._id
        break
      case 'Simple':
        typesId.typeSimple = type._id
        break
      case 'Equipment':
        typesId.typeEquipment = type._id
        break
      case 'Market':
        typesId.typeMarket = type._id
        break
      case 'Lane Score':
        typesId.typeLaneScore = type._id
        break
      case 'Lead Time':
        typesId.typeLeadTime = type._id
        break
      case 'Region':
        typesId.typeRegion = type._id
        break
      case 'MCI':
        typesId.typeMci = type._id
        break
      case 'L/T Ratio':
        typesId.typeLtRatio = type._id
        break

      default:
        break
    }
    return typesId
  })
  return typesId
}

export const parseLtRatio = (array = []) => {
  const newObject = {}
  array.forEach(element => {
    if (element?.days) {
      newObject[element?.days] = { ...element }
    }
  });

  return newObject
}

/**
   we select the min value in days to display the last fuel value
   */
export const getMiniTo = (days) => {
  const numbers = Object.keys(days);
  return Math.min(...numbers)
}

export const daysLTRatio = (ltrOrigin = [], ltrDestination = []) => {
  const daysOrigin = parseLtRatio(ltrOrigin)
  const daysDestination = parseLtRatio(ltrDestination)
  const eightDaysOrigin = daysOrigin[8]
  const thirtyDaysOrigin = daysOrigin[30]
  const displayEightDaysOrigin = eightDaysOrigin ? Number(eightDaysOrigin?.LT).toFixed(1) : ""
  const displayThirtyDaysOrigin = thirtyDaysOrigin ? Number(thirtyDaysOrigin?.LT).toFixed(1) : ""

  const eightDaysDestination = daysDestination[8]
  const thirtyDaysDestination = daysDestination[30]
  const displayEightDestination = eightDaysDestination ? Number(eightDaysDestination?.LT).toFixed(1) : ""
  const displayThirtyDestination = thirtyDaysDestination ? Number(thirtyDaysDestination?.LT).toFixed(1) : ""

  const minO = getMiniTo(daysOrigin)
  const minD = getMiniTo(daysDestination)
  const minOrigin = minO !== 8 && minO !== 30 ? minO : 0
  const minDestination = minD !== 8 && minD !== 30 ? minD : 0

  const lastDaysOrigin = daysOrigin[minOrigin]
  const lastDaysDestiantion = daysDestination[minDestination]
  const displayLastDaysOrigin = lastDaysOrigin ? Number(lastDaysOrigin?.LT).toFixed(1) : ""
  const displayLastDaysDestination = lastDaysDestiantion ? Number(lastDaysDestiantion?.LT).toFixed(1) : ""

  return {
    "1": { origin: displayLastDaysOrigin, destination: displayLastDaysDestination },
    "8": { origin: displayEightDaysOrigin, destination: displayEightDestination },
    "30": { origin: displayThirtyDaysOrigin, destination: displayThirtyDestination }
  }
}