import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: [],
  typesId: {},
}

export const { requestGetRuleType, successGetRuleType, failureGetRuleType } = createActions({
  REQUEST_GET_RULE_TYPE: () => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
  }),
  SUCCESS_GET_RULE_TYPE: (data, typesId) => ({
    data,
    typesId,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_RULE_TYPE: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
})

const getRuleType = handleActions(
  {
    [requestGetRuleType]: produce((draft, { payload: { fetching, error, success } }) => {
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successGetRuleType]: produce((draft, { payload: { message, fetching, success, data, error, typesId } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data
      draft.typesId = typesId
    }),
    [failureGetRuleType]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = !error
    }),
  },
  defaultState
)

export default getRuleType
