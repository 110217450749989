import PropTypes from 'prop-types'
import RouteMap from './RouteMap'

RouteMap.propTypes = {
  center: PropTypes.object.isRequired,
  zoom: PropTypes.number.isRequired,
  markers: PropTypes.array,
  withBubble: PropTypes.bool,
  mapParams: PropTypes.object.isRequired,
}

RouteMap.defaultProps = {
  withBubble: false,
  markers: [],
}

export default RouteMap
