export const convertToNumber = (string = '') => `$${Number(Number(string.replace(/[^0-9.-]+/g, '')).toFixed())}`

export const inNumber = (string = '', fixed) =>
  `${Number(Number(string.replace(/[^0-9.-]+/g, '')).toFixed(fixed))}`

export const validateGS = (gsRate = {}) => {
  if (gsRate && gsRate?.rate) {
    return gsRate?.rate === '$0.00'
  }
  return true
}

export const validateDATTooltip = (datRate = {}) => {
  if (datRate && datRate.datLow) {
    return datRate.datLow.rate === '$0.00'
  }
  return true
}

export const validateDataLake = (gsDatalake = {}) => {
  if (gsDatalake && gsDatalake.rate) {
    return gsDatalake.rate === '$0.00'
  }
  return true
}

export const validateRateBasic = (rateBasic = {}) => {
  if (rateBasic && rateBasic?.priceBase) {
    return rateBasic.priceBase === '$0.00'
  }
  return true
}

export const validatePrice = (rateBasic = {}) => {
  if (rateBasic && rateBasic.priceSpecialServices) {
    return rateBasic.priceSpecialServices === '$0.00'
  }
  return true
}

export const validateRateCost = (rateBasic = {}) => {
  if (rateBasic && rateBasic.rateCost) {
    return rateBasic.rateCost === '$0.00'
  }
  return true
}
