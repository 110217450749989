import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: [],
}

export const {
  requestReturnVersionRule,
  successReturnVersionRule,
  failureReturnVersionRule,
  clearReturnVersion,
  clearReturn,
} = createActions({
  REQUEST_RETURN_VERSION_RULE: (data) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    data,
  }),
  SUCCESS_RETURN_VERSION_RULE: (data) => ({
    data,
    message: data.message,
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_RETURN_VERSION_RULE: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  CLEAR_RETURN_VERSION_RULE: () => ({
    error: false,
    success: false,
    message: '',
    fetching: false,
    data: [],
  }),
  CLEAR_RETURN: () => ({
    message: '',
    fetching: false,
    error: false,
    success: false,
    target: 'origin',
    routeMarkers: [],
  }),
})

const returnVersionRule = handleActions(
  {
    [requestReturnVersionRule]: produce((draft, { payload: { fetching, data, error, success } }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successReturnVersionRule]: produce((draft, { payload: { message, fetching, success, data, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data
    }),
    [failureReturnVersionRule]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = !error
    }),
    [clearReturnVersion]: produce((draft, { payload: { fetching, data, error, success, message } }) => {
      draft.data = data
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [clearReturn]: produce((draft, { payload: { message, fetching, error, success } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
  },
  defaultState
)

export default returnVersionRule
