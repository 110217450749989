import moment from 'moment'
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx';
import { getTotalFlatFromRules, getTotalMarginFromRules, getMilles } from 'utils/get';
import { daysLTRatio } from 'utils/parseResponse';

const downloadRates = ({ title, fileName, rates }) => {
  const wb = XLSX.utils.book_new()
  wb.Props = {
    Title: title,
  }

  wb.SheetNames.push(title)
  wb.Sheets[title] = XLSX.utils.aoa_to_sheet(
    [
      [
        'Date of Quote',
        'Customer',
        'Origin City',
        'Origin State',
        'Origin Market',
        'Destination City',
        'Destination State',
        'Destination Market',
        'Miles',
        'Pickup Date',
        'Delivery Date',
        'Equipment',
        "DAT Rate",
        "GS Target Rate",
        "GS Network Rate",
        "UD Score",
        "Rule % Total",
        "Rule Flat Total",
        "Est Carrier Cost",
        "Customer % Markup",
        "Customer Flat Markup",
        "Final Rate (Line Haul)",
        "Final Rate (All-In)",
        "L/T Ratio Origin",
        "L/T Ratio Destination",
        "MCI Origin / 7 days",
        "MCI Origin / Forecast",
        "MCI Destination / 7 days",
        "MCI Destination / Forecast",
        "Load Won"
      ],
    ].concat(
      rates.map((rate) => {
        const maximumMarkdown = rate?.maximumMarkdown
        const summarizerMargin = getTotalMarginFromRules(rate?.details?.ruleAdjustment ?? [], rate?.details?.multiStepAdjustment ?? [])
        const totalMargin = maximumMarkdown > summarizerMargin ? maximumMarkdown : summarizerMargin
        const ltRatio = daysLTRatio(rate?.details?.ltrOrigin, rate?.details?.ltrDestination)
        const mci = rate?.details?.marketConditions

        return [
          moment(rate.createdAt).startOf('day').toDate(),
          rate.customer.name,
          rate.lane.origin.city,
          rate.lane.origin.state,
          rate?.details?.base_rate?.datRate?.datExtraInfo?.originGeography ?? "",
          rate.lane.destination.city,
          rate.lane.destination.state,
          rate?.details?.base_rate?.datRate?.datExtraInfo?.destinationGeography ?? "",
          getMilles(rate?.totalMilles, rate?.stops),
          moment(rate.pickupDate).startOf('day').toDate(),
          moment(rate.deliveryDate).startOf('day').toDate(),
          rate.truckTypeId.name,
          rate?.details?.base_rate?.datRate?.datAvg?.rate ?? "",
          rate?.details?.base_rate?.gsPredictionRate?.rate ?? "",
          rate?.details?.base_rate?.gsRate?.rate ?? "",
          rate?.udScore ? rate?.details?.udScore ?? "" : "",
          totalMargin,
          getTotalFlatFromRules(rate?.details?.ruleAdjustment ?? [], rate?.details?.multiStepAdjustment ?? []),
          rate?.details?.rateCost ?? "",
          rate?.customerRateInfo?.margin ?? "",
          rate?.customerRateInfo?.flat ?? "",
          rate?.LH ?? "",
          rate?.rate ?? "",
          ltRatio[1].origin ?? "",
          ltRatio[1].destination ?? "",
          mci?.INBOUND?.historyMciScore ?? "",
          mci?.INBOUND?.forecastMciScore ?? "",
          mci?.OUTBOUND?.historyMciScore ?? "",
          mci?.OUTBOUND?.forecastMciScore ?? "",
          rate?.loadWon,
        ]
      })
    )
  )

  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })
  function s2ab(s) {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    // eslint-disable-next-line no-bitwise
    for (let i = 0; i < s.length; i += 1) view[i] = s.charCodeAt(i) & 0xff
    return buf
  }

  saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), `${fileName}.xlsx`)
}

export default downloadRates
