import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: [],
}

export const {
  requestReturnRuleset,
  successReturnRuleset,
  failureReturnRuleset,
  clearReturnRuleset,
} = createActions({
  REQUEST_RETURN_RULESET: (data) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    data,
  }),
  SUCCESS_RETURN_RULESET: (data) => ({
    data,
    message: data.message,
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_RETURN_RULESET: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  CLEAR_RETURN_RULESET: () => ({
    ...defaultState,
  }),
})

const returnRuleset = handleActions(
  {
    [requestReturnRuleset]: produce((draft, { payload: { fetching, data, error, success } }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successReturnRuleset]: produce((draft, { payload: { message, fetching, success, data, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data
    }),
    [failureReturnRuleset]: produce((draft, { payload: { message, fetching, error, success } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [clearReturnRuleset]: produce((draft, { message, fetching, success, data, error }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
      draft.message = message
    }),
  },
  defaultState
)

export default returnRuleset
