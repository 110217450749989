import { put, takeLatest, call } from 'redux-saga/effects'
import { integrations } from 'services'
import { successGetZipHere, requestGetZipHere, failureGetZipHere } from 'store/duck/getZipHere.duck'

function* getUsersRequestAsync(data) {
  try {
    const response = yield call(integrations.getZipHere, data.payload.entry)
    yield put(successGetZipHere(response))
  } catch (error) {
    yield put(failureGetZipHere(error))
  }
}

function* watchGetUsersAsync() {
  yield takeLatest(requestGetZipHere, getUsersRequestAsync)
}

export default watchGetUsersAsync
