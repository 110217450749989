import React from 'react'

export default function DisplayComponent({ ValueToEvaluate = null, ComponentToDisplay = <div>display</div>}) {
    if (ValueToEvaluate) {
        if (ValueToEvaluate()) {
            return ComponentToDisplay
        }
        return <div />

    }

    return ComponentToDisplay


}
