import React from 'react'
import useHereMap from 'hooks/useHereMap'
import MapContainer from './styles'

const RouteMap = () => {
  const mapElement = useHereMap()

  return <MapContainer ref={mapElement} />
}

export default RouteMap
