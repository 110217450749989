import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  field: '',
  data: [],
}

export const { requestGetZipcodes, successGetZipcodes, failureGetZipcodes } = createActions({
  REQUEST_GET_ZIPCODES: (data) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    data,
  }),
  SUCCESS_GET_ZIPCODES: (data) => ({
    data,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_ZIPCODES: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
})

const zipcodes = handleActions(
  {
    [requestGetZipcodes]: produce((draft, { payload: { fetching, error, success, data } }) => {
      draft.fetching = fetching
      draft.error = error
      draft.success = success
      draft.field = data
    }),
    [successGetZipcodes]: produce((draft, { payload: { message, fetching, success, data, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data.map((entry) => {
        return { label: `${entry.code}, ${entry.cityState}`, value: entry.code }
      })
    }),
    [failureGetZipcodes]: produce((draft, { payload: { message, fetching, error, success } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
  },
  defaultState
)

export default zipcodes
