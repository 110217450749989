import { put, takeLatest, call } from 'redux-saga/effects'
import { users } from 'services'
import { requestCreateUser, successCreateUser, failureCreateUser } from 'store/duck/createUser.duck'

function* createUserRequestAsync(data) {
  try {
    const response = yield call(users.createUser, data.payload.data)
    yield put(successCreateUser(response, 'success'))
  } catch (error) {
    yield put(failureCreateUser(error.response.data.message))
  }
}

function* watchCreateUserAsync() {
  yield takeLatest(requestCreateUser, createUserRequestAsync)
}

export default watchCreateUserAsync
