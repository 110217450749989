import { put, takeLatest, call } from 'redux-saga/effects'
import { customers } from 'services/'
import { parseCustomersData } from 'utils/parseResponse'
import { requestGetAllCustomer, successGetAllCustomer, failureGetAllCustomer } from '../duck/getAllCustomer.duck'

function* getAllCustomersAsync(data) {
  try {
    const response = yield call(customers.getCustomers, data.payload.params)
    const customersData = parseCustomersData(response.data)
    yield put(successGetAllCustomer(customersData))
  } catch (error) {
    yield put(failureGetAllCustomer(error))
  }
}

function* watchGetAllCustomersAsync() {
  yield takeLatest(requestGetAllCustomer, getAllCustomersAsync)
}

export default watchGetAllCustomersAsync
