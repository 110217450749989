import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: {},
}

export const { requestGetDatHistory, successGetDatHistory, failureGetDatHistory } = createActions({
  REQUEST_GET_DAT_HISTORY: (data) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    data,
  }),
  SUCCESS_GET_DAT_HISTORY: (data) => ({
    data,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_DAT_HISTORY: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
})

const getDatHistory = handleActions(
  {
    [requestGetDatHistory]: produce((draft, { payload: { fetching, data, error, success } }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successGetDatHistory]: produce((draft, { payload: { message, fetching, success, data, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data.map((entry) => {
        return {
          data: entry.data,
          id: entry.key.replace(/([a-z])([A-Z])/g, '$1 $2'),
          ...entry,
        }
      })
    }),
    [failureGetDatHistory]: produce((draft, { payload: { message, fetching, success, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
  },
  defaultState
)

export default getDatHistory
