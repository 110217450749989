import React from 'react'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import { H1 } from 'components/Text/TextHeader'
import UserProfileInfo from 'containers/users/UserProfileInfo'
import ProfileContainer from './styles'

const UserProfile = () => {
  return (
    <ProfileContainer margin="4rem" justify="center">
      <Col span={22}>
        <Row>
          <H1>User Profile</H1>
        </Row>
        <ProfileContainer margin="5rem" justify="start">
          <UserProfileInfo />
        </ProfileContainer>
      </Col>
    </ProfileContainer>
  )
}

export default UserProfile
