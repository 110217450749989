import styled from 'styled-components'
import { IoIosArrowUp } from 'react-icons/io'

const StyledArrow = styled(IoIosArrowUp)`
  ${(props) => `transform: rotate(${props.change})`} !important;
  color: #002555;
  margin-top: -0.5rem;
`

export default StyledArrow
