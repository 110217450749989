import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: {},
  email: '',
}

export const {
  requestSendEmail,
  successSendEmail,
  failureSendEmail,
  saveSendEmail,
  clearSendEmail,
} = createActions({
  REQUEST_SEND_EMAIL: (email, data) => ({
    ...defaultState,
    fetching: true,
    data,
    email,
  }),
  SUCCESS_SEND_EMAIL: (data, message) => ({
    data,
    message,
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_SEND_EMAIL: (message) => ({
    message,
    fetching: false,
    error: true,
  }),
  SAVE_SEND_EMAIL: (data) => ({
    data,
  }),
  CLEAR_SEND_EMAIL: () => ({}),
})

const sendEmail = handleActions(
  {
    [requestSendEmail]: produce((draft, { payload: { message, fetching, success, error, data, email } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data
      draft.email = email
    }),
    [successSendEmail]: produce((draft, { payload: { message, fetching, success } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
    }),
    [failureSendEmail]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
    }),
    [saveSendEmail]: produce((draft, { payload: { data } }) => {
      draft.data = { ...draft.data, ...data }
    }),
    [clearSendEmail]: produce((draft) => {
      draft.message = defaultState.message
      draft.fetching = defaultState.fetching
      draft.error = defaultState.error
      draft.success = defaultState.success
      draft.data = defaultState.data
      draft.email = defaultState.email
    }),
  },
  defaultState
)

export default sendEmail
