import styled from 'styled-components'
import { Link as LinkRouter } from 'react-router-dom'
import Row from 'antd/lib/row'
import { LoginBg } from 'assets/img'

export const LoginContainer = styled(Row)`
  display: grid;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  padding-left: 8%;
  padding-right: 8%;
  padding-top: 2rem;
  
  .ant-input:hover,
  .ant-input-affix-wrapper:hover {
    background-color: transparent !important;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,

  
`

export const LoginContent = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6%;
  justify-content: center;
  @media only screen and (min-width: 1280px) {
    margin-top: 2%;
  }
`

export const LoginContentForm = styled.div`
  width: 100%;
  display: flex;
  padding: 30px 0px 50px 0px;
  justify-content: center;
  height: fit-content;
  align-items: center;
  margin-bottom: 20px;
  background: #f9fafe;
  @media only screen and (min-width: 600px) {
    width: 90%;
  }
  @media only screen and (min-width: 768px) {
    width: 70%;
  }
  @media only screen and (min-width: 920px) {
    width: 60%;
  }
  @media only screen and (min-width: 1024px) {
    width: 50%;
  }
  @media only screen and (min-width: 1280px) {
    width: 35%; 
    padding: 20px 0px 50px 0px;
    margin-bottom: 0px;
  }
  @media only screen and (min-width: 1440px) {
    width: 30%;
  }
  @media only screen and (min-width: 1600px) {
    width: 24%;
  }
`

export const StyledText = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 30px;
  color: white;
  width: 80%;
`

export const Link = styled(LinkRouter)`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #3594ff;
`

export const LinkContainer = styled.div`
  margin-top: 6px;
  text-align: center;
`
export const ImgContainer = styled.div`
  min-height: 100vh;
  background: linear-gradient(rgba(0, 37, 85, 0.7), RGB(0, 37, 85, 0.7)), url(${LoginBg});
  background-size: cover;
  object-fit: cover;
  display: flex;
  justify-content: center;
`
