import React from 'react'

export default function InputLineContainer({
  label = '',
  Component = <div />,
  widthComponent = '90%',
  marginComponent = '0 3%',
  margin = '0px 0px 0px 0px',
  marginLabel = '12px 0px 0px 0px',
  padding = '0px 0px 0px 0px',
  dataTestid = '',
}) {
  return (
    <div style={{ display: 'flex', margin: `${margin}`, padding: `${padding}` }} data-testid={dataTestid}>
      <div style={{ margin: marginLabel }}>{label}</div>
      <div style={{ width: widthComponent, margin: marginComponent }}>{Component}</div>
    </div>
  )
}
