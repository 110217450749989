import React, { useEffect, useState } from 'react'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Input from 'antd/lib/input'
import Swal from 'sweetalert2'
import { AiOutlineSearch } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { requestUpdateRuleset } from 'store/duck/updateRuleset.duck'
import { requestGetMultiSteps, clearGetMultiSteps } from 'store/duck/getMultiSteps.duck'
import ButtonPrimary from 'components/Button/ButtonPrimary'
import Modal from 'components/Modal'
import Table from 'components/Table'
import Columns from './utils/TableColumns'

const AddMultistep = ({ handleCancel, visible, multiSelected }) => {
  const dispatch = useDispatch()
  const [totalMultistep, setTotalMultistep] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [search, setSearch] = useState('')
  const [params, setParams] = useState({ text: '', page: 1 })
  const { multiSteps, success, error, message, totalRecords } = useSelector((state) => state.getMultiSteps)
  const { data: rulesetData } = useSelector((state) => state.getRulesetById)

  useEffect(() => {
    if (success) {
      setTotalMultistep(multiSteps)
      setSelectedRows(multiSelected)
    }
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Get multistep error',
        text: message,
      })
      dispatch(clearGetMultiSteps())
      handleCancel(false)
    }
  }, [success, error, message, multiSteps, dispatch, handleCancel, multiSelected])

  const handleAssignMultistep = () => {
    const data = {
      id: rulesetData._id,
      rulesetName: rulesetData.rulesetName,
      multisteps: selectedRows,
    }
    dispatch(requestUpdateRuleset(data))
    handleCancel(false)
  }

  const handleSearch = (event) => {
    if (!event.target.value) {
      const nextParams = { text: '', page: 1 }
      dispatch(requestGetMultiSteps(nextParams))
      setParams(nextParams)
      return
    }
    setSearch(event.target.value)
  }

  const handleKeyPress = (event) => {
    if (event.key !== 'Enter') return
    const queryParams = { ...params }
    queryParams.text = search
    setParams(queryParams)
    dispatch(requestGetMultiSteps(queryParams))
  }

  const handleTableChange = (pagination) => {
    const currentParams = { ...params }
    currentParams.page = pagination.current
    setParams(currentParams)
    dispatch(requestGetMultiSteps(currentParams))
  }

  const handleSelectChange = (selectedRowKeys) => {
    setSelectedRows(selectedRowKeys)
  }

  const onRowSelection = {
    selectedRowKeys: selectedRows,
    onChange: handleSelectChange,
  }

  return (
    <Modal
      title="Add Multisteps"
      visible={visible}
      onCancel={() => {
        handleCancel(false)
      }}
    >
      <Row gutter={8}>
        <Col span={8}>
          <Input
            prefix={<AiOutlineSearch />}
            placeholder="Search"
            onChange={handleSearch}
            onKeyPress={handleKeyPress}
            allowClear
          />
        </Col>
      </Row>
      <Table
        columns={Columns}
        data={totalMultistep}
        loading={!success}
        rowSelection={onRowSelection}
        rowKey="_id"
        pagination={{ pageSize: 10, total: totalRecords, current: params.page }}
        onChange={handleTableChange}
        width="96%"
        padding="4px 2px"
      />
      <Row justify="end">
        <Col span={6}>
          <ButtonPrimary width="170px" height="38px" onClick={handleAssignMultistep}>
            Add Multisteps
          </ButtonPrimary>
        </Col>
      </Row>
    </Modal>
  )
}

export default AddMultistep
