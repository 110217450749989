import React from 'react'
import { useDispatch } from 'react-redux'
import { clearForgotPassword } from 'store/duck/forgotPassword.duck'
import Link from './styles'

const ButtonBack = () => {
  const dispatch = useDispatch()

  const cleanData = () => {
    dispatch(clearForgotPassword())
  }

  return (
    <Link to="/login" onClick={cleanData}>
     Go Back
    </Link>
  )
}

export default ButtonBack
