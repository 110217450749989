import { put, takeLatest, call } from 'redux-saga/effects'
import { rules } from 'services'
import { requestDeleteRule, successDeleteRule, failureDeleteRule } from '../duck/deleteRule.duck'

function* deleteRuleAsync(data) {
  try {
    const response = yield call(rules.deleteRule, data.payload.itemId)
    yield put(successDeleteRule(response))
  } catch (error) {
    yield put(failureDeleteRule(error.response.data.message))
  }
}

function* watchDeleteRuleAsync() {
  yield takeLatest(requestDeleteRule, deleteRuleAsync)
}

export default watchDeleteRuleAsync
