import { put, takeLatest, call } from 'redux-saga/effects'
import { rates } from 'services'
import {
  successGetDownloadMultipleRates,
  requestGetDownloadMultipleRates,
  failureGetDownloadMultipleRates,
} from '../duck/getDownloadMultipleRates.duck'

function* getGetDownloadMultipleRates(data) {
  try {
    const response = yield call(rates.downloadMultipleRate, data.payload.data)
    yield put(successGetDownloadMultipleRates(response))
  } catch (error) {
    yield put(failureGetDownloadMultipleRates(error))
  }
}

function* watchGetDownloadMultipleRatesAsync() {
  yield takeLatest(requestGetDownloadMultipleRates, getGetDownloadMultipleRates)
}

export default watchGetDownloadMultipleRatesAsync
