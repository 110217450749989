const moment = require('moment')

export const expToDate = (exp = 0) => moment(exp * 1000)

export const biggerThanCurrentDate = (date = moment()) => date > moment()

export const hasTheTokenExpired = (exp = 0) => {
    try {
        const toDate = expToDate(exp)
        return !biggerThanCurrentDate(toDate)
    } catch (error) {
        console.error(error)
        return true
    }
}

