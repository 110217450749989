import React, { useState, useEffect, useCallback } from 'react'
import { addRule } from 'store/duck/createRule.duck'
import { useDispatch, useSelector, batch } from 'react-redux'
import FilterRules from 'components/Form/FilterRules'
import Table from 'components/Table'
import { clearMessageSnackBar } from 'store/duck/messageSnackBar.duck'
import notify from 'utils/notify'
import ModalDeleteRules from 'components/ModalDeleteRules'
import CreateRuleModal from 'containers/rules/CreateNewRule'
import { changeMarketByGeographic } from 'utils/rulesFormat'
import { clearDeleteRule, requestDeleteRule } from 'store/duck/deleteRule.duck'
import { requestUpdateStatusRule } from 'store/duck/updateStatusRule.duck'
import { clearUpdateRule, requestUpdateRule } from 'store/duck/updateRule.duck'
import { replaceActions } from 'utils/formatValues'
import TextBodyModal from './utils/TextBodyModal'
import geograpchicColumns from './utils/GeographicColumns'
import lengthColumns from './utils/LengthColumns'
import simpleColumns from './utils/SimpleColumns'
import marketRules from './utils/MarketColumns'
import dateRangeColumns from './utils/DateRangeColumns'
import equipmentColumns from './utils/EquipmentColumns'
import RulesContainer from '../styles'
import useGetRulesIds from '../ListRules/utils/useGetRulesIds'
import leadTimeColumns from './utils/LeadTimeColumns'
import DeleteBetaRules from './DeleteBetaRules/DeleteBetaRules'
import LaneScoreColumns from '../columnsRules/LaneScoreColumns'
import MciColumns from '../columnsRules/MciColumns'
import LTRatioColumns from '../columnsRules/LTRatioColumns'
import RegionColumns from "../columnsRules/RegionColumns"

export default function MasterRules({ enableEdit = true }) {
  const dispatch = useDispatch()
  const [params, setParams] = useState({ page: 1, limit: 20 })
  const { rules, success, totalRules } = useSelector((state) => state.getMasterRulesByParams)
  const { shippingRule, referenceRule, typesId, queryParams } = useGetRulesIds()
  const [columnsParams, setColumnsParams] = useState({
    type: 'geographicRule',
    shipping: 'outState',
    reference: 'state',
    queryParams,
  })
  const {
    success: successUpdate,
    error: errorUpdate,
    message: msmUpdate,
  } = useSelector((state) => state.updateStatusRule)
  const { message: msgSnack } = useSelector((state) => state.messageSnackBar)
  const {
    success: successUpdateRule,
    error: errorUpdateRule,
    message: messageUpdateRule,
  } = useSelector((state) => state.updateRule)
  const deleteRule = useSelector((state) => state.deleteRule)
  const { trucktypes } = useSelector((state) => state.getTruckTypeList)
  const [rulesToDelete, setRulesToDelete] = useState({ rules: [], multiStep: [], ruleSets: [] })
  const [visible, setVisible] = useState(false)
  const [columns, setColumns] = useState([])
  const [status, setStatus] = useState('All')
  const [rulesData, setRulesData] = useState([])
  const [openDelete, setOpenDelete] = useState(false)
  const { data } = useSelector((state) => state.getRuleType)

  useEffect(() => {
    setRulesData([])
    if (success) {
      const getColumns = {
        geographicRule: replaceActions(
          geograpchicColumns(dispatch, columnsParams.shipping, columnsParams.reference, setVisible, enableEdit),
          enableEdit,
          dispatch,
          setVisible
        ),
        lengthRule: replaceActions(
          lengthColumns(dispatch, setVisible, enableEdit),
          enableEdit,
          dispatch,
          setVisible
        ),
        simpleRule: replaceActions(
          simpleColumns(dispatch, columnsParams.shipping, setVisible, enableEdit),
          enableEdit,
          dispatch,
          setVisible
        ),
        dateRangeRule: replaceActions(
          dateRangeColumns(dispatch, columnsParams.shipping, setVisible, enableEdit),
          enableEdit,
          dispatch,
          setVisible
        ),
        truckType: replaceActions(
          equipmentColumns(dispatch, trucktypes, setVisible, enableEdit),
          enableEdit,
          dispatch,
          setVisible
        ),
        marketRule: replaceActions(
          marketRules(dispatch, columnsParams.shipping, setVisible, enableEdit),
          enableEdit,
          dispatch,
          setVisible
        ),
        laneScoreRule: replaceActions(LaneScoreColumns(dispatch, setVisible), enableEdit, dispatch, setVisible),
        leadTimeRule: replaceActions(leadTimeColumns(dispatch, setVisible), enableEdit, dispatch, setVisible),
        regionRule: replaceActions(RegionColumns(dispatch, setVisible), enableEdit, dispatch, setVisible),
        mciRule: replaceActions(MciColumns(dispatch, setVisible), enableEdit, dispatch, setVisible),
        ltRatioRule: replaceActions(LTRatioColumns(dispatch, setVisible), enableEdit, dispatch, setVisible),
      }
      setColumns(getColumns[columnsParams.type])
      setRulesData(rules)
    }
  }, [dispatch, trucktypes, columnsParams, rules, success, enableEdit])

  const handleCreateRule = () => {
    dispatch(addRule())
    setVisible(true)
  }
  const showModalDelete = () => {
    setOpenDelete(true)
  }
  const closeModalDelete = () => {
    setOpenDelete(false)
  }
  const closeModal = (some) => {
    setVisible(some)
  }

  const handleTableChange = useCallback((pagination) => {
    setParams((prevParams) => ({
      ...prevParams,
      page: pagination.current ?? prevParams.page,
      limit: pagination.limit ?? prevParams.limit,
    }))
  }, [])

  const multipleDelete = () => {
    batch(() => {
      for (const rule of rulesToDelete.rules) {
        dispatch(requestDeleteRule(rule))
      }
    })
  }

  const handleData = useCallback(
    (rows) => {
      setRulesToDelete({
        ruleSets: rows
          .flatMap((id) => [...rulesData.filter((rule) => rule._id === id)])
          .flatMap((rule) => [...(rule?.rulesetsInfo?.ruleSetsNames ?? [])]),
        multiStep: rows
          .flatMap((id) => [...rulesData.filter((rule) => rule._id === id)])
          .flatMap((rule) => [...(rule?.multiStepsRuleInfo?.multistepNames ?? [])]),
        rules: rows,
      })
    },
    [rulesData]
  )

  useEffect(() => {
    if (deleteRule.successDelete) {
      closeModalDelete()
    }
    if (!deleteRule.error) return
    dispatch(clearDeleteRule())
  }, [deleteRule, dispatch])

  useEffect(() => {
    if (successUpdate) {
      notify('success', msgSnack)
      dispatch(clearMessageSnackBar())
    }
    if (successUpdateRule) {
      dispatch(clearUpdateRule())
    }
    if (errorUpdate || errorUpdateRule) {
      notify('error', errorUpdate ? 'Error updating the ruleset.' : messageUpdateRule)
      dispatch(clearMessageSnackBar())
    }
  }, [
    successUpdateRule,
    successUpdate,
    errorUpdate,
    msmUpdate,
    dispatch,
    params,
    msgSnack,
    errorUpdateRule,
    messageUpdateRule,
  ])

  return (
    <RulesContainer>
      <CreateRuleModal
        masterRule
        typesId={typesId}
        shippingRule={shippingRule}
        referenceRule={referenceRule}
        visible={visible}
        onCloseModal={closeModal}
        columnsParams={changeMarketByGeographic(columnsParams)}
        enableEdit={enableEdit}
      />
      <FilterRules
        queryParams={queryParams}
        handleColumnsParams={setColumnsParams}
        onClickNewRule={handleCreateRule}
        changeStatus={(newStatus) => setStatus(newStatus)}
        page={params.page}
        limit={params.limit}
        getMasterRules
        typesId={data}
        enableEdit={enableEdit}
      />
      <Table
        enableEdit={enableEdit}
        displayComponent={() => {
          return <DeleteBetaRules />
        }}
        request={(nextData) => dispatch(requestUpdateRule(nextData))}
        columns={columns}
        data={rulesData}
        loading={!success}
        rowKey="_id"
        width="100%"
        padding="4px 0px"
        margin="0px"
        pagination={{ total: totalRules }}
        limitPerPage={50}
        paginationOptions={['50', '100', '150', '250']}
        updatedPagination
        onChange={handleTableChange}
        rowSelection
        ruleSelected={columnsParams.type}
        status={status}
        dispatch={dispatch}
        pauseRequest={requestUpdateStatusRule}
        avoidNotify
        openDeleteModal={showModalDelete}
        handleData={handleData}
        CustomModalDelete={
          <ModalDeleteRules
            visible={openDelete}
            ruleSets={rulesToDelete.ruleSets}
            multiSteps={rulesToDelete.multiStep}
            close={closeModalDelete}
            componentBody={() => (
              <TextBodyModal
                multiQuantity={rulesToDelete.multiStep.length}
                ruleSetQuantity={rulesToDelete.ruleSets.length}
              />
            )}
            onClick={() => multipleDelete()}
            fetching={deleteRule.fetching}
          />
        }
        addHeight={-30}
      />
    </RulesContainer>
  )
}
