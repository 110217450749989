import styled from 'styled-components'
import { Menu, Layout } from 'antd'

export const StyledContainer = styled(Layout)``

export const MenuContainer = styled(Layout.Sider)`
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  background: #002555;
  height: 100%;
  overflow: hidden auto !important;
  ::-webkit-scrollbar {
    width: 0px;
  }
  scrollbar-width: none;
`

export const SidebarHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  height: 64px;
  background: #002555;
  display: grid;
  place-items: center;
  img {
    max-width: 10rem;
  }
`

export const LayoutContainer = styled(Layout.Content)`
  background: #fff;
  margin-left: ${(props) => props.expanded};
  transition: all 0.2s;
  width: 100%;
  height: 100vh;
  @media screen and (min-width: 1800px) {
    //TODO: after change styles on <CarrierCost/>
    /* overflow: hidden; */
  }
`
export const ChildrenContainer = styled.div`
  background: #fff;
  width: 100%;
`

export const StyledCollapser = styled.button`
  border: none;
  background-color: transparent;
  &:hover {
    background-color: transparent;
  }
  margin-left: 1.5rem;
  position: fixed !important;
  bottom: 25px !important;
`

export const StyledMenu = styled(Menu)`
  padding-top: 10px;
  color: white;
  background: #002555;
  border-right: none;
  .ant-menu-item-only-child:active {
    background: none;
  }
  .ant-menu-item::after {
    border: none !important;
  }
  .ant-menu-item-selected {
    background: none !important;
    color: white;
    border-radius: 4px 4px 4px 4px !important;
    div {
      background: #3594ff;
    }
    .item-content {
      border-left: none;
      background: #3594ff;
      border-radius: 0px 4px 4px 0px !important;
    }
  }
`
export const StyledSubMenu = styled(Menu.SubMenu)`
  .ant-menu-submenu-title {
    height: 60px !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    &::after,
    &:active,
    &:focus {
      background: none;
    }
    i {
      display: none;
    }
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      width: 60px;
      margin-left: 7px;
      &:hover {
        background: none;
      }
    }
    svg {
      width: 24px;
      height: 21px;
    }
    span {
      width: 7.5rem;
      padding-left: 0.2rem;
    }
  }
  ul {
    background: none !important;
    color: white;
  }

  .ant-menu-item-selected div {
    background: none;
    color: #3594ff;
  }
  .ant-menu-item {
    margin-left: 1.5rem !important;
    .item-content {
      width: 6rem !important;
      border: none;
      margin-left: -0.8rem;
    }
  }
`

export const StyledItem = styled(Menu.Item)`
  padding: 0 !important;
  margin: 5px 0px 5px 9px !important;
  height: 50px !important;
  max-width: 200px;
  display: flex;
  &:hover {
    color: white;
  }
  &::after,
  &:active,
  &:focus {
    background: none;
  }
  .ant-menu-item-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 60px;
  }
  img {
    object-fit: none;
    width: 28px;
    height: 28px;
  }
  .item-content {
    display: flex;
    height: 100%;
    align-items: center;
    width: 7.5rem;
    display: flex;
    justify-content: flex-start;
    padding-left: 0.5rem;
  }
  .ant-menu-item-icon {
    margin-right: 0;
  }
`
