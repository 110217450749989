import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import RuleSet from 'containers/rules/Ruleset/LoadRuleSet'
import TabsRules from 'containers/rules/TabsRules/TabsRules'

const LayoutRoutes = () => {
  const { login } = useSelector((state) => ({ login: state.login.data.roleId.name }))

  return (
    <Switch>
      {login !== 'Basic' && (
        <>
          <TabsRules />
          <Route path="/rules/ruleset/:id" component={RuleSet} />
        </>
      )}
    </Switch>
  )
}

export default LayoutRoutes
