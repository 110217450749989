import React, { useState, useRef, useEffect } from 'react'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Button from 'antd/lib/button'
import { useSelector } from 'react-redux'
import { longNameRule } from 'utils/inputRules'
import { AiOutlineCheckCircle } from 'react-icons/ai'
import EditIcon from 'components/Icon/EditIcon'
import { StyledWrapper, StyledInput, StyledText, StyledForm } from './styles'

const TitleTextEdit = ({ title, text, handleEditText }) => {
  const [edit, isEdit] = useState(false)
  const inputRef = useRef(null)
  const { success, error } = useSelector((state) => state.updateRuleset)

  useEffect(() => {
    if (edit) {
      inputRef.current.focus()
    }
  }, [edit])

  useEffect(() => {
    if (success || error) {
      isEdit(false)
    }
  }, [success, error])

  const handleEditRuleset = (values) => {
    handleEditText(values.name)
  }

  return (
    <StyledWrapper>
      <Row>
        <Col span={20}>
          {edit ? (
            <StyledForm layout="vertical" name="edit-ruleset" onFinish={handleEditRuleset} initialValues={{ name: title }}>
              <Form.Item style={{ width: '90%'}} name="name" rules={longNameRule}>
                <StyledInput style={{ width: '100%'}} ref={inputRef} />
              </Form.Item>
            </StyledForm>
          ) : (
            <span>{title}</span>
          )}
        </Col>
        <Col span={4}>
          {edit ? (
            <Button style={{ border: 'none'}} element="editRuleset" htmlType="submit" form="edit-ruleset">
              <AiOutlineCheckCircle
                style={{ color: '#002555', cursor: 'pointer' }}
                size="1.5rem"
              />
            </Button>
          ) : (
            <EditIcon size="1.3rem" onClick={() => isEdit(!edit)} />
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <StyledText>{text}</StyledText>
        </Col>
      </Row>
    </StyledWrapper>
  )
}

export default TitleTextEdit
