import styled from 'styled-components'
import Input from 'antd/lib/input'
import Item from 'components/Input/FormItem'

export const EditButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
`

export const FieldWrapper = styled.div`
  position: relative;
`

export const IconContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`

export const FormItem = styled(Item)`
  margin-bottom: 0.5rem;
`
export const EditableInput = styled(Input)`
  transition: all 0.3s;
  &:hover {
    border: 1px #40a9ff solid;
  }
  span.ant-input-wrapper.ant-input-group input.ant-input {
    border-right: none;
    &.ant-input-disabled {
      border-right: none;
    }
  }
  span.ant-input-wrapper.ant-input-group span.ant-input-group-addon {
    background-color: ${(props) => (props.disabled ? '#f5f5f5' : '#fff')};
  }
  span.ant-input-wrapper.ant-input-group input.ant-input {
    &:hover {
      border: none;
    }
    &:focus {
      border: none;
    }
  }
`
