import React from 'react'
import Col from 'antd/lib/col'
import BoxContainer from './styles'

const Box = ({
  marginTop,
  children,
  width,
  height,
  title,
  Header,
  secondary,
  borderless,
  backgroundColor,
  bordertop,
  ...props
}) => {
  return (
    <BoxContainer
      width={width}
      height={height}
      borderless={borderless}
      backgroundColor={backgroundColor}
      bordertop={bordertop}
      marginTop={marginTop}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <div id="header">
        <Col style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <span className={secondary ? 'titleSecondary' : 'title'}>{title}</span>
        </Col>
        {Header}
      </div>
      <div id="content">{children}</div>
    </BoxContainer>
  )
}

export default Box
