import { put, takeLatest, call } from 'redux-saga/effects'
import { trucktypes } from 'services'
import {
  requestDeleteTruckType,
  successDeleteTruckType,
  failureDeleteTruckType,
} from '../duck/deleteTruckType.duck'

function* deleteTruckTypeAsync(data) {
  try {
    const response = yield call(trucktypes.deleteTruckType, data.payload.id)
    yield put(successDeleteTruckType(response))
  } catch (error) {
    yield put(failureDeleteTruckType(error))
  }
}

function* watchDeleteTruckTypeAsync() {
  yield takeLatest(requestDeleteTruckType, deleteTruckTypeAsync)
}

export default watchDeleteTruckTypeAsync
