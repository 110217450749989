import React from 'react'
import { InputGeneric } from 'components/Input'
import DrawerItem from 'components/Form/DrawerItem'
import ControlInput from 'components/Form/ControlInput'
import InputWithLabel from 'components/Form/InputWithLabel'

const TextInput = ({ Input, label, name, rules, ...props }) => (
  <InputWithLabel label={label}>
    {(hasValue) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <DrawerItem style={{ marginBottom: 0 }} name={name} rules={rules}>
        <ControlInput hasValue={hasValue}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {({ value, onChange }) => Input ? <Input size="large" value={value} onChange={onChange} {...props} /> : <InputGeneric size="large" value={value} onChange={onChange} {...props} />}
        </ControlInput>
      </DrawerItem>
    )}
  </InputWithLabel>
)

export default TextInput
