import React, { useCallback, useState, useRef, useEffect, useMemo } from 'react'
import Select from 'antd/lib/select'
import { debounce } from 'lodash'
import colors from 'providers/theme/config/colorPallete'
import InputWithLabel from 'components/Form/InputWithLabel'
import DrawerItem from 'components/Form/DrawerItem'
import ControlInput from 'components/Form/ControlInput'
import ClosablePill from 'containers/rules/Ruleset/CreateRuleset/utils/ClosablePill'
import TitleDrawer from 'components/TitleDrawer'
import { BsPlusSquareFill } from 'react-icons/bs'
import './style.css'
// import { StyledText } from '../styles'

export default function RenderRegionRule({ states = [],
  resetFormValue = () => '',
  handleValues = (some) => some,
  loadCustomers = [], }) {
  const [selectedCustomers, setSelectedCustomers] = useState([])
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [textInput, setTextInput] = useState('')
  const [openSelect, setopenSelect] = useState(false)
  const selectRef = useRef(null)
  const [position, setPosition] = useState('')
  const [isLoad, setIsLoad] = useState(false)


  useEffect(() => {
    if (loadCustomers.length > 0 && selectedCustomers.length === 0 && !isLoad) {
      setSelectedCustomers(loadCustomers)
      setIsLoad(true)
    }
  }, [loadCustomers, selectedCustomers, isLoad])

  useEffect(() => {
    handleValues(selectedCustomers)
  }, [selectedCustomers, handleValues])

  const addCustomer = () => {
    if (selectedCustomer) {
      setSelectedCustomers((prev) => [...prev, selectedCustomer.value])
      setSelectedCustomer(null)
      resetFormValue()
    }
  }
  const searching = useCallback(
    debounce((textToSearch) => setTextInput(textToSearch), 400),
    [setTextInput]
  )
  // Drag and drop funcitonality (start)
  function onDragOver(e) {
    e.preventDefault()
    e.dataTransfer.dropEffect = 'move'
  }
  function onDragStart(e, info) {
    const element = e.target

    element.classList.add('novisible')

    e.dataTransfer.setData('id', info)
    setPosition(info)
  }

  function onDrop(e, info) {
    // const id = e.dataTransfer.getData('id')
    const getNewValue = selectedCustomers[position]
    const getPreviousValue = selectedCustomers[info]
    const newData = [...selectedCustomers]
    newData[info] = getNewValue
    newData[position] = getPreviousValue
    setSelectedCustomers(newData)
  }
  function endDrag(e) {
    const element = e.target
    element.classList.remove('novisible')
  }

  const items = selectedCustomers.map((customer, i) => {
    return (
      <div        
        key={i}
        draggable="true"
        onDragOver={(e) => onDragOver(e)}
        onDragStart={(e) => onDragStart(e, i)}
        onDrop={(e) => onDrop(e, i)}
        onDragEnd={(e) => endDrag(e)}
      >
        <ClosablePill
          key={customer}
          text={customer}
          onClose={() => {
            const newSelectedCustomers = [...selectedCustomers]
            newSelectedCustomers.splice(i, 1)
            setSelectedCustomers(newSelectedCustomers)
          }}
        />
      </div>
    )
  })
  // Drag and drop funcitonality (finish)

  const removeSelecteds = (array = [], selecteds = []) => array.map((item) => item?.label).filter((text) => !selecteds.map((item) => item).includes(text))
  const sortAndfilter = (array, text) => array.sort().filter((item) => item.toString().toLowerCase().match(text.toString().toLocaleLowerCase()))
  const sortAndRemove = useCallback(
    (array, selecteds, text) => {
      return sortAndfilter(removeSelecteds(array, selecteds), text).map((item) => {
        return {
          label: item,
          value: item
        }
      })
    },
    [],
  )

  const memoFilter = useMemo(() => sortAndRemove(states, selectedCustomers, textInput), [selectedCustomers, sortAndRemove, states, textInput])
 

  return (
    <>
      <InputWithLabel label="State*">
        {(hasValue) => (
          <DrawerItem
            style={{ marginBottom: 0 }}
            name="singleState"
            rules={[{ required: false }]}
          >
            <ControlInput hasValue={hasValue}>
              {({ value, onChange }) => (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Select
                    ref={selectRef}
                    allowClear
                    showSearch
                    size="large"
                    onSearch={searching}
                    options={memoFilter}
                    defaultActiveFirstOption={false}
                    notFoundContent={null}
                    value={value?.value}
                    onChange={(_, option) => {
                      onChange(option)
                      setopenSelect(false)
                      setSelectedCustomer(option)
                    }}
                    autoFocus={false}
                    onPressEnter={() => addCustomer()}
                    onInputKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        addCustomer()
                        setopenSelect(false)
                        if (selectRef.current) {
                          selectRef.current.blur()
                        }
                      }
                    }}
                    onFocus={() => {
                      setopenSelect(true)
                    }}
                    open={openSelect}
                    filterOption={false}
                  />
                  <BsPlusSquareFill
                    style={{
                      fontSize: '18px',
                      color: colors.light_blue.default,
                      marginTop: 12,
                      marginLeft: 18,
                      cursor: 'pointer',
                    }}
                    onClick={() => addCustomer()}
                  />
                </div>
              )}
            </ControlInput>
          </DrawerItem>
        )}
      </InputWithLabel>
      {selectedCustomers.length > 0 && (
        <div style={{ marginBottom: "10px" }}>
          <TitleDrawer title="States assigned" />

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 16,
              flexWrap: 'wrap',
            }}
          >
            {items}
          </div>
        </div>
      )}
    </>
  )
}
