import React from 'react'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import ButtonPrimary from 'components/Button/ButtonPrimary'
import Typography from 'antd/lib/typography'
import { MdDelete } from 'react-icons/md'
import StyleModalDelete from './StyleModalDelete'

const { Text } = Typography

const styleTitles = {
  display: 'flex',
  alignItems: 'flex-start',
  fontWeight: 700,
  textAlign: 'center',
  fontSize: '17px',
  padding: '0px 10px',
  marginTop: '3px',
}

export default function ModalDeleteRules({
  close,
  visible = false,
  ruleSets = [],
  multiSteps = [],
  componentBody = () => '',
  onClick = () => {},
  fetching = false,
}) {
  const returnBody = () => {
    if (typeof componentBody === 'function' && React.isValidElement(componentBody())) {
      return componentBody()
    }
    return ''
  }

  const titleMultiStep = () => {
    if (multiSteps.length > 0) {
      return <Col style={styleTitles}>Multi-Step</Col>
    }
    return ''
  }
  const titleRuleSets = () => {
    if (ruleSets.length > 0) {
      return <Col style={styleTitles}>RuleSets</Col>
    }
    return ''
  }
  return (
    <StyleModalDelete transitionName="" footer={null} visible={visible} width={580} centered>
      <Row style={{ padding: '37px 18px 10px 18px' }}>
        <Col
          span={24}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '50px',
              height: '50px',
              backgroundColor: '#3594FF',
              borderRadius: '8px',
            }}
          >
            <MdDelete fontSize="35px" style={{ color: '#FFFFFF' }} />
          </div>
        </Col>
        <Col
          span={24}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#545454',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%', 
            }}
          >
            {returnBody()}
          </div>
        </Col>
        {titleMultiStep()}
        {multiSteps.map((rule) => (
          <Col
            key={rule}
            span={24}
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              fontWeight: '400',
              textAlign: 'center',
              fontSize: '16px',
              padding: '0px 10px',
            }}
          >
            <Text style={{ color: '#3594FF' }}>{rule}</Text>
          </Col>
        ))}
        {titleRuleSets()}
        {ruleSets.map((r) => (
          <Col
            key={r}
            span={24}
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              fontWeight: '400',
              textAlign: 'center',
              fontSize: '16px',
              padding: '0px 10px',
            }}
          >
            <Text style={{ color: '#3594FF' }}>{r}</Text>
          </Col>
        ))}
        <Col
          span={24}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingRight: '0px',
            paddingLeft: '0px',
            margin: '10px',
          }}
        >
          <ButtonPrimary
            onClick={onClick}
            loading={fetching}
            style={{ width: '100%', height: '40px', borderRadius: '4px' }}
          >
            Delete
          </ButtonPrimary>
        </Col>
        <Col
          span={24}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingRight: '0px',
            paddingLeft: '0px',
            margin: '10px',
          }}
        >
          <ButtonPrimary
            onClick={close}
            style={{
              width: '100%',
              height: '40px',
              borderRadius: '4px',
              color: '#3594FF',
              backgroundColor: '#FFFFFF',
            }}
          >
            Cancel
          </ButtonPrimary>
        </Col>
      </Row>
    </StyleModalDelete>
  )
}
