import { useEffect, useMemo, useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { REFERENCE_STATE, REFERENCE_ZIP, SHIPPING_INBOUND, SHIPPING_OUTBOUND } from 'utils/constants'
import { clearGetGeographicReference, requestGetGeographicReference } from 'store/duck/getGeographicReference.duck'
import { clearGetShippingType, requestGetShippingType } from 'store/duck/getShippingType.duck'
import { requestGetRuleType } from 'store/duck/getRuleType.duck'
import { requestGetTruckTypeList } from 'store/duck/getTruckTypeList.duck'

const useGetRulesIds = () => {
  const dispatch = useDispatch()

  const { typesId } = useSelector((state) => state.getRuleType)
  // const { trucktypes, success: successTruckType } = useSelector((state) => state.getTruckTypeList)
  const { data: shippingInfo, success: successShipping } = useSelector((state) => state.getShippingType)
  const { data: referenceInfo, success: successReference } = useSelector((state) => state.getGeographicReference)

  const [shippingRule, setShippingRule] = useState({ inShipping: '', outShipping: '' })
  const [referenceRule, setReferenceRule] = useState({ referenceState: '', referenceZip: '', page: 1, limit: 20 })

  const queryParams = useMemo(
    () => ({
      geographic: {
        inState: {
          typeRuleId: typesId.typeGeographic,
          typeShippingId: shippingRule.inShipping,
          typeReferenceGeographicId: referenceRule.referenceState,
        },
        inZip: {
          typeRuleId: typesId.typeGeographic,
          typeShippingId: shippingRule.inShipping,
          typeReferenceGeographicId: referenceRule.referenceZip,
        },
        outState: {
          typeRuleId: typesId.typeGeographic,
          typeShippingId: shippingRule.outShipping,
          typeReferenceGeographicId: referenceRule.referenceState,
        },
        outZip: {
          typeRuleId: typesId.typeGeographic,
          typeShippingId: shippingRule.outShipping,
          typeReferenceGeographicId: referenceRule.referenceZip,
        },
        inRegion: {
          typeRuleId: typesId.typeRegion,
          typeShippingId: shippingRule.inShipping,
        },
        outRegion: {
          typeRuleId: typesId.typeRegion,
          typeShippingId: shippingRule.outShipping,
        },
      },
      length: {
        typeRuleId: typesId.typeLength,
      },
      simple: {
        inbound: {
          typeRuleId: typesId.typeSimple,
          typeShippingId: shippingRule.inShipping,
        },
        outbound: {
          typeRuleId: typesId.typeSimple,
          typeShippingId: shippingRule.outShipping,
        },
      },
      dateRange: {
        inbound: {
          typeRuleId: typesId.typeDate,
          typeShippingId: shippingRule.inShipping,
        },
        outbound: {
          typeRuleId: typesId.typeDate,
          typeShippingId: shippingRule.outShipping,
        },
      },
      equipment: { typeRuleId: typesId.typeEquipment },
      market: {
        inbound: {
          typeRuleId: typesId.typeMarket,
          typeShippingId: shippingRule.inShipping,
        },
        outbound: {
          typeRuleId: typesId.typeMarket,
          typeShippingId: shippingRule.outShipping,
        },
      },
      laneScore: {
        typeRuleId: typesId.typeLaneScore,
      },
      leadTime: {
        typeRuleId: typesId.typeLeadTime,
      },
      region: {
        inRegion: {
          typeRuleId: typesId.typeRegion,
          typeShippingId: shippingRule.inShipping,
        },
        outRegion: {
          typeRuleId: typesId.typeRegion,
          typeShippingId: shippingRule.outShipping,
        },
      },
      analytics: {
        laneScore: {
          typeRuleId: typesId.typeLaneScore, 
        },
        outMCI: {
          typeRuleId: typesId.typeMci,
          typeShippingId: shippingRule.outShipping, 
        },
        inMCI: {
          typeRuleId: typesId.typeMci,
          typeShippingId: shippingRule.inShipping, 
        },
        outLT:{
          typeRuleId: typesId.typeLtRatio,
          typeShippingId: shippingRule.outShipping, 
        },
        inLT:{
          typeRuleId: typesId.typeLtRatio,
          typeShippingId: shippingRule.inShipping, 
        }     
      },
    }),
    [
      referenceRule.referenceState,
      referenceRule.referenceZip,
      shippingRule.inShipping,
      shippingRule.outShipping,
      typesId.typeDate,
      typesId.typeGeographic,
      typesId.typeLength,
      typesId.typeSimple,
      typesId.typeEquipment,
      typesId.typeMarket,
      typesId.typeLaneScore,
      typesId.typeLeadTime,
      typesId.typeRegion, 
      typesId.typeMci, 
      typesId.typeLtRatio,     
    ]
  )

  useEffect(() => {
    if (successReference) {
      const tmp = { ...referenceRule }
      referenceInfo.map((item) => {
        if (item.name === REFERENCE_STATE) tmp.referenceState = item._id
        if (item.name === REFERENCE_ZIP) tmp.referenceZip = item._id
        return tmp
      })
      dispatch(clearGetGeographicReference())
      setReferenceRule(tmp)
    }
    if (successShipping) {
      const tmp = { ...shippingRule }
      shippingInfo.map((item) => {
        if (item.name === SHIPPING_INBOUND) tmp.inShipping = item._id
        if (item.name === SHIPPING_OUTBOUND) tmp.outShipping = item._id
        return tmp
      })
      dispatch(clearGetShippingType())
      setShippingRule(tmp)
    }
  }, [dispatch, shippingRule, successShipping, referenceRule, shippingInfo, successReference, referenceInfo])

  useEffect(() => {
    batch(() => {
      dispatch(requestGetRuleType())
      dispatch(requestGetGeographicReference())
      dispatch(requestGetShippingType())
      dispatch(requestGetTruckTypeList())
    })
  }, [dispatch])

  return {
    shippingRule,
    referenceRule,
    typesId,
    queryParams,
  }
}

export default useGetRulesIds
