import React from 'react'
import Typography from 'antd/lib/typography'
import DateContainer from './styles'

const { Text } = Typography

const AuthTitle = ({ date, time }) => {
  return (
    <DateContainer>
      <Text>{date}</Text>
      <Text>{time}</Text>
    </DateContainer>
  )
}

export default AuthTitle
