const colors = [
    '#002555',
    '#3594FF',
    '#8C54FF',
    '#F6A15B',
    '#F44D6C',
    '#56B158',
    '#d73027',
    '#542788',
    '#545454',
    '#EFF3FF',
    '#EAFFFD',
    '#FEF4F5',
    '#FFFCE2',
    '#FFFFFF',
    '#cfe5ff',
]

export default colors