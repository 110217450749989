import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: {},
}

export const {
  requestUpdateRuleset,
  successUpdateRuleset,
  failureUpdateRuleset,
  clearUpdateRuleset,
} = createActions({
  REQUEST_UPDATE_RULESET: (data) => ({
    ...defaultState,
    fetching: true,
    success: false,
    data,
  }),
  SUCCESS_UPDATE_RULESET: (data, message) => ({
    data,
    message: message ?? '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_UPDATE_RULESET: (message) => ({
    message: message ?? '',
    fetching: false,
    error: true,
  }),
  CLEAR_UPDATE_RULESET: () => ({
    ...defaultState,
  }),
})

const updateRuleset = handleActions(
  {
    [requestUpdateRuleset]: produce((draft, { payload: { message, fetching, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = false
      draft.data = data
    }),
    [successUpdateRuleset]: produce((draft, { payload: { message, fetching, success, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.data = data
    }),
    [failureUpdateRuleset]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
    }),
    [clearUpdateRuleset]: produce((draft, { payload: { message, fetching, error, success } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
  },
  defaultState
)

export default updateRuleset
