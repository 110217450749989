import moment from 'moment'
import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: {},
  wayPoints: [],
  finished: false,
  finalRate: 0,
  totalMilles: 0,
}

export const {
  requestSaveQuote,
  successSaveQuote,
  failureSaveQuote,
  saveLane,
  saveRate,
  savePricingLogs,
  saveCustomerRate,
  saveTotalMilles,
  addWayPoint,
  removeWayPoint,
  loadWayPoints,
  clearRate,
} = createActions({
  REQUEST_SAVE_QUOTE: (data) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    data,
  }),
  SUCCESS_SAVE_QUOTE: (data) => ({
    data,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_SAVE_QUOTE: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  SAVE_LANE: (data) => ({
    data,
  }),
  SAVE_RATE: (data, services, customerId) => ({
    data,
    services,
    customerId,
  }),
  SAVE_PRICING_LOGS: (pricingLogs) => ({
    pricingLogs,
  }),
  SAVE_CUSTOMER_RATE: (flat, margin, finalRate) => ({
    flat,
    margin,
    finalRate,
    finished: true,
  }),
  SAVE_TOTAL_MILLES: (totalMilles, totalStops, stops) => ({
    totalMilles,
    totalStops,
    stops,
  }),
  ADD_WAY_POINT: (data) => ({
    wayPoint: data,
  }),
  REMOVE_WAY_POINT: (wayPoints) => ({
    wayPoints,
  }),
  LOAD_WAY_POINTS: (wayPoints) => ({
    wayPoints,
  }),
  CLEAR_RATE: () => ({
    message: '',
    fetching: false,
    error: false,
    success: false,
    data: {},
    finished: false,
    wayPoints: [],
  }),
})

const saveQuote = handleActions(
  {
    [requestSaveQuote]: produce((draft, { payload: { fetching, data, error, success } }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successSaveQuote]: produce((draft, { payload: { message, fetching, success, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = false
      draft.data = data
    }),
    [failureSaveQuote]: produce((draft, { payload: { message, fetching } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = true
      draft.success = false
    }),
    [saveLane]: produce((draft, { payload: { data } }) => {
      draft.data = { ...draft.data, lane: { ...draft.data.lane, ...data } }
    }),
    [saveRate]: produce((draft, { payload: { data, services, customerId } }) => {
      draft.data = {
        ...draft.data,
        customerName: data.customer,
        customer: customerId,
        pickupDate: moment(data.pickup._d).format('YYYY/MM/DD'),
        deliveryDate: moment(data.delivery._d).format('YYYY/MM/DD'),
        weight: parseInt(data.weight, 10),
        ...(services.length > 0 && { specialServices: [...services] }),
        truckTypeId: data.truckTypeId,
      }
    }),
    [savePricingLogs]: produce((draft, { payload: { pricingLogs } }) => {
      draft.data = {
        ...draft.data,
        pricingLogsId: pricingLogs,
      }
    }),
    [saveCustomerRate]: produce((draft, { payload: { flat, margin, finished, finalRate } }) => {
      draft.data = {
        ...draft.data,
        customerRate: { margin, flat },
        rate: finalRate,
      }
      draft.finished = finished
    }),
    [saveTotalMilles]: produce((draft, { payload: { totalMilles, totalStops, stops } }) => {
      draft.data = {
        ...draft.data,
        totalMilles,
        totalStops,
        stops,
      }
      draft.totalMilles = totalMilles
    }),
    [addWayPoint]: produce((draft, { payload: { wayPoint } }) => {
      draft.wayPoints = [...draft.wayPoints, wayPoint]
    }),
    [removeWayPoint]: produce((draft, { payload: { wayPoints } }) => {
      draft.wayPoints = wayPoints
    }),
    [loadWayPoints]: produce((draft, { payload: { wayPoints } }) => {
      draft.wayPoints = wayPoints
    }),
    [clearRate]: produce((draft, { payload: { data, message, fetching, error, success, wayPoints } }) => {
      draft.data = data
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
      draft.finished = false
      draft.wayPoints = wayPoints
    }),
  },
  defaultState
)

export default saveQuote
