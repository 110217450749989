import React, { useEffect, useState, useRef } from 'react'
import Grid from 'antd/lib/grid'
import { OpenInFull } from 'assets/svg'
import { useSelector } from 'react-redux'
import MapContainer from './styles'
import { ModalMap, ButtonFullScreen } from './fullScreenStyles'

const { useBreakpoint } = Grid

const RouteMap = ({ center, zoom, markers, withBubble, mapParams, success, closeFullScren = '' }) => {
  const { polilyne } = useSelector((state) => state.getRate)
  const [map, setMap] = useState(null)
  const [ui, setUi] = useState(null)
  const [drawed, setDrawed] = useState(false)
  const mapElement = useRef(null)

  const screen = useBreakpoint()

  useEffect(() => {
    const platform = new window.H.service.Platform({
      apikey: process.env.REACT_APP_HERE_MAPS_KEY,
    })

    const defaultLayers = platform.createDefaultLayers()

    if (mapElement.current && !map) {
      const _map = new window.H.Map(mapElement.current, defaultLayers.vector.normal.map, {
        zoom,
        center,
        pixelRatio: window.devicePixelRatio || 1,
        padding: { top: 50, left: 50, bottom: 50, right: 50 },
      })
      setMap(_map)
      const _behavior = new window.H.mapevents.Behavior(new window.H.mapevents.MapEvents(_map))
      _behavior.disable(window.H.mapevents.Behavior.WHEELZOOM)
      const _ui = window.H.ui.UI.createDefault(_map, defaultLayers)
      setUi(_ui)
    }

    const clearPointer = () => map && map.removeObjects(map.getObjects())
    const addCustomIcon = (icon) => icon && new window.H.map.Icon(icon)
    const createMarker = ({ icon = null, coordinate }) => {
      const marker = new window.H.map.Marker(coordinate, {
        volatility: true,
        icon: addCustomIcon(icon),
      })
      return marker
    }
    const getBounds = (group) =>
      map &&
      map.getViewModel().setLookAtData({
        bounds: group.getBoundingBox(),
      })

    const addBubbles = (locations) => {
      const bubblesGroup = new window.H.map.Group() 
      const pointers = locations.map(({ content, ...rest }) => {
        const marker = createMarker(rest)
        const group = new window.H.map.Group()
        let markerContent = ''
        markerContent = `<div>
          <p style{{ margin: '0px' }}>${content?.city}</p>
          <p>${content?.zipCode}</p>
        </div>`
        if (withBubble) {
          group.addEventListener(
            'tap',
            (e) => {
              const bubble = new window.H.ui.InfoBubble(e.target.getGeometry(), { content: markerContent })
              ui.addBubble(bubble)
            },
            false
          )
        }
        group.addObject(marker)
        return group
      })

      // const pointers = polilyne.locations.map(({ content, ...rest }) => {
      //   const marker = createMarker(rest)
      //   const group = new window.H.map.Group()
      //   let markerContent = ''
      //   markerContent = `<div>
      //     <p style{{ margin: '0px' }}>${content?.city && ""}</p>
      //     <p>${content?.zipCode && ""}</p>
      //   </div>`
      //   if (withBubble) {
      //     group.addEventListener(
      //       'tap',
      //       (e) => {
      //         const bubble = new window.H.ui.InfoBubble(e.target.getGeometry(), { content: markerContent })
      //         ui.addBubble(bubble)
      //       },
      //       false
      //     )
      //   }
      //   group.addObject(marker)
      //   return group
      // })
      bubblesGroup.addObjects([...pointers])
      map.addObject(bubblesGroup)
      if (markers.length > 0) {
        getBounds(bubblesGroup)
      }
    }
    const drawRoute = () => {
      if (polilyne?.poli) {
        if (polilyne?.poli) {
          if (polilyne?.poli.length > 0) {
            const lineString = new window.H.geo.LineString()
            polilyne.poli.forEach((point) => {
              lineString.pushPoint({ lat: point[0], lng: point[1] })
            })

            const polyline = new window.H.map.Polyline(lineString, {
              style: {
                lineWidth: 4,
                strokeColor: '#005FB5',
              },
            })

            map.addObject(polyline)
            map.getViewModel().setLookAtData({
              bound: polyline.getBoundingBox(),
            })
          }
        }
      }
    }

    if (!drawed && map && markers.length > 0 && Object.keys(mapParams).length > 0) {
      clearPointer()
      addBubbles(markers)
      drawRoute()
      setDrawed(true)
    }
  }, [map, markers, ui, withBubble, zoom, center, mapParams, drawed, polilyne.poli])

  useEffect(() => {
    if (!success) setDrawed(false)
  }, [success])

  const [visible, setvisible] = useState(false)
 
  const actionModal = () => {
    if (closeFullScren === '') {
      setvisible(true)
    } else {
      closeFullScren()
    }
  }

  return (
    <MapContainer ref={mapElement} breakpoint={!!screen.xxl}>
      <ModalMap style={{ top: 0 }} visible={visible} onCancel={() => setvisible(false)} width="100%" height="95vh">
        <RouteMap
          center={center}
          zoom={zoom}
          markers={markers}
          withBubble={withBubble}
          mapParams={mapParams}
          success={success}
          closeFullScren={() => setvisible(false)}
        />
      </ModalMap>
      <ButtonFullScreen breakpoint={!!screen.xxl} onClick={actionModal}>
        <img alt="" style={{ width: '12px', height: '12px' }} src={OpenInFull} />
      </ButtonFullScreen>
    </MapContainer>
  )
}

export default RouteMap
