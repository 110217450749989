import React from 'react'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import { FaPlus } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { requestUpdateRuleset } from 'store/duck/updateRuleset.duck'
import TextPrimary from 'components/Text/TextPrimary'
import { CardInputLarge } from 'components/Card/CardInput'
import IconDelete from 'components/Icon/DeleteIcon'

const MultistepList = ({ data, rulesetData }) => {
  const dispatch = useDispatch()
  const handleDeleteMultistepInRuleset = () => {
    const initialMultisteps = []
    rulesetData.multisteps.map((item) => {
      initialMultisteps.push(item._id)
      return initialMultisteps
    })
    const udateMultisteps = initialMultisteps.filter((e) => e !== data._id)
    const updateData = {
      id: rulesetData._id,
      multisteps: [...udateMultisteps],
    }
    dispatch(requestUpdateRuleset(updateData))
  }

  return (
    <CardInputLarge>
      <Row gutter={[0, 24]}>
        <Col span={22}>{data.multistepName}</Col>
        <Col span={1} offset={1}>
          <IconDelete onClick={handleDeleteMultistepInRuleset} />
        </Col>
      </Row>
      {data.sentences.map((sentence, index) => {
        return (
          <Row key={index}>
            <Col span={1} />
            <Col span={1}>If</Col>
            <Col span={10}>
              <TextPrimary>{sentence.ruleId.name}</TextPrimary>
            </Col>
            <Col span={data.sentences.length === index + 1 ? 3 : 7}>{sentence.condition}</Col>
            {data.sentences.length === index + 1 && (
              <>
                <Col span={1.5}>
                  <TextPrimary>{data.margin} %</TextPrimary>
                </Col>
                <Col span={1}>
                  <FaPlus size="1rem" style={{ color: '#002555', margin: '0.2rem 0 0 0.7rem' }} />
                </Col>
                <Col span={2}>
                  <TextPrimary>{data.flat}</TextPrimary> USD
                </Col>
              </>
            )}
          </Row>
        )
      })}
    </CardInputLarge>
  )
}

export default MultistepList
