import { put, takeLatest } from 'redux-saga/effects'
import { requestSaveStops, successSaveStops, failureSaveStops } from 'store/duck/saveStops.duck'

function* saveStopsAsync(data) {
  try {
    yield put(successSaveStops(data.payload.lane, data.payload.wayPoints))
  } catch (error) {
    yield put(failureSaveStops(error))
  }
}

function* watchSaveStopsAsync() {
  yield takeLatest(requestSaveStops, saveStopsAsync)
}

export default watchSaveStopsAsync
