import React from 'react'
import FloatLabel from 'components/Label/FloatLabel'
import InputMedium from 'components/Input/InputMedium'
import { FormItem, FieldWrapper } from './styles'

const InputEditable = ({ label, name, type }) => {
  return (
    <FieldWrapper>
      <FloatLabel label={label} show>
        <FormItem name={name}>
          <InputMedium type={type} />
        </FormItem>
      </FloatLabel>
    </FieldWrapper>
  )
}

export default InputEditable
