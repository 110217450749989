import React from 'react'
import { useSelector } from 'react-redux'
import Graphics from 'containers/dashboard/Graphics'
import DatalakeTable from 'components/DatalakeTable'
import Tabs from 'components/Tabs'
import CardShadow from 'components/Card/CardShadow'
import colors from 'providers/theme/config/colorPallete'
import GraphMCI from 'containers/rates/GraphMCI'
import RateInformation from 'containers/dashboard/RateInformation'
import { round } from 'lodash'

const DashboardTab = () => {
  const { success, rate, datRate, mciGraphics, greenScreens, graphic, routeMarkers, markers } = useSelector((state) => ({
    success: state.getRate.success,
    rate: state.getRate.rate,
    datRate: state.getRate.rate?.base_rate?.datRate,
    mciGraphics: state.getRate.rate.mciGraphics,
    greenScreens: state.getRate.greenScreens ?? [],
    graphic: state?.getRate?.rate?.graphics ?? [],
    routeMarkers: state?.getZipHere?.routeMarkers ?? [],
    markers: state?.getZipHere?.markers ?? [],
  }))
  const datalakeTable = useSelector((state) => state.getRate.rate.datalakeTable)  

  const { TabPane } = Tabs

  const displayGraph = () => {
    if (mciGraphics) {
      const value = JSON.stringify(mciGraphics)
      return value !== '{}'
    }
    return false
  }

  const displayTable = () => {
    const display = false
    if (datalakeTable) {
      const properties = ['items']
      let size = 0
      properties.forEach((element) => {
        if (datalakeTable[element]) {
          size += datalakeTable[element].length
        }
      })
      if (size > 0) {
        return !display
      }
    }
    if (greenScreens.length > 0) return !display

    return display
  }

  const datRateComponent = (component) => {
    if (!datRate?.datLow) {
      return null
    }
    if (datRate?.datLow?.rate === '$0.00' || datRate?.datContract?.rate === '$0.00') {
      return null
    }
    return component
  }

  const udScore = round(rate?.udScore ?? 0, 2)

  return (
    <CardShadow borderless title="">
      {datRateComponent(
        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <span
            style={{
              color: '#3594ff',
              fontSize: 15,
              fontWeight: 600,
              marginRight: '5px',
            }}
          >
            Origin market:
          </span>
          {datRate?.datExtraInfo?.originGeography}
          <span
            style={{
              color: '#3594ff',
              fontSize: 15,
              fontWeight: 600,
              marginLeft: '8px',
              marginRight: '5px',
            }}
          >
            Destination market:
          </span>
          {datRate?.datExtraInfo?.destinationGeography}
          <span
            style={{
              color: '#3594ff',
              fontSize: 15,
              fontWeight: 600,
              marginLeft: '8px',
              marginRight: '5px',
            }}
          >
            Miles:
          </span>
          {rate?.mileage}
          {
            rate?.udScore ? <span
              style={{
                color: '#3594ff',
                fontSize: 15,
                fontWeight: 600,
                marginLeft: '8px',
                marginRight: '5px',
              }}
            >
              UD score:
            </span> : ""
          }
          {rate?.udScore ? udScore : ""}
        </div>
      )}

      <span
        style={{
          color: colors.dark_blue.default,
          fontSize: 20,
          fontWeight: 700,
        }}
      >
        Historical Data
      </span>

      <Tabs defaultActiveKey="1">
        {graphic.length > 0 ? (<TabPane tab="Graph" key="1">
          {success ? (
            <Graphics />
          ) : (
            <div>
              <p>
                Empty section, please, enter the data to make the quote and you will be able to view the Dashboard
                information.
              </p>
            </div>
          )}
        </TabPane>) : ""}
        {displayTable() && success ? (
          <TabPane tab="Historical Data" key="2" style={{ position: 'relative' }}>
            <DatalakeTable Datalake={rate.datalakeTable} greenscreens={greenScreens.slice(0, 20)} />
          </TabPane>
        ) : (
          ''
        )}
        {displayGraph() && success ? (
          <TabPane tab="Market conditions" key="3" style={{ position: 'relative' }}>
            <GraphMCI data={mciGraphics} />
          </TabPane>
        ) : null}

        {success && markers.length === 2 && routeMarkers.length >= 0 ? (
          <TabPane tab="Route Map" key="4" style={{ position: 'relative' }}>
            <RateInformation />{' '}
          </TabPane>
        ) : (
          ''
        )}
      </Tabs>
    </CardShadow>
  )
}

export default DashboardTab
