import React from 'react'
import { GoCheck } from 'react-icons/go'
import UserNameAvatar from 'components/User/UserNameAvatar'
import { GrFormClose } from 'react-icons/gr'
import { getStatus } from 'utils/getStatus'
import { StyledBadge } from 'containers/rules/ListMultiStep/utils/styles'
import actionsDropdown from './actions'

const Columns = (dispatch) => {
  return [
    {
      title: <div style={{ fontSize: '16px' }}>Full Name</div>,
      dataIndex: 'fullName',
      key: 'name',
      render: (name, record) => {
        return (
          <UserNameAvatar
            name={name}
            avatar={record.avatar}
            lastLoginDate={record.lastLoginDate ?? ''}
            hiddenDate={false}
          />
        )
      },
      width: 300,
    },
    {
      title: <div style={{ fontSize: '16px' }}>Role</div>,
      dataIndex: ['roleId', 'name'],
      key: 'roleId',
      render: (role) => <div style={{ color: '#545454', fontSize: '16px' }}>{role}</div>,
      width: 100,
    },
    {
      title: <div style={{ fontSize: '16px' }}>Email</div>,
      dataIndex: 'email',
      key: 'email',
      render: (email) => <div style={{ fontSize: '16px' }}>{email}</div>,
      width: 200,
    },
    {
      title: <div style={{ fontSize: '16px' }}>Phone</div>,
      dataIndex: 'phone',
      key: 'phone',
      render: (phone) => <div style={{ fontSize: '16px' }}>{phone}</div>,
      width: 180,
    },

    {
      title: <div style={{ fontSize: '16px' }}>DAT</div>,
      dataIndex: ['servicesErrors', 'DAT'],
      key: 'DAT',
      render: (username) =>
        username ?  <div style={{ display: 'flex' }}><GrFormClose color="#002555" size="1.8rem" /></div>  :  <div style={{ display: 'flex' }}><GoCheck color="#002555" size="1.3rem" /></div>,
      width: 100,
    }, 
    {
      title: <div style={{ fontSize: '16px' }}>SONAR</div>,
      dataIndex: ['servicesErrors', 'SONAR'],
      key: 'SONAR',
      render: (username) =>
        username ?  <div style={{ display: 'flex' }}> <GrFormClose color="#002555" size="1.8rem" /> </div>: <div style={{ display: 'flex' }}><GoCheck color="#002555" size="1.3rem" /></div> ,
      width: 100,
    },
    {
      title: <div style={{ fontSize: '16px' }}>DAT request</div>, 
      dataIndex: 'datRequests',
      key: 'datRequests',
      render: (datRequests) => <div style={{ fontSize: '16px' }}>{datRequests}</div>,
      width: 100,
    },
    {
      title: <div style={{ fontSize: '16px' }}>Status</div>,
      dataIndex: 'active',
      key: 'status',
      render: (active, rate) => {
        return  <div style={{ display: 'flex' }}><StyledBadge variant={getStatus(rate.status, active)} /></div>
      },
      width: 100,
    },
    {
      ...actionsDropdown(dispatch),
    },
  ]
}

export default Columns
