import { put, takeLatest, call } from 'redux-saga/effects'
import { settings } from 'services'
import {
  requestAssignTrustPercentage,
  successAssignTrustPercentage,
  failureAssignTrustPercentage,
} from 'store/duck/assignTrustPercentage.duck'

function* assignTrustPercentageRequestAsync(data) {
  try {
    const response = yield call(settings.assignTrustPercentage, data.payload.data)
    yield put(successAssignTrustPercentage(response, 'success'))
  } catch (error) {
    yield put(failureAssignTrustPercentage(error.response.data.message))
  }
}

function* watchAssignTrustPercentageAsync() {
  yield takeLatest(requestAssignTrustPercentage, assignTrustPercentageRequestAsync)
}

export default watchAssignTrustPercentageAsync
