import styled from 'styled-components'
import Form from 'antd/lib/form' 

const StyledItem = styled(Form.Item)`
  width: 100%;
  padding: 0px; 
  .ant-form-item-label {
    padding: 0px;
    margin: 0px;
  }
  .ant-form-item-label > label {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: rgb(110, 127, 141);
  }

  .ant-input-affix-wrapper {
    border-radius: 4px;
  }

  .ant-select-selector {
    border-radius: 4px !important;
  }
  .ant-select-arrow {
    color: #002555;
    top: 34%;
    margin-right: 15px;
  }
`

export default StyledItem
