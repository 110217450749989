import React from 'react'
import Table from 'components/Table'
import { useSelector } from 'react-redux'

const columns = () => {
  const styleLastColumn = (totals) => (totals?.user === 'Total' ? { fontWeight: 'bold' } : {})
  return [
    {
      title: <div style={{ fontSize: '16px' }}>User</div>,
      dataIndex: 'customer',
      key: 'user',
      render: (customer, totals) => {
        return (
          <div style={styleLastColumn(totals)}>
            {customer} {}
          </div>
        )
      },
      align: 'left',
    },
    {
      title: <div style={{ fontSize: '16px' }}>Rates run</div>,
      dataIndex: 'ratesRun',
      key: 'ratesRun',
      render: (ratesRun, totals) => {
        return <div style={styleLastColumn(totals)}>{ratesRun}</div>
      },
      align: 'left',
    },
  ]
}

export default function TableReporting() {
  const { data, fetching } = useSelector((state) => state.getTotalStatistics)
  const total = {
    customer: 'Total',
    ratesRun: 0,
    datSpotCalls: 0,
    datGraphCalls: 0,
    ...data?.total,
  }

  const getData = () => {
    let allData = []
    if (data?.data) {
      allData = [...data?.data]
    }
    allData = [...allData, total]
    return allData
  }

  return (
    <Table
      rowKey={(record) => record.user}
      width="100%"
      loading={fetching}
      heightContainer="auto"
      paddingBottom="0px"
      margin="0px"
      pagination={false}
      updatedPagination={false}
      showBulkActions={false}
      data={getData()}
      columns={columns()}
    />
  )
}
