import React from 'react'
import TitleList from 'components/TitleList'
import Tabs from 'antd/lib/tabs'
import StyledTabs from 'components/Tabs'
import { useHistory } from 'react-router-dom'

export default function TabsLane({
  componentOne = '',
  componetTwo = '',
  componetThree = '',
}) {
  const history = useHistory()
  const handleTabClick = (key) => {
    history.push(`/${key}`)
  }

  return (
    <>
      <div style={{ margin: 'auto', marginTop: '30px', width: '96%' }}>
        <TitleList title="Lane" />
      </div>
      <StyledTabs
        defaultActiveKey="history"
        onChange={handleTabClick}
        tabBarStyle={{
          width: '96%',
          margin: '0px 0px 0px 35px',
          color: '#002555',
          fontWeight: 600,
          marginBottom: 15,
        }}
        destroyInactiveTabPane
      >
        <Tabs.TabPane tab="History" key="lane/hist-lanes">
          {componentOne}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Reporting" key="lane/reports">
          {componetTwo}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Tableau" key="lane/tableau">
          {componetThree}
        </Tabs.TabPane>
      </StyledTabs>
    </>
  )
}
