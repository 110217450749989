import styled from 'styled-components'

const RouteMiles = styled.div`
  .strong {
    color: #3594ff;
    font-weight: 700;
    font-size: 20px;
    @media (min-width: 1600px) {
      font-size: 16px;
    }
    @media (min-width: 1768px) {
      font-size: 18px;
    }
  }
  color: #545454;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin-left: 25px;
  @media (min-width: 1600px) {
    font-size: 10px;
  }
  @media (min-width: 1768px) {
    font-size: 12px;
  }
`

export default RouteMiles
