import { put, takeLatest, call } from 'redux-saga/effects'
import { specialServices } from 'services'
import {
  requestUpdateSpecialService,
  successUpdateSpecialService,
  failureUpdateSpecialService,
} from '../duck/updateSpecialService.duck'

function* updateSpecialServiceAsync(data) {
  try {
    const response = yield call(specialServices.updateSpecialService, data.payload.data)
    yield put(successUpdateSpecialService(response))
  } catch (error) {
    yield put(failureUpdateSpecialService(error.response.data.message))
  }
}

function* watchUpdateSpecialServiceAsync() {
  yield takeLatest(requestUpdateSpecialService, updateSpecialServiceAsync)
}

export default watchUpdateSpecialServiceAsync
