import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  successDelete: false,
  data: {},
}

export const {
  requestDeleteMultiStep,
  successDeleteMultiStep,
  failureDeleteMultiStep,
  clearDeleteMultiStep,
} = createActions({
  REQUEST_DELETE_MULTI_STEP: (data) => ({
    ...defaultState,
    fetching: true,
    data,
  }),
  SUCCESS_DELETE_MULTI_STEP: (data, message) => ({
    data,
    message,
    fetching: false,
    error: false,
    successDelete: true,
  }),
  FAILURE_DELETE_MULTI_STEP: (message) => ({
    message,
    fetching: false,
    error: true,
    successDelete: false,
  }),
  CLEAR_DELETE_MULTI_STEP: () => ({
    successDelete: false,
    error: false,
    data: {},
    message: '',
    fetching: false,
  }),
})

const deleteMultiStep = handleActions(
  {
    [requestDeleteMultiStep]: produce((draft, { payload: { message, fetching, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.data = data
    }),
    [successDeleteMultiStep]: produce((draft, { payload: { message, fetching, successDelete, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.successDelete = successDelete
      draft.data = data
    }),
    [failureDeleteMultiStep]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
    }),
    [clearDeleteMultiStep]: produce((draft, { payload: { message, successDelete, fetching, error, data } }) => {
      draft.message = message
      draft.successDelete = successDelete
      draft.fetching = fetching
      draft.error = error
      draft.data = data
    }),
  },
  defaultState
)

export default deleteMultiStep
