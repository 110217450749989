import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: {},
}

export const {
  requestGetRulesetById,
  successGetRulesetById,
  failureGetRulesetById,
  clearGetRulesetById,
} = createActions({
  REQUEST_GET_RULESET_BY_ID: (id) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    id,
  }),
  SUCCESS_GET_RULESET_BY_ID: (data) => ({
    data,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_RULESET_BY_ID: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  CLEAR_GET_RULESET_BY_ID: () => ({
    ...defaultState,
  }),
})

const getRulesetById = handleActions(
  {
    [requestGetRulesetById]: produce((draft, { payload: { fetching, data, error, success } }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successGetRulesetById]: produce((draft, { payload: { message, fetching, success, data, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data
    }),
    [failureGetRulesetById]: produce((draft, { payload: { message, fetching, error, success } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [clearGetRulesetById]: produce((draft, { payload: { message, fetching, success, data, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data
    }),
  },
  defaultState
)

export default getRulesetById
