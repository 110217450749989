import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: {},
  editTrustPercentage: false,
  trustPercentageSelected: {},
}

export const {
  requestAssignTrustPercentage,
  successAssignTrustPercentage,
  failureAssignTrustPercentage,
  clearCreateTrustPercentage,
  loadTrustPercentage,
  addTrustPercentage,
} = createActions({
  REQUEST_ASSIGN_TRUST_PERCENTAGE: (data) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    data,
  }),
  SUCCESS_ASSIGN_TRUST_PERCENTAGE: (data) => ({
    data,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_ASSIGN_TRUST_PERCENTAGE: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  CLEAR_CREATE_TRUST_PERCENTAGE: () => ({
    ...defaultState,
  }),
  LOAD_TRUST_PERCENTAGE: (data) => ({
    ...defaultState,
    editTrustPercentage: true,
    trustPercentageSelected: data,
  }),
  ADD_TRUST_PERCENTAGE: () => ({
    ...defaultState,
    editTrustPercentage: false,
  }),
})

const assignTrustPercentage = handleActions(
  {
    [requestAssignTrustPercentage]: produce((draft, { payload: { fetching, data, error, success } }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successAssignTrustPercentage]: produce((draft, { payload: { message, fetching, success, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = false
      draft.data = data
    }),
    [failureAssignTrustPercentage]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = false
    }),
    [clearCreateTrustPercentage]: produce((draft, { payload: { message, fetching, error, success, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
      draft.data = data
    }),
    [loadTrustPercentage]: produce((draft, { payload: { editTrustPercentage, trustPercentageSelected } }) => {
      draft.editTrustPercentage = editTrustPercentage
      draft.trustPercentageSelected = trustPercentageSelected
    }),
    [addTrustPercentage]: produce((draft, { payload: { editTrustPercentage } }) => {
      draft.editTrustPercentage = editTrustPercentage
    }),
  },
  defaultState
)

export default assignTrustPercentage
