import React, { useEffect, useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Col from 'antd/lib/col'
import Graph from 'containers/rates/Graph'
import ColorLegendSmall from 'components/ColorLegend'
import { setGraphics, filterGraphics, requestGetGsRate } from 'store/duck/getRate.duck'
import moment from 'moment'
import Select from 'antd/lib/select'
import { getMaxGraphing, getMinGraphing } from 'utils/get'
import { ContainerGraphics, HeaderGraph } from './styles'

const colors = ['#002555', '#3594FF', '#8C54FF', '#F6A15B', '#F44D6C', '#56B158', '#d73027', '#542788']

const Graphics = () => {
  const dispatch = useDispatch()
  const { filterParams, success, graphic, rateBasic, quote, greenScreens, gsSuccess } = useSelector((state) => ({
    filterParams: state.getRate.filter,
    success: state.getRate.success,
    graphic: state.getRate.graphics ?? [],
    rateBasic: state.getRate.rate,
    quote: state.saveQuote.data,
    greenScreens: state.getRate.greenScreens,
    gsSuccess: state.getRate.gsSuccess,
  }))

  const { lane, truckTypeId, customer, pickupDate } = quote || {}
  const { base_rate: baseRate } = rateBasic || {}
  const averageFuelSurchargeRateDat = baseRate?.datRate?.datExtraInfo?.averageFuelSurchargeRateDat
  const averageFuelSurchargeRateDatContract = baseRate?.datRate?.datExtraInfo?.averageFuelSurchargeRateDatContract
  const mileage = rateBasic?.mileage
  const params = useMemo(
    () => ({
      lane,
      truckTypeId,
      customer,
      pickupDate,
      averageFuelSurchargeRateDat,
      averageFuelSurchargeRateDatContract,
      mileage,
    }),
    [
      lane,
      truckTypeId,
      customer,
      pickupDate,
      averageFuelSurchargeRateDat,
      averageFuelSurchargeRateDatContract,
      mileage,
    ]
  )

  const isMontly = filterParams?.month < 6

  useEffect(() => {
    if (success || gsSuccess) dispatch(setGraphics())
  }, [dispatch, filterParams, success, gsSuccess])

  const getIdsGraph = graphic.map((item) => item.id)

  const max = graphic.length > 0 ? getMaxGraphing(graphic) + 100 : 100
  const min = graphic.length > 0 ? getMinGraphing(graphic) - 100 : 0

  const styleById = {
    Dat: {
      strokeWidth: 1,
    },
    default: {
      strokeWidth: 0,
    },
  }
  const Dots = ({ series, lineGenerator, xScale, yScale }) => {
    return series.map(({ id, data, color }) => (
      <path
        key={id}
        d={lineGenerator(
          data.map((d) => ({
            x: xScale(d.data.x),
            y: yScale(d.data.y),
          }))
        )}
        fill="none"
        stroke={color}
        style={styleById[id] || styleById.default}
      />
    ))
  }
  const [month, setMonths] = useState('6')

  useEffect(() => {
    if (month === '12' && !gsSuccess) {
      dispatch(requestGetGsRate(params))
    } 
  }, [month, dispatch, params, gsSuccess])


  return (
    <ContainerGraphics>
      {graphic.length > 0 && (
        <HeaderGraph>
          <span style={{ fontSize: '16px', fontWeight: 700, color: '#002555' }}>Lane History</span>
          <Col span={6}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <span style={{ marginRight: '5px' }}>Time frame: </span>
              <Select
                defaultValue={month}
                onSelect={(e) => {
                  setMonths(e)
                  dispatch(filterGraphics({ month: e }))
                }}
              >
                <Select.Option value="1">Last Month</Select.Option>
                <Select.Option value="6">Last 6 months</Select.Option>
                <Select.Option value="12">Last 12 months</Select.Option>
              </Select>
            </div>
          </Col>
        </HeaderGraph>
      )}
      <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
        <Col>
          {success && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ fontSize: '16px', fontWeight: 700, color: '#002555' }}>Sources</span>
              <ColorLegendSmall colors={colors} data={graphic} />
            </div>
          )}
        </Col>
        <Graph
          width={1050}
          data={graphic}
          success={success}
          margin={{ top: 10, right: 15, bottom: 40, left: 50 }}
          xScale={{
            precision: 'day',
            type: 'time',
            format: '%Y-%m-%d',
            useUTC: false,
          }}
          axisBottom={{
            format: (value) =>
              (getIdsGraph.length === 1 && getIdsGraph[0] === 'Internal') || isMontly
                ? moment(value).format('DD MMM YY')
                : moment(value).format('MMM YY'),
          }}
          yScale={{
            type: 'linear',
            stacked: false,
            max,
            min,
            reverse: false,
          }}
          areaBaselineValue={min}
          enableArea={false}
          enableCrosshair={false}
          layers={['grid', 'markers', 'areas', Dots, 'lines', 'slices', 'axes', 'points', 'legends']}
          lineWidth={0}
          // enableGridX
          // enableGridY
          isInteractive
        />
      </div>
    </ContainerGraphics>
  )
}

export default Graphics
