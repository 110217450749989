import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  history: [],
}

export const { requestGetTruckTypesHistory, successGetTruckTypesHistory, failureGetTruckTypesHistory, clearGetTruckTypesHistory } = createActions({
  REQUEST_GET_TRUCK_TYPES_HISTORY: (id) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    id
  }),
  SUCCESS_GET_TRUCK_TYPES_HISTORY: (history) => ({
    history,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_TRUCK_TYPES_HISTORY: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  CLEAR_TRUCK_TYPES_HISTORY: () => ({
    ...defaultState,
  }),
})

const getTruckTypesHistory = handleActions(
  {
    [requestGetTruckTypesHistory]: produce((draft, { payload: { fetching, error, success } }) => {
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successGetTruckTypesHistory]: produce((draft, { payload: { message, fetching, success, history, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.history = history.data
    }),
    [failureGetTruckTypesHistory]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = !error
    }),
    [clearGetTruckTypesHistory]: produce((draft, { payload: { message, fetching, success, history, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.history = history.data
    }),
  },
  defaultState
)

export default getTruckTypesHistory
