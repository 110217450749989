import { put, takeLatest, call } from 'redux-saga/effects'
import { customers } from 'services'
import {
  requestActivateCustomer,
  successActivateCustomer,
  failureActivateCustomer,
} from 'store/duck/activateCustomer.duck'

function* activateCustomerRequestAsync(data) {
  try {
    const response = yield call(customers.editCustomer, data.payload.data, data.payload.customerId)
    yield put(successActivateCustomer(response, 'success'))
  } catch (error) {
    yield put(failureActivateCustomer(error.response.data.message))
  }
}

function* watchActivateCustomerAsync() {
  yield takeLatest(requestActivateCustomer, activateCustomerRequestAsync)
}

export default watchActivateCustomerAsync
