import styled from 'styled-components'
import Row from 'antd/lib/row'
import Typography from 'antd/lib/typography'
import Badge from 'antd/lib/badge'
import Col from 'antd/lib/col'

const { Text } = Typography

export const StyledDiv = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: auto;
  margin-top: 30px;
`

export const StyledRow = styled(Row)`
  width: 90%;
  margin: auto;
  margin-top: 16px;
  margin-bottom: 20px;
`

export const StyledRowMenu = styled(Row)({
  display: 'flex',
  alignItems: 'center',
  padding: '0px 5px',
  width: '100%',
  height: '40px',
  margin: '0px',
  color: '#002555',
  borderRadius: '4px',
  '&:hover': {
    background: '#3594FF',
    color: '#FFFFFF',
  },
})

export const StyledCol = styled(Col)({
  display: 'flex',
  alignItems: 'center',
  padding: '1px',
})


export const PriceLabel = styled(Text)`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: rgb(0, 37, 85);
  cursor: pointer;
`
const stylesByStatus = {
  Activated: {
    count: 'Activated',
    backgroundColor: '#00C48C',
    color: '#FFFFFF',
  },
  Deactivated: {
    count: 'Blocked',
    backgroundColor: '#F85359',
    color: '#FFFFFF',
  },
}

export const StyledBadge = styled(Badge).attrs((props) => {
  const variant = stylesByStatus[props.variant] ?? stylesByStatus.Activated

  return {
    count: variant.count,
    style: {
      backgroundColor: variant.backgroundColor,
      color: variant.color,
      fontWeight: 400,
      fontSize:16
    },
  }
})``