import { MdClose } from 'react-icons/md'
import React, { useCallback, useEffect, useState, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import TitleDrawer from 'components/TitleDrawer'
import PrimarySubmit from 'components/PrimarySubmit'
import DropdownApiInput from 'components/Form/DropdownApiInput'
import CategoriesAddedTable from 'components/CategoriesAddedTable'
import useGetRulesIds from 'containers/rules/ListRules/utils/useGetRulesIds'
import { StyledFooter, StyledModal } from 'containers/rules/CreateNewRule/styles'
import colors from 'providers/theme/config/colorPallete'
import Form from 'antd/lib/form'
import Button from 'antd/lib/button'
import Text from 'antd/lib/typography/Text'
import { sortBy } from 'lodash/collection'
import { isEmpty } from 'lodash/lang'
import { requestGetRuleSets } from 'store/duck/getRuleSets.duck'
import { clearMessageSnackBar } from 'store/duck/messageSnackBar.duck'
import { clearUpdateRuleset, requestUpdateRuleset } from 'store/duck/updateRuleset.duck'
import { clearGetRulesetById, requestGetRulesetById } from 'store/duck/getRulesetById.duck'
import notify from 'utils/notify'
import {
  MULTI_STEPS,
  RULE_DATE_RANGE,
  RULE_GEOGRAPHIC,
  RULE_LENGTH,
  RULE_SIMPLE,
  RULE_EQUIPMENT,
} from 'utils/constants'

const formName = 'add-to-rulesets'

const defaultValues = {
  ruleset: '',
}

const AddToRulesetsForm = ({ onSubmit, queryParams }) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const {
    data: { data: rulesetsData = [] },
    fetching,
  } = useSelector((state) => state.getRuleSets)
  const { data: loadedRulesetData, fetching: fetchingRulesetData } = useSelector((state) => state.getRulesetById)

  const [selectedRuleset, setSelectedRuleset] = useState(null)
  const [expandedRowKey, setExpandedRowKey] = useState(null)

  const [selectedGeographicInState, setSelectedGeographicInState] = useState([])
  const [selectedGeographicInZip, setSelectedGeographicInZip] = useState([])
  const [selectedGeographicOutState, setSelectedGeographicOutState] = useState([])
  const [selectedGeographicOutZip, setSelectedGeographicOutZip] = useState([])
  const [selectedLength, setSelectedLength] = useState([])
  const [selectedDatePickup, setSelectedDatePickup] = useState([])
  const [selectedDateDelivery, setSelectedDateDelivery] = useState([])
  const [selectedSimplePickup, setSelectedSimplePickup] = useState([])
  const [selectedSimpleDelivery, setSelectedSimpleDelivery] = useState([])
  const [selectedMultistep, setSelectedMultistep] = useState([])
  const [selectedEquipment, setSelectedEquipment] = useState([])

  const handleValuesChange = (changedValues) => {
    if (changedValues.ruleset) {
      setSelectedRuleset(changedValues.ruleset)
    }
  }

  useEffect(() => {
    if (selectedRuleset?.value) {
      dispatch(requestGetRulesetById(selectedRuleset.value))
    }
  }, [dispatch, selectedRuleset])

  const addMarginAndFlatDropdownOptions = (item) => ({
    ...item,
    value: item.name,
    label: `${item.name} (Percentage ${item.rule.margin}% - Flat ${item.rule.flat})`,
  })

  useEffect(() => {
    if (!isEmpty(loadedRulesetData)) {
      const getSelectedRules = (RULE_TYPE, rulesIds = {}) =>
        loadedRulesetData.rules
          .filter((rule) => loadedRulesetData.ignoreMasterRules || !rule.crossRule)
          .filter((rule) => rule.ruleModel === RULE_TYPE)
          .filter((rule) => !rulesIds.typeRuleId || rule.typeRuleId._id === rulesIds.typeRuleId)
          .filter((rule) => !rulesIds.typeShippingId || rule.rule.typeShippingId._id === rulesIds.typeShippingId)
          .filter(
            (rule) =>
              !rulesIds.typeReferenceGeographicId ||
              rule.rule.typeReferenceGeographicId._id === rulesIds.typeReferenceGeographicId
          )
          .map(addMarginAndFlatDropdownOptions)

      const newSelectedInState = getSelectedRules(RULE_GEOGRAPHIC, queryParams.geographic.inState)
      const newSelectedInZip = getSelectedRules(RULE_GEOGRAPHIC, queryParams.geographic.inZip)
      const newSelectedOutState = getSelectedRules(RULE_GEOGRAPHIC, queryParams.geographic.outState)
      const newSelectedOutZip = getSelectedRules(RULE_GEOGRAPHIC, queryParams.geographic.outZip)
      setSelectedGeographicInState(newSelectedInState)
      setSelectedGeographicInZip(newSelectedInZip)
      setSelectedGeographicOutState(newSelectedOutState)
      setSelectedGeographicOutZip(newSelectedOutZip)
      const existsAnyGeographicRule =
        newSelectedInState.length + newSelectedInZip.length + newSelectedOutState.length + newSelectedOutZip.length

      const newSelectedDateDelivery = getSelectedRules(RULE_DATE_RANGE, queryParams.dateRange.inbound)
      const newSelectedDatePickup = getSelectedRules(RULE_DATE_RANGE, queryParams.dateRange.outbound)
      setSelectedDateDelivery(newSelectedDateDelivery)
      setSelectedDatePickup(newSelectedDatePickup)
      const existsAnyDateRule = newSelectedDateDelivery.length + newSelectedDatePickup.length

      const newSelectedLength = getSelectedRules(RULE_LENGTH, queryParams.length)
      setSelectedLength(newSelectedLength)
      const existsAnyLengthRule = newSelectedLength.length

      const newSelectedSimpleDelivery = getSelectedRules(RULE_SIMPLE, queryParams.simple.inbound)
      const newSelectedSimplePickup = getSelectedRules(RULE_SIMPLE, queryParams.simple.outbound)
      setSelectedSimpleDelivery(newSelectedSimpleDelivery)
      setSelectedSimplePickup(newSelectedSimplePickup)
      const existsAnySimpleRule = newSelectedSimpleDelivery.length + newSelectedSimplePickup.length

      const newSelectedMultistep = loadedRulesetData.multisteps.map((item) => ({
        ...item,
        value: item.multistepName,
      }))
      setSelectedMultistep(newSelectedMultistep)
      const existsAnyMultiStep = newSelectedMultistep.length

      const newSelectedEquipment = getSelectedRules(RULE_EQUIPMENT)
      setSelectedEquipment(newSelectedEquipment)
      const existsAnyEquipment = newSelectedEquipment.length

      const existingCategoriesWithAtLeastOneRule = [
        existsAnyDateRule ? RULE_DATE_RANGE : undefined,
        existsAnyGeographicRule ? RULE_GEOGRAPHIC : undefined,
        existsAnyLengthRule ? RULE_LENGTH : undefined,
        existsAnyMultiStep ? MULTI_STEPS : undefined,
        existsAnySimpleRule ? RULE_SIMPLE : undefined,
        existsAnyEquipment ? RULE_EQUIPMENT : undefined,
      ].filter(Boolean)

      setExpandedRowKey(existingCategoriesWithAtLeastOneRule?.[0])
    }
  }, [
    form,
    loadedRulesetData,
    queryParams.dateRange.inbound,
    queryParams.dateRange.outbound,
    queryParams.geographic.inState,
    queryParams.geographic.inZip,
    queryParams.geographic.outState,
    queryParams.geographic.outZip,
    queryParams.length,
    queryParams.simple.inbound,
    queryParams.simple.outbound,
  ])

  return (
    <div style={{ marginBottom: 10 }}>
      <Text style={{ color: '#545454', fontWeight: 400, fontSize: '16px', marginBottom: 16 }}>
        Select the RuleSet where this rule will be added
      </Text>

      <Form
        form={form}
        layout="vertical"
        name={formName}
        initialValues={defaultValues}
        onFinish={onSubmit}
        onValuesChange={handleValuesChange}
        style={{ marginTop: 16 }}
        hideRequiredMark
      >
        <DropdownApiInput
          formProps={{
            name: 'ruleset',
            rules: [{ required: true, message: 'Please select a Ruleset' }],
          }}
          inputProps={{
            options: sortBy(
              rulesetsData.map(({ _id, rulesetName }) => ({ value: _id, label: rulesetName })),
              'label'
            ),
          }}
          onChange={(option) => setSelectedRuleset(option)}
          label="RuleSet Name *"
          loading={fetching}
          onSearch={(text) => {
            dispatch(requestGetRuleSets({ text }))
          }}
          onPressEnter={(option) => setSelectedRuleset(option)}
        />

        <div style={{ marginBottom: 12, marginTop: 20 }}>
          <TitleDrawer title="Categories Added" />
        </div>

        <CategoriesAddedTable
          selectedGeographicInState={selectedGeographicInState}
          selectedGeographicInZip={selectedGeographicInZip}
          selectedGeographicOutState={selectedGeographicOutState}
          selectedGeographicOutZip={selectedGeographicOutZip}
          selectedLength={selectedLength}
          selectedDatePickup={selectedDatePickup}
          selectedDateDelivery={selectedDateDelivery}
          selectedSimplePickup={selectedSimplePickup}
          selectedSimpleDelivery={selectedSimpleDelivery}
          selectedMultistep={selectedMultistep}
          selectedEquipment={selectedEquipment}
          expandedRowKey={expandedRowKey}
          setExpandedRowKey={setExpandedRowKey}
          hideActionsColumn
          loading={fetchingRulesetData}
        />
      </Form>
    </div>
  )
}

const AddToRulesetsModal = ({ visible, onClose, onTriggerSubmitForm }) => {
  const dispatch = useDispatch()
  const { data: loadedRulesetData } = useSelector((state) => state.getRulesetById)
  const { editRule, ruleSelected, data: responseCreateRule, success: successCreate } = useSelector(
    (state) => state.createRule
  )
  const { editMultistep, ruleSelected: multistepSelected, data: responseCreateMultistep } = useSelector(
    (state) => state.createMultiStep
  )
  const { success: successUpdate, error: errorUpdate, message: msmUpdate } = useSelector(
    (state) => state.updateRuleset
  )

  const { queryParams } = useGetRulesIds()
  const [loading, setLoading] = useState(false)
  const [waitingCreateResponse, setWaitingCreateResponse] = useState(false)

  const handleSubmit = () => {
    setLoading(true)

    if (editMultistep) {
      const loadedMultiSteps = loadedRulesetData.multisteps.map((rule) => rule._id)

      if (loadedMultiSteps.includes(multistepSelected._id)) {
        notify('error', 'This multistep is already included in this ruleset')
        setLoading(false)

        return
      }

      dispatch(
        requestUpdateRuleset({
          id: loadedRulesetData._id,
          multisteps: [...loadedMultiSteps, multistepSelected._id],
        })
      )
    } else if (editRule) {
      const loadedRules = loadedRulesetData.rules.map((rule) => rule._id)

      if (loadedRules.includes(ruleSelected._id)) {
        notify('error', 'This rule is already included in this ruleset')
        setLoading(false)

        return
      }

      dispatch(
        requestUpdateRuleset({
          id: loadedRulesetData._id,
          rules: [...loadedRules, ruleSelected._id],
        })
      )
    } else {
      setWaitingCreateResponse(true)

      onTriggerSubmitForm()
    }
  }

  const handleClose = useCallback(() => {
    onClose()

    dispatch(clearGetRulesetById())
  }, [dispatch, onClose])

  useLayoutEffect(() => {
    if (successCreate && waitingCreateResponse) {
      const loadedRules = loadedRulesetData.rules.map((rule) => rule._id)
      dispatch(
        requestUpdateRuleset({
          id: loadedRulesetData._id,
          rules: [...loadedRules, responseCreateRule.id],
        })
      )
      setWaitingCreateResponse(false)
    }
  }, [waitingCreateResponse, dispatch, loadedRulesetData, responseCreateRule, successCreate])

  useEffect(() => {
    if (waitingCreateResponse) {
      if (responseCreateMultistep._id) {
        const loadedRules = loadedRulesetData.multisteps.map((rule) => rule._id)

        dispatch(
          requestUpdateRuleset({
            id: loadedRulesetData._id,
            multisteps: [...loadedRules, responseCreateMultistep._id],
          })
        )

        setWaitingCreateResponse(false)
      }
    }
  }, [
    dispatch,
    loadedRulesetData._id,
    loadedRulesetData.multisteps,
    loadedRulesetData.rules,
    responseCreateMultistep._id,
    responseCreateRule,
    waitingCreateResponse,
  ])

  useEffect(() => {
    if (!visible) {
      return
    }

    if (successUpdate || errorUpdate) {
      setLoading(false)

      if (successUpdate) {
        notify('success', 'The rule was added successfully to the ruleset')
      }
      if (errorUpdate) {
        notify('error', 'Error adding the rule to the ruleset')
      }
      dispatch(clearMessageSnackBar())
      dispatch(clearUpdateRuleset())

      handleClose()
    }
  }, [successUpdate, errorUpdate, msmUpdate, dispatch, visible, handleClose])

  
  return (
    <StyledModal
      title={
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
          <AiOutlineArrowLeft
            style={{ color: '#000000', fontSize: '20px', marginRight: 6, cursor: 'pointer' }}
            onClick={() => handleClose()}
          />

          <TitleDrawer title="Add to RuleSets" />
        </div>
      }
      width={500}
      style={{ top: 100 }}
      onCancel={() => {
        handleClose()
      }}
      visible={visible}
      bodyStyle={{ padding: '16px 24px 0px' }}
      destroyOnClose
      footer={
        <StyledFooter>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', flexGrow: 1 }}>
            <PrimarySubmit label="Add Rule" loading={loading} formName={formName} />
            <Button
              type="text"
              style={{ backgroundColor: 'white', marginLeft: 0, marginTop: 16 }}
              onClick={() => handleClose()}
            >
              <span style={{ color: colors.light_blue.default, fontWeight: 400 }}>Cancel Addition</span>
            </Button>
          </div>
        </StyledFooter>
      }
      closeIcon={<MdClose size={24} color="#000000" />}
    >
      <AddToRulesetsForm onSubmit={() => handleSubmit()} queryParams={queryParams} />
    </StyledModal>
  )
}

export default AddToRulesetsModal
