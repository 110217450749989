import React, { useCallback, useEffect, useState } from 'react'
import { BsPlusSquareFill } from 'react-icons/bs'
import { pick } from 'lodash/object'
import { sortBy } from 'lodash/collection'
import { intersection } from 'lodash/array'
import TitleDrawer from 'components/TitleDrawer'
import DropdownApiInput from 'components/Form/DropdownApiInput'
import colors from 'providers/theme/config/colorPallete'

import ClosablePill from './ClosablePill'
import MasterRulePill from './MasterRulePill'

const SelectEntitiesWithChips = ({
  title,
  name,
  form,
  options,
  label,
  loading,
  masterRules = [],
  showMasterRules = false,
  selectedEntities,
  onChangeSelectedEntities,
  onSearch,
  enableEdit = true,
}) => {
  const [selectedEntity, setSelectedEntity] = useState(null)

  const handleAddEntity = () => {
    if (selectedEntity) {
      onChangeSelectedEntities((prev) => [...prev, selectedEntity])

      form.setFieldsValue({ [name]: null })
      setSelectedEntity(null)
    }
  }

  const handleRemoveEntity = useCallback(
    (idToRemove) => {
      onChangeSelectedEntities((prev) => prev.filter((item) => item._id !== idToRemove))
    },
    [onChangeSelectedEntities]
  )

  useEffect(() => {
    if (showMasterRules) {
      intersection(
        selectedEntities.map((item) => item._id),
        masterRules.map((item) => item._id)
      ).forEach((entityInBothArrays) => handleRemoveEntity(entityInBothArrays))
    }
  }, [handleRemoveEntity, masterRules, selectedEntities, showMasterRules, name])

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
          <DropdownApiInput
            disabled={!enableEdit}
            formProps={{
              name,
            }}
            inputProps={{
              options: sortBy(
                options
                  .map((customer) => pick(customer, ['_id', 'value', 'label']))
                  .filter((customer) => !selectedEntities.map((item) => item._id).includes(customer._id))
                  .filter(
                    (customer) => !masterRules.map((item) => item._id).includes(customer._id) || !showMasterRules
                  ),
                'value'
              ),
            }}
            onChange={(option) => setSelectedEntity(option)}
            label={label}
            loading={loading}
            onSearch={onSearch}
            onPressEnter={() => handleAddEntity()}
          />
        </div>

        <BsPlusSquareFill
          style={{
            fontSize: '18px',
            color: colors.light_blue.default,
            marginTop: 12,
            marginLeft: 18,
            cursor: 'pointer',
          }}
          onClick={() => {
            if (enableEdit) handleAddEntity()
          }}
        />
      </div>

      {(selectedEntities.length > 0 || (showMasterRules && masterRules.length > 0)) && (
        <div>
          <TitleDrawer title={title} />
          <div style={{ display: 'flex', flexDirection: 'row', gap: 16, marginTop: 10, flexWrap: 'wrap' }}>
            {showMasterRules &&
              masterRules.map((masterRule) => (
                <MasterRulePill
                  key={masterRule._id}
                  text={masterRule.name ? masterRule.name : masterRule.multistepName}
                />
              ))}

            {selectedEntities.map((entity) => (
              <ClosablePill
                key={entity._id}
                text={entity.value}
                onClose={() => {
                  if (enableEdit) handleRemoveEntity(entity._id)
                }}
              />
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default SelectEntitiesWithChips
