import styled from 'styled-components'
import Typography from 'antd/lib/typography'

const { Text } = Typography

const PriceLabel = styled(Text)`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: rgb(0, 37, 85);
`

export default PriceLabel
