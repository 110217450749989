import { put, takeLatest, call } from 'redux-saga/effects'
import { history} from 'services/'
import {
  requestGetHistory,
  successGetHistory,
  failureGetHistory,
} from '../duck/getHistory.duck'

function* getHistoryAsync(data) {
  try {
    const response = yield call(history.getHistoryTrustPercentage, data.payload.params)
    yield put(successGetHistory(response))
  } catch (error) {
    yield put(failureGetHistory(error))
  }
}

function* watchGetHistoryAsync() {
  yield takeLatest(requestGetHistory, getHistoryAsync)
}

export default watchGetHistoryAsync
