import styled from 'styled-components'
import StyledModal from 'components/Modal/styles'

export const ButtonFullScreen = styled.div`
  margin: ${(props) => (props.breakpoint ? '10px 0px 0px 0px' : '0px')};
  padding: 0px;
  color: red;
  position: absolute;
  z-index: 1;
  right: 40px;
  height: 25px;
  width: 25px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0em 0 0.4em 0 rgb(15 22 33 / 60%);
  cursor: pointer;
`

export const ModalMap = styled(StyledModal)`
  .ant-modal-close {
    display: none !important;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-body {
    padding: 0px;
    height: ${(props) => props.height};
  }
`
