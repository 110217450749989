import React, { useCallback, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { useDispatch, useSelector } from 'react-redux'
import { requestGetTrustPercentages, clearGetTrustPercentages } from 'store/duck/getTrustPercentages.duck'
import { clearUpdateTrustPercentage } from 'store/duck/updateTrustPercentage.duck'
import Table from 'components/Table'
import StyledSearch from 'components/Input/Search'
import notify from 'utils/notify'
import TrustPercentageModal from 'containers/settings/TrustPercentageModal'
import Form from 'antd/lib/form'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

import { TrustPercentageContainer } from './styles'
import columns from './utils/TableColumns'

const TrustPercentageList = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const { success, fetching, error, trustPercentages, totalRecords } = useSelector(
    (state) => state.getTrustPercentages
  )
  const { success: successUpdate, error: errorUpdate, message: msmUpdate } = useSelector(
    (state) => state.updateTrustPercentage
  )
  const [visible, setVisible] = useState(false)
  const [params, setParams] = useState({ page: 1, limit: 20 })
  const [currentPercentages, setCurrentPercentages] = useState(trustPercentages)
  const tableColumns = columns(dispatch, setVisible)

  useEffect(() => {
    if (!visible) dispatch(requestGetTrustPercentages(params))
  }, [visible, dispatch, params])

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error getting rate sources!',
      })
    }
  }, [error])

  useEffect(() => {
    if (success) {
      setCurrentPercentages(trustPercentages)
      dispatch(clearGetTrustPercentages())
    }
  }, [dispatch, success, trustPercentages])

  useEffect(() => {
    if (successUpdate) {
      notify('success', 'The Rule has been successfully edited')
      dispatch(clearUpdateTrustPercentage())
      dispatch(requestGetTrustPercentages())
    }
    if (errorUpdate) {
      Swal.fire({
        icon: 'error',
        title: 'Error updating rate source!',
        text: msmUpdate,
      })
      dispatch(clearUpdateTrustPercentage())
    }
  }, [errorUpdate, msmUpdate, successUpdate, dispatch])

  const handleSearchBarChange = (values) => {
    setCurrentPercentages(
      trustPercentages.filter((item) => item.truckTypeId.name.toLowerCase().includes(values.text.toLowerCase()))
    )
  }

  const handleTableChange = useCallback((pagination) => {
    setParams((prevParams) => ({
      ...prevParams,
      page: pagination.current ?? prevParams.page,
      limit: pagination.limit ?? prevParams.limit,
    }))
  }, [])

  return (
    <TrustPercentageContainer>
      <Form
        layout="vertical"
        name="filter-trust-percentage-form"
        style={{ width: '100%', margin: '0px', padding: '0px' }}
        form={form}
        onValuesChange={handleSearchBarChange}
        hideRequiredMark
      >
        <Row>
          <Col flex={24}>
            <Form.Item name="text">
              <StyledSearch />
            </Form.Item>
          </Col>
        </Row>
        <TrustPercentageModal visible={visible} onCloseDrawer={setVisible} />
        <Table
          width="100%"
          columns={tableColumns}
          data={currentPercentages}
          loading={fetching}
          rowKey="_id"
          pagination={{ total: totalRecords }}
          updatedPagination
          onChange={handleTableChange}
          showBulkActions={false}
        />
      </Form>
    </TrustPercentageContainer>
  )
}

export default TrustPercentageList
