/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import DrawerItem from 'components/Form/DrawerItem'
import ControlInput from 'components/Form/ControlInput'
import InputWithLabel from 'components/Form/InputWithLabel'
import TimePicker from 'antd/lib/time-picker'
import styled from 'styled-components'
import moment from 'moment'

const StyledTime = styled(TimePicker)`
  width: 100%;
  border-radius: 4px; 
`

const format = 'HH:mm'

const InputTime = ({ Input, label, name, rules, ...props }) => (
  <InputWithLabel label={label}>
    {(hasValue) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <DrawerItem style={{ marginBottom: 0 }} name={name} rules={rules}>
        <ControlInput hasValue={hasValue}>
          {({ value, onChange }) => (
            <StyledTime
              style={{ width: '100%', borderRadius: '4px' }}
              value={value && moment(value, format)}
              onSelect={(sel) => {
                const sendString = moment(sel).format('HH:mm') 
                onChange(sendString)
              }}
              onChange={(value1) => {
                const sendString = moment(value1).format('HH:mm') 
                onChange(sendString)
              }}
              size="large"
              format={format}
              {...props}
            />
          )}
        </ControlInput>
      </DrawerItem>
    )}
  </InputWithLabel>
)

export default InputTime
