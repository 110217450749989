import { put, call, debounce } from 'redux-saga/effects'
import { integrations } from 'services'
import { requestGetRouteData, successGetRouteData, failureGetRouteData } from 'store/duck/getRouteData.duck'

function* getRouteDataAsync(data) {
  try {
    const response = yield call(integrations.getRouteData, data.payload.params)
    yield put(successGetRouteData(response))
  } catch (error) {
    yield put(failureGetRouteData(error))
  }
}

function* watchGetRouteDataAsync() {
  yield debounce(1500, requestGetRouteData, getRouteDataAsync)
}

export default watchGetRouteDataAsync
