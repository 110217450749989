import { put, debounce, call } from 'redux-saga/effects'
import { rules } from 'services/'

import { requestGetAllRules, successGetAllRules, failureGetAllRules } from '../duck/getAllRules.duck'

function* getAllRulesAsync(data) {
  try {
    const response = yield call(rules.getRules, data.payload.params)
    yield put(successGetAllRules(response.data))
  } catch (error) {
    yield put(failureGetAllRules(error))
  }
}

function* watchGetAllRulesAsync() {
  yield debounce(1500, requestGetAllRules, getAllRulesAsync)
}

export default watchGetAllRulesAsync
