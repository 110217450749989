import { put, debounce, call } from 'redux-saga/effects'
import { integrations } from 'services'
import {
  successGetZipAutoComplete,
  requestGetZipAutoComplete,
  failureGetZipAutoComplete,
} from 'store/duck/getZipAutoComplete.duck'

function* getZipAutocomplete(data) {
  try {
    const response = yield call(integrations.getAllZipHeres, data.payload.entry)
    yield put(successGetZipAutoComplete(response))
  } catch (error) {
    yield put(failureGetZipAutoComplete(error))
  }
}

function* watchGetZipAutocompleteAsync() {
  yield debounce(150, requestGetZipAutoComplete, getZipAutocomplete)
}

export default watchGetZipAutocompleteAsync
