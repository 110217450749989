import styled from 'styled-components'
import Row from 'antd/lib/row'

const DateContainer = styled(Row)`
  width: 100%;
  justify-content: center;
  flex-direction: column;
`

export default DateContainer
