import styled from 'styled-components'
import Input from 'antd/lib/input'
import Form from 'antd/lib/form'

export const StyledWrapper = styled.div`
  transition: all 2s;
  width: 20rem;
  span {
    margin-right: 0.5rem;
    padding: 0;
  }
  svg {
    color: #6e7f8d;
    margin-top: 0.3rem;
    &:hover {
      color: #002555;
    }
  }
`
export const StyledText = styled.span`
  margin-top: -1rem;
  color: #6e7f8d;
  font-size: 14px;
  font-weight: 400;
`

export const StyledInput = styled(Input)`
  all: unset;
  &:focus {
    all: unset;
  }
`

export const StyledForm = styled(Form)`
  .ant-form-item-has-error .ant-form-item-explain, .ant-form-item-has-error .ant-form-item-split {
    color: #ff4d4f;
    font-size: 12px;
    font-weight: 400;
  }
`
