import { put, takeLatest, call } from 'redux-saga/effects'
import { customers } from 'services'
import {
  requestUpdateBulkFuelPrice,
  successUpdateBulkFuelPrice,
  failureUpdateBulkFuelPrice,
} from 'store/duck/updateBulkFuelPrice.duck'

function* updateBulkFuelPriceRequestAsync(data) {
  try {
    const response = yield call(customers.updateBulkFuelPrice, data.payload.data)
    yield put(successUpdateBulkFuelPrice(response, 'success'))
  } catch (error) {
    yield put(failureUpdateBulkFuelPrice(error.response.data.message))
  }
}

function* watchupdateBulkFuelPriceAsync() {
  yield takeLatest(requestUpdateBulkFuelPrice, updateBulkFuelPriceRequestAsync)
}

export default watchupdateBulkFuelPriceAsync
