import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  successDeleteBulk: false,
  data: {},
  itemId: '',
}

export const {
  requestDeleteBulkCustomer,
  successDeleteBulkCustomer,
  failureDeleteBulkCustomer,
  clearDeleteBulkCustomer,
} = createActions({
  REQUEST_DELETE_BULK_CUSTOMER: (itemId) => ({
    ...defaultState,
    fetching: true,
    itemId,
  }),
  SUCCESS_DELETE_BULK_CUSTOMER: (data, message) => ({
    data,
    message,
    fetching: false,
    error: false,
    successDeleteBulk: true,
  }),
  FAILURE_DELETE_BULK_CUSTOMER: (message) => ({
    message,
    fetching: false,
    error: true,
    successDeleteBulk: false,
  }),
  CLEAR_DELETE_BULK_CUSTOMER: () => ({
    ...defaultState,
  }),
})

const deleteBulkCustomer = handleActions(
  {
    [requestDeleteBulkCustomer]: produce((draft, { payload: { message, fetching, itemId } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.itemId = itemId
    }),
    [successDeleteBulkCustomer]: produce((draft, { payload: { message, fetching, successDeleteBulk, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.successDeleteBulk = successDeleteBulk
      draft.data = data
    }),
    [failureDeleteBulkCustomer]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
    }),
    [clearDeleteBulkCustomer]: produce(
      (draft, { payload: { message, fetching, error, data, successDeleteBulk } }) => {
        draft.message = message
        draft.fetching = fetching
        draft.error = error
        draft.data = data
        draft.successDeleteBulk = successDeleteBulk
      }
    ),
  },
  defaultState
)

export default deleteBulkCustomer
