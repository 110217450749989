import styled from 'styled-components'
import Button from 'antd/lib/button'

const ButtonPrimary = styled(Button).attrs((props) => ({
  type: 'primary',
  ...props,
}))`
  background: ${(props) => props.theme.colors.light_blue.default};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  > *:not(:first-child) {
    margin-left: 8px;
  }
  border: none;
  color: white;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  border-radius: 4px;
`

export default ButtonPrimary
