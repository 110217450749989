import React from 'react'
import Typography from 'antd/lib/typography'
import PriceLabel from 'components/Label/PriceLabel'
import BoxCell from 'components/Box/BoxCell'

const { Text } = Typography

const columns = (shippingType) => [
  {
    title: shippingType === 'pickup' ? 'Pick Up' : 'Delivery',
    dataIndex: 'shippingType',
    key: 'shippingType',
    render: () => {
      return (
        <BoxCell>
          <Text>Day of Week</Text>
          <Text>{shippingType === 'pickup' ? '(Pick Up)' : '(Delivery)'}</Text>
        </BoxCell>
      )
    },
    width: 115,
    ellipsis: true,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name),
    sortDirections: ['descend', 'ascend'],
    width: 210,
    ellipsis: true,
  },
  {
    title: 'Day',
    dataIndex: ['rule', 'day'],
    key: 'day',
    render: (day) => {
      return <PriceLabel>{day}</PriceLabel>
    },
    width: 90,
    ellipsis: true,
  },
  {
    title: 'Percentage',
    dataIndex: ['rule', 'margin'],
    key: 'margin',
    render: (margin) => {
      return <PriceLabel>{margin} %</PriceLabel>
    },
    width: 80,
    ellipsis: true,
  },
  {
    title: 'Flat',
    dataIndex: ['rule', 'flat'],
    key: 'flat',
    render: (flat) => {
      return <PriceLabel>{flat}</PriceLabel>
    },
    width: 80,
    ellipsis: true,
  },
]

export default columns
