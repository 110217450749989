import styled from 'styled-components'

export const StyledContainer = styled.div``

export const ContainerGraphics = styled.div`
  border: 1px solid rgba(217, 217, 217, 0.1);
  box-shadow: rgb(217 217 217 / 20%) 0px 10px 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  padding: 1rem;
`
export const HeaderGraph = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`


