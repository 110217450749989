import { put, takeLatest, call } from 'redux-saga/effects'
import { rates } from 'services/'
import { requestGetGsRate, successGetGsRate, failureGetGsRate } from '../duck/getRate.duck'

function* getGsRateAsync(data) {
  try {
    const response = yield call(rates.getGreenScreenRate, data.payload.paramsGs) 
    yield put(successGetGsRate(response ?? []))
  } catch (error) {
    yield put(failureGetGsRate(error.response.data.message))
  }
}

function* watchGetGsRateAsync() {
  yield takeLatest(requestGetGsRate, getGsRateAsync)
}

export default watchGetGsRateAsync
