import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: {},
}

export const {
  requestAssignCustomers,
  successAssignCustomers,
  failureAssignCustomers,
  clearAssignCustomers,
} = createActions({
  REQUEST_ASSIGN_CUSTOMERS: (data) => ({
    ...defaultState,
    fetching: true,
    success: false,
    data,
  }),
  SUCCESS_ASSIGN_CUSTOMERS: (data, message) => ({
    data,
    message,
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_ASSIGN_CUSTOMERS: (message) => ({
    message,
    fetching: false,
    error: true,
  }),
  CLEAR_ASSIGN_CUSTOMERS: () => ({
    ...defaultState,
  }),
})

const assignCustomers = handleActions(
  {
    [requestAssignCustomers]: produce((draft, { payload: { message, fetching, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = false
      draft.data = data
    }),
    [successAssignCustomers]: produce((draft, { payload: { message, fetching, success, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.data = data
    }),
    [failureAssignCustomers]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
    }),
    [clearAssignCustomers]: produce((draft, { payload: { message, fetching, error, success } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
  },
  defaultState
)

export default assignCustomers
