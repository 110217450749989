import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'
import { editFormat } from 'utils/dateManager'
import { generateFormatPolyline } from 'utils/formatValues'
import { formatLineGraph } from 'utils/formatGraph'
import removeDuplicatedObjects from 'utils/removeDuplicatedObjects'
import { between30Days } from 'utils/filters'
import { orderBy } from 'lodash'


const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  rate: {},
  params: {},
  graphics: [],
  mciGraphics: {},
  filter: { id: [], month: '6' },
  entries: [],
  polilyne: {},
  source: '',
  greenScreens: [],
  gsSuccess: false,
  paramsGs: {},
  messageGs: '',
  errorGs: false,
}

export const {
  requestGetRate,
  successGetRate,
  failureGetRate,
  clearGetRate,
  filterGraphics,
  setGraphics,
  requestGetGsRate,
  successGetGsRate,
  failureGetGsRate,
} = createActions({
  REQUEST_GET_RATE: (params) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    params,
  }),
  SUCCESS_GET_RATE: (rate) => ({
    rate,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_RATE: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  CLEAR_GET_RATE: () => ({
    ...defaultState,
  }),
  FILTER_GRAPHICS: (filter) => ({
    filter,
  }),
  SET_GRAPHICS: () => ({}),
  REQUEST_GET_GS_RATE: (paramsGs) => ({
    paramsGs,
  }),
  SUCCESS_GET_GS_RATE: (greenScreens) => ({
    greenScreens,
    gsSuccess: true,
    messageGs: '',
    errorGs: false,
  }),
  FAILURE_GET_GS_RATE: (messageGs) => ({
    messageGs,
    errorGs: true,
  }),
})

const getRate = handleActions(
  {
    [requestGetRate]: produce((draft, { payload: { fetching, error, success, graphics = [] } }) => {
      draft.fetching = fetching
      draft.error = error
      draft.success = success
      draft.graphics = graphics
    }),
    [successGetRate]: produce((draft, { payload: { message, fetching, success, rate, error } }) => {
      const base = rate?.base_rate ? rate?.base_rate : {}
      const datRate = base?.datRate ? base?.datRate : {}
      const datAvg = datRate?.datAvg ? datRate?.datAvg : {}
      const avg = datAvg?.rate ? datAvg?.rate : '0'

      const formatData = (element) => {
        const newData = []
        const newElement = { ...element }
        const size = element.data.length
        if (element.id === 'Dat') {
          for (let index = 0; index < size; index += 1) {
            const item = element.data[index]
            if (index === size - 1) {
              const y = JSON.stringify(item.y)
              if (y === 'null') item.y = avg.replace(/[^0-9.-]+/g, '')
            }
            if (item.y) newData.push({ ...item, x: editFormat(item?.x) })
          }
          if (newData.length > 0) newElement.data = newData
        }

        return newElement
      }

      const format = (arr = []) => {
        const clear = []
        arr.forEach((ele) => {
          const modify = formatData(ele)
          clear.push({ ...modify, [ele.id]: ele.highest.ratePerMile })
        })
        return clear
      }
      const clearGraph = rate?.graphics ? format(rate?.graphics) : []
      const clearMciGraph = rate?.marketConditions ? formatLineGraph(rate?.marketConditions) : {}
      const formatRate = { ...rate }
      formatRate.graphics = removeDuplicatedObjects(clearGraph)
      formatRate.mciGraphics = clearMciGraph

       
 

      /**
       * "ltrOrigin": {
        "_id": "65cca5591a0286900f3d3a11",
        "city": "Augusta",
        "days": 1,
        "state": "ME",
        "truckType": "VAN",
        "LT": 0,
        "__v": 0,
        "createdAt": "2024-02-14T11:34:49.339Z",
        "dateFrom": "2024-02-12",
        "dateTo": "2024-02-13",
        "loads": 0,
        "trucks": 0,
        "updatedAt": "2024-02-14T11:34:54.083Z"
    },
    "ltrDestination": {
        "_id": "65cca5641a0286900f3d4a3e",
        "city": "Buffalo",
        "days": 1,
        "state": "NY",
        "truckType": "VAN",
        "LT": 0,
        "__v": 0,
        "createdAt": "2024-02-14T11:35:00.035Z",
        "dateFrom": "2024-02-12",
        "dateTo": "2024-02-13",
        "loads": 0,
        "trucks": 0,
        "updatedAt": "2024-02-14T11:35:00.035Z"
    }
       */
      draft.polilyne = generateFormatPolyline(rate)
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.rate = formatRate
      draft.graphics = removeDuplicatedObjects(clearGraph)
      draft.entries = removeDuplicatedObjects(clearGraph)
      draft.mciGraphics = clearMciGraph
      draft.filter = { id: [], month: '6' }
    }),
    [failureGetRate]: produce((draft, { payload: { message, fetching, error, success } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [clearGetRate]: produce((draft, { payload: { message, fetching, success, rate, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.rate = rate
      draft.filter = {}
      draft.graphics = []
      draft.gsSuccess = false
      draft.messageGs = ''
      draft.greenScreens = []
      draft.errorGs = false
    }),
    [filterGraphics]: produce((draft, { payload: { filter } }) => {
      draft.filter = { ...draft.filter, ...filter }
    }),
    [setGraphics]: produce((draft) => {
      const arr = draft.rate.graphics
      const { id, month } = draft.filter
      const gs = draft.greenScreens
      const respon = []
      if (month === '12') {
        for (const i of arr) {
          if (!id.find((element) => element === i.id) || i.id === null) continue
          const newObj = {
            id: i.id,
            data: [],
          }
          let gsData = []
          gs.forEach((g) => {
            if (g.id === i.id) {
              const format = (a) => {
                const newArray = []
                a.forEach((no) => {
                  newArray.push({ ...no, x: editFormat(no.x) })
                })
                return newArray
              }

              gsData = [...format(g.data)]
              newObj.highest = g.highest
              if (g?.Dat) newObj.Dat = g?.Dat
            }
          })
          const addition = [...i.data, ...gsData]
          newObj.data = orderBy(addition, ['x'], 'asc')
          respon.push(newObj)
        }
      }
      if (month === '6') {
        for (const i of arr) {
          if (!id.find((element) => element === i.id) || i.id === null) continue
          const newObj = {
            id: i.id,
            data: [],
          }
          newObj.data = [...i.data]
          respon.push(newObj)
        }
      }
      if (month === '1') {
        for (const i of arr) {
          if (!id.find((element) => element === i.id) || i.id === null) continue
          const newObj = {
            id: i.id,
            data: [],
          }
          newObj.data = [...between30Days(i?.data)]
          respon.push(newObj)
        }
      }

      draft.graphics = respon
    }),

    [requestGetGsRate]: produce((draft, { payload: { greenScreens } }) => {
      draft.greenScreens = greenScreens
    }),
    [successGetGsRate]: produce((draft, { payload: { greenScreens, gsSuccess, messageGs, errorGs } }) => {
      draft.greenScreens = greenScreens?.graphics ?? []
      draft.gsSuccess = gsSuccess
      draft.messageGs = messageGs
      draft.errorGs = errorGs
    }),
    [failureGetGsRate]: produce((draft, { payload: { message, error } }) => {
      draft.messageGs = message
      draft.errorGs = error
    })
  },
  defaultState
)

export default getRate
