import React, { useEffect, useState, useReducer } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch, batch } from 'react-redux'
import Switch from 'antd/lib/switch'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Tooltip from 'antd/lib/tooltip'
import Select from 'antd/lib/select'
import Swal from 'sweetalert2'
import { BsArrowLeft, BsPlus } from 'react-icons/bs'
import { AiOutlineSearch } from 'react-icons/ai'
import { requestGetRulesetById } from 'store/duck/getRulesetById.duck'
import { requestGetCustomers } from 'store/duck/getCustomers.duck'
import { requestGetMultiSteps } from 'store/duck/getMultiSteps.duck'
import { requestGetRules } from 'store/duck/getRules.duck'
import { requestGetRuleType } from 'store/duck/getRuleType.duck'
import { requestGetGeographicReference, clearGetGeographicReference } from 'store/duck/getGeographicReference.duck'
import { requestGetShippingType, clearGetShippingType } from 'store/duck/getShippingType.duck'
import { requestUpdateRuleset, clearUpdateRuleset } from 'store/duck/updateRuleset.duck'
import { clearAssignCustomers } from 'store/duck/assignCustomers.duck'
import { REFERENCE_STATE, REFERENCE_ZIP, SHIPPING_INBOUND, SHIPPING_OUTBOUND } from 'utils/constants'
import { parseRulesetData } from 'utils/parseResponse'
import ContentContainer from 'components/ContentContainer'
import TitleTextEdit from 'components/TitleTextEdit'
import ButtonAdd from 'components/Button/ButtonAdd'
import Table from 'components/Table'
import Modal from 'components/Modal'
import InputGeneric from 'components/Input/InputGeneric'
import MultistepList from 'components/Rules/MultistepList'
import CustomersRuleset from 'containers/rules/Ruleset/CustomersRuleset'
import AddRulesModal from 'containers/rules/Ruleset/AddRulesModal'
import AddMultistep from 'containers/rules/Ruleset/AddMultistep'
import LoadGeographicRule from 'containers/rules/Ruleset/LoadGeographicRule'
import LoadSimpleRule from 'containers/rules/Ruleset/LoadSimpleRule'
import LoadDateRangeRule from 'containers/rules/Ruleset/LoadDateRangeRule'
import SelectOption from 'components/SelectOption'
import SpinLoading from 'components/SpinLoading'
import Avatar, { AvatarAdd, AvatarGroup } from 'components/Avatar'
import customerAvatar from 'assets/img/avatar.png'
import reducer from './utils/reducer'
import columns from './utils/TableColumns'

const LoadRuleSet = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams()
  const initialState = {
    modalTitle: '',
    ruleType: { name: '' },
    search: '',
    totalCustomers: [],
    rulesetData: {},
    rulesSelected: [],
    multiSelected: [],
    params: { page: 1 },
  }
  const [
    { modalTitle, ruleType, search, totalCustomers, rulesetData, params, rulesSelected, multiSelected },
    setValues,
  ] = useReducer(reducer, initialState)
  const [showAddRule, setShowAddRule] = useState(false)
  const [showAddMultistep, setShowAddMultistep] = useState(false)
  const [showRule, setShowRule] = useState('Geographic')
  const [shippingData, setShippingData] = useState({ inShipping: '', outShipping: '' })
  const [referenceData, setReferenceData] = useState({ referenceState: '', referenceZip: '' })
  const [modalDisplay, setModal] = useState(false)
  const { success, error, message, customers } = useSelector((state) => state.getCustomers)
  const { data, success: successRuleset, fetching } = useSelector((state) => state.getRulesetById)
  const { typesId } = useSelector((state) => state.getRuleType)
  const { data: referenceInfo, success: successReference } = useSelector((state) => state.getGeographicReference)
  const { data: shippingInfo, success: successShipping } = useSelector((state) => state.getShippingType)
  const { success: successUpdate, error: errorUpdate, message: msmUpdate, data: updateData } = useSelector(
    (state) => state.updateRuleset
  )
  const {
    error: errorAssignCustomer,
    success: successAssignCustomer,
    message: messageAssignCustomer,
  } = useSelector((state) => state.assignCustomers)

  useEffect(() => {
    if (successAssignCustomer) {
      Swal.fire({
        icon: 'success',
        title: 'Customers assigned successfully!',
        showConfirmButton: false,
        timer: 1500,
      })
      dispatch(clearAssignCustomers())
      dispatch(requestGetRulesetById(id))
    }
    if (errorAssignCustomer) {
      Swal.fire({
        icon: 'error',
        title: 'Customer assignment error!',
        text: messageAssignCustomer,
      })
      dispatch(requestGetRulesetById(id))
      dispatch(clearAssignCustomers())
    }
  }, [successAssignCustomer, errorAssignCustomer, messageAssignCustomer, id, dispatch])

  useEffect(() => {
    batch(() => {
      dispatch(requestGetCustomers({ text: '', active: true }))
      dispatch(requestGetRulesetById(id))
      dispatch(requestGetRuleType())
      dispatch(requestGetGeographicReference())
      dispatch(requestGetShippingType())
    })
  }, [dispatch, id])

  useEffect(() => {
    if (successReference) {
      const tmp = { ...referenceData }
      referenceInfo.map((item) => {
        if (item.name === REFERENCE_STATE) tmp.referenceState = item._id
        if (item.name === REFERENCE_ZIP) tmp.referenceZip = item._id
        return tmp
      })
      dispatch(clearGetGeographicReference())
      setReferenceData(tmp)
    }
    if (successShipping) {
      const tmp = { ...shippingData }
      shippingInfo.map((item) => {
        if (item.name === SHIPPING_INBOUND) tmp.inShipping = item._id
        if (item.name === SHIPPING_OUTBOUND) tmp.outShipping = item._id
        return tmp
      })
      dispatch(clearGetShippingType())
      setShippingData(tmp)
    }
  }, [dispatch, shippingData, successShipping, referenceData, shippingInfo, successReference, referenceInfo])

  useEffect(() => {
    if (successRuleset) {
      const parseData = parseRulesetData(data)
      setValues({ type: 'ruleset-data', rulesetData: parseData })
    }
  }, [data, successRuleset])

  useEffect(() => {
    if (success) {
      setValues({ type: 'customers', totalCustomers: customers })
    }
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Get customers error!',
        text: message,
      })
    }
  }, [success, error, message, customers])

  useEffect(() => {
    if (successUpdate) {
      Swal.fire({
        icon: 'success',
        title: 'Ruleset updated successfully!',
        showConfirmButton: false,
        timer: 1500,
      })
      const parseData = parseRulesetData(updateData)
      setValues({ type: 'ruleset-data', rulesetData: parseData })
      dispatch(clearUpdateRuleset())
      dispatch(requestGetRulesetById(id))
      setShowAddRule(false)
      setShowAddMultistep(false)
    }
    if (errorUpdate) {
      Swal.fire({
        icon: 'error',
        title: 'Error updating the ruleset.',
        text: msmUpdate,
      })
      dispatch(clearUpdateRuleset())
    }
  }, [successUpdate, errorUpdate, msmUpdate, id, dispatch, updateData])

  const handleSearch = (event) => {
    if (!event.target.value) {
      setValues({ type: 'customers', totalCustomers: customers })
    }
    setValues({ type: 'search', search: event.target.value })
  }

  const handleKeyPress = (event) => {
    if (event.key !== 'Enter') return
    const filter = totalCustomers.filter((cust) => {
      const element = cust.name.toLowerCase()
      const result = element.includes(search.toLowerCase())
      return result
    })
    setValues({ type: 'customers', totalCustomers: filter })
  }

  const handleFetchLengthRules = (ruleTypeName, title, currentRules) => {
    const queryParams = { page: 1, typeRuleId: typesId.typeLength }
    const rulesId = []
    currentRules.map((item) => {
      rulesId.push(item._id)
      return rulesId
    })
    dispatch(requestGetRules(queryParams))
    setValues({
      type: 'fetch-rules',
      modalTitle: title,
      ruleType: { ruleTypeName },
      params: queryParams,
      rulesSelected: rulesId,
    })
    setShowAddRule(true)
  }

  const handleFetchDateRules = (ruleTypeName, title, shipping, shippingId, rulesId) => {
    const queryParams = {
      page: 1,
      typeRuleId: typesId.typeDate,
      typeShippingId: shippingId,
    }
    dispatch(requestGetRules(queryParams))
    setValues({
      type: 'fetch-rules',
      modalTitle: title,
      ruleType: { ruleTypeName, shipping },
      params: queryParams,
      rulesSelected: rulesId,
    })
    setShowAddRule(true)
  }

  const handleFetchSimpleRules = (ruleTypeName, title, shipping, shippingId, rulesId) => {
    const queryParams = {
      page: 1,
      typeRuleId: typesId.typeSimple,
      typeShippingId: shippingId,
    }
    dispatch(requestGetRules(queryParams))
    setValues({
      type: 'fetch-rules',
      modalTitle: title,
      ruleType: { ruleTypeName, shipping },
      params: queryParams,
      rulesSelected: rulesId,
    })
    setShowAddRule(true)
  }

  const handleFetchGeographicRules = (
    ruleTypeName,
    title,
    shipping,
    shippingId,
    reference,
    referenceId,
    rulesId
  ) => {
    const queryParams = {
      page: 1,
      typeRuleId: typesId.typeGeographic,
      typeShippingId: shippingId,
      typeReferenceGeographicId: referenceId,
    }
    dispatch(requestGetRules(queryParams))
    const ruleTypeData = { ruleTypeName, shipping, reference }
    setValues({
      type: 'fetch-rules',
      modalTitle: title,
      ruleType: ruleTypeData,
      params: queryParams,
      rulesSelected: rulesId,
    })
    setShowAddRule(true)
  }

  const handleFetchMultistep = () => {
    const multistepsId = []
    rulesetData.multisteps.map((item) => {
      multistepsId.push(item._id)
      return multistepsId
    })
    dispatch(requestGetMultiSteps({ page: 1, text: '' }))
    setValues({
      type: 'fetch-multistep',
      multiSelected: multistepsId,
    })
    setShowAddMultistep(true)
  }

  const handleEditRulesetName = (rulesetName) => {
    dispatch(requestUpdateRuleset({ id, rulesetName }))
  }

  const handleUpdateRulesetStatus = (status) => {
    dispatch(requestUpdateRuleset({ id, active: !status }))
  }

  return (
    <>
      {rulesetData._id === id && !fetching ? (
        <ContentContainer
          title={
            <TitleTextEdit title={rulesetData.rulesetName} text="RuleSet" handleEditText={handleEditRulesetName} />
          }
          left={<BsArrowLeft size="1.5rem" onClick={() => history.push('/rules/ruleset')} />}
          right={
            <Switch
              defaultChecked={rulesetData.active}
              onClick={() => handleUpdateRulesetStatus(rulesetData.active)}
              checkedChildren="Activated"
              unCheckedChildren="Deactivated"
            />
          }
          subHeader={
            <Row gutter={[16, 16]}>
              <Col span={7}>
                <InputGeneric
                  prefix={<AiOutlineSearch style={{ color: '#002555' }} />}
                  placeholder="Search"
                  onChange={handleSearch}
                  onKeyPress={handleKeyPress}
                  allowClear
                />
              </Col>
              <Col span={6}>
                <Select
                  placeholder="Select rule type"
                  style={{ width: '100%' }}
                  value={showRule}
                  onSelect={(value) => setShowRule(value)}
                >
                  <SelectOption key="Geographic" value="Geographic">
                    Geographic
                  </SelectOption>
                  <SelectOption key="LengthofHaul" value="LengthofHaul">
                    Length of Haul
                  </SelectOption>
                  <SelectOption key="Date" value="Date">
                    Date
                  </SelectOption>
                  <SelectOption key="DateBased" value="DateBased">
                    Date Based
                  </SelectOption>
                  <SelectOption key="Multi-Steps" value="Multi-Steps">
                    Multi-Steps
                  </SelectOption>
                </Select>
              </Col>
              <Col xl={{ span: 5, offset: 6 }} xxl={{ span: 4, offset: 7 }}>
                <AvatarGroup
                  maxCount={5}
                  maxStyle={{ color: 'white', backgroundColor: '#3594FF', width: '40px', height: '40px' }}
                >
                  {rulesetData.customers.map((entry) => (
                    <Tooltip key={entry._id} title={entry.name}>
                      <Avatar size="large" src={entry.avatar || customerAvatar} />
                    </Tooltip>
                  ))}
                </AvatarGroup>
                <AvatarAdd size="large" onClick={() => setModal(true)}>
                  <Tooltip title="Add customer to ruleset">
                    <BsPlus size="1.8rem" style={{ marginTop: '2px' }} />
                  </Tooltip>
                </AvatarAdd>
              </Col>
            </Row>
          }
        >
          <Modal
            title="Assign RuleSet to Customer Company"
            visible={modalDisplay}
            onOk={() => setModal(false)}
            onCancel={() => setModal(false)}
            width={1200}
          >
            <CustomersRuleset
              customers={totalCustomers}
              customerSelected={rulesetData.customersId}
              loading={!success}
              close={setModal}
            />
          </Modal>
          <>
            <AddRulesModal
              visible={showAddRule}
              handleCancel={setShowAddRule}
              title={modalTitle}
              typeRule={ruleType}
              params={params}
              rulesSelected={rulesSelected}
            />
            <AddMultistep
              visible={showAddMultistep}
              handleCancel={setShowAddMultistep}
              multiSelected={multiSelected}
            />
            <Row gutter={[16, 64]}>
              {showRule === 'Geographic' && (
                <Col span={24}>
                  <LoadGeographicRule
                    handleFetchRules={handleFetchGeographicRules}
                    rules={rulesetData.totalRules.geographic}
                    rulesetData={rulesetData}
                    shippingData={shippingData}
                    referenceData={referenceData}
                  />
                </Col>
              )}
              {showRule === 'LengthofHaul' && (
                <Col span={24}>
                  {rulesetData.totalRules.lengthRule.length > 0 && (
                    <Table
                      columns={columns(dispatch, rulesetData.rulesId, rulesetData._id)}
                      data={rulesetData.totalRules.lengthRule}
                      loading={false}
                      rowKey="_id"
                      width="100%"
                      padding="4px 2px"
                    />
                  )}
                  <ButtonAdd
                    element="lenghtHNR"
                    onClick={() =>
                      handleFetchLengthRules('RuleLength', 'Length of Haul', rulesetData.totalRules.lengthRule)
                    }
                  >
                    New Rule
                  </ButtonAdd>
                </Col>
              )}
              {showRule === 'Date' && (
                <Col span={24}>
                  <LoadSimpleRule
                    handleFetchRules={handleFetchSimpleRules}
                    rules={rulesetData.totalRules.simple}
                    rulesetData={rulesetData}
                    shippingData={shippingData}
                  />
                </Col>
              )}
              {showRule === 'DateBased' && (
                <Col span={24}>
                  <LoadDateRangeRule
                    handleFetchRules={handleFetchDateRules}
                    rules={rulesetData.totalRules.dateRange}
                    rulesetData={rulesetData}
                    shippingData={shippingData}
                  />
                </Col>
              )}
              {showRule === 'Multi-Steps' && (
                <Col span={24}>
                  <>
                    {rulesetData.multisteps.map((item) => {
                      return <MultistepList data={item} rulesetData={rulesetData} key={item._id} />
                    })}
                    <ButtonAdd element="LenghtHNMulti" onClick={() => handleFetchMultistep()}>
                      New Multistep
                    </ButtonAdd>
                  </>
                </Col>
              )}
            </Row>
          </>
        </ContentContainer>
      ) : (
        <Row justify="center" align="center" style={{ height: '90%' }}>
          <SpinLoading style={{ margin: 'auto !important' }} />
        </Row>
      )}
    </>
  )
}

export default LoadRuleSet
