import { put, takeLatest, call } from 'redux-saga/effects'
import { roles } from 'services/'
import { requestGetRoles, successGetRoles, failureGetRoles } from 'store/duck/getRoles.duck'

function* getRolesAsync() {
  try {
    const response = yield call(roles.getRoles)
    yield put(successGetRoles(response))
  } catch (error) {
    yield put(failureGetRoles(error))
  }
}

function* watchGetRolesAsync() {
  yield takeLatest(requestGetRoles, getRolesAsync)
}

export default watchGetRolesAsync
