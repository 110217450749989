import React, { useState, useEffect } from 'react'
import Button from 'antd/lib/button'
import { useSelector } from 'react-redux'
import { AiOutlineUser } from 'react-icons/ai'
import ProfileMenu from 'components/Menu/LogoutMenu' 

import { StyledRow, StyledWelcomeLabel, TransformedAvatar } from './styles'

const TopBar = () => {
  const { data, success } = useSelector((state) => state.login)
  const newAvatar = useSelector((state) => state.updateAvatar)
  const [avatar, setAavatar] = useState(data.avatar)
  const [name, setName] = useState(data.name)
  const [setLastName] = useState(data.lastName)

  useEffect(() => {
    setAavatar(newAvatar.image)
    if (newAvatar.name && newAvatar.lastName) {
      setName(newAvatar.name)
      setLastName(newAvatar.lastName)
    }
  }, [newAvatar.image, newAvatar.name, newAvatar.lastName, setLastName])

  return (
    <StyledRow align="middle" justify="end">
      <ProfileMenu>
        <div className="ant-dropdown-link">
          {success && (
            <StyledWelcomeLabel>
              Hey, <strong>{name}!</strong>
            </StyledWelcomeLabel>
          )}
          <Button style={{ border: 'none', padding: '0px', marginRight: '2rem' }}>
            <TransformedAvatar size="large" src={avatar || data.avatar} icon={<AiOutlineUser />} />
          </Button>
        </div>
      </ProfileMenu>
    </StyledRow>
  )
}

export default TopBar
