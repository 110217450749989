export default function (state, action) {
  switch (action.type) {
    case 'createRate':
      return {
        ...state,
      }
    case 'addService':
      return {
        ...state,
        specialServices: [
          ...state.specialServices,
          { serviceId: action.payload.id, quantity: action.payload.quantity },
        ],
        currentQuantity: '',
        currentSpecialService: undefined,
      }
    case 'addCurrentService':
      return {
        ...state,
        currentSpecialService: action.payload.data,
        currentQuantity: action.payload.quantity,
      }
    case 'addCurrentQuantity':
      return { ...state, currentQuantity: action.payload.data }
    case 'rate-params':
      return { ...state, params: action.params }
    case 'clearSpecial':
      return {
        ...state,
        currentSpecialService: undefined,
        currentQuantity: '',
      }
    case 'remove-special-services':
      return { ...state, specialServices: [] }
    default:
      return state
  }
}
