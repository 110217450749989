import React, { useState, useCallback } from 'react'
import FloatLabel from 'components/Label/FloatLabel'

const InputWithLabel = ({ label, floatLabel, labelStyle, children }) => {
  const [show, setShow] = useState(false)

  const hasValue = useCallback((value) => {
    setShow(value !== null && value !== undefined && value !== 0);
  }, []);

  return (
    <FloatLabel label={label} show={floatLabel !== undefined ? floatLabel : show} style={labelStyle}>
      {children(hasValue)}
    </FloatLabel>
  )
}

export default InputWithLabel
