import { put, takeLatest, call } from 'redux-saga/effects'
import { customers } from 'services/'
import {
  requestGetDefaultCustomer,
  successGetDefaultCustomer,
  failureGetDefaultCustomer,
} from 'store/duck/getDefaultCustomer.duck'

function* getDefaultCustomerAsync() {
  try {
    const response = yield call(customers.getDefaultCustomer)
    yield put(successGetDefaultCustomer(response))
  } catch (error) {
    yield put(failureGetDefaultCustomer(error))
  }
}

function* watchGetDefaultCustomerAsync() {
  yield takeLatest(requestGetDefaultCustomer, getDefaultCustomerAsync)
}

export default watchGetDefaultCustomerAsync
