import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  lane: {},
  wayPoints: [],
}

export const { requestSaveStops, successSaveStops, failureSaveStops, clearSaveStops } = createActions({
  REQUEST_SAVE_STOPS: (lane, wayPoints) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    lane,
    wayPoints,
  }),
  SUCCESS_SAVE_STOPS: (lane, wayPoints) => ({
    lane,
    wayPoints,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_SAVE_STOPS: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  CLEAR_SAVE_STOPS: () => ({
    ...defaultState,
  }),
})

const saveStops = handleActions(
  {
    [requestSaveStops]: produce((draft, { payload: { fetching, error, success, lane, wayPoints } }) => {
      draft.fetching = fetching
      draft.error = error
      draft.success = success
      draft.wayPoints = wayPoints
      draft.lane = lane
    }),
    [successSaveStops]: produce((draft, { payload: { message, fetching, success, lane, wayPoints, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.wayPoints = wayPoints
      draft.lane = lane
    }),
    [failureSaveStops]: produce((draft, { payload: { message, fetching, error, success } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [clearSaveStops]: produce((draft, { payload: { message, fetching, error, success, lane, wayPoints } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
      draft.wayPoints = wayPoints
      draft.lane = lane
    }),
  },
  defaultState
)

export default saveStops
