export const changeMarketByGeographic = (columnParams = {}) => {
  const { type } = columnParams
  if (type && type === 'marketRule' || type && type === 'regionRule') {
    return { ...columnParams, type: 'geographicRule' }
  }
  return columnParams
}
// TODO: removed this dummy function when add a new fucntion in this file
export const changeMarketByGeographic2 = (columnParams = {}) => {
  const { type } = columnParams
  if (type && type === 'marketRule') {
    return { ...columnParams, type: 'geographicRule' }
  }
  return columnParams
}
