import { isEqual, uniqWith } from 'lodash'
import basicActionsDropdown from 'containers/rules/MasterRules/utils/basicActionsDropdown'
import basicMultiStepActionsDropDown from 'containers/rules/ListMultiStep/utils/basicMultiStepActionsDropdown'
import basicRulesetsActionsDropdown from 'containers/rules/Ruleset/ListRuleset/utils/basicRulesetsActionsDropdown'
import returnDateRanges from 'containers/rates/HistoryLanes/utils/HistoryLane.util'
import { formatStatistics } from './formatGraph'

const polyJS = require('./decodeHereMaps')

export const formatCustomer = (arr = []) => arr.map((item) => ({ label: item.value, value: item.id })).sort()

export const formatCustomerName = (arr = []) => arr.map((item) => ({ label: item.value, value: item.value }))

export const generateFormatPolyline = (rates) => {
  const poli = rates?.routeInfo
  const finalObject = { locations: [] }
  let data = []
  if (poli) {
    if (poli?.routes) {
      poli.routes.forEach((element) => {
        const encodedInput = polyJS.decode(element.polyline)
        data = data.concat(encodedInput.polyline)
      })
    }
  }
  if (poli?.originCoordinates) {
    finalObject.originCoordinates = { ...poli?.originCoordinates }
  }
  if (poli?.destinationCoordinates) {
    finalObject.destinationCoordinates = { ...poli?.destinationCoordinates }
  }
  if (poli?.stopCoordinates) {
    finalObject.stopCoordinates = [...poli?.stopCoordinates]
  }
  finalObject.poli = data
  return finalObject
}

export const formatPoints = (destinations = []) => {
  const arr = [...destinations]
  arr.shift()
  arr.pop()
  const points = []
  const getCity = (str = '') => str.split('-')
  const getState = (str = '') => str.split(',')
  const getDigitZip3 = (str = '') => str.slice(0, 4)

  const size = arr.length
  for (let index = 0; index < size; index += 1) {
    const element = arr[index]
    const firstSplit = getCity(element)
    const secondSplit = getState(firstSplit[0])
    const digitZip3 = getDigitZip3(firstSplit[1]).trim()
    points.push({
      zipCode: firstSplit[1].trim(),
      city: firstSplit[0].trim(),
      state: secondSplit[1].trim(),
      digitZip3,
    })
  }
  return points
}

export function fullNameCity(obj) {
  const city = obj?.content?.city ?? ''
  const zipCode = obj?.content?.zipCode ?? ''
  if (city !== '' && zipCode !== '') {
    return `${obj?.content?.city ?? ''} - ${obj?.content?.zipCode ?? ''}`
  }
  return ''
}

export function formatStops(st = []) {
  return st.map((item) => {
    return fullNameCity(item)
  })
}

/**
 * we use this function to create an arrays of objects, each object has the properties to display a mark in the map
 * @param {*} lane a string that is got of saveStops
 * @param {*} wayPoints a string that is got of saveStops
 * @param {*} originCoordinates a object that is got of getrate success
 * @param {*} destinationCoordinates a object that is got of getrate success
 * @param {*} stopsCoordinates an array that is got of getrate success
 * @returns
 */
export const formatContent = (
  lane,
  wayPoints,
  originCoordinates,
  destinationCoordinates,
  stopsCoordinates = []
) => {
  const locations = []
  const format = {
    coordinate: {},
    content: {},
    icon: '',
  }

  const types = ['Origin', 'Destination']
  const coordinates = {
    Origin: originCoordinates ?? {},
    Destination: destinationCoordinates ?? {},
  }
  const icons = {
    Origin: '/static/media/truck.cac5513c.svg',
    default: '/static/media/location.a21645b9.svg',
  }

  const generateContent = (type = '', lanes = {}, coordinate = {}) => {
    const lowerType = type.toLowerCase()
    const newFormat = { ...format }
    if (lanes[lowerType]) {
      const { city, zipCode } = lanes[lowerType]
      newFormat.content = { type, city, zipCode }
      if (icons[type]) newFormat.icon = icons[type]
      else newFormat.icon = icons.default
      if (coordinate) newFormat.coordinate = coordinate
    }
    return newFormat
  }

  if (typeof lane === 'string') {
    const jsonLane = JSON.parse(lane)
    types.forEach((element) => {
      locations.push(generateContent(element, jsonLane, coordinates[element] ?? {}))
    })
  }
  if (typeof wayPoints === 'string') {
    const jsonWayPoints = JSON.parse(wayPoints)
    if (Array.isArray(jsonWayPoints)) {
      const size = jsonWayPoints.length
      for (let index = 0; index < size; index += 1) {
        const element = jsonWayPoints[index]
        const newFormat = { ...format }
        const type = 'Way Point'
        const { city, zipCode } = element
        newFormat.content = { type, city, zipCode }
        newFormat.icon = icons.default
        newFormat.coordinate = stopsCoordinates[index]
        locations.push(newFormat)
      }
    }
  }

  return locations
}

/**
 *
 * @param {} obj object form of the search bar in lane history
 * @returns
 */
export const formatParamsSearchLane = (obj = {}) => {
  let newParams = {}
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      const element = obj[key]
      const isDate = element?._isAMomentObject
      if (element) {
        if (typeof element === 'object' && !isDate) {
          if (element.value) {
            newParams[key] = element.value
          } else if (Object.keys(element).length > 0) {
            newParams = { ...newParams, ...returnDateRanges(obj) }
          }

        } else {
          newParams[key] = element
        }
      }
    }
  } 
  return newParams
}

const formatStateLane = (city = '', state = '') => {
  let clean = ''
  const array = city.split(',')
  if (array.length > 0) {
    array.forEach((element) => {
      if (clean === '') clean += element.trim()
      else clean = `${clean}, ${element.replace(/\s/g, '')}`
    })
    if (array.length === 1) {
      clean = `${clean}, ${state}`
    }
  }
  return clean
}
/**
 *
 * @param {*} obj lane object from lane history
 * @param {*} toSearch string to search the specific origin or destination
 * @returns
 */
export const getStateLaneHistory = (obj = {}, toSearch = '') => {
  let value = ''
  const main = obj?.lane
  if (main) {
    if (main[toSearch]) {
      const city = main[toSearch]?.city
      const state = main[toSearch]?.state
      value = formatStateLane(city, state)
    }
  }
  return value
}

export const formatResultsZipCode = (obj) => {
  let items = []
  const main = obj?.Response?.View
  if (main && Array.isArray(main)) {
    if (main[0]?.Result) {
      items = main[0]?.Result.map((item, index) => {
        const { Address } = item.Location
        return {
          key: `${Address.PostalCode.substring(0, 5)}-${index}`,
          value: `${Address.City}, ${Address.State} - ${Address.PostalCode.substring(0, 5)}`,
          searchparameter: `${Address.City}, ${Address.State}, ${Address.PostalCode.substring(0, 5)}`,
        }
      })
    }
  }
  return items
}

const JoinRulesOperators = (operators, rules) => {
  const joinExpression = []

  const sO = operators.length
  let makeIndex = 0
  for (let index = 0; index < sO; index += 1) {
    const element = operators[index]
    const previous = operators[index - 1]
    // console.log({ previous, element })
    if (!previous && element) {
      if (element === '(') {
        joinExpression.push(element)
      } else {
        joinExpression.push(rules[makeIndex])
        joinExpression.push(element)
        makeIndex += 1
      }
    }
    if (previous === 'and' && element === '(') {
      joinExpression.push(element)
    }
    if (previous === '(' && element === 'or') {
      joinExpression.push(rules[makeIndex])
      joinExpression.push(element)
      makeIndex += 1
    }
    if (previous === element) {
      joinExpression.push(rules[makeIndex])
      joinExpression.push(element)
      makeIndex += 1
    }
    if (previous === 'or' && element === ')') {
      joinExpression.push(rules[makeIndex])
      joinExpression.push(element)
      makeIndex += 1
    }
    if (previous === ')' && element === 'and') {
      joinExpression.push(element)
    }
    if (previous === 'and' && element === 'end') {
      joinExpression.push(rules[makeIndex])
      joinExpression.push(element)
    }
    if (previous === ')' && element === 'end') {
      joinExpression.push(element)
    }
  }

  return joinExpression
}

const getOperators = (array = []) => {
  const size = array.length
  const operators = []
  for (let index = 0; index < size; index += 1) {
    const element = array[index]
    const isOperator = () => element.length < 5
    if (isOperator()) {
      operators.push({ operator: element, position: index })
    }
  }
  return operators
}

export const generateExpression = (multisteps = {}) => {
  const expression = []
  const { sentences } = multisteps
  if (sentences && Array.isArray(sentences)) {
    sentences.forEach((element) => {
      let { ruleId } = element
      const { negation, condition } = element
      // add not together rule to not lost the right position to apply not
      if (negation) {
        ruleId = `not${ruleId}`
      }
      expression.push(ruleId)
      expression.push(condition.toLowerCase())
    })
  }

  const getOperator = getOperators(expression)
  // console.log(getOperator)

  const sizeExp = expression.length
  const newExp = []
  // include parenthesis
  for (let index = 0; index < getOperator.length; index += 1) {
    const element = getOperator[index]
    const { operator } = element
    const previous = getOperator[index - 1]
    const oPrevious = previous?.operator
    // console.log({ oPrevious, operator })
    if (oPrevious && operator) {
      if (oPrevious === 'and' && operator === 'or') {
        newExp.push('(')
        newExp.push(operator)
      } else if (oPrevious === 'and' && operator === 'and') {
        newExp.push(operator)
      } else if (oPrevious === 'or' && operator === 'and') {
        newExp.push(')')
        newExp.push(operator)
      } else if (oPrevious === 'or' && operator === 'or') {
        newExp.push(operator)
      } else if (oPrevious === 'or' && operator === 'end') {
        newExp.push(')')
        newExp.push(operator)
      } else if (operator === 'end') {
        newExp.push(operator)
      }
    } else {
      if (operator === 'or') {
        newExp.push('(')
      }
      newExp.push(operator)
    }
  }

  // separte rules
  const rules = []
  for (let index = 0; index < sizeExp; index += 1) {
    const element = expression[index]
    // valid if we had to do a validation
    const operatorObject = getOperator.filter((item) => item.position === index)
    const makeValid = operatorObject.length > 0
    if (!makeValid) {
      rules.push(element)
    }
  }

  // splice  not operator form each rule
  const joinRulesAndOperators = JoinRulesOperators(newExp, rules)
  const sizeJoin = joinRulesAndOperators.length
  const lastExpression = []
  for (let index = 0; index < sizeJoin; index += 1) {
    const element = joinRulesAndOperators[index]
    const word = element.substring(0, 3)
    if (word === 'not') {
      const last = element.length
      const spliceElement = element.substring(3, last)
      lastExpression.push(word)
      lastExpression.push(spliceElement)
    } else {
      lastExpression.push(element)
    }
  }
  return lastExpression
}

export const mixingInternalAndGreenScreens = (greenScreens = [], graphics = [], filter = 'month') => {
  const newGraphics = []
  let createInternalData = true
  const createData = () => {
    let internalData = []
    const id = 'Internal'
    const x = 'loadPickupDate'
    const y = 'totalCarrierCost'
    const format = formatStatistics(greenScreens, id, x, y, true)
    internalData = uniqWith(format[0].data, isEqual)
    return internalData
  }

  if (filter === 'month') {
    return graphics
  }
  graphics.forEach((element) => {
    if (element.id === 'Internal') {
      createInternalData = false
      newGraphics.push({ id: element.id, data: createData().slice(0, 20) })
    } else {
      newGraphics.push(element)
    }
  })

  if (createInternalData && greenScreens.length > 0) {
    newGraphics.push({ id: 'Internal', data: createData().slice(0, 20) })
    return newGraphics
  }
  const ids = newGraphics.map((item) => item.id)
  if ([...ids].findIndex((item) => item === 'Internal') < 0) {
    newGraphics.push({ id: 'Internal', data: [] })
  }

  return newGraphics
}

export const formatCustomerAssigned = (array = []) =>
  array.map((customer, i) => {
    return { order: i + 1, id: customer._id }
  })

export const formatCustomersFromUsers = (arrayCustomers) => {
  return arrayCustomers.map(({ id }) => {
    return {
      _id: id._id,
      value: id.name,
    }
  })
}

const removeActions = (columns = [], editable = true) => {
  const newColumns = [...columns]
  if (!editable) {
    newColumns.pop()
  }
  if (!editable) {
    newColumns.map((item) => {
      if (item?.key === 'flat' || item?.key === 'margin') {
        item.editable = editable
      }
      return item
    })
  }
  return newColumns
}

export const replaceActions = (columns = [], editable = true, dispatch, openDrawer) => {
  const newColumns = removeActions(columns, editable)

  if (!editable) {
    newColumns.push({
      ...basicActionsDropdown(dispatch, openDrawer),
    })
  }
  return newColumns
}

export const replaceMultiStepActions = (columns = [], editable = true, dispatch, openDrawer) => {
  const newColumns = removeActions(columns, editable)

  if (!editable) {
    newColumns.push({
      ...basicMultiStepActionsDropDown(dispatch, openDrawer),
    })
  }
  return newColumns
}

export const replaceRulesetsActions = (
  columns = [],
  editable = true,
  dispatch = (param) => param,
  openDrawer = (param) => param
) => {
  const newColumns = [...columns]
  if (!editable) {
    newColumns.pop()
  }

  if (!editable) {
    newColumns.push({
      ...basicRulesetsActionsDropdown(dispatch, openDrawer),
    })
  }
  return newColumns
}
