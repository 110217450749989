export default function (api) {
  return {
    getUsers: (params) => api.get(`api/users`, { params: { limit: 12,...params } }),
    createUser: (userData) => api.post('api/users', userData),
    editUser: (userData, userID) => api.put(`api/users/${userID}`, userData),
    deactivateUser: (userID) => api.delete(`api/users/${userID}`),
    getUser: (userId) => api.get(`api/users/${userId}`),
    resendInvite: (userEmail) => api.post(`api/users/resend-invitation`, userEmail)
  }
}
