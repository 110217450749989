import React from 'react'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import NumberInput from 'components/Form/NumerInput'
import { StyledText } from '../styles'

const renderMCIRule = (enableEdit = true) => {
    return (
        <Row gutter={10}>
            <Col span={24} style={{ marginBottom: 10 }}>
                <StyledText>If MCI is between</StyledText>
            </Col>
            <Col span={12}>
                <NumberInput
                    disabled={!enableEdit}
                    name="udMCIinit"
                    rules={[
                        { required: true, message: 'Please enter a value' },
                        {
                            type: 'number',
                            min: -100,
                            max: 99,
                            message: 'The value should not be less than -100',
                            transform: Number,
                        },
                    ]}
                />
            </Col>
            <Col span={12}>
                <NumberInput 
                    disabled={!enableEdit}
                    name="udMCIend"
                    rules={[
                        { required: true, message: 'Please enter a value' },
                        {
                            type: 'number',
                            max: 100,
                            min: -99,
                            message: 'The value should not be more than 100',
                            transform: Number,
                        },
                    ]}
                />
            </Col>
        </Row>
    )
}
export default renderMCIRule
