import styled from 'styled-components'
import Row from 'antd/lib/row'
import Tabs from 'antd/lib/tabs'
import Badge from 'antd/lib/badge'

export const DivContainer = styled.div`
  margin: 0px;
  padding: 0px;
`

export const StyledDiv = styled.div`
  width: 96%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: auto;
  margin-top: 30px;
`

export const StyledRow = styled(Row)`
  width: 96%;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-end;
`

export const StyledTabs = styled(Tabs)`
  width: ${(props) => (props.width ? props.width : '100%')};
  .ant-tabs-tab {
    padding-bottom: 2px;
    &.ant-tabs-tab-active .ant-tabs-tab-btn {
      font-weight: 700;
    }
  }
`

const stylesByStatus = {
  Activated: {
    count: 'Activated',
    backgroundColor: '#00C48C',
    color: '#FFFFFF',
  },
  Deactivated: {
    count: 'Blocked',
    backgroundColor: '#F85359',
    color: '#FFFFFF',
  },
  Paused: {
    count: 'Paused',
    backgroundColor: '#F7981C',
    color: '#FFFFFF',
  },
}

export const StyledBadge = styled(Badge).attrs((props) => ({
  count: stylesByStatus[props.variant].count,
  style: {
    backgroundColor: stylesByStatus[props.variant].backgroundColor,
    color: stylesByStatus[props.variant].color,
  },
}))``
