import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: {},
  customerId: '',
}

export const { requestEditCustomer, successEditCustomer, failureEditCustomer, resetEditCustomer } = createActions({
  REQUEST_EDIT_CUSTOMER: (data, customerId) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    data,
    customerId,
  }),
  SUCCESS_EDIT_CUSTOMER: (data) => ({
    data,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_EDIT_CUSTOMER: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  RESET_EDIT_CUSTOMER: () => ({
    ...defaultState,
  }),
})

const editCustomer = handleActions(
  {
    [requestEditCustomer]: produce((draft, { payload: { fetching, data, error, success } }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successEditCustomer]: produce((draft, { payload: { message, fetching, success, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = false
      draft.data = data
    }),
    [failureEditCustomer]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = false
    }),
    [resetEditCustomer]: produce((draft, { payload: { message, fetching, success, error, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data
    }),
  },
  defaultState
)

export default editCustomer
