import { put, takeLatest, call } from 'redux-saga/effects'
import { settings } from 'services'
import {
  requestUpdateTrustPercentage,
  successUpdateTrustPercentage,
  failureUpdateTrustPercentage,
} from '../duck/updateTrustPercentage.duck'

function* updateTrustPercentageAsync(data) {
  try {
    const response = yield call(settings.updateTrustPercentage, data.payload.data)
    yield put(successUpdateTrustPercentage(response))
  } catch (error) {
    yield put(failureUpdateTrustPercentage(error.response.data.message))
  }
}

function* watchUpdateTrustPercentageAsync() {
  yield takeLatest(requestUpdateTrustPercentage, updateTrustPercentageAsync)
}

export default watchUpdateTrustPercentageAsync
