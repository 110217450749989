/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

export const StyledSubtitle = styled.div`
  margin-top: -20px;
  color: #3594ff;
  font-size: 19px;
  margin-bottom: 20px;

  .subtitleRate {
    font-weight: bold;
  }
`

export const StyledForm = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: -20px;
`
