const isDate = (toValidate) => {
  return toValidate instanceof Date
}

function isObject(object) {
  return object != null && typeof object === 'object' && !Array.isArray(object)
}

const jsonDiff = (select, send) => {
  let obj = {}
  for (const key in send) {
    if (Object.hasOwnProperty.call(send, key)) {
      const newValue = send[key]
      const previous = select[key]
      if (!isObject(newValue) && !isObject(previous)) {
        let arrayNew = ''
        let arrayOld = ''
        if (Array.isArray(newValue) && Array.isArray(previous)) {
          arrayNew = JSON.stringify(newValue)
          arrayOld = JSON.stringify(previous)
          if (arrayNew !== '' && arrayOld !== '') {
            if (arrayNew !== arrayOld) {
              obj = Object.assign(obj, { [key]: newValue })
            }
          }
        } else if (newValue !== previous) {
          obj = Object.assign(obj, { [key]: newValue })
        }
      } else if (isDate(newValue)) {
        const pre = JSON.stringify(previous)
        const next = JSON.stringify(newValue)
        if (next !== pre) {
          obj = Object.assign(obj, { [key]: newValue })
        }
      } else {
        obj = Object.assign(obj, { [key]: jsonDiff(previous, newValue) })
      }
    }
  }
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      if (JSON.stringify(obj[key]) === '{}') {
        delete obj[key]
      }       
    }
  }
  return obj
}

export default jsonDiff
