import { put, takeLatest, call } from 'redux-saga/effects'
import { specialServices } from 'services/'
import {
  requestGetSpecialServicesList,
  successGetSpecialServicesList,
  failureGetSpecialServicesList,
} from '../duck/getSpecialServices.duck'

function* getSpecialServicesListAsync(data) {
  try {
    const response = yield call(specialServices.getSpecialServicesList, data.payload.params)
    yield put(successGetSpecialServicesList(response))
  } catch (error) {
    yield put(failureGetSpecialServicesList(error))
  }
}

function* watchGetSpecialServicesListAsync() {
  yield takeLatest(requestGetSpecialServicesList, getSpecialServicesListAsync)
}

export default watchGetSpecialServicesListAsync
