import React from 'react'
import Dropdown from 'antd/lib/dropdown'
import { StyledButton, StyledIcon } from './styles'

const MoreDropdown = ({ menu }) => {
  return (
    <Dropdown overlay={menu} placement="bottomRight">
      <StyledButton>
        <StyledIcon />
      </StyledButton>
    </Dropdown>
  )
}

export default MoreDropdown
