export default function (api) {
  return {
    getCustomers: (params) =>
      api.get('api/customers', {
        params: {
          limit: 1000,
          ...params,
        },
      }),
    createCustomer: (data) => api.post('api/customers', data),
    editCustomer: (customerData, customerID) => api.put(`api/customers/${customerID}`, customerData),
    updateBulkFuelPrice: (customerData) => api.put('api/customers/customFuelPrices', customerData),
    setDefault: (customerID) => api.put(`api/customers/default/${customerID}`),
    getDefaultCustomer: () => api.get('api/customers/get/default'),
    getCustomer: (customerID) => api.get(`api/customers/${customerID}`),
    deleteCustomer: (customerID) => api.delete(`api/customers/${customerID}`),
    getRules: (params) => {
      return api.get('api/customers/rules', {
        params: {
          limit: 20,
          ...params,
        },
      })
    },
    getMultiSteps: (params) => {
      const defaultValue = params?.customer?.length > 0 ? params.customer : '[]'
      const customer = params.customer ? defaultValue : '[]'
      const queryObject = { customer }

      return api.get('api/customers/multisteps', {
        params: {
          ...params,
          limit: 20,
          ...queryObject
        },
      })
    },
  }
}
