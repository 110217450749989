/**
 *
 * @param {*} filter object with props to search and its values to compare
 * @param {*} array
 * @returns
 */
const filterArrayByProps = (filter = {}, array = []) => {
 
  return array.filter((item) => {
    for (const key in filter) {
      if (item[key] === undefined || item[key] !== filter[key]) return false
    }
    return true
  })
}

export default filterArrayByProps
