export const getRouteMarkersValue = (hereData = {}, position = 0, props = [], mainProp = "routeMarkers") => {
    let value = ""
    if (hereData) {
        if (hereData[mainProp]) {
            const marker = hereData[mainProp][position]
            if (marker) {
                let deepValue = marker
                const size = props.length
                for (let index = 0; index < size; index += 1) {
                    deepValue = deepValue[props[index]]
                }
                value = deepValue
            }
        }
    }
    return value

}

export const totalMovementsFromRoutes = (routeData = {}) => {
    try {
        let total = 0
        const datas = routeData.data
        if (datas) {
            if (datas.routes) {
                if (datas.routes[0]) {
                    total = datas.routes[0].sections.length
                }
            }
        }
        return total
    } catch (error) {
        console.error({ from: "totalMovementsFromRoutes", payload: routeData })
        return 0
    }
}

export function returnEntity(name, markersParam) {
    return markersParam.filter((ele) => ele?.content?.type === name)[0] ?? {}
}

export const stringToHereValue = (input = "") => {
    const [cityState, zipCode] = input.split(" - ");
    const [city, state] = cityState.split(", ");
    return {
        zipCode, city: `${city}, ${state}`, state
    }

}

const addDigitZip3 = (object = {}) => {
    const digitZip3 = object.zipCode.slice(0, 3)
    return { ...object, digitZip3 }
}

export const convertToLane = (stringOrigin = "", stringDestination = "") => {
    const origin = addDigitZip3(stringToHereValue(stringOrigin))
    const destination = addDigitZip3(stringToHereValue(stringDestination))
    return JSON.stringify({ origin, destination })
}

export const isValidForLane = (value = "") => {
    return value && value !== ""
}


