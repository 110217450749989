import React from 'react'
import Col from 'antd/lib/col'
import { Result } from 'antd'
import { Link } from 'react-router-dom' 
import { ErrorContainer, ErrorWrapper } from './styles'

const ErrorServer = () => {

  return (
    <ErrorContainer justify="center"> 
      <Col span={24}>
        <ErrorWrapper>
          <Result
            status="error"
            title="Server Error"
            subTitle="Sorry, something went wrong. Try again in a few minutes."
            extra={[
              <Link to="/login">Go to Login </Link>
            ]}
          />
        </ErrorWrapper>
        
      </Col>
    </ErrorContainer>
  )
}

export default ErrorServer
