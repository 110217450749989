import styled from 'styled-components'

export const ContainerGraphics = styled.div`
  border: 1px solid rgba(217, 217, 217, 0.1);
  box-shadow: rgb(217 217 217 / 20%) 0px 10px 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white; 
`

export const SimbolGraph = styled.span` 
.material-symbols-outlined {
  font-variation-settings:
  'FILL' 1,
  'wght' 400,
  'GRAD' -25,
  'opsz' 48
} `
 
