import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  rates: [],
  totalRecords: 0,
  params: {},
}

export const { requestGetAllRates, successGetAllRates, failureGetAllRates } = createActions({
  REQUEST_GET_ALL_RATES: (params) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    params,
  }),
  SUCCESS_GET_ALL_RATES: (rates) => ({
    rates: rates.data,
    totalRecords: rates.totalRecords,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_ALL_RATES: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
})

const getAllRates = handleActions(
  {
    [requestGetAllRates]: produce((draft, { payload: { fetching, data, error, success } }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successGetAllRates]: produce(
      (draft, { payload: { message, fetching, success, rates, totalRecords, error } }) => {
        draft.message = message
        draft.fetching = fetching
        draft.success = success
        draft.error = error
        draft.rates = rates
        draft.totalRecords = totalRecords
      }
    ),
    [failureGetAllRates]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = !error
    }),
  },
  defaultState
)

export default getAllRates
