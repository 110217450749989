import uniqWith from 'lodash/uniqWith'
import isEqual from 'lodash/isEqual'

const removeDuplicatedObjects = (object) => {
  const graphic = []
  for (const key in object) {
    if (Object.hasOwnProperty.call(object, key)) {
      const element = object[key]
      const splitData = uniqWith(element.data, isEqual)
      const newElement = { ...element }
      newElement.data = [...splitData]
      graphic.push(newElement)
    }
  }
  return graphic
}

export default removeDuplicatedObjects
