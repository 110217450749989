import { removeStorage } from 'utils/storage'
import notify from 'utils/notify' 

export default function (api) {
  return {
    loginUser: (data) => api.post('api/authentications/login', data),
    activeAccount: (data) => api.post('api/authentications/reset-password', data),
    resetPassword: (data) => api.post('api/authentications/reset-password', data),
    forgotPassword: (data) => api.get(`api/authentications/forgot-password/${data.email}`),
    refreshToken: () => {
      const refreshToken = window.localStorage.getItem('altprtk')
      if (refreshToken !== null) {
        return api.post('/api/authentications/token', { token: refreshToken }).then((resp) => {
 
          return resp
        }).catch((e) => {
          removeStorage('expiration')
          removeStorage('altptk')
          removeStorage('altprtk')
          removeStorage('altpntk')
          notify('info', 'Your session has expired')           
          return e
        })
      }
      return null 

    },
  }
}
