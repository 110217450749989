import React from 'react'
import PropTypes from 'prop-types'
import Box from './Box'

Box.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  Header: PropTypes.node,
  secondary: PropTypes.bool,
  borderless: PropTypes.bool,
  backgroundColor: PropTypes.bool,
  bordertop: PropTypes.bool,

}

Box.defaultProps = {
  title: '',
  width: '100%',
  height: '100%',
  Header: <div />,
  secondary: false,
  borderless: false,
  backgroundColor: false,
  bordertop: false,
}

export default Box
