import styled from 'styled-components'

const MapContainer = styled.div`
  width: 100%;
  height: 100%;

  & .H_ib_content {
    margin: 5px 10px 0px 10px;
    font-family: 'Roboto', sans-serif;
  }
  & .dotted {
    border-top: 2px dotted #125fb5;
  }
  & .mapContent {
    min-width: 150px;
    font-size: 13px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    color: #a4a5a6;
    text-align: left;
    line-height: 8px;
    margin-left: 20px;
  }
  & .H_l_anchor .H_l_horizontal {
    display: none;
  }
  & .H_l_bottom {
    bottom: 10px;
    & .H_l_right {
      right: 100%;
    }
  }
  & .H_btn {
    background: white;
    width: 25px;
    height: 25px;
    & svg.H_icon {
      width: 10px;
      height: 10px;
    }
  }
  & .H_rdo .H_btn.H_active {
    width: 150px;
  }
  & .H_ctl.H_grp {
    background: white;
  }
  & .H_l_vertical .H_zoom .H_el::after {
    left: 0px;
    background: rgba(255, 255, 255, 0.3);
  }
  & .H_ib_close .H_btn {
    top: 2px;
    right: 0px;
    background: none;
    height: 20;
    & svg.H_icon {
      width: 10;
      padding: 0.5em;
    }
  }
`

export default MapContainer
