import { put, takeLatest, call } from 'redux-saga/effects'
import { rules } from 'services'
import { requestUpdateRuleset, successUpdateRuleset, failureUpdateRuleset } from '../duck/updateRuleset.duck'

function* updateRulesetAsync(data) {
  try {
    const response = yield call(rules.updateRuleset, data.payload.data)
    yield put(successUpdateRuleset(response))
  } catch (error) {
    yield put(failureUpdateRuleset(error.response.data.message))
  }
}

function* watchUpdateRulesetAsync() {
  yield takeLatest(requestUpdateRuleset, updateRulesetAsync)
}

export default watchUpdateRulesetAsync
