import React, { useState } from 'react'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Typography from 'antd/lib/typography'
import { MdDelete } from 'react-icons/md'
import { IoMdSwitch } from 'react-icons/io'
import ModalConfirmation from 'components/ModalConfirmation/ModalConfirmation'
import { requestDeleteBetaRules } from 'store/duck/deleteBetaRules.duck'
import { requestSwitchBetaRules } from 'store/duck/switchBetaRules.duck'
import { useDispatch } from 'react-redux'
import { StyledRowBeta } from '../utils/styles'

const { Text } = Typography
const defaultPropsModal = {
  visible: false,
  message: ' ',
  icon: (props) => props,
  textButon: '',
  textSuccessfull: '',
  actionConfirm: (props) => props,
}

export default function DeleteBetaRules() {
  const [propsModal, setPropsModal] = useState(defaultPropsModal)
  const dispatch = useDispatch()

  const close = () => {
    setPropsModal({
      visible: false,
      message: ' ',
      icon: (props) => props,
      textButon: '',
      textSuccessfull: '',
      actionConfirm: (props) => props,
    })
  }

  const confirmDeleteRules = () => {
    dispatch(requestDeleteBetaRules())
    close()
  }

  const confirmSwitchRules = () => {
    dispatch(requestSwitchBetaRules())
    close()
  }

  const deleteBetas = () => {
    setPropsModal({
      visible: true,
      message: `Are you sure you want to delete Beta rules?`,
      // eslint-disable-next-line react/jsx-props-no-spreading
      icon: (props) => <MdDelete {...props} />,
      textButon: 'Delete',
      textSuccessfull: `The Beta rules has been deleted successfully!`,
      actionConfirm: confirmDeleteRules,
    })
  }
  // IoMdSwitch
  const switchBetaRules = () => {
    setPropsModal({
      visible: true,
      message: `Are you sure you want to switch Beta rules?`,
      // eslint-disable-next-line react/jsx-props-no-spreading
      icon: (props) => <IoMdSwitch {...props} />,
      textButon: 'Update',
      textSuccessfull: `The Beta rules has been updating successfully!`,
      actionConfirm: confirmSwitchRules,
    })
  }

  return (
    <Row gutter={16}>
      <ModalConfirmation
        actionConfirm={propsModal.actionConfirm}
        textSuccessfull={propsModal.textSuccessfull}
        textButton={propsModal.textButon}
        close={close}
        icon={propsModal.icon}
        message={propsModal.message}
        visible={propsModal.visible}
        height="296px"
        width={350}
        centered
        avoidNotify
      />
      <Col>
        <StyledRowBeta onClick={switchBetaRules}>
          <IoMdSwitch style={{ fontSize: '24px' }} />
          <Text underline style={{ marginLeft: '7px', color: 'inherit', fontWeight: 500, fontSize: '16px' }}>
            Switch alpha
          </Text>
        </StyledRowBeta>
      </Col>
      <Col>
        <StyledRowBeta onClick={deleteBetas}>
          <MdDelete style={{ fontSize: '24px' }} />
          <Text underline style={{ marginLeft: '7px', color: 'inherit', fontWeight: 500, fontSize: '16px' }}>
            Delete beta
          </Text>
        </StyledRowBeta>
      </Col>
    </Row>
  )
}
