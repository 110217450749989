import React from 'react'
import Modal from 'components/Modal'
import Row from 'antd/lib/row'
import UserProfileInfo from 'containers/users/UserProfileInfo'

export default function ModalUserProfile({ visible, close }) {
  return (
    <Modal
      centered={false}
      style={{ top: 100 }}
      title="User Profile"
      visible={visible}
      onCancel={() => {
        close(false)
      }}
      width={880}
      height="570px"      
    >
      <Row>
        <UserProfileInfo />
      </Row>
    </Modal>
  )
}
