const colors = {
  dark_blue: {
    default: '#002555',
    hover: '#0B2A53',
    focus: '#001633',
  },
  light_blue: {
    default: '#3594FF',
  },
  primary_2: {
    default: '#0095fa',
  },
  dark_grey: {
    default: '#545454',
  },
  light_grey: {
    default: '#d9d9d9',
    opacity: 'rgba(217, 217, 217,0.4)',
  },
  bright_white: {
    default: '#fffff9',
  },
  secondary_1: {
    default: '#253136',
    hover: '#2f3538',
    focus: '#171e21',
  },
  secondary_2: {
    default: '#6b7f8c',
    hover: '#7b868d',
    focus: '#44525b',
  },
  med_blue: {
    default: '#273136',
  },
  second_med_blue: {
    default: '#6e7f8d',
  },
  red: {
    default: '#fa2e2e',
  },
  white: {
    default: '#ffffff',
    hover: '#ffffff',
    focus: '#ffffff',
  },
  modal_white: {
    default: '#f9fafe',
  },
}

export default colors
