import { put, takeLatest, call } from 'redux-saga/effects'
import { rules } from 'services'
import { requestReturnVersionRule, successReturnVersionRule, failureReturnVersionRule } from '../duck/returnVersionRule.duck'

function* returnVersionRuleAsync(data) {
  try {
    const response = yield call(rules.returnVersionRule, data.payload.data)
    yield put(successReturnVersionRule(response))
  } catch (error) {
    yield put(failureReturnVersionRule(error.response.data.message))
  }
}

function* watchReturnVersionRuleAsync() {
  yield takeLatest(requestReturnVersionRule, returnVersionRuleAsync)
}

export default watchReturnVersionRuleAsync
