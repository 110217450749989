import { put, takeLatest, call } from 'redux-saga/effects'
import { rates } from 'services'
import { requestCreateRate, successCreateRate, failureCreateRate } from '../duck/createRate.duck'

function* createRateAsync(data) {
  
  try {
    const response = yield call(rates.createRate, data.payload.rate)
    yield put(successCreateRate(response))
  } catch (error) {
    yield put(failureCreateRate(error))
  }
}

function* watchCreateRateAsync() {
  yield takeLatest(requestCreateRate, createRateAsync)
}

export default watchCreateRateAsync
