import PropTypes from 'prop-types'
import CustomersRuleset from './CustomersRuleset'

CustomersRuleset.propTypes = {
  customers: PropTypes.array.isRequired,
  close: PropTypes.func.isRequired,
  customerSelected: PropTypes.array.isRequired,
  loading: PropTypes.bool,
}

CustomersRuleset.defaultProps = {
  loading: false,
}

export default CustomersRuleset
