import styled from 'styled-components'
import Badge from 'antd/lib/badge'
import Typography from 'antd/lib/typography'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

const { Text } = Typography
const stylesByStatus = {
  Activated: {
    count: 'Activated',
    backgroundColor: '#00C48C',
    color: '#FFFFFF',
  },
  Deactivated: {
    count: 'Blocked',
    backgroundColor: '#F85359',
    color: '#FFFFFF',
  },
  Paused: {
    count: 'Paused',
    backgroundColor: '#F7981C',
    color: '#FFFFFF',
  },
}
export const StyledRow = styled(Row)({
  display: 'flex',
  alignItems: 'center',
  padding: '0px 5px',
  width: '100%',
  height: '40px',
  margin: '0px',
  color: '#002555',
  borderRadius: '4px',
  '&:hover': {
    background: '#3594FF',
    color: '#FFFFFF',
  },
})

export const StyledCol = styled(Col)({
  display: 'flex',
  alignItems: 'center',
  padding: '1px',
})

export const StyledText = styled(Text)`
  color: #545454 !important;
  font-weight: 400 !important;
  margin-left: 2px;
`
export const TitleText = styled(Text)`
  color: #545454 !important;
  font-weight: 600 !important;
  margin-right: 2px;
`

export const StyledBadge = styled(Badge).attrs((props) => {
  const variant = stylesByStatus[props.variant] ?? stylesByStatus.Activated

  return {
    count: variant.count,
    style: {
      backgroundColor: variant.backgroundColor,
      color: variant.color,
      fontWeight: 400,
      fontSize:16
    },
  }
})``
