import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  multiSteps: [],
  totalRecords: 0,
  params: {},
}

export const {
  requestGetMultiStepsByCustomer,
  successGetMultiStepsByCustomer,
  failureGetMultiStepsByCustomer,
  clearGetMultiStepsByCustomer,
} = createActions({
  REQUEST_GET_MULTI_STEPS_BY_CUSTOMER: (params) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    params,
  }),
  SUCCESS_GET_MULTI_STEPS_BY_CUSTOMER: (multiSteps) => ({
    multiSteps: multiSteps.data,
    totalRecords: multiSteps.totalRecords,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_MULTI_STEPS_BY_CUSTOMER: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  CLEAR_GET_MULTI_STEPS_BY_CUSTOMER: () => ({
    ...defaultState,
  }),
})

const getMultiStepsByCustomer = handleActions(
  {
    [requestGetMultiStepsByCustomer]: produce((draft, { payload: { fetching, error, success } }) => {
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successGetMultiStepsByCustomer]: produce(
      (draft, { payload: { message, fetching, success, multiSteps, totalRecords, error } }) => {
        draft.message = message
        draft.fetching = fetching
        draft.success = success
        draft.error = error
        draft.multiSteps = multiSteps
        draft.totalRecords = totalRecords
      }
    ),
    [failureGetMultiStepsByCustomer]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = !error
    }),
    [clearGetMultiStepsByCustomer]: produce(
      (draft, { payload: { message, fetching, success, multiSteps, totalRecords, error } }) => {
        draft.message = message
        draft.fetching = fetching
        draft.success = success
        draft.error = error
        draft.multiSteps = multiSteps
        draft.totalRecords = totalRecords
      }
    ),
  },
  defaultState
)

export default getMultiStepsByCustomer
