import produce from 'immer'

import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: [],
}

export const {
  requestDeactivateCustomer,
  successDeactivateCustomer,
  failureDeactivateCustomer,
  resetDeactivateCustomer,
} = createActions({
  REQUEST_DEACTIVATE_CUSTOMER: (params) => ({
    ...defaultState,
    fetching: true,
    params,
  }),

  SUCCESS_DEACTIVATE_CUSTOMER: (data, message) => ({
    data,
    message,
    fetching: false,
    error: false,
    success: true,
  }),

  FAILURE_DEACTIVATE_CUSTOMER: (message) => ({
    message,
    fetching: false,
    error: true,
  }),
  RESET_DEACTIVATE_CUSTOMER: () => ({
    ...defaultState,
  }),
})

const customerDeactivate = handleActions(
  {
    [requestDeactivateCustomer]: produce((draft, { payload: { message, fetching, success, error, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data
    }),

    [successDeactivateCustomer]: produce((draft, { payload: { message, fetching, success, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.data = data
    }),

    [failureDeactivateCustomer]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.data = {}
    }),
    [resetDeactivateCustomer]: produce((draft, { payload: { message, fetching, success, error, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data
    }),
  },

  defaultState
)

export default customerDeactivate
