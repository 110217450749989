import jwt from 'jsonwebtoken'

export const parseLoginData = (token) => {
  try {
    const decoded = jwt.decode(token)
    return decoded
  } catch (err) {
    return 'error'
  }
}

export const validateToken = (token = "") => {
  try { 
    if (token && token !== "") {
      const { exp } = jwt.decode(token);
      if (exp) {
        const now = new Date().getTime();
        const currentTime = Math.trunc(now / 1000)
        const valid = currentTime <= exp;
        return valid;
      }

    }
    return true;
  } catch (error) {
    return true
  }
};

export const validateDueDate = (token) => {
  try {
    const decoded = jwt.decode(token)
    return decoded
  } catch (err) {
    if (err.name === 'TokenExpiredError') {
      return 'expired'
    }
    return 'error'
  }
}
