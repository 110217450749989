import { put, takeLatest, call } from 'redux-saga/effects'
import { rules } from 'services'
import {
  requestAssignCustomers,
  successAssignCustomers,
  failureAssignCustomers,
} from '../duck/assignCustomers.duck'

function* assignCustomersAsync(data) {
  try {
    const response = yield call(rules.assignCustomers, data.payload.data)
    yield put(successAssignCustomers(response))
  } catch (error) {
    yield put(failureAssignCustomers(error))
  }
}

function* watchAssignCustomersAsync() {
  yield takeLatest(requestAssignCustomers, assignCustomersAsync)
}

export default watchAssignCustomersAsync
