import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: [],
}

export const { requestUserList, successUserList, failureUserList, resetUserList } = createActions({
  REQUEST_USER_LIST: (params) => ({
    ...defaultState,
    fetching: true,
    params,
  }),
  SUCCESS_USER_LIST: (data, message) => ({
    data,
    message,
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_USER_LIST: (message) => ({
    message,
    fetching: false,
    error: true,
  }),
  RESET_USER_LIST: () => ({
    ...defaultState,
  }),
})

const userList = handleActions(
  {
    [requestUserList]: produce((draft, { payload: { message, fetching, success, error, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data
    }),
    [successUserList]: produce((draft, { payload: { message, fetching, success, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.data = data
    }),
    [failureUserList]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.data = {}
    }),
    [resetUserList]: produce((draft, { payload: { message, fetching, success, error, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data
    }),
  },
  defaultState
)

export default userList
