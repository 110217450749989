import PropTypes from 'prop-types'
import CreateRuleModal from './CreateRuleModal'

CreateRuleModal.propTypes = {
  typesId: PropTypes.object.isRequired,
  shippingRule: PropTypes.object.isRequired,
  referenceRule: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  columnsParams: PropTypes.object.isRequired,
  addNewRuleForCreateMultistep: PropTypes.bool,
}

export default CreateRuleModal
