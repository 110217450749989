import PropTypes from 'prop-types'
import FilterRules from './FilterRules'
 
FilterRules.propTypes = {  
  handleColumnsParams: PropTypes.func.isRequired,
  onClickNewRule: PropTypes.func.isRequired,
  changeStatus:PropTypes.func.isRequired
}

export default FilterRules
