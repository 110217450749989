import React, { useEffect, useState } from 'react'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Input from 'antd/lib/input'
import Swal from 'sweetalert2'
import Modal from 'components/Modal'
import { AiOutlineSearch } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { requestGetRules, clearGetRules } from 'store/duck/getRules.duck'
import AddRuleTable from 'containers/rules/Ruleset/AddRuleTable'

const AddRulesModal = ({ handleCancel, visible, title, typeRule, params, rulesSelected }) => {
  const dispatch = useDispatch()
  const [totalRules, setTotalRules] = useState([])
  const [search, setSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(params.page)
  const { rules, success, error, message, totalRules: totalRecords } = useSelector((state) => state.getRules)

  useEffect(() => {
    if (success) {
      setTotalRules(rules)
    }
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error getting rules!',
        text: message,
      })
      dispatch(clearGetRules())
      handleCancel(false)
    }
  }, [success, error, message, rules, dispatch, handleCancel])

  const handleSearch = (event) => {
    if (!event.target.value) {
      dispatch(requestGetRules(params))
    }
    setSearch(event.target.value)
  }

  const handleKeyPress = (event) => {
    if (event.key !== 'Enter') return
    const queryParams = { ...params }
    queryParams.text = search
    dispatch(requestGetRules(queryParams))
  }

  const handleTableChange = (pagination) => {
    dispatch(requestGetRules({ page: pagination.current, ...params}))
    setCurrentPage(pagination.current)
  }

  return (
    <Modal
      title={`Add ${title} Rules`}
      visible={visible}
      onCancel={() => {
        handleCancel(false)
      }}
    >
      <Row gutter={8}>
        <Col span={8}>
          <Input
            prefix={<AiOutlineSearch />}
            placeholder="Search"
            onChange={handleSearch}
            onKeyPress={handleKeyPress}
            allowClear
          />
        </Col>
      </Row>
      <AddRuleTable
        rules={totalRules}
        totalRecords={totalRecords}
        handleTableChange={handleTableChange}
        currentPage={currentPage}
        rulesSelected={rulesSelected}
        loading={!success}
        typeRule={typeRule}
      />
    </Modal>
  )
}

export default AddRulesModal
