import React, { useEffect, useState } from 'react'
import Modal from 'antd/lib/modal'
import Button from 'antd/lib/button'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { requestUser, resetUser } from 'store/duck/getSingleUser.duck'
import { logout } from 'store/duck/login.duck'
import { removeStorage } from 'utils/storage'
import socket from 'utils/socketManager'

const UserStatusListener = () => {
  const dispatch = useDispatch()
  const userId = useSelector((state) => state.login.data.id)
  const history = useHistory()
  const { data, success } = useSelector((state) => state.getSingleUser)
  const [visible, setVisble] = useState(false)

  const handleCloseSession = () => {
    dispatch(logout())
    removeStorage('altptk')
    removeStorage('expiration')
    history.push('/login')
    dispatch(resetUser())
  }

  useEffect(() => {
    socket.on('inActiveUser', () => {
      if (userId) dispatch(requestUser(userId))
    })
    if (success) {
      if (data.active === false) {
        setVisble(true)
      }
    }
  }, [data.active, success, userId, dispatch])
  return (
    <>
      <Modal
        closable={false}
        afterClose={handleCloseSession}
        visible={visible}
        footer={<Button onClick={() => setVisble(false)}>Ok</Button>}
      >
        <p>Your user has been blocked</p>
      </Modal>
    </>
  )
}

export default UserStatusListener
