import { getStateLaneHistory } from './formatValues'

const returnStops = (data = {}) => {
  const st = []
  st.push(getStateLaneHistory(data, 'origin'))
  if (data?.stops && data?.stops.length > 0) {
    data.stops.forEach((element) => {
      if (st[0].match(element?.origin.city) === null) st.push(`${element?.origin.city}`)
      if (data?.lane.destination.city.match(element?.destination.city) === null)
        st.push(`${element?.destination.city}`)
    })
  }
  return new Set([...st, getStateLaneHistory(data, 'destination')])
}

export default returnStops
