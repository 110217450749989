import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Dashboard from 'containers/dashboard/Dashboard'

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/dashboard" component={Dashboard} />
    </Switch>
  )
}

export default Routes
