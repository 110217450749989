import styled from 'styled-components'
import { Link as LinkRouter } from 'react-router-dom'

const Link = styled(LinkRouter)`
  position: absolute;
  left: 15%;
  top: 3.5rem;
  margin: 0px auto;
  width: 70%;
`

export default Link
