
// eslint-disable-next-line react/jsx-props-no-spreading
import moment from 'moment'
import Menu from 'antd/lib/menu'
import React, { useState } from 'react'
import notify from 'utils/notify'
import MoreDropdown from 'components/MoreDropdown'
import ModalConfirmation from 'components/ModalConfirmation'
import { MdDelete, MdFileDownload } from 'react-icons/md'
import { IoMdDocument, IoIosUndo } from 'react-icons/io' 
import { PingTruck, PingLocation } from 'assets/img'
import { requestDeleteRate } from 'store/duck/deleteRate.duck'
import { loadRateSelected } from 'store/duck/createRate.duck'
import { loadPricingLog } from 'store/duck/createPricingLog.duck'
import { saveServices } from 'store/duck/saveSpecialServices.duck'
import { clearGetZipHere, loadRouteMarkers, loadSonarData } from 'store/duck/getZipHere.duck'
import { loadWayPoints, saveTotalMilles } from 'store/duck/saveQuote.duck'
import { requestGetRate } from 'store/duck/getRate.duck'
import { requestSingleCustomer } from 'store/duck/getCustomers.duck'
import { useDispatch, useSelector } from 'react-redux'
import { requestMessageSnackBar } from 'store/duck/messageSnackBar.duck'
import { saveSendEmail } from 'store/duck/sendEmail.duck'
import { loadModalRate } from 'store/duck/getModalRate.duck'
import { StyledCol, StyledRow } from './styles'
import downloadRates from './downloadRates'

const sizeIcon = '24'
const paddingItem = { padding: '0px 8px 0px 8px' }

const defaultPropsModal = {
  visible: false,
  message: ' ',
  icon: (props) => props,
  textButon: '',
  textSuccessfull: '',
  actionConfirm: (props) => props,
}

const ActionsDropdown = ({ record }) => {
  const [propsModal, setPropsModal] = useState(defaultPropsModal)
  const dispatch = useDispatch() 
  const truckTypes = useSelector((state) => state.getTruckTypes.trucktypes) 
  const loadRate = React.useCallback(() => {
    dispatch(loadModalRate(record))
  }, [record, dispatch])
  const handleDownload = React.useCallback(() => {
    downloadRates({
      title: 'Single Rate',
      fileName: `SingleRate_${moment().format('LL HH:mm:ss').replace(/,/, '')}`,
      rates: [record],
    })

    notify('success', 'The rate was downloaded successfully')
  }, [record])

  const handleDelete = async (id) => {
    setPropsModal({
      visible: true,
      message: 'Are you sure you want to delete this Lane?',
      // eslint-disable-next-line react/jsx-props-no-spreading
      icon: (props) => <MdDelete {...props} />,
      textButon: 'Delete',
      textSuccessfull: 'The Lane has been successfully deleted',
      actionConfirm: () => {
        dispatch(requestMessageSnackBar('The Lane has been successfully deleted'))
        dispatch(requestDeleteRate(id))
        setPropsModal(defaultPropsModal)
      },
    })
  }

  const createRouteMarkers = (routes) => {
    const movements = routes.length
    const totalMarkers = {
      markers: [],
      routeMarkers: [],
      stopMarkers: [],
    }
    if (movements === 0) {
      return totalMarkers
    }
    totalMarkers.markers.push({
      coordinate: { lat: routes[0]?.origin?.coordinate?.lat ?? "", lng: routes[0]?.origin?.coordinate?.lng ?? "" },
      content: {
        type: 'Origin',
        city: routes[0].origin.city,
        zipCode: routes[0].origin.zipCode,
      },
      icon: PingTruck,
    })
    if (movements === 1) {
      totalMarkers.markers.push({
        coordinate: { lat: routes[0]?.destination?.coordinate?.lat ?? "", lng: routes[0]?.destination?.coordinate?.lng ?? "" },
        content: {
          type: 'Destination',
          city: routes[0].destination.city,
          zipCode: routes[0].destination.zipCode,
        },
        icon: PingLocation,
      })
      totalMarkers.routeMarkers = totalMarkers.markers
      return totalMarkers
    }
    totalMarkers.markers.push({
      coordinate: {
        lat: routes[movements - 1].destination?.coordinate?.lat ?? "",
        lng: routes[movements - 1].destination?.coordinate?.lng ?? "",
      },
      content: {
        type: 'Destination',
        city: routes[movements - 1].destination.city,
        zipCode: routes[movements - 1].destination.zipCode,
      },
      icon: PingLocation,
    })
    routes.map((item, index) => {
      if (index > 0) {
        totalMarkers.stopMarkers.push({
          coordinate: { lat: item.origin?.coordinate?.lat ?? "", lng: item.origin?.coordinate?.lng ?? ""},
          content: {
            type: 'Way Point',
            city: item.origin.city,
            zipCode: item.origin.zipCode,
          },
          icon: PingLocation,
        })
      }
      return totalMarkers.stopMarkers
    })
    totalMarkers.routeMarkers = [...totalMarkers.markers, ...totalMarkers.stopMarkers]
    return totalMarkers
  }

  const handleExecuteRate = (data) => { 
    dispatch(loadRateSelected(data))
    dispatch(loadPricingLog(data.pricingLogsId))
    const origin = { ...data.lane.origin }
    const destination = { ...data.lane.destination }
    const totalMarkers = createRouteMarkers(data.stops)
    const wayPoints = []

    if (totalMarkers !== undefined) {
      dispatch(loadRouteMarkers(totalMarkers))
      totalMarkers.stopMarkers.map((item) => {
        wayPoints.push({
          wayPoint: { city: item.content.city, zipCode: item.content.zipCode },
        })
        return wayPoints
      })
    }

    const origin3Digit = data.lane.origin.zipCode.substr(0, 3)
    const destination3Digit = data.lane.destination.zipCode.substr(0, 3)
    origin.digitZip3 = origin3Digit
    destination.digitZip3 = destination3Digit
    dispatch(loadSonarData(origin3Digit, destination3Digit))
    dispatch(loadWayPoints(wayPoints))

    const specialServices = []
    data.specialServices.map((item) => {
      specialServices.push({ quantity: item.quantity, serviceId: item.serviceId._id })
      return specialServices
    })

    const customerSelected = {
      id: data.customer._id,
      key: data.customer._id,
      flat: data.customer.flat,
      margin: data.customer.margin,
      value: data.customer.name,
      name: data.customer.name,
      email: data.customer.email,
      consider: data.customer.considerCustomFuelPrice,
      fuelPrice: data.customer.customFuelPrice,
    }

    const params = {
      customer: data.customer._id,
      lane: JSON.stringify({ origin, destination }),
      pickupDate: moment(data.pickupDate).format('YYYY/MM/DD'),
      deliveryDate: moment(data.deliveryDate).format('YYYY/MM/DD'),
      truckTypeId: data.truckTypeId._id,
      weight: data.weight,
      totalStops: data.totalStops,
      totalMilles: data.totalMilles,
    }
    if (specialServices.length > 0) params.specialServices = JSON.stringify(specialServices)
    dispatch(saveServices(specialServices)) 
    dispatch(requestSingleCustomer(customerSelected))
    dispatch(requestGetRate(params))
    dispatch(clearGetZipHere())
    dispatch(saveTotalMilles(params.totalMilles))
   

    dispatch(
      saveSendEmail({
        customer: data.customer.name,
        pickupDate: moment(data.pickup).format('YYYY/MM/DD'),
        deliveryDate: moment(data.delivery).format('YYYY/MM/DD'),
        origin: data.lane.origin.city,
        destination: data.lane.destination.city,
        equimentType: truckTypes.find((entry) => entry._id === data.truckTypeId._id).name ?? '',
        weight: data.weight,
        totalSepecialServices: data.specialServices.length,
      })
    )
  }
 
  const confirmation = () => {
    setPropsModal({
      visible: false,
      message: ' ',
      icon: (props) => props,
      textButon: '',
      textSuccessfull: '',
      actionConfirm: (props) => props,
    })
  }

  return (
    <>
      <MoreDropdown
        menu={
          <Menu>
            <Menu.Item style={paddingItem} onClick={handleDownload}>
              <StyledRow>
                <StyledCol>
                  <MdFileDownload size={sizeIcon} />
                </StyledCol>
                <StyledCol>
                  <span>Download File</span>
                </StyledCol>
              </StyledRow>
            </Menu.Item>
            <Menu.Item style={paddingItem} onClick={loadRate}>
              <StyledRow>
                <StyledCol>
                  <IoMdDocument size={sizeIcon} />
                </StyledCol>
                <StyledCol>
                  <span>View Rate</span>
                </StyledCol>
              </StyledRow>
            </Menu.Item>
            <Menu.Item style={paddingItem} onClick={() => handleExecuteRate(record)}>
              <StyledRow>
                <StyledCol>
                  <IoIosUndo size={sizeIcon} />
                </StyledCol>
                <StyledCol>
                  <span>Re-quote</span>
                </StyledCol>
              </StyledRow>
            </Menu.Item>
            <Menu.Item style={paddingItem} onClick={() => handleDelete(record._id)}>
              <StyledRow>
                <StyledCol>
                  <MdDelete size={sizeIcon} />
                </StyledCol>
                <StyledCol>
                  <span>Delete Rate</span>
                </StyledCol>
              </StyledRow>
            </Menu.Item>

            <ModalConfirmation
              actionConfirm={propsModal.actionConfirm}
              textSuccessfull={propsModal.textSuccessfull}
              textButton={propsModal.textButon}
              close={confirmation}
              icon={propsModal.icon}
              message={propsModal.message}
              visible={propsModal.visible}
              height="296px"
              width={350}
              centered
              avoidNotify
            />
          </Menu>
        }
      />
    </>
  )
}

const actionsDropdown = () => ({
  title: <div style={{ fontSize: '16px' }}>Actions</div>,
  dataIndex: 'actions',
  key: 'actions',
  textWrap: 'word-break',
  render: (_, record) => {
    return <ActionsDropdown record={record} />
  },
  width: 80,
  align: 'center',
})

export default actionsDropdown
