import { put, takeLatest, call } from 'redux-saga/effects'
import { trucktypes } from 'services'
import {
  requestGetTruckTypeList,
  successGetTruckTypeList,
  failureGetTruckTypeList,
} from '../duck/getTruckTypeList.duck'

function* getTruckTypeListAsync(data) {
  try {
    const response = yield call(trucktypes.getTruckTypeList, data.payload.params)
    yield put(successGetTruckTypeList(response))
  } catch (error) {
    yield put(failureGetTruckTypeList(error))
  }
}

function* watchGetTruckTypeListAsync() {
  yield takeLatest(requestGetTruckTypeList, getTruckTypeListAsync)
}

export default watchGetTruckTypeListAsync
