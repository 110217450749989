import styled from 'styled-components'
import Row from 'antd/lib/row'
import StyledModal from 'components/Modal/styles'

const ForgotContainer = styled(Row)`
 display: grid;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  padding-left: 8%;
  padding-right: 8%;
  padding-top: 2rem;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: #fff;
  }
`
export const StyleModal = styled(StyledModal)`
  .ant-modal-close {
    padding: 0px;
    margin: 0px;
  }
  .ant-modal-close-x {
    display: flex;
    justify-content: flex-end;
    font-size: 18px;
    color: #545454;
    padding: 7px 10px 0px 0px;
    height: 35px;
  }
`

export default ForgotContainer
