import PropTypes from 'prop-types'
import LoadSimpleRule from './LoadSimpleRule'

LoadSimpleRule.propTypes = {
  handleFetchRules: PropTypes.func.isRequired,
  rules: PropTypes.object.isRequired,
  rulesetData: PropTypes.object.isRequired,
  shippingData: PropTypes.object.isRequired,
}

export default LoadSimpleRule
