import React from 'react'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import FiltersGraph from './FiltersGraph'
import GraphReporting from './GraphReporting'
import TableReporting from './TableReporting'
import FilterTable from './FilterTable'

export default function Reporting() {
  return (
    <Row gutter={[8, 8]} style={{ width: '96%', margin: '10px auto 15px',paddingTop:"10px" }}>
      <Col span={24}>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <FiltersGraph />
          </Col>
          <Col span={24}>
            <GraphReporting />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <FilterTable />
          </Col>
          <Col span={24}>
            <TableReporting />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
