import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: {},
}

export const { requestGetDefaultCustomer, successGetDefaultCustomer, failureGetDefaultCustomer } = createActions({
  REQUEST_GET_DEFAULT_CUSTOMER: (data) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    data,
  }),
  SUCCESS_GET_DEFAULT_CUSTOMER: (data) => ({
    data,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_DEFAULT_CUSTOMER: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
})

const defaultCustomer = handleActions(
  {
    [requestGetDefaultCustomer]: produce((draft, { payload: { fetching, error, success } }) => {
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successGetDefaultCustomer]: produce((draft, { payload: { message, fetching, success, data, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = {
        id: data?._id,
        key: data?._id,
        value: data?.name,
        flat: data?.flat,
        margin: data?.margin,
        email: data?.email,
        consider: data?.considerCustomFuelPrice ?? false,
        fuelPrice: data?.customFuelPrice,
      }
    }),
    [failureGetDefaultCustomer]: produce((draft, { payload: { message, fetching, success, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
  },
  defaultState
)

export default defaultCustomer
