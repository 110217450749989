import { put, takeLatest, call } from 'redux-saga/effects'
import { trucktypes } from 'services'
import {
  requestCreateTruckType,
  successCreateTruckType,
  failureCreateTruckType,
} from 'store/duck/createTruckType.duck'

function* createTruckTypeAsync(data) {
  try {
    const response = yield call(trucktypes.createTruckType, data.payload.data)
    yield put(successCreateTruckType(response, 'success'))
  } catch (error) {
    yield put(failureCreateTruckType(error.response.data.message))
  }
}

function* watchTruckTypeAsync() {
  yield takeLatest(requestCreateTruckType, createTruckTypeAsync)
}

export default watchTruckTypeAsync
