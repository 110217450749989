import { Space, Select, Col, Button } from 'antd'
import styled from 'styled-components'
import StyledModal from 'components/Modal/styles'
import { AiOutlineLoading } from 'react-icons/ai'

export const ButtonCollapse = styled(Button)`
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #545454 !important;
  white-space: nowrap;
  font-family: 'Roboto', sans-serif !important;
  box-shadow: none;
  text-decoration: none;
  outline: none;
  border-color: none;
  .ant-btn.ant-btn-primary:active {
    outline: none;
    border-color: transparent !important;
  }
  animation-duration: 0s !important;
  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    background-color: none;
    border-color: none !important;
  }
`

export const InputCollapse = styled.input`
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #545454 !important;
  white-space: nowrap;
  font-family: 'Roboto', sans-serif !important;
`

export const ContainerButtonHidden = styled.div`
  -moz-transition: padding 0.5s;
  -ms-transition: padding 0.5s;
  -o-transition: padding 0.5s;
  -webkit-transition: padding 0.5s;
  transition: padding 0.5s;
  height: 10;
  padding: 0%;
  overflow: hidden;
`

export const HiddenCol = styled(Col)`
  -moz-transition: height 0.5s;
  -ms-transition: height 0.5s;
  -o-transition: height 0.5s;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
  height: 0;
  overflow: hidden;
  visibility: hidden;
`

export const BorderSelect = styled(Select)`
  .ant-select-selector {
    border-bottom-color: #002555 !important;
    // background-color: red !important;
    max-height: 24px !important;
    // overflow: auto;
  }
  .ant-select-selection-search-input {
    // background-color: blue !important;
    max-height: 24px !important;
  }
  /* .ant-select-selection-item[title] {
    padding: 9px 0px 0px 0px; 764.52
  } */
  .ant-select-selection-search {
    padding-top: 2px !important;
    // max-height: 22px !important;
  }
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LabelForm = styled.div`
  color: #002555;
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;
`
export const SpecialService = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -14px;
  width: 100%;
`
export const StyledTag = styled.div`
  border-radius: 16px;
  padding: 4px 10px;
  background-color: #bdc8d5;
  color: #545454;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: pre;
  gap: 8px;
  min-width: 150px;
  height: fit-content;
`

export const SpecialServiceList = styled.div`
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  margin-top: 3px;
  height: 55px;
  overflow-y: auto;
  overflow-x: hidden;
`
export const StyleModal = styled(StyledModal)`
  .ant-modal-close {
    display: none !important;
  }
`
export const SpinAnimated = styled(AiOutlineLoading)`
  animation: spin-animation 0.5s infinite;
  font-size: 26px;
  color: white;
  display: flex;
  margin: 0px;
  padding: 0px;
  @keyframes spin-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
`

export const CityPoint = styled.div`
  width: 20px;
  height: 20px;
  background: #002555;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
`

export const CitiesList = styled.div`
  width: 100%;
  height: 149px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: -10px;
  padding-top: 5px;
`

export const StyledSpace = styled(Space)`
  .ant-space-item {
    width: 100%;
  }
  .ant-row .ant-form-item {
    margin-bottom: 0px;
  }
`

export const ContainerItem = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  gap: 5px;
`
