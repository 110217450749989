import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  successDelete: false,
  data: {},
  itemId: '',
}

export const {
  requestDeleteSpecialService,
  successDeleteSpecialService,
  failureDeleteSpecialService,
  clearDeleteSpecialService,
} = createActions({
  REQUEST_DELETE_SPECIAL_SERVICE: (itemId) => ({
    ...defaultState,
    fetching: true,
    itemId,
  }),
  SUCCESS_DELETE_SPECIAL_SERVICE: (data, message) => ({
    data,
    message,
    fetching: false,
    error: false,
    successDelete: true,
  }),
  FAILURE_DELETE_SPECIAL_SERVICE: (message) => ({
    message,
    fetching: false,
    error: true,
    successDelete: false,
  }),
  CLEAR_DELETE_SPECIAL_SERVICE: () => ({
    ...defaultState,
  }),
})

const deleteSpecialService = handleActions(
  {
    [requestDeleteSpecialService]: produce((draft, { payload: { message, fetching, itemId } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.itemId = itemId
    }),
    [successDeleteSpecialService]: produce((draft, { payload: { message, fetching, successDelete, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.successDelete = successDelete
      draft.data = data
    }),
    [failureDeleteSpecialService]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
    }),
    [clearDeleteSpecialService]: produce(
      (draft, { payload: { message, fetching, error, data, successDelete } }) => {
        draft.message = message
        draft.fetching = fetching
        draft.error = error
        draft.data = data
        draft.successDelete = successDelete
      }
    ),
  },
  defaultState
)

export default deleteSpecialService
