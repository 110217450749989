import { put, takeLatest, call } from 'redux-saga/effects'
import { specialServices } from 'services/'
import { parseSpecialServicesData } from 'utils/parseResponse'
import {
  requestGetSpecialServices,
  successGetSpecialServices,
  failureGetSpecialServices,
} from '../duck/getSpecialServices.duck'

function* getSpecialServicesAsync(data) {
  try {
    const response = yield call(specialServices.getSpecialServices, data.payload.search.text)
    const servicesData = parseSpecialServicesData(response.data)
    yield put(successGetSpecialServices(servicesData))
  } catch (error) {
    yield put(failureGetSpecialServices(error))
  }
}

function* watchGetSpecialServicesAsync() {
  yield takeLatest(requestGetSpecialServices, getSpecialServicesAsync)
}

export default watchGetSpecialServicesAsync
