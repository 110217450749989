import { put, takeLatest, call } from 'redux-saga/effects'
import { auth } from 'services'
import {
  requestForgotPassword,
  successForgotPassword,
  failureForgotPassword,
} from 'store/duck/forgotPassword.duck';

function* forgotPasswordRequestAsync(data) {
  try {
    const response = yield call(auth.forgotPassword, data.payload.data)
    yield put(successForgotPassword(response, 'success'));
  } catch (error) {
    yield put(failureForgotPassword(error.response.data.message));
  }
}

function* watchForgotPasswordAsync() {
  yield takeLatest(requestForgotPassword, forgotPasswordRequestAsync);
}

export default watchForgotPasswordAsync
