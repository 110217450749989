import styled from 'styled-components'
import Card from 'antd/lib/card'

const CardInput = styled(Card)`
  height: 3.43rem;
  border: 1px solid rgba(217, 217, 217, 0.1);
  box-shadow: 0 10px 20px rgba(217, 217, 217, 0.2);
  margin-bottom: 1rem;
  .ant-card-body {
    padding: 12px 10px 10px 16px !important;
  }
`

export const CardInputLarge = styled(Card)`
  min-height: 3rem;
  border: 1px solid rgba(217, 217, 217, 0.1);
  box-shadow: 0 10px 20px rgba(217, 217, 217, 0.2);
  margin-bottom: 1rem;
  .ant-card-body {
    padding: 1.3rem 10px 10px 2rem !important;
  }
`

export default CardInput
