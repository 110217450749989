import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { requestUser } from 'store/duck/getSingleUser.duck'

const useCustomersFromUser = () => {
  const dispatch = useDispatch()
  const [request, setRequest] = useState(false)
  const [dropdownData, setDropdownData] = useState([])

  const { dataLogin, userData } = useSelector((state) => ({
    dataLogin: state.login.data,
    userData: state.getSingleUser.data,
  }))
  const { customers: customersData } = userData
  const id = dataLogin?.id ?? ''

  useEffect(() => {
    if (id !== '' && request) {
      dispatch(requestUser(id))
    }
  }, [dispatch, id, request])

  useEffect(() => {
    if (customersData) {
      if (customersData.length > 0 && request && dropdownData.length === 0) {
        setDropdownData(
          customersData.map((item) => {
            return { id: item.id._id, value: item.id.name }
          })
        )
      }
    }
  }, [customersData, request, dropdownData])

  return [dropdownData, setRequest]
}

export default useCustomersFromUser
