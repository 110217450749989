import { debounce } from 'lodash'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import Input from 'antd/lib/input'
import { AiOutlineSearch } from 'react-icons/ai'

const StyledSearchView = styled(Input).attrs((props) => ({
  prefix: <AiOutlineSearch style={{ fontSize: 20, color: '#545454' }} />,
  placeholder: 'Search',
  allowClear: true,
  ...props,
}))`
  height: 40px;
  border-radius: 4px;
  input {
    line-height: 16px;
    font-size: 16px;

    ::placeholder {
      color: #545454;
      font-size: 16px;
      line-height: 16px;
    }
  }
`

const StyledSearch = ({ onChange, ...props }) => {
  const [text, setText] = useState('')

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChange = useCallback(
    debounce((nextValue) => onChange(nextValue), 400),
    [onChange]
  )

  const handleChange = (newValue) => {
    setText(newValue.target.value)
    debouncedChange(newValue.target.value)
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <StyledSearchView {...props} value={text} onChange={handleChange} />
}

export default StyledSearch
