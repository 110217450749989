import styled from 'styled-components'
import Form from 'antd/lib/form'

const StyledItem = styled(Form.Item)`
  width: 100%;
  margin-bottom: 30px;
  .ant-form-item-label {
    padding: 0px;
    margin: 0px;
    margin-bottom: 6px;
  }
  .ant-form-item-label > label {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: rgb(110, 127, 141);
  }

  .ant-select {
    padding: 1px 0px;
  }
`

export default StyledItem
