import axios from 'axios'
import { validateDueDate } from './parseAuthData'
import { removeStorage } from './storage'

const BASE_URL = process.env.REACT_APP_BASE_BACKEND_URL
const token = window.localStorage.getItem('altptk')
const CONTENT_TYPE = 'application/x-www-form-urlencoded'



class AxiosManager {
  constructor() {
    this.axiosDefault = axios.create()
    this.init()
  }

  init() {
    this.setHeader()
    this.setAuthorization()
    this.handleToken()
    this.handleError()
  }

  api(url = BASE_URL) {
    this.axiosDefault.defaults.baseURL = url
    return this.axiosDefault
  }

  setHeader(contentType = CONTENT_TYPE) {
    this.axiosDefault.defaults.headers.post['Content-Type'] = contentType
  }

  setAuthorization(incomingToken = token) {
    if (incomingToken !== '') {
      this.axiosDefault.defaults.headers.Authorization = `Bearer ${incomingToken}`
    }
  }

  handleToken() {
    this.axiosDefault.interceptors.request.use((config) => {
      return config
    }, null, { synchronous: true })
  }




  handleError() {
    this.axiosDefault.interceptors.response.use(
      (response) => {

        const sleeping = window.localStorage.getItem('sleeping')
        if (sleeping === "true") {
          window.localStorage.setItem('sleeping', false)
        }
        return response.data
      },
      (error) => {
        const sleeping = window.localStorage.getItem('sleeping')
        const originalRequest = error.config
        error.config.headers = { ...error.config.headers }
        if (error.response === undefined || error.messagge === 'Network Error') {
          window.location.href = '/error'
          return Promise.reject(error)
        }
        if (error.response.status === 401 && !originalRequest._retry) {
          const refreshToken = window.localStorage.getItem('altprtk')
          error.config._retry = true


          return this.axiosDefault.post('/api/authentications/token', { token: refreshToken }).then(response => {
            if (sleeping === "false") {
              const decode = validateDueDate(response.token)
              const { exp: expToken } = decode
              window.localStorage.setItem('expiration', expToken)
              window.localStorage.setItem('altptk', response.token)
              window.localStorage.setItem('sleeping', true)
              window.localStorage.setItem('norefresh', false)
            }
            this.setAuthorization(response.token)
            error.config.headers = { ...error.config.headers }
            error.config.headers.Authorization = `Bearer ${response.token}`
            error.config.sent = true
            return this.axiosDefault.request(error.config)
          }).catch((e) => {
            window.localStorage.setItem('sleeping', false)
            removeStorage('expiration')
            removeStorage('altptk')
            removeStorage('altprtk')
            window.localStorage.setItem('norefresh', true)
            return Promise.reject(e)
          })
        }

        if (error.response.status === 400) {
          window.localStorage.setItem('norefresh', originalRequest.url)
        }
        return Promise.reject(error)
      }, null, { synchronous: true })
  }

  deleteInterceptor(interceptor) {
    this.axiosDefault.interceptors.request.eject(interceptor)
  }
}

export default new AxiosManager()
