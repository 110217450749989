import { put, takeLatest, call } from 'redux-saga/effects'
import { rules } from 'services'
import {
  requestUpdateStatusMultiStep,
  successUpdateStatusMultiStep,
  failureUpdateStatusMultiStep,
} from '../duck/updateStatusMultiStep.duck'

function* updateStatusMultistepAsync(data) {
  try {
    const response = yield call(rules.updateMultiStep, data.payload.data)
    yield put(successUpdateStatusMultiStep(response))
  } catch (error) {
    yield put(failureUpdateStatusMultiStep(error.response.data.message))
  }
}

function* watchUpdateStatusMultistepAsync() {
  yield takeLatest(requestUpdateStatusMultiStep, updateStatusMultistepAsync)
}

export default watchUpdateStatusMultistepAsync
