import React from 'react'
import PriceLabel from 'components/Label/PriceLabel'
import Typography from 'antd/lib/typography'
import BoxCell from 'components/Box/BoxCell'

const { Text } = Typography

const Columns = [
  {
    title: 'Name',
    dataIndex: 'multistepName',
    key: 'multistepName',
    sorter: (a, b) => a.multistepName.localeCompare(b.multistepName),
    sortDirections: ['descend', 'ascend'],
    width: 150,
    ellipsis: true,
  },
  {
    title: 'Total Rules',
    dataIndex: 'sentences',
    key: 'sentences',
    colSpan: 2,
    render: (sentences) => {
      return <PriceLabel>{sentences.length} Rules</PriceLabel>
    },
    width: 70,
    ellipsis: true,
  },
  {
    title: '',
    dataIndex: 'name',
    key: 'mileage',
    colSpan: 0,
    render: () => {
      return (
        <BoxCell>
          <Text>make these rate</Text>
          <Text>adjustments</Text>
        </BoxCell>
      )
    },
    width: 105,
    ellipsis: true,
  },
  {
    title: 'Percentage',
    dataIndex: 'margin',
    key: 'margin',
    render: (margin) => {
      return <PriceLabel>{margin} %</PriceLabel>
    },
    width: 75,
    ellipsis: true,
  },
  {
    title: 'Flat',
    dataIndex: 'flat',
    key: 'flat',
    render: (flat) => {
      return <PriceLabel>{flat}</PriceLabel>
    },
    width: 75,
    ellipsis: true,
  },
]

export default Columns
