import { put, takeLatest, call } from 'redux-saga/effects'
import { users } from 'services'

import { requestUser, successUser, failureUser } from 'store/duck/getSingleUser.duck'

function* getSingleUserRequestAsync(data) {
  try {
    const response = yield call(users.getUser, data.payload.data)
    yield put(successUser(response, 'success'))
  } catch (error) {
    yield put(failureUser(error.response.data.message))
  }
}

function* watchGetSingleUserAsync() {
  yield takeLatest(requestUser, getSingleUserRequestAsync)
}

export default watchGetSingleUserAsync
