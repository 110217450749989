import React, { useState } from 'react'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import { StyledCol } from 'components/Text/TextPrimary'
import { toNumber } from 'lodash'
import Divider from 'antd/lib/divider'
import RulesModal from 'containers/dashboard/RulesModal'
import Collapse from 'components/Collapse'
import SpecialServicesModal from 'containers/dashboard/SpecialServicesModal'
import { removeRulesNoFlatAndMarginZero } from 'utils/filters'
import cleanServices from 'utils/cleanServices'
import { convertToNumber, inNumber, validateRateBasic, validatePrice, validateRateCost } from './helpers'
import RowInfo from './RowInfo'
import RowRule from './RowRule'

export default function Details({
  datRate = {},
  gsRate = {},
  gsPredictionRate = {},
  gsDatalake = {},
  rateBasic = {},
  datRateTotal = '',
  gsRateTotal = '',
  gsPredictionRateTotal = '',
  ruleAdjustments = [],
  multiSteptAdjustments = [],
  specialServices = [],
}) {
  const { Panel } = Collapse
  const sumDG = toNumber(datRateTotal) + toNumber(gsRateTotal) + toNumber(gsPredictionRateTotal)
  const DAT_CONTRACT_TOTAL = convertToNumber(datRate?.datContract?.total ?? '')
  const hiddenDatContract = toNumber(inNumber(datRate?.datContract?.total ?? '')) > 0
  const DAT_HIGH_TOTAL = convertToNumber(datRate?.datHigh?.total ?? '')
  const hiddenDatHight = toNumber(inNumber(datRate?.datHigh?.total ?? '')) > 0
  const DAT_LOW_TOTAL = convertToNumber(datRate?.datLow?.total ?? '')
  const hiddenDatLow = toNumber(inNumber(datRate?.datLow?.total ?? '')) > 0
  const DAT_WEIGHT_TOTAL = convertToNumber(datRate?.datAvg?.total ?? '')
  const hiddenDatWeight = toNumber(inNumber(datRate?.datAvg?.total ?? '')) > 0
  const hiddenGsNetwork = toNumber(gsRate?.confidencePercentage) > 0
  const hiddenGsTarget = toNumber(gsPredictionRate?.confidencePercentage ?? 0) > 0
  const hiddenDatalake = toNumber(inNumber(convertToNumber(gsDatalake?.total ?? ''))) > 0
  const [visible, setVisible] = useState(false)
  const clearRulesAdjustments = removeRulesNoFlatAndMarginZero(ruleAdjustments)
  const clearMultiSteps = removeRulesNoFlatAndMarginZero(multiSteptAdjustments)
  const allRules = [...clearRulesAdjustments, ...clearMultiSteps]
  const totalMargin = allRules.reduce((a, b) => {
    const a1 = a
    const b1 = parseInt(b.margin,10) ?? 0
    return a1 + b1
  }, 0)
  const totalFlat = allRules.reduce((a, b) => {
    const a1 = a
    const b1 = parseInt(b.flat,10) ?? 0
    return a1 + b1
  }, 0)
  const DisplayRules = () => {
    return clearRulesAdjustments.map(({ name, flat, margin }) => {
      return (
        <RowRule
          key={name}
          name={name}
          margin={margin}
          flat={flat}
          setVisible={() => setVisible((prev) => !prev)}
        />
      )
    })
  }
  const DisplayMultiSteps = () => {
    return clearMultiSteps.map(({ multiStepName, flat, margin }) => {
      return (
        <RowRule
          key={multiStepName}
          name={multiStepName}
          margin={margin}
          flat={flat}
          setVisible={() => setVisible((prev) => !prev)}
        />
      )
    })
  }

  const specialServicesValue = validatePrice(rateBasic) ? `N/A` : convertToNumber(rateBasic?.priceSpecialServices)
  const maximumMarkdown = rateBasic?.maximumMarkdown

  return (
    <>
      <Row gutter={[16, 16]} justify="space-between" style={{ marginLeft: '0' }}>
        <StyledCol span={24}>BASE RATE</StyledCol>
        <RowInfo
          label="DAT Contract"
          percentage={datRate?.datContract?.confidencePercentage ?? 0}
          value={convertToNumber(datRate?.datContract?.rate ?? '')}
          calculationValue={DAT_CONTRACT_TOTAL}
          hidden={!hiddenDatContract}
        />
        <RowInfo
          label="DAT High"
          percentage={datRate?.datHigh?.confidencePercentage ?? 0}
          value={convertToNumber(datRate?.datHigh?.rate ?? '')}
          calculationValue={DAT_HIGH_TOTAL}
          hidden={!hiddenDatHight}
        />
        <RowInfo
          label="DAT Low"
          percentage={datRate?.datLow?.confidencePercentage ?? '0'}
          value={convertToNumber(datRate?.datLow?.rate ?? '')}
          calculationValue={DAT_LOW_TOTAL}
          hidden={!hiddenDatLow}
        />
        <RowInfo
          label="DAT Weight"
          percentage={datRate?.datAvg?.confidencePercentage ?? 0}
          value={convertToNumber(datRate?.datAvg?.rate ?? '')}
          calculationValue={DAT_WEIGHT_TOTAL}
          hidden={!hiddenDatWeight}
        />
        <RowInfo
          label="Gs Network"
          percentage={gsRate?.confidencePercentage ?? 0}
          value={convertToNumber(gsRate?.rate ?? '')}
          calculationValue={convertToNumber(gsRate?.total)}
          hidden={!hiddenGsNetwork}
        />

        <RowInfo
          label="Gs Target"
          percentage={gsPredictionRate?.confidencePercentage ?? 0}
          value={convertToNumber(gsPredictionRate?.rate ?? '')}
          calculationValue={convertToNumber(gsPredictionRate?.total ?? '')}
          hidden={!hiddenGsTarget}
        />
        <StyledCol span={14}>Base total</StyledCol>
        <Col span={7} style={{ paddingLeft: '36px' }}>
          <StyledCol> {`$${sumDG}`}</StyledCol>
        </Col>

        {!hiddenDatalake ? '' : <StyledCol span={24}>ADJUSMENT</StyledCol>}
        <RowInfo
          label="Datalake"
          percentage={inNumber(gsDatalake?.confidencePercentage, 2)}
          value={convertToNumber(gsDatalake?.rate)}
          calculationValue={convertToNumber(gsDatalake?.total)}
          hidden={!hiddenDatalake}
        />
        <RowInfo
          label="Base total"
          percentage={inNumber(gsDatalake?.confidencePercentage, 2)}
          value={sumDG}
          calculationValue={convertToNumber(rateBasic?.base_rate?.total)}
          hidden={!hiddenDatalake}
        />

        {!hiddenDatalake ? (
          ''
        ) : (
          <>
            <StyledCol span={7}>Adjusted total</StyledCol>
            <Col span={7} style={{ paddingLeft: '36px' }}>
              <StyledCol>{validateRateBasic(rateBasic) ? `N/A` : convertToNumber(rateBasic?.priceBase)}</StyledCol>
            </Col>
          </>
        )}
        <Divider style={{ margin: '12px 0', background: 'rgba(217, 217, 217, 0.4)' }} />
      </Row>
      <Row gutter={[16, 16]}>
        <RowRule name="Rules" margin="Margin" flat="Flat" symbols={false} visible={allRules.length > 0} />
        <DisplayRules />
        <DisplayMultiSteps />
        <RulesModal visible={visible} onClose={() => setVisible(!visible)} />
        <RowRule
          name="Total"
          margin={`${totalMargin}%`}
          flat={`$${totalFlat}`}
          symbols={false}
          visible={allRules.length > 0}
        />
        {/* maximum Markdown = {maximumMarkdown} <br />
        typeof = {JSON.stringify(typeof maximumMarkdown)} <br />
        total margin = {totalMargin} <br />
        typeof = {JSON.stringify(typeof totalMargin)} <br />
        display maximum = {JSON.stringify(typeof maximumMarkdown > totalMargin)} <br />  */}
        <RowRule
          name="Maximum Markdown"
          margin={`${maximumMarkdown}%`}
          flat=""
          symbols={false}
          visible={maximumMarkdown > totalMargin}
        />
        <Divider style={{ margin: '12px 0', background: 'rgba(217, 217, 217, 0.4)' }} />
      </Row>
      {specialServicesValue !== 'N/A' || rateBasic?.extraStops > 0 ? (
        <Collapse defaultActiveKey="1" ghost expandIconPosition="right">
          <Panel header="Trend Adjustment" key="1">
            <Row gutter={[16, 8]} justify="space-between">
              <div style={{ display: 'flex', width: '100%' }}>
                <Col style={{ width: '67%', paddingRight: '0' }}>Extra Stops</Col>
                <Col style={{ width: '100%', paddingLeft: '0' }}>
                  <StyledCol>{rateBasic?.extraStops}</StyledCol>
                </Col>
              </div>
              <div style={{ display: 'flex', width: '100%' }}>
                <Col style={{ width: '67%', paddingRight: '0' }}>Special Services</Col>
                <Col style={{ width: '58%', paddingLeft: '0' }}>
                  <StyledCol>{specialServicesValue}</StyledCol>
                </Col>
                <Col span={6} style={{ paddingRight: '15px', paddingLeft: '0' }}>
                  <SpecialServicesModal services={cleanServices(specialServices)} />
                </Col>
              </div>
            </Row>
          </Panel>
        </Collapse>
      ) : (
        ''
      )}
      <Row gutter={[16, 32]} justify="space-between">
        <Col span={4} style={{ whiteSpace: 'nowrap' }}>
          <StyledCol>TOTAL COST</StyledCol>
        </Col>
        <Col xxl={{ span: 7 }} xl={{ span: 8 }} style={{ paddingLeft: '46px' }}>
          <StyledCol>{validateRateCost(rateBasic) ? `N/A` : convertToNumber(rateBasic?.rateCost)}</StyledCol>
        </Col>
      </Row>
    </>
  )
}
