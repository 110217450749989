import styled from 'styled-components'
import Typography from 'antd/lib/typography'

const { Text } = Typography

const TitleLabel = styled(Text)`
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgb(0, 37, 85);
`

export default TitleLabel
