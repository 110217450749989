import React from 'react'
import { getStatus } from 'utils/getStatus'
import Typography from 'antd/lib/typography'
import Tooltip from 'antd/lib/tooltip' 
import actionsDropdown from './actionsDropdown'
import { StyledBadge } from './styles'

const { Text } = Typography

const columns = (dispatch, history, openDrawer) => {
  return [
    {
      title: <div style={{ fontSize: '16px' }}>Name</div>,
      dataIndex: 'rulesetName',
      key: 'name',
      width: 200,
      align: 'left',
      render: (name) => {
        return <div style={{ fontSize: '16px', display: 'flex' }}>{name}</div>
      },
    },
    {
      title: <div style={{ fontSize: '16px' }}># Rules</div>,
      key: 'rules',
      render: (text, record) => {
        if (record.rules) return <div style={{ fontSize: '16px', display: 'flex' }}>{record.rules.length}</div>
        return '0'
      },
      width: 100,
      align: 'left',
    },
    {
      title: <div style={{ fontSize: '16px' }}> # Multisteps</div>,
      key: 'rules',
      render: (text, record) => {
        if (record.multisteps) { 
          return (
            <Tooltip
              placement="bottom"
              title={
                record.multisteps.length > 0
                  ? record.multisteps.map((v ) => <div key={v._id}>{v.multistepName}</div>)
                  : null
              }
              color="#3594FF"
            >
              <Text underline style={{ color: '#3594FF', cursor: 'pointer', fontSize: '16px', display: 'flex' }}>
                {record.multisteps.length}
              </Text>
            </Tooltip>
          )
        }
        return '0'
      },
      width: 125,
      align: 'left',
    },
    {
      title: <div style={{ fontSize: '16px' }}># Customers</div>,
      key: 'customers',
      render: (text, record) => {
        if (record.customers) return <div style={{ fontSize: '16px', display: 'flex' }}> {record.customers.length}</div>
        return '0'
      },
      width: 125,
      align: 'left',
    },
    {
      title: <div style={{ fontSize: '16px' }}>Status</div>,
      dataIndex: 'active',
      key: ['ruleset', 'active'],
      render: (active, ruleset) => {
        return<div style={{ display: 'flex' }}><StyledBadge variant={getStatus(ruleset.status, active)} /></div> 
      },
      width: 125,
      align: 'left',
    },
    {
      ...actionsDropdown(dispatch, openDrawer),
    },
  ]
}

export default columns
