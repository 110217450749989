import styled from 'styled-components'

const StyledLegend = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-flow: column wrap;
  flex-direction: row;
  padding-top: 0.5rem;
  cursor: pointer;
  gap:5px;
`

const StyledElement = styled.div`
  height: 2rem;
  border-radius: 2px;
  width: auto;
  font-size: 14px;
  margin-bottom: 0.7rem;
  background: ${(props) => props.color};
  color: white;
  display: grid;
  place-items: center start;
  cursor: pointer;

  span {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
`

const BoxContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const BoxColor = styled.div`
  width: 16px;
  height: 14px;
  background: ${(props) => props.color};
  margin: 1rem 6px 0 6px;
  border-radius: 2px;
  cursor: pointer;
`

export { StyledLegend, StyledElement, BoxContainer, BoxColor }
