import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  successDeleteBulk: false,
  data: {},
  itemId: '',
}

export const {
  requestDeleteBulkRuleset,
  successDeleteBulkRuleset,
  failureDeleteBulkRuleset,
  clearDeleteBulkRuleset,
} = createActions({
  REQUEST_DELETE_BULK_RULESET: (itemId) => ({
    ...defaultState,
    fetching: true,
    itemId,
  }),
  SUCCESS_DELETE_BULK_RULESET: (data, message) => ({
    data,
    message,
    fetching: false,
    error: false,
    successDeleteBulk: true,
  }),
  FAILURE_DELETE_BULK_RULESET: (message) => ({
    message,
    fetching: false,
    error: true,
    successDeleteBulk: false,
  }),
  CLEAR_DELETE_BULK_RULESET: () => ({
    ...defaultState,
  }),
})

const deleteBulkRuleset = handleActions(
  {
    [requestDeleteBulkRuleset]: produce((draft, { payload: { message, fetching, itemId } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.itemId = itemId
    }),
    [successDeleteBulkRuleset]: produce((draft, { payload: { message, fetching, successDeleteBulk, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.successDeleteBulk = successDeleteBulk
      draft.data = data
    }),
    [failureDeleteBulkRuleset]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
    }),
    [clearDeleteBulkRuleset]: produce((draft, { payload: { message, fetching, error, data, successDeleteBulk } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.data = data
      draft.successDeleteBulk = successDeleteBulk
    }),
  },
  defaultState
)

export default deleteBulkRuleset
