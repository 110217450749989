import React, { useEffect, useReducer, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Grid from 'antd/lib/grid'
import Tooltip from 'antd/lib/tooltip'
import { filterGraphics } from 'store/duck/getRate.duck'
import reducer from './utils/reducer'
import { StyledLegend, StyledElement, BoxContainer, BoxColor } from './styles'

const { useBreakpoint } = Grid
const ColorLegend = ({ data, margin }) => {
  const screen = useBreakpoint()
  const dispatch = useDispatch()
  const [{ filter }, setFilter] = useReducer(reducer, { filter: [] })
  const [mount, setMount] = useState(false)

  const { entries } = useSelector((state) => ({
    entries: state.getRate.entries,
  }))

  const boxColors = {
    Dat: '#002555',
    Internal: '#3594FF',
    Its: '#8C54FF',
    Sonar: '#F6A15B',
  }

  const getColor = (entry) => boxColors[entry.id]

  useEffect(() => {
    if (mount) return
    setFilter({ type: 'all', payload: data })
    setMount(true)
  }, [data, mount])

  useEffect(() => {
    dispatch(filterGraphics({ id: filter }))
  }, [dispatch, filter])

  return screen.xxl ? (
    <StyledLegend margin={margin}>
      <StyledElement
        color="#002555"
        onClick={() => { 
          setFilter({ type: 'all', payload: [...entries] })
        }}
      >
        <span>All Sources</span>
      </StyledElement>

      {data.map((entry, index) => {
        return (
          <StyledElement
            key={index}
            color={getColor(entry)}
            onClick={() => {
              setFilter({ type: 'toggle', payload: entry.id })
            }}
          >
            <span>{entry.id}</span>
          </StyledElement>
        )
      })}
    </StyledLegend>
  ) : (
    <BoxContainer>
      {data.length > 0 && (
        <Tooltip placement="bottom" title={<span>All Sources</span>}>
          <BoxColor
            color="#002555"
            onClick={() => {
              setFilter({ type: 'all', payload: entries })
            }}
          />
        </Tooltip>
      )}
      {data.map((entry, index) => {
        return (
          <Tooltip key={index} placement="bottom" title={<span>{entry.id} RateView</span>}>
            <BoxColor
              key={index}
              color={getColor(entry)}
              onClick={() => {
                setFilter({ type: 'toggle', payload: entry.id })
              }}
            />
          </Tooltip>
        )
      })}
    </BoxContainer>
  )
}

export default ColorLegend
