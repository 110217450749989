import React from 'react'
import { StyledCol } from 'components/Text/TextPrimary'
import Col from 'antd/lib/col'

export default function RowInfo({
  label = '',
  percentage = '',
  value = '',
  calculationValue = '',
  hidden = false,
}) {
  if (hidden) {
    return ''
  }
  
  return (
    <>
      <StyledCol span={6}>{label}</StyledCol>
      {/* <div style={{ display: 'flex', justifyContent: 'space-around' }}> */}
        <Col span={3} style={{ fontSize: '16px'}}>
          {percentage}%
        </Col>
        <Col span={2}>
          <Col style={{ fontSize: '16px', paddingLeft: '0' }}>X</Col>
        </Col>
        <Col span={5} style={{ fontSize: '16px' }}>
          {value}
        </Col>
      {/* </div> */}
      <Col span={1}>=</Col>
      <Col span={5} style={{ paddingLeft: '36px' }}>
        <StyledCol>{calculationValue}</StyledCol>
      </Col>
    </>
  )
}
