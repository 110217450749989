const parseStatesToHashMap = (states = []) => {
  const converted = {}
  if (states.length > 0) {
    states.forEach((element) => {
      converted[element.abbreviation] = element.label
    })
  }

  return converted
}

export default parseStatesToHashMap
