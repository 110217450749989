import styled from 'styled-components'
import Button from 'antd/lib/button'
import { FaRegEdit } from 'react-icons/fa'

export const StyledEditIcon = styled(FaRegEdit)`
  color: #6e7f8d;
  font-size: 20px;
`

export const StyledButton = styled(Button)`
  border: none;
  padding: 0px;
`
