import React from 'react'
import Tooltip from 'antd/lib/tooltip'
import { StyledEditIcon, StyledButton } from './styles'

const EditIcon = ({ onClick, title }) => {
  return (
    <Tooltip title={title}>
      <span>
        <StyledButton onClick={onClick}>
          <StyledEditIcon />
        </StyledButton>
      </span>    
    </Tooltip>
  )
}

export default EditIcon
