import { put, takeLatest, call } from 'redux-saga/effects'
import { rules } from 'services'
import { requestDeleteRuleset, successDeleteRuleset, failureDeleteRuleset } from '../duck/deleteRuleset.duck'

function* deleteRulesetAsync(data) {
  try {
    const response = yield call(rules.deleteRuleset, data.payload.itemId)
    yield put(successDeleteRuleset(response))
  } catch (error) {
    yield put(failureDeleteRuleset(error))
  }
}

function* watchDeleteRulesetAsync() {
  yield takeLatest(requestDeleteRuleset, deleteRulesetAsync)
}

export default watchDeleteRulesetAsync
