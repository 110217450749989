import styled from 'styled-components'

export const TextBody = styled.p`
  font-size: ${(props) => props.theme.fonts.bodyText_1.size};
  line-height: ${(props) => props.theme.fonts.bodyText_1.lineHeight};
  font-weight: ${(props) => props.theme.fonts.bodyText_1.fontWeight};
  color: ${(props) => props.theme.colors.light_grey.default};
`

export const TextBody2 = styled.p`
  font-size: ${(props) => props.theme.fonts.bodyText_1.size};
  line-height: ${(props) => props.theme.fonts.bodyText_1.lineHeight};
  font-weight: ${(props) => props.theme.fonts.bodyText_1.fontWeight};
  color: ${(props) => props.theme.colors.dark_grey.default};
`
export const TextBody3 = styled.p`
  font-size: ${(props) => props.theme.fonts.bodyText_2.size};
  line-height: ${(props) => props.theme.fonts.bodyText_2.lineHeight};
  font-weight: ${(props) => props.theme.fonts.bodyText_2.fontWeight};
  color: ${(props) => props.theme.colors.dark_blue.default};
`

export const TextBody4 = styled.p`
  font-size: ${(props) => props.theme.fonts.bodyText_3.size};
  line-height: ${(props) => props.theme.fonts.bodyText_3.lineHeight};
  font-weight: ${(props) => props.theme.fonts.bodyText_3.fontWeight};
  color: ${(props) => props.theme.colors.dark_grey.default};
`
export const TextBody5 = styled.p`
  font-size: ${(props) => props.theme.fonts.bodyText_4.size};
  line-height: ${(props) => props.theme.fonts.bodyText_4.lineHeight};
  font-weight: ${(props) => props.theme.fonts.bodyText_4.fontWeight};
  color: ${(props) => props.theme.colors.dark_blue.default};
`
