import { put, takeLatest, call } from 'redux-saga/effects'
import { trucktypes } from 'services'
import {
  requestUpdateTruckType,
  successUpdateTruckType,
  failureUpdateTruckType,
} from '../duck/updateTruckType.duck'

function* updateTruckTypeAsync(data) {
  try {
    const response = yield call(trucktypes.updateTruckType, data.payload.data)
    yield put(successUpdateTruckType(response))
  } catch (error) {
    yield put(failureUpdateTruckType(error.response.data.message))
  }
}

function* watchUpdateTruckTypeAsync() {
  yield takeLatest(requestUpdateTruckType, updateTruckTypeAsync)
}

export default watchUpdateTruckTypeAsync
