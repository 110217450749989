import React from 'react'

const TextBodyModal = ({ multiQuantity = 0, ruleSetQuantity = 0 }) => {
  return (
    <div style={{ fontWeight: 400, fontSize: '17px', textAlign: 'center' }}>
      Are you sure you want to delete this <span style={{ fontWeight: 700 }}>Rule</span>?
      {multiQuantity + ruleSetQuantity > 0 ? 'If you accept, the rule will be removed from the following ' : ''}
      {multiQuantity > 0 ? 'Multi-Step' : ''}
      {multiQuantity > 0 && ruleSetQuantity > 0 ? ' and ' : ''}
      {ruleSetQuantity > 0 ? 'RuleSets' : ''}
      {multiQuantity + ruleSetQuantity > 0 ? ' as well:' : ''}
    </div>
  )
}
export default TextBodyModal
