import { put, takeLatest, call } from 'redux-saga/effects'
import { customers } from 'services/'
import { requestGetCustomer, successGetCustomer, failureGetCustomer } from '../duck/getCustomer.duck'

function* getCustomerAsync(data) {
  try {
    const response = yield call(customers.getCustomer, data.payload.params)
    yield put(successGetCustomer(response))
  } catch (error) {
    yield put(failureGetCustomer(error))
  }
}

function* watchGetCustomerAsync() {
  yield takeLatest(requestGetCustomer, getCustomerAsync)
}

export default watchGetCustomerAsync
