import { Col } from 'antd'
import styled from 'styled-components'

const StyledText = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: #002555;
  vertical-align: middle;
`
export const TextPrimaryLarge = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: #545454;
  white-space: nowrap;
`

export const StyledCol = styled(Col)`
  font-weight: 400;
  color: #545454;
  font-size: 16px;
`

export default StyledText
