import React, { useState, useEffect, useMemo } from 'react'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Form from 'antd/lib/form'
import Text from 'antd/lib/typography/Text'
import Swal from 'sweetalert2'
import Modal from 'components/Modal'
import InputWithLabel from 'components/Form/InputWithLabel'
import { InputGeneric } from 'components/Input'
import ControlInput from 'components/Form/ControlInput'
import { useDispatch, useSelector } from 'react-redux'
import { requestCreateTruckType, clearCreateTruckType } from 'store/duck/createTruckType.duck'
import { requestUpdateTruckType } from 'store/duck/updateTruckType.duck'
import TitleDrawer from 'components/TitleDrawer'
import PrimarySubmit from 'components/PrimarySubmit'
import DrawerItem from 'components/Form/DrawerItem'
import { requiredDescription, stringRules } from 'utils/inputRules'
import StyledTabs from 'components/Tabs'
import { requestGetTruckTypesHistory, clearGetTruckTypesHistory } from 'store/duck/getTruckTypeHistory.duck'
import HistoryItems from 'components/HistoryItem'
import { TRUCK_TYPE } from 'utils/constants'
import jsonDiff from 'utils/jsonDiff'
import { requestMessageSnackBar } from 'store/duck/messageSnackBar.duck'

const TruckType = ({ visible, onCloseDrawer }) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { success, error, editTruckType, truckTypeSelected } = useSelector((state) => state.createTruckType)
  const { success: successUpdate, error: errorUpdate } = useSelector((state) => state.updateTruckType)
  const values = useMemo(() => (
    {
      name: editTruckType ? truckTypeSelected.name : '',
      description: editTruckType ? truckTypeSelected.description : '',
      fuelPrice: editTruckType ? truckTypeSelected.fuelPrice : '',
      maxWeight: editTruckType ? truckTypeSelected?.maxWeight ?? 0 : '',
      defaultFuel: editTruckType ? truckTypeSelected?.defaultFuel : ""
    }
  ), [editTruckType, truckTypeSelected])

  const defaultValues = values
  const [loading, setLoading] = useState(false)
  const { history } = useSelector((state) => state.getTruckTypesHistory)
  const DEFAULTACTIVEKEYEDITTAB = '1'
  const [activeKeyEditTab, setActiveKeyEditTab] = useState(DEFAULTACTIVEKEYEDITTAB)

  useEffect(() => {
    if (success) {
      setLoading(false)
      Swal.fire({
        icon: 'success',
        title: 'Truck type created successfully!',
        showConfirmButton: false,
        timer: 1500,
      })
      form.resetFields()
      dispatch(clearCreateTruckType())
      onCloseDrawer(false)
    }
    if (error) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Truck type creation error',
        text: 'Equipment type name already in use!',
      })
      dispatch(clearCreateTruckType())
      onCloseDrawer(false)
    }
  }, [error, success, onCloseDrawer, form, dispatch])

  useEffect(() => {
    if (successUpdate || errorUpdate) {
      setLoading(false)
      onCloseDrawer(false)
    }
    if (truckTypeSelected._id) {
      dispatch(requestGetTruckTypesHistory(truckTypeSelected._id))
      setActiveKeyEditTab(DEFAULTACTIVEKEYEDITTAB)
    }
  }, [errorUpdate, successUpdate, onCloseDrawer, truckTypeSelected, dispatch])

  useEffect(() => {
    if (!loading) form.setFieldsValue(defaultValues)
  }, [form, defaultValues, loading])

  const submitTruckType = (data) => {
    setLoading(true)
    const equipmentType = {
      name: data.name,
      description: data.description,
      price: data.price,
      fuelPrice: data.fuelPrice,
      maxWeight: data.maxWeight,
      defaultFuel: data?.defaultFuel
    }
    if (editTruckType) {
      const nextData = jsonDiff(
        {
          name: truckTypeSelected.name,
          description: truckTypeSelected.description,
          fuelPrice: truckTypeSelected.fuelPrice,
          maxWeight: truckTypeSelected.maxWeight,
          defaultFuel: truckTypeSelected?.defaultFuel
        },
        equipmentType
      )
      dispatch(requestUpdateTruckType({ ...nextData, id: truckTypeSelected._id }))
      dispatch(requestMessageSnackBar('Equipment Type has been edited'))
    } else {
      dispatch(requestCreateTruckType(equipmentType))
    }
  }

  const onClose = () => {
    onCloseDrawer(false)
    dispatch(clearGetTruckTypesHistory())
  }

  return (
    <Modal
      title={<TitleDrawer title={editTruckType ? 'Edit Equipment Type' : 'Create Equipment Type'} />}
      width={550}
      style={{ top: 100 }}
      height="420px"
      onClose={onClose}
      visible={visible}
      forceRender
      footer={
        <div style={{ textAlign: 'center', marginTop: '5px' }}>
          <PrimarySubmit
            label={editTruckType ? 'Update Equipment Type' : 'Create Equipment Type'}
            loading={loading}
            formName="truck-type-form"
          />
        </div>
      }
      footerStyle={{ border: 'none' }}
      headerStyle={{ border: 'none' }}
      onCancel={() => {
        onCloseDrawer(false)
        form.resetFields()
      }}
    >
      <StyledTabs
        activeKey={activeKeyEditTab}
        onChange={(newActiveKey) => setActiveKeyEditTab(newActiveKey)}
        tabBarStyle={{ width: '100%' }}
      >
        <StyledTabs.TabPane tab="Equipment Type Info" key="1">
          {' '}
          <Form
            layout="vertical"
            name="truck-type-form"
            form={form}
            initialValues={defaultValues}
            onFinish={submitTruckType}
            hideRequiredMark
          >
            <Row>
              <Col span={24} style={{ margin: '0px 0px 16px 0px' }}>
                <Text style={{ color: '#545454', fontWeight: 400, fontSize: '16px' }}>
                  Add the information of this new equipment type.
                </Text>
              </Col>
              <Col span={24} style={{ margin: '0px' }}>
                <InputWithLabel label="Name *" labelStyle={{ leftBeforeFloat: '24px' }}>
                  {(hasValue) => (
                    <DrawerItem
                      name="name"
                      rules={stringRules('equipment type name')}
                      style={{ marginBottom: '0px' }}
                    >
                      <ControlInput hasValue={hasValue}>
                        {({ value, onChange }) => (
                          <InputGeneric
                            style={{ backgroundColor: '#BDC8D5', color: '#545454' }}
                            disabled
                            size="large"
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      </ControlInput>
                    </DrawerItem>
                  )}
                </InputWithLabel>
              </Col>
              <Col span={24} style={{ margin: '0px' }}>
                <InputWithLabel label="Default Fuel Price *" labelStyle={{ leftBeforeFloat: '24px' }}>
                  {(hasValue) => (
                    <DrawerItem
                      name="defaultFuel" 
                      style={{ marginBottom: '0px' }}
                    >
                      <ControlInput hasValue={hasValue}>
                        {({ value, onChange }) => (
                          <InputGeneric disabled type="number" size="large" prefix="$" value={value} onChange={onChange} />
                        )}
                      </ControlInput>
                    </DrawerItem>
                  )}
                </InputWithLabel>
              </Col>
              <Col span={24} style={{ margin: '0px' }}>
                <InputWithLabel label="Fuel Price *" labelStyle={{ leftBeforeFloat: '24px' }}>
                  {(hasValue) => (
                    <DrawerItem
                      name="fuelPrice"
                      rules={[{ required: true, message: 'Please enter a truck type fuel price' }]}
                      style={{ marginBottom: '0px' }}
                    >
                      <ControlInput hasValue={hasValue}>
                        {({ value, onChange }) => (
                          <InputGeneric type="number" size="large" prefix="$" value={value} onChange={onChange} />
                        )}
                      </ControlInput>
                    </DrawerItem>
                  )}
                </InputWithLabel>
              </Col>
              <Col span={24} style={{ margin: '0px' }}>
                <InputWithLabel label="Max weight *" labelStyle={{ leftBeforeFloat: '24px' }}>
                  {(hasValue) => (
                    <DrawerItem
                      name="maxWeight"
                      rules={[{ required: true, message: 'Please enter a value for the max weight' }]}
                      style={{ marginBottom: '0px' }}
                    >
                      <ControlInput hasValue={hasValue}>
                        {({ value, onChange }) => (
                          <InputGeneric type="number" size="large" value={value} onChange={onChange} />
                        )}
                      </ControlInput>
                    </DrawerItem>
                  )}
                </InputWithLabel>
              </Col>
              <Col span={24}>
                <InputWithLabel label="Description">
                  {(hasValue) => (
                    <DrawerItem name="description" rules={requiredDescription}>
                      <ControlInput hasValue={hasValue}>
                        {({ value, onChange }) => (
                          <InputGeneric.TextArea rows={4} size="large" value={value} onChange={onChange} />
                        )}
                      </ControlInput>
                    </DrawerItem>
                  )}
                </InputWithLabel>
              </Col>
            </Row>
          </Form>
        </StyledTabs.TabPane>
        <StyledTabs.TabPane tab="History" key="2">
          <HistoryItems data={history} category={TRUCK_TYPE} height="340px" />
        </StyledTabs.TabPane>
      </StyledTabs>
    </Modal>
  )
}

export default TruckType
