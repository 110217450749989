import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: {},
}

export const { requestResendInvite, successResendInvite, failureResendInvite, resetResendInvite } = createActions({
  REQUEST_RESEND_INVITE: (data) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    data,
  }),
  SUCCESS_RESEND_INVITE: (data) => ({
    data,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_RESEND_INVITE: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  RESET_RESEND_INVITE: () => ({
    ...defaultState,
  }),
})

const resendInvite = handleActions(
  {
    [requestResendInvite]: produce((draft, { payload: { fetching, data, error, success } }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successResendInvite]: produce((draft, { payload: { message, fetching, success, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = false
      draft.data = data
    }),
    [failureResendInvite]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = false
    }),
    [resetResendInvite]: produce((draft, { payload: { message, fetching, success, error, data } }) => {
        draft.message = message
        draft.fetching = fetching
        draft.success = success
        draft.error = error
        draft.data = data
      }),
  },
  defaultState
)

export default resendInvite
