import { put, takeLatest, call } from 'redux-saga/effects'
import { specialServices } from 'services'
import {
  requestCreateSpecialService,
  successCreateSpecialService,
  failureCreateSpecialService,
} from '../duck/createSpecialService.duck'

function* createSpecialServiceAsync(data) {
  try {
    const response = yield call(specialServices.createSpecialService, data.payload.data)
    yield put(successCreateSpecialService(response, 'success'))
  } catch (error) {
    yield put(failureCreateSpecialService(error.response.data.message))
  }
}

function* watchCreateSpecialServiceAsync() {
  yield takeLatest(requestCreateSpecialService, createSpecialServiceAsync)
}

export default watchCreateSpecialServiceAsync
