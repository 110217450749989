import styled from 'styled-components'
import { Form } from 'antd'

const FormItem = styled(Form.Item)`
  label {
    color: #6e7f8d;
    font-size: 12px;
    font-weight: 400;
    @media (min-width: 1768px) {
      font-size: 14px;
    }
  }
  .ant-form-item-explain {
    font-size: 12px;
  }
`
export default FormItem
