import styled from 'styled-components'
import { Modal } from 'antd'

const StyledModal = styled(Modal)`
  font-size: 14px;
  font-weight: 400;
  color: #6e7f8d;
  .ant-modal-content {
    margin: 1.5rem 2rem 3rem 2rem;
    background: #f9fafe;
    border-radius: 4px 4px 0px 0px;
    border-width: 4px;
    border-style: none none solid;
    border-color: #3594ff;
  }
  .ant-modal-close {
    color: #002555;
    padding: 0px;
    margin: 0px;
  }
  .ant-modal-close-x {
    font-size: 16px;
    color: #000000;
    justify-content: center;
    align-items: center;  
  }
  .ant-modal-header {
    border-bottom: none;
    background: #f9fafe; 
    padding: 16px 24px;
    border-width: 1px;
    border-style: none none solid;
    border-color: #d9d9d9;
    border-radius: 4px 4px 0px 0px;
    .ant-modal-title { 
      border-width: 1px;  
      font-weight: 700;
      font-size: 16px;
      color: #002555; 
    }
  }
  .ant-modal-body {
    padding: 2px 24px 16px;
    height: ${(props) => props.height};
  }
  .heavy {
    font-size: 20px;
    font-weight: 700;
    color: #002555;
    margin-left: 0.5rem;
  }
  .warning-label {
    font-size: 14px;
    font-weight: 400;
    color: #fa2e2e;
    margin-top: 5px;
    margin-left: 0.5rem;
  }
  .ant-modal-footer {
    border-top: none;
  }
`

export default StyledModal
