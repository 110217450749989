import { put, debounce, call } from 'redux-saga/effects'
import { integrations } from 'services'
import {
  successGetAutocompleteHere,
  requestGetAutocompleteHere,
  failureGetAutocompleteHere,
} from '../duck/getAutocompleteHere.duck'

function* getGetAutocompleteHere(data) {
  try {
    const response = yield call(integrations.getAutocompleteHere, data.payload.params)
    yield put(successGetAutocompleteHere(response))
  } catch (error) {
    yield put(failureGetAutocompleteHere(error))
  }
}

function* watchGetAutocompleteHereAsync() {
  yield debounce(150, requestGetAutocompleteHere, getGetAutocompleteHere)
}

export default watchGetAutocompleteHereAsync
