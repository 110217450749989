import moment from 'moment'
import CryptoJS from 'crypto-js'
import { capitalize } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { clearResetPassword, requestResetPassword } from 'store/duck/resetPassword.duck'
import AuthForm from 'components/Form/AuthForm'
import CompanyLogo from 'components/CompanyLogo'
import SpinLoading from 'components/SpinLoading'
import AuthTitle from 'components/Label/AuthTitle'
import PasswordInput from 'components/Form/PasswordInput'
import ButtonPrimary from 'components/Button/ButtonPrimary'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import { useForm } from 'antd/lib/form/Form'
import notify from 'utils/notify'
import { validateDueDate } from 'utils/parseAuthData'
import { resetPasswordRules } from 'utils/inputRules'
import Typography from 'antd/lib/typography'
import { ImgContainer, LinkContainer, LoginContainer, LoginContent, StyledText } from '../Login/styles'

const { Link } = Typography

const ConfirmPassword = ({ title, description, passwordInputLabel, submitButtonText }) => {
  const [form] = useForm()
  const dispatch = useDispatch()
  const history = useHistory()
  const { token } = useParams()

  const { success: successLogin } = useSelector((state) => state.login)
  const { success, error, message } = useSelector((state) => state.resetPassword)
  const [loading, setLoading] = useState(false)
  const [userEmail, setUserEmail] = useState('')

  const onFinishFailed = () => {
    notify('error', 'There was an error in reset password form.')
  }

  useEffect(() => {
    const tokenStatus = validateDueDate(token)
    if (tokenStatus === 'expired') {
      notify('error', 'Your token has expired, please contact an administrator')
    } else if (tokenStatus === 'error') {
      notify('error', 'Invalid token')
    } else {
      setUserEmail(tokenStatus.email)
      return
    }
    history.push('/login')
    dispatch(clearResetPassword())
  }, [dispatch, history, token])

  useEffect(() => {
    if (success) {
      notify('success', 'Password updated! Please enter your new credentials to complete the sign in process')

      setLoading(false)
      dispatch(clearResetPassword())

      history.push('/login')
    }
    if (error) {
      notify('error', `Reset password error! ${message}`)

      setLoading(false)
      dispatch(clearResetPassword())
    }
  }, [dispatch, error, history, message, success])

  useEffect(() => {
    if (successLogin) {
      setLoading(false)

      history.push('/dashboard')
      if (window.localStorage.getItem('expiration') === null) {
        window.localStorage.setItem('expiration', moment())
      }
    }
  }, [history, successLogin])

  const onFinish = (data) => {
    if (data.password !== data.repeatPassword) {
      notify('error', 'Passwords do not match')
      return
    }
    setLoading(true)
    dispatch(
      requestResetPassword({
        email: userEmail,
        password: CryptoJS.AES.encrypt(data.password, process.env.REACT_APP_CRYPTO_KEY).toString(),
      })
    )
  }

  return (
    <ImgContainer>
      <LoginContainer>
        <div style={{ height: 'fit-content' }}>
          <CompanyLogo />
        </div>
        <LoginContent>
          <div style={{ width: '40%', marginRight: '50px' }}>
            <StyledText>
              Timing and accuracy is vital in transportation and logistics. <strong>Ally Logistics</strong> has the
              expertise to help you manage the changing digital environment.
            </StyledText>
          </div>
          <div
            style={{
              display: 'flex',
              background: '#F9FAFE',
              padding: '50px 0px',
              width: '50%',
              justifyContent: 'center',
              height: 'fit-content',
              alignItems: 'center',
              marginBottom: '20px',
            }}
          >
            <AuthForm
              name="login-form"
              layout="vertical"
              onFinish={onFinish}
              form={form}
              onFinishFailed={onFinishFailed}
              hideRequiredMark
            >
              <AuthTitle marginBotton="1rem" />
              <div style={{ color: '#002555', fontSize: '20px', fontWeight: 'bold' }}>{title}</div>
              <div style={{ color: '#545454', fontSize: '16px', marginBottom: 16 }}>{description}</div>

              <Row gutter={[0, 24]}>
                <Col span={24}>
                  <PasswordInput
                    label={capitalize(passwordInputLabel)}
                    name="password"
                    rules={resetPasswordRules}
                  />
                </Col>
                <Col span={24}>
                  <PasswordInput
                    label={capitalize(`Repeat ${passwordInputLabel}`)}
                    name="repeatPassword"
                    rules={resetPasswordRules}
                  />
                </Col>
              </Row>

              <Form.Item>
                <ButtonPrimary
                  htmlType="submit"
                  width="100%"
                  height="100%"
                  disabled={loading}
                  style={{ padding: '10px 0', marginTop: 4 }}
                >
                  {loading ? <SpinLoading /> : submitButtonText}
                </ButtonPrimary>
                <LinkContainer style={{ marginTop: 14 }}>
                  <Link href="/login">Go back</Link>
                </LinkContainer>
              </Form.Item>
            </AuthForm>
          </div>
        </LoginContent>
      </LoginContainer>
    </ImgContainer>
  )
}

export default ConfirmPassword
