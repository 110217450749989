import { put, takeLatest, call } from 'redux-saga/effects'
import { rules } from 'services'
import { requestReturnRuleset, successReturnRuleset, failureReturnRuleset } from '../duck/returnRuleSet.duck'

function* returnRulesetAsync(data) {
  try {
    const response = yield call(rules.returnVersionRuleset, data.payload.data) 
    yield put(successReturnRuleset(response))
  } catch (error) { 
    yield put(failureReturnRuleset(error.response.data.message))
  }
}

function* watchReturnRulesetAsync() {
  yield takeLatest(requestReturnRuleset, returnRulesetAsync)
}

export default watchReturnRulesetAsync
