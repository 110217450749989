import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  fetchingList: false,
  errorList: false,
  successList: false,
  services: [],
  servicesList: [],
  totalRecords: 0,
  params: {},
  search: {},
}

export const {
  requestGetSpecialServices,
  successGetSpecialServices,
  failureGetSpecialServices,
  requestGetSpecialServicesList,
  successGetSpecialServicesList,
  failureGetSpecialServicesList,
  clearGetSpecialServices,
  clearGetSpecialServicesList,
} = createActions({
  REQUEST_GET_SPECIAL_SERVICES: (search) => ({
    search,
    fetching: true,
    error: false,
    success: false,
  }),
  SUCCESS_GET_SPECIAL_SERVICES: (services) => ({
    services,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_SPECIAL_SERVICES: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  REQUEST_GET_SPECIAL_SERVICES_LIST: (params) => ({
    params,
    fetchingList: true,
    errorList: false,
    successList: false,
  }),
  SUCCESS_GET_SPECIAL_SERVICES_LIST: (servicesList) => ({
    servicesList: servicesList.data,
    fetchingList: false,
    errorList: false,
    successList: true,
    totalRecords: servicesList.totalRecords,
  }),
  FAILURE_GET_SPECIAL_SERVICES_LIST: (message) => ({
    message,
    fetchingList: false,
    errorList: true,
    successList: false,
  }),
  CLEAR_GET_SPECIAL_SERVICES: () => ({
    search: {},
    fetching: false,
    error: false,
    success: false,
    services: [],
  }),
  CLEAR_GET_SPECIAL_SERVICES_LIST: () => ({
    params: {},
    fetchingList: false,
    errorList: false,
    successList: false,
    servicesList: [],
  }),
})

const getSpecialServices = handleActions(
  {
    [requestGetSpecialServices]: produce((draft, { payload: { fetching, error, success, search } }) => {
      draft.fetching = fetching
      draft.error = error
      draft.success = success
      draft.search = search
    }),
    [successGetSpecialServices]: produce((draft, { payload: { message, fetching, success, services, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.services = services
    }),
    [failureGetSpecialServices]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = !error
    }),
    [requestGetSpecialServicesList]: produce(
      (draft, { payload: { fetchingList, errorList, successList, params } }) => {
        draft.fetchingList = fetchingList
        draft.errorList = errorList
        draft.successList = successList
        draft.params = params
      }
    ),
    [successGetSpecialServicesList]: produce(
      (draft, { payload: { fetchingList, successList, errorList, servicesList, totalRecords } }) => {
        draft.fetchingList = fetchingList
        draft.successList = successList
        draft.errorList = errorList
        draft.servicesList = servicesList
        draft.totalRecords = totalRecords
      }
    ),
    [failureGetSpecialServicesList]: produce((draft, { payload: { message, fetchingList, errorList } }) => {
      draft.message = message
      draft.fetchingList = fetchingList
      draft.errorList = errorList
      draft.successList = !errorList
    }),
    [clearGetSpecialServicesList]: produce(
      (draft, { payload: { successList, fetchingList, errorList, totalRecords } }) => {
        draft.successList = successList
        draft.fetchingList = fetchingList
        draft.errorList = errorList
        draft.totalRecords = totalRecords
      }
    ),
    [clearGetSpecialServices]: produce((draft, { payload: { success, fetching, error, search } }) => {
      draft.success = success
      draft.fetching = fetching
      draft.error = error
      draft.search = search
    }),
  },
  defaultState
)

export default getSpecialServices
