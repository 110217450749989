import styled from 'styled-components'

export const CompanyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`

export const CompanyContainerImg = styled.img`
  width: 300px;
  height: 90%;
  @media only screen and (min-width: 600px) {
    width: 400px;
  }
`
