import styled from 'styled-components'

export const H1 = styled.h1`
  font-size: ${(props) => props.theme.fonts.header_1.size};
  line-height: ${(props) => props.theme.fonts.header_1.lineHeight};
  font-weight: ${(props) => props.theme.fonts.header_1.fontWeight};
  color: ${(props) => props.theme.colors.dark_blue.default};
`
export const H2 = styled.h2`
  font-size: ${(props) => props.theme.fonts.header_2.size};
  line-height: ${(props) => props.theme.fonts.header_2.lineHeight};
  font-weight: ${(props) => props.theme.fonts.header_2.fontWeight};
  color: ${(props) => props.theme.colors.dark_blue.default};
`
export const H3 = styled.h3`
  font-size: ${(props) => props.theme.fonts.header_3.size};
  line-height: ${(props) => props.theme.fonts.header_3.lineHeight};
  font-weight: ${(props) => props.theme.fonts.header_3.fontWeight};
  color: ${(props) => props.theme.colors.dark_blue.default};
`
export const H4 = styled.h4`
  font-size: ${(props) => props.theme.fonts.header_4.size};
  line-height: ${(props) => props.theme.fonts.header_4.lineHeight};
  font-weight: ${(props) => props.theme.fonts.header_4.fontWeight};
  color: ${(props) => props.theme.colors.secondary_2.default};
`
export const H5 = styled.h5`
  font-size: ${(props) => props.theme.fonts.header_5.size};
  line-height: ${(props) => props.theme.fonts.header_5.lineHeight};
  font-weight: ${(props) => props.theme.fonts.header_5.fontWeight};
  color: ${(props) => props.theme.colors.secondary_2.default};
`
