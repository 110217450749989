import { useEffect } from 'react'

const ControlInput = ({ value, onChange, hasValue, children }) => {
  useEffect(() => {
    hasValue(value)
  }, [hasValue, value])

  return children({ value, onChange })
}

export default ControlInput
