import { put, takeLatest, call } from 'redux-saga/effects'
import { rates } from 'services'
import { requestSendEmail, successSendEmail, failureSendEmail } from '../duck/sendEmail.duck'

function* sendEmailAsync(data) {
  try {
    const response = yield call(rates.sendEmail, data.payload.email, data.payload.data)
    yield put(successSendEmail(response))
  } catch (error) {
    yield put(failureSendEmail(error))
  }
}

function* watchSendEmailAsync() {
  yield takeLatest(requestSendEmail, sendEmailAsync)
}

export default watchSendEmailAsync
