import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: [],
}

export const {
  requestUserEditAdmin,
  successUserEditAdmin,
  failureUserEditAdmin,
  resetUserEditAdmin,
} = createActions({
  REQUEST_USER_EDIT_ADMIN: (data) => ({
    ...defaultState,
    fetching: true,
    data,
  }),
  SUCCESS_USER_EDIT_ADMIN: (data, message) => ({
    data,
    message,
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_USER_EDIT_ADMIN: (message) => ({
    message,
    fetching: false,
    error: true,
  }),
  RESET_USER_EDIT_ADMIN: () => ({
    ...defaultState,
  }),
})

const userEditAdmin = handleActions(
  {
    [requestUserEditAdmin]: produce((draft, { payload: { message, fetching, success, error, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data
    }),
    [successUserEditAdmin]: produce((draft, { payload: { message, fetching, success, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.data = data
    }),
    [failureUserEditAdmin]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.data = {}
    }),
    [resetUserEditAdmin]: produce((draft, { payload: { message, fetching, success, error, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data
    }),
  },
  defaultState
)

export default userEditAdmin
