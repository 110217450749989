/* eslint-disable react/jsx-filename-extension */
import Menu from 'antd/lib/menu'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { requestMessageSnackBar } from 'store/duck/messageSnackBar.duck'
import MoreDropdown from 'components/MoreDropdown'
import ModalConfirmation from 'components/ModalConfirmation'
import { AiOutlineCheck } from 'react-icons/ai'
import { MdBlock, MdDelete, MdModeEdit } from 'react-icons/md'
import { loadSpecialService } from 'store/duck/createSpecialService.duck'
import { requestDeleteSpecialService } from 'store/duck/deleteSpecialService.duck'
import { requestActivateSpecialService } from 'store/duck/activateSpecialService.duck'
import { StyledCol, StyledRow } from './styles'

const sizeIcon = '20'
const paddingItem = { padding: '0px 8px 0px 8px' }

const ActionsDropdown = ({ record, openDrawer, status }) => {
  const dispatch = useDispatch()

  const [propsModal, setPropsModal] = useState({
    visible: false,
    message: 'Are you sure you want to delete this Special Service?',
    // eslint-disable-next-line react/jsx-props-no-spreading
    icon: (props) => <MdDelete {...props} />,
    textButon: 'Delete',
    textSuccessfull: 'Special Service has been deleted',
    actionConfirm: (some) => some,
  })

  const loadRuleSelected = () => {
    openDrawer(true)
    dispatch(loadSpecialService(record))
  }

  const handleDeleteRule = async (id) => {
    setPropsModal({
      visible: true,
      message: 'Are you sure you want to delete this Special Service?',
      // eslint-disable-next-line react/jsx-props-no-spreading
      icon: (props) => <MdDelete {...props} />,
      textButon: 'Delete',
      textSuccessfull: 'Special Service has been deleted',
      actionConfirm: () => {
        dispatch(requestDeleteSpecialService(id))
        setPropsModal({
          visible: false,
          message: ' ',
          icon: (props) => props,
          textButon: '',
          textSuccessfull: '',
          actionConfirm: (props) => props,
        })
      },
    })
  }

  const messageModal = {
    false: 'activated',
    true: 'blocked',
  }

  const handleUpdateStatus = async (customerId = '') => {
    if (!status) {
      dispatch(requestMessageSnackBar(`Special Service has been ${messageModal[status].toLowerCase()}`))
      dispatch(requestActivateSpecialService({ active: !status }, customerId))
    } else {
      setPropsModal({
        visible: true,
        message: `Are you sure you want to ${messageModal[status]} this Special Service?`,
        // eslint-disable-next-line react/jsx-props-no-spreading
        icon: (props) => <AiOutlineCheck {...props} />,
        textButon: messageModal[status].charAt(0).toUpperCase() + messageModal[status].slice(1),
        textSuccessfull: `Special Services has been ${messageModal[status].toLowerCase()}`,
        actionConfirm: () => {
          dispatch(requestMessageSnackBar(`Special Service has been ${messageModal[status].toLowerCase()}`))
          dispatch(requestActivateSpecialService({ active: !status }, customerId))
          setPropsModal({
            visible: false,
            message: ' ',
            icon: (props) => props,
            textButon: '',
            textSuccessfull: '',
            actionConfirm: (props) => props,
          })
        },
      })
    }
  }

  const assets = {
    false: {
      icon: <AiOutlineCheck size={sizeIcon} />,
      text: <span>Activate Special Service</span>,
      onClick: (id) => handleUpdateStatus(id),
    },
    true: {
      icon: <MdBlock size={sizeIcon} />,
      text: <span>Block Special Service</span>,
      onClick: (id) => handleUpdateStatus(id),
    },
  }

  const displayedStatusModifiers = (id) => {
    return (
      <Menu.Item key={id} style={paddingItem} onClick={() => assets[status].onClick(id)}>
        <StyledRow>
          <StyledCol>{assets[status].icon}</StyledCol>
          <StyledCol>
            <span>{assets[status].text}</span>
          </StyledCol>
        </StyledRow>
      </Menu.Item>
    )
  }

  return (
    <MoreDropdown
      menu={
        <Menu>
          <Menu.Item style={paddingItem} onClick={() => loadRuleSelected()}>
            <StyledRow>
              <StyledCol>
                <MdModeEdit size={sizeIcon} />
              </StyledCol>
              <StyledCol>
                <span>Edit Special Service</span>
              </StyledCol>
            </StyledRow>
          </Menu.Item>
          {displayedStatusModifiers(record._id)}
          <Menu.Item style={paddingItem} onClick={() => handleDeleteRule(record._id)}>
            <StyledRow>
              <StyledCol>
                <MdDelete size={sizeIcon} />
              </StyledCol>
              <StyledCol>
                <span>Delete Special Service</span>
              </StyledCol>
            </StyledRow>
          </Menu.Item>
          <ModalConfirmation
            actionConfirm={propsModal.actionConfirm}
            textSuccessfull={propsModal.textSuccessfull}
            textButton={propsModal.textButon}
            close={() => {
              setPropsModal({
                visible: false,
                message: ' ',
                icon: (props) => props,
                textButon: '',
                textSuccessfull: '',
                actionConfirm: (props) => props,
              })
            }}
            icon={propsModal.icon}
            message={propsModal.message}
            visible={propsModal.visible}
            height="296px"
            width={350}
            centered
            avoidNotify
          />
        </Menu>
      }
    />
  )
}

const actionsDropdown = (_, openDrawer) => ({
  title:  <div style={{fontSize: '16px'}}>Actions</div>,
  dataIndex: 'actions',
  key: 'actions',
  textWrap: 'word-break',
  render: (edit, record) => {
    return <ActionsDropdown record={record} openDrawer={openDrawer} status={record.active} />
  },
  width: 80,
  align: 'center',
})

export default actionsDropdown
