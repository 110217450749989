import React from 'react'
import moment from 'moment'
import Tooltip from 'antd/lib/tooltip'
import Typography from 'antd/lib/typography'
import colors from 'providers/theme/config/colorPallete'
import { getStatus } from 'utils/getStatus'
import MasterRule from 'components/MasterRule/MasterRule'
import { AiFillStar } from 'react-icons/ai'
import ExpirationDate from 'components/ExpirationDate'
import outdated from 'utils/outDateRule'
import { StyledBadge, StyledText } from './styles'
import actionsDropdown from './actionsDropdown'

const { Text } = Typography
const columns = (dispatch, shippingType, openDrawer, enableEdit = true) => {
  return [
    {
      title: '',
      dataIndex: 'crossRule',
      render: (crossRule) => {
        return crossRule ? <AiFillStar fontSize="12px" style={{ color: '#F7981C', margin: '0px 4px' }} /> : null
      },
      width: 20,
      align: 'left',
    },
    {
      title: <div style={{ fontSize: '16px' }}>Name</div>,
      dataIndex: 'name',
      key: ['rule', 'name'],
      render: (name, rule) => {
        return (
          <MasterRule
            style={{ fontSize: '16px', display: 'flex' }}
            name={name}
            lastUseDate={rule?.lastUsedDate ?? ''}
            masterRule={rule.crossRule ?? false}
          />
        )
      },
      width: 120,
      ellipsis: true,
    },
    {
      title: '',
      dataIndex: 'expirationDate',
      render: (expirationDate) => {
        return expirationDate && moment(expirationDate).isValid() ? (
          <ExpirationDate
            expirationDate={moment(expirationDate).format('YYYY-MM-DD')}
            isExpirated={outdated(expirationDate)}
          />
        ) : null
      },
      width: 30,
      align: 'left',
    },
    {
      title: <div style={{ fontSize: '16px' }}>Percentage</div>,
      dataIndex: ['rule', 'margin'],
      key: 'margin',
      render: (margin) => {
        return <StyledText style={{ fontSize: '16px', display: 'flex' }}>{margin} %</StyledText>
      },
      width: 80,
      ellipsis: true,
      editable: enableEdit,
    },
    {
      title: <div style={{ fontSize: '16px' }}>Flat</div>,
      dataIndex: ['rule', 'flat'],
      key: 'flat',
      render: (flat) => {
        return <StyledText style={{ fontSize: '16px', display: 'flex' }}>{flat}</StyledText>
      },
      width: 80,
      ellipsis: true,
      editable: enableEdit,
    },
    {
      title: <div style={{ fontSize: '16px' }}>Market</div>,
      dataIndex: ['rule', 'market'],
      key: 'market',
      render: (market) => {
        return <StyledText style={{ fontSize: '16px', display: 'flex' }}>{market?.marketAreaName}</StyledText>
      },
      width: 120,
      ellipsis: true,
    },
    {
      title: <div style={{ fontSize: '16px' }}># Multi-step rules</div>,
      dataIndex: 'multiStepsRuleInfo',
      key: 'multiStepsRuleInfo',
      colSpan: 1,
      render: (multiStepsRuleInfo) => {
        multiStepsRuleInfo = multiStepsRuleInfo ?? { quantity: 0, multistepNames: [] }
        return (
          <Tooltip
            placement="bottom"
            title={
              multiStepsRuleInfo.multistepNames.length > 0
                ? multiStepsRuleInfo.multistepNames.map((v, index) => <div key={index}>{v}</div>)
                : null
            }
            color="#3594FF"
          >
            <Text underline style={{ color: colors.light_blue.default, cursor: 'pointer', fontSize: '16px' }}>
              {multiStepsRuleInfo.quantity}
            </Text>
          </Tooltip>
        )
      },
      width: 110,
      ellipsis: true,
    },
    {
      title: <div style={{ fontSize: '16px' }}># RulesSets</div>,
      dataIndex: 'rulesetsInfo',
      key: 'rulesetsInfo',
      colSpan: 1,
      render: (rulesetsInfo) => {
        rulesetsInfo = rulesetsInfo ?? { quantity: 0, ruleSetsNames: [] }
        return (
          <Tooltip
            placement="bottom"
            title={
              rulesetsInfo.ruleSetsNames.length > 0
                ? rulesetsInfo.ruleSetsNames.map((v, index) => <div key={index}>{v}</div>)
                : null
            }
            color="#3594FF"
          >
            <Text underline style={{ color: colors.light_blue.default, cursor: 'pointer', fontSize: '16px' }}>
              {rulesetsInfo.quantity}
            </Text>
          </Tooltip>
        )
      },
      width: 110,
      ellipsis: true,
    },

    {
      title: <div style={{ fontSize: '16px' }}>Status</div>,
      dataIndex: 'active',
      key: ['rate', 'active'],
      render: (active, rate) => {
        return (
          <div style={{ display: 'flex' }}>
            <StyledBadge variant={getStatus(rate.status, active)} />
          </div>
        )
      },
      width: 70,
    },
    actionsDropdown(dispatch, openDrawer),
  ]
}

export default columns
