import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: [],
}

export const { requestUser, successUser, failureUser, resetUser } = createActions({
  REQUEST_USER: (data) => ({
    ...defaultState,
    fetching: true,
    data,
  }),
  SUCCESS_USER: (data, message) => ({
    data,
    message,
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_USER: (message) => ({
    message,
    fetching: false,
    error: true,
  }),
  RESET_USER: () => ({
    ...defaultState,
  }),
})

const user = handleActions(
  {
    [requestUser]: produce((draft, { payload: { message, fetching, success, error, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data
    }),
    [successUser]: produce((draft, { payload: { message, fetching, success, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.data = data
    }),
    [failureUser]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.data = {}
    }),
    [resetUser]: produce((draft, { payload: { message, fetching, success, error, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data
    }),
  },
  defaultState
)

export default user
