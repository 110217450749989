import React from 'react'
import StyledLoading from './styles'

const SpinLoading = () => {
  return (
    <StyledLoading />
  )
}

export default SpinLoading
