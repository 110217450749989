import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  successDelete: false,
  data: {},
  itemId: '',
}

export const {
  requestDeleteRuleset,
  successDeleteRuleset,
  failureDeleteRuleset,
  clearDeleteRuleset,
} = createActions({
  REQUEST_DELETE_RULESET: (itemId) => ({
    ...defaultState,
    fetching: true,
    itemId,
  }),
  SUCCESS_DELETE_RULESET: (data, message) => ({
    data,
    message,
    fetching: false,
    error: false,
    successDelete: true,
  }),
  FAILURE_DELETE_RULESET: (message) => ({
    message,
    fetching: false,
    error: true,
    successDelete: false,
  }),
  CLEAR_DELETE_RULESET: () => ({
    ...defaultState,
  }),
})

const deleteRuleset = handleActions(
  {
    [requestDeleteRuleset]: produce((draft, { payload: { message, fetching, itemId } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.itemId = itemId
    }),
    [successDeleteRuleset]: produce((draft, { payload: { message, fetching, successDelete, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.successDelete = successDelete
      draft.data = data
    }),
    [failureDeleteRuleset]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
    }),
    [clearDeleteRuleset]: produce((draft, { payload: { message, fetching, error, data, successDelete } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.data = data
      draft.successDelete = successDelete
    }),
  },
  defaultState
)

export default deleteRuleset
