import { put, takeLatest, call } from 'redux-saga/effects'
import { rates } from 'services/'
import { requestGetRate, successGetRate, failureGetRate } from '../duck/getRate.duck'

function* getRateAsync(data) {
  try {
    const response = yield call(rates.getRate, data.payload.params)
    yield put(successGetRate(response))
  } catch (error) {
    yield put(failureGetRate(error.response.data.message))
  }
}

function* watchGetRateAsync() {
  yield takeLatest(requestGetRate, getRateAsync)
}

export default watchGetRateAsync
