import React from 'react' 
import { loadRuleset } from 'store/duck/createRuleSet.duck'
import { AiFillEye } from 'react-icons/ai'

const sizeIcon = '20'

const basicRulesetsActionsDropdown = (dispatch, openDrawer) => ({
  title: <div style={{ fontSize: '16px' }}>Actions</div>,
  dataIndex: 'actions',
  key: 'actions',
  textWrap: 'word-break',
  render: (edit, record) => (
    <AiFillEye
      size={sizeIcon}
      onClick={() => {
        dispatch(loadRuleset(record))
        openDrawer(true)
      }}
    />
  ),
  width: 80,
  align: 'center',
})

export default basicRulesetsActionsDropdown
