import React, { useState, useEffect } from 'react'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { addMultiStep } from 'store/duck/createMultiStep.duck'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Form from 'antd/lib/form'
import { ArrowDownIcon } from 'assets/svg'
import { FormItem } from 'components/Input'
import FloatLabel from 'components/Label/FloatLabel'
import { BsPlusSquareFill } from 'react-icons/bs'
import Select from 'antd/lib/select'
import StyledSearch from 'components/Input/Search'
import CustomSelect from 'components/CustomSelect'
import ButtonPrimary from 'components/Button/ButtonPrimary'
import { useDispatch, useSelector } from 'react-redux'
import CreateMultistepRuleModal from 'containers/rules/ListMultiStep/utils/CreateMultistepRule'
import { requestGetMultiSteps } from 'store/duck/getMultiSteps.duck'
import { requestGetMultiStepsByCustomer } from 'store/duck/getMultiStepsByCustomer.duck'
import useCustomersFromUser from 'hooks/useCustomersFromUser'
import { requestSaveSelectedCustomer } from 'store/duck/saveSelectedCustomer.duck'
import StyledItem from '../StyledItem/Index'
import StyledOptions from '../StyledOptions'
import DropDownCustomers from '../DropDownCustomers'

export default function FilterMultiStep({
  isOpenCreateModal = false,
  setIsOpenCreateModal = () => '',
  enableEdit = true,
}) {
  const [form] = Form.useForm()
  const { xl } = useBreakpoint()
  const [params, setParams] = useState({ text: '', page: 1, status: '', customer: [], limit: 100 })
  const dispatch = useDispatch()
  const [dropdownData, setRequest] = useCustomersFromUser()
  const { selectedCustomer } = useSelector((state) => state.saveSelectedCustomer)


  useEffect(() => {
    if (!enableEdit && dropdownData.length === 0) {
      setRequest(!enableEdit)
    }
  }, [enableEdit, setRequest, dropdownData, params])

  useEffect(() => {
    const include = params.customer.indexOf(selectedCustomer)
    if (selectedCustomer !== "" && include < 0) {
      setParams({ ...params, customer: [selectedCustomer] })
      form.setFieldsValue({ customer: selectedCustomer })
    }
  }, [params, form, selectedCustomer])



  useEffect(() => {
    if (enableEdit) dispatch(requestGetMultiSteps(params))
    else dispatch(requestGetMultiStepsByCustomer(params))
  }, [params, dispatch, enableEdit])

  const handleValuesChange = (values) => {
    const isAlpha = form.getFieldValue('isAlpha')
    const isMaster = form.getFieldValue('isMaster')
    const status = values.status ?? params.status
    const objectParams = {
      ...params,
      text: values.text ?? params.text,
      status: status !== 'All' ? status : undefined,
    }
    const type = typeof isAlpha
    if (type === 'boolean') {
      objectParams.isAlpha = isAlpha
    }
    if (type === undefined || isAlpha === undefined) {
      delete objectParams.isAlpha
    }
    const typeMaster = typeof isMaster
    if (typeMaster === 'boolean') {
      objectParams.isMaster = isMaster
    }
    if (typeMaster === undefined || isMaster === undefined) {
      delete objectParams.isMaster
    }


    if (!enableEdit) {
      const customer = values?.customer ?? ''
      if (customer !== '') {
        objectParams.customer = [customer]
        dispatch(requestSaveSelectedCustomer(customer))
      }
      else {
        objectParams.customer = []
        if (selectedCustomer !== "") dispatch(requestSaveSelectedCustomer(""))
      }
    }
    setParams(objectParams)
  }


  const handleCreateMultistepRule = () => {
    setIsOpenCreateModal(true)
    dispatch(addMultiStep())
  }

  return (
    <>
      <Form
        layout="vertical"
        name="filter-multistep-rules-form"
        style={{ width: '100%', margin: '0px', padding: '0px' }}
        form={form}
        initialValues={{
          status: 'All',
        }}
        onValuesChange={handleValuesChange}
        hideRequiredMark
      >
        <Row gutter={[24, 16]} justify="end">
          <Col flex={xl ? 4 : undefined} xs={!xl ? 24 : undefined}>
            <Form.Item name="text">
              <StyledSearch />
            </Form.Item>
          </Col>
          {!enableEdit && <DropDownCustomers dropdownData={dropdownData} />}

          {enableEdit && (
            <Col flex={xl ? 3 : undefined} xs={!xl ? 24 : undefined}>
              <FloatLabel label="Type Rules" show>
                <FormItem name="isMaster">
                  <CustomSelect
                    showSearch
                    allowClear
                    placeholder=""
                    keysort="label"
                    size="large"
                    options={[
                      {
                        value: true,
                        label: 'Master Rules',
                      },
                      {
                        value: false,
                        label: 'Customer Rules',
                      },
                    ]}
                  />
                </FormItem>
              </FloatLabel>
            </Col>
          )}
          <Col flex={xl ? 2 : undefined} xs={!xl ? 24 : undefined}>
            <FloatLabel label="Alpha/Beta" show>
              <FormItem name="isAlpha">
                <CustomSelect
                  showSearch
                  allowClear
                  placeholder=""
                  keysort="label"
                  size="large"
                  options={[
                    {
                      value: true,
                      label: 'Alpha',
                    },
                    {
                      value: false,
                      label: 'Beta',
                    },
                  ]}
                />
              </FormItem>
            </FloatLabel>
          </Col>
          <Col flex="auto">
            <FloatLabel label="Status" show>
              <StyledItem name="status">
                <Select suffixIcon={<ArrowDownIcon />} placeholder="Select" size="large">
                  <StyledOptions key="All" value="All">
                    All
                  </StyledOptions>
                  <StyledOptions key="Activated" value="Activated">
                    Activated
                  </StyledOptions>
                  <StyledOptions key="Deactivated" value="Deactivated">
                    Blocked
                  </StyledOptions>
                  <StyledOptions key="Paused" value="Paused">
                    Paused
                  </StyledOptions>
                </Select>
              </StyledItem>
            </FloatLabel>
          </Col>
          {enableEdit && (
            <Col flex="none">
              <Form.Item>
                <ButtonPrimary
                  icon={<BsPlusSquareFill size="16px" />}
                  height="40px"
                  onClick={handleCreateMultistepRule}
                >
                  New Multi-Step Rule
                </ButtonPrimary>
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
      <CreateMultistepRuleModal
        enableEdit={enableEdit}
        visible={isOpenCreateModal}
        onCloseModal={setIsOpenCreateModal}
      />
    </>
  )
}
