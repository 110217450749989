import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UserList from 'containers/users/UserList'
import { unloadUser } from 'store/duck/createUser.duck'
import { requestUserEditAdmin } from 'store/duck/userEditAdmin.duck'

import CreateUserModal from './utils/CreateUserModal'
import { CustomerContainer } from './styles'

const UserListPage = () => {
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)
  const { editUser, userSelected } = useSelector((state) => state.createUser)
  const idUser = userSelected._id

  useEffect(() => {
    if (editUser) {
      setVisible(true)

      dispatch(requestUserEditAdmin(idUser))
    }
  }, [dispatch, editUser, idUser])

  useEffect(() => {
    if (!visible) {
      dispatch(unloadUser())
    }
  }, [dispatch, visible])

  return (
    <CustomerContainer id="container">
      <UserList setVisible={setVisible} />
      <CreateUserModal visible={visible} handleOnClose={() => setVisible(false)} />
    </CustomerContainer>
  )
}

export default UserListPage
