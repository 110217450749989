const handleMarketState = (arr = []) => {
  let state = ''
  if (arr.length > 1) {
    if (arr[1].label) {
      const colon = arr[1].label.match(/,/)
      state = arr[1].label.substring(colon.index + 1)
    }
  }
  return state.replace(/\s/g, "")
}

export default handleMarketState
