import { put, takeLeading, call } from 'redux-saga/effects'
import { auth } from 'services'
import { requestPostRefreshToken, successPostRefreshToken, failurePostRefreshToken } from 'store/duck/refreshToken.duck'
import { validateDueDate } from 'utils/parseAuthData'
import { saveStorage } from 'utils/storage'
import axiosManager from 'utils/axiosManager'

function* refreshTokenRequestAsync() {
    try {
        const response = yield call(auth.refreshToken) 
        const decode = validateDueDate(response.token) 
        const { exp } = decode
        window.localStorage.setItem('expiration', exp)
        window.localStorage.setItem('sleeping', false)
        saveStorage(response.token, 'altptk')
        axiosManager.setAuthorization(response.token)
        yield put(successPostRefreshToken())
    } catch (error) {
        yield put(failurePostRefreshToken(error.response.data.message))
    }
}

function* watchRefreshTokenAsync() {
    yield takeLeading(requestPostRefreshToken, refreshTokenRequestAsync)
}

export default watchRefreshTokenAsync
