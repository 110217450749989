import React from 'react'
import Avatar from 'antd/lib/avatar'
import { AiOutlineUser } from 'react-icons/ai'
import customerAvatar from 'assets/img/avatar.png'

const Columns = [
  {
    title: '',
    dataIndex: ['customer', 'avatar'],
    key: 'avatar',
    render: (avatar) => {
      return <Avatar size="large" src={avatar || customerAvatar} icon={<AiOutlineUser />} />
    },
    width: 55,
    ellipsis: true,
  },
  {
    title: 'Name',
    dataIndex: ['customer', 'name'],
    key: 'name',
    sorter: (a, b) => a.customer.name.localeCompare(b.customer.name),
    sortDirections: ['descend', 'ascend'],
    width: 150,
    ellipsis: true,
  },
  {
    title: 'TMS ID',
    dataIndex: ['customer', 'tmsId'],
    key: 'tmsId',
    sorter: (a, b) => a.customer.tmsId.localeCompare(b.customer.tmsId),
    width: 120,
    ellipsis: true,
  },
  {
    title: 'Email',
    dataIndex: ['customer', 'email'],
    key: 'email',
    sorter: (a, b) => a.customer.email.localeCompare(b.customer.email),
    width: 180,
    ellipsis: true,
  },
  {
    title: 'Phone',
    dataIndex: ['customer', 'phone'],
    key: 'phone',
    sorter: (a, b) => a.customer.phone.length - b.customer.phone.length,
    width: 150,
    ellipsis: true,
    align: 'center',
  },
  {
    title: 'Address',
    dataIndex: ['customer', 'address'],
    key: 'address',
    sorter: (a, b) => a.customer.address.localeCompare(b.customer.address),
    width: 150,
    ellipsis: true,
  },
]

export default Columns
