import styled from 'styled-components'
import { DatePicker } from 'antd'

const SyledDatePicker = styled(DatePicker)`
  padding: 5px;
  border-radius: 4px;
  .ant-picker-suffix,
  .ant-picker-clear {
    color: #002555;
  }
`

export default SyledDatePicker
