import { put, takeLatest, call } from 'redux-saga/effects'
import { history } from 'services'
import { requestGetMultiStepsHistory, successGetMultiStepsHistory, failureGetMultiStepsHistory } from '../duck/getMultiStepsHistory.duck'

function* getMultiStepsHistoryAsync(data) {
  try {
    const response = yield call(history.getMultiStepsHistory, data.payload.params)
    yield put(successGetMultiStepsHistory(response))
  } catch (error) {
    yield put(failureGetMultiStepsHistory(error))
  }
}

function* watchGetMultiStepsHistoryAsync() {
  yield takeLatest(requestGetMultiStepsHistory, getMultiStepsHistoryAsync)
}

export default watchGetMultiStepsHistoryAsync
