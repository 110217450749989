import React from 'react'
import Typography from 'antd/lib/typography'
import ButtonPrimary from 'components/Button/ButtonPrimary'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import notify from 'utils/notify'
import StyleModal from './styles'

const { Text } = Typography

export default function ModalConfirmation({
  visible,
  height,
  width,
  centered,
  message,
  icon,
  close,
  textButton,
  textSuccessfull,
  actionConfirm,
  avoidNotify = false,
}) {
  const Icon = (prop) => {
    return icon(prop)
  }

  const returnAlertConfirmation = () => {
    actionConfirm()
    if (!avoidNotify) notify('success', `${textSuccessfull}`)
  }

  return (
    <StyleModal footer={null} centered={centered} visible={visible} height={height} width={width}>
      <Row gutter={[16, 14]} style={{ padding: '37px 20px 20px 20px' }}>
        <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '50px',
              height: '50px',
              backgroundColor: '#3594FF',
              borderRadius: '8px',
            }}
          >
            <Icon fontSize="35px" style={{ color: '#FFFFFF' }} />
          </div>
        </Col>
        <Col
          span={24}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#545454',
            fontWeight: '400',
            fontSize: '16px',
            textAlign: 'center',
          }}
        >
          <Text>{message}</Text>
        </Col>
        <Col
          span={24}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingRight: '0px',
            paddingLeft: '0px',
          }}
        >
          <ButtonPrimary
            onClick={returnAlertConfirmation}
            style={{ width: '100%', height: '40px', borderRadius: '4px' }}
          >
            {textButton}
          </ButtonPrimary>
        </Col>
        <Col
          span={24}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingRight: '0px',
            paddingLeft: '0px',
          }}
        >
          <ButtonPrimary
            onClick={close}
            style={{
              width: '100%',
              height: '40px',
              borderRadius: '4px',
              color: '#3594FF',
              backgroundColor: '#FFFFFF',
            }}
          >
            Cancel
          </ButtonPrimary>
        </Col>
      </Row>
    </StyleModal>
  )
}
