import styled from 'styled-components'

export const ImgSvg = styled.img`
  width: 22px;
  height: 22px;
  filter: ${(props) =>
    props.version === 'false'
      ? 'invert(67%) sepia(0%) saturate(338%) hue-rotate(228deg) brightness(98%) contrast(83%)'
      : `invert(47%) sepia(18%) saturate(3478%) hue-rotate(187deg) brightness(106%) contrast(101%)`};
  cursor: pointer;
`
export const ImgIcon = styled.img`
  width: 35px;
  height: 35px;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(186deg) brightness(103%) contrast(103%);
`
