import styled from 'styled-components'
import Input from 'antd/lib/input'

const InputGeneric = styled(Input)`
  width: ${(props) => (props.width ? props.width : '100%')};
  .ant-input-prefix,
  .ant-input-suffix {
    color: #002555;
    font-weight: 700;
    font-size: 14px;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export default InputGeneric
