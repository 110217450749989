export default function (state, action) {
  switch (action.type) {
    case 'customers':
      return { ...state, totalCustomers: action.totalCustomers }
    case 'search':
      return { ...state, search: action.search }
    case 'fetch-rules':
      return {
        ...state,
        modalTitle: action.modalTitle,
        ruleType: action.ruleType,
        params: action.params,
        rulesSelected: action.rulesSelected,
      }
    case 'ruleset-data':
      return { ...state, rulesetData: action.rulesetData }
    case 'fetch-multistep':
      return { ...state, multiSelected: action.multiSelected }
    default:
      return state
  }
}
