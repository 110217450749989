import { put, takeLatest, call } from 'redux-saga/effects'
import { rules } from 'services'
import { requestCreateRule, successCreateRule, failureCreateRule } from '../duck/createRule.duck'

function* createRuleAsync(data) {
  try {
    const response = yield call(rules.createRule, data.payload.data)
    yield put(successCreateRule(response))
  } catch (error) {
    yield put(failureCreateRule(error.response.data.message))
  }
}

function* watchCreateRuleAsync() {
  yield takeLatest(requestCreateRule, createRuleAsync)
}

export default watchCreateRuleAsync
