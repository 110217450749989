import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: [],
  params: {},
}

export const {
  requestGetAllCustomer,
  successGetAllCustomer,
  failureGetAllCustomer,
  clearGetAllCustomer,
} = createActions({
  REQUEST_GET_ALL_CUSTOMER: (params) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    params,
  }),
  SUCCESS_GET_ALL_CUSTOMER: (data) => ({
    ...defaultState,
    data,
    message: '',
    fetching: false,
    error: false,
    success: true,
    
  }),
  FAILURE_GET_ALL_CUSTOMER: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  CLEAR_GET_ALL_CUSTOMER: () => ({
    ...defaultState,
  }),
})

const getAllCustomer = handleActions(
  {
    [requestGetAllCustomer]: produce((draft, { payload: { fetching, error, success } }) => {
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successGetAllCustomer]: produce((draft, { payload: { message, fetching, success, data, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data
    }),
    [failureGetAllCustomer]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = !error
    }),
    [clearGetAllCustomer]: produce((draft, { payload: { fetching, error, success, message } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
    }),
  },
  defaultState
)

export default getAllCustomer
