import { put, takeLatest, call } from 'redux-saga/effects'
import { markets } from 'services/'
import { requestGetMarkets, successGetMarkets, failureGetMarkets } from 'store/duck/getMarkets.duck'

function* getMarketsAsync(data) {
  try {
    const response = yield call(markets.getMarkets,data.payload.data)
    yield put(successGetMarkets(response))
  } catch (error) {
    yield put(failureGetMarkets(error))
  }
}

function* watchGetMarketsAsync() {
  yield takeLatest(requestGetMarkets, getMarketsAsync)
}

export default watchGetMarketsAsync
