import styled from 'styled-components'

const StyledDiv = styled.div`
  width: 96%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: auto;
  margin-top: 30px;
`

export default StyledDiv
