import styled from 'styled-components'
import { TextBody } from 'components/Text/TextBody'
import Steps from 'antd/lib/steps'
import { Typography } from 'antd'

const { Text } = Typography

export const StyledAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
`
export const StyledTextBody = styled(TextBody)`
  font-style: italic;
  font-size: 15px;
  color: #a1a1a1;
`
export const StyledTexTitle = styled(Text)`
  font-weight: 700;
  color: #545454;
`
export const StyledTextDescription = styled(Text)`
  font-weight: 400;
  font-size: small;
  color: #545454;
`

export const StyledSteps = styled(Steps)`
  overflow-y: scroll;
  max-height: ${(props) => props.height};
  .ant-steps-item {
    margin: 0px 0px 18px 0px;
  }
  .ant-steps-item-container .ant-steps-item-tail {
    height: 150% !important;
    left: 20px !important;
    background: #ffffff !important;
    border-style: none none none dashed !important;
    border-color: #3594ff !important;
  }
  .ant-steps-item-container > .ant-steps-item-tail::after {
    background: #ffffff !important;
  }
`
export const StyledStep = styled(Steps.Step)`
  .ant-steps-item {
    margin: 0px;
  }
  .ant-steps-item-container {
    outline: none;
    margin: 0px 0px -8px 0px;
  }
  .ant-steps-item-container .ant-steps-item-tail {
    height: 150% !important;
    left: 25px !important;
    background: #ffffff !important;
    border-style: none none none dashed !important;
    border-color: #3594ff !important;
    line-height: 10px !important;
  }
  .ant-steps-item-container > .ant-steps-item-tail::after {
    background: #ffffff !important;
  }
  .ant-steps-item-container .ant-steps-item-title {
    line-height: 11px !important;
    margin: 0px 0px 0px 0px;
  }
`
