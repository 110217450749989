import PropTypes from 'prop-types'
import AddRulesModal from './AddRulesModal'

AddRulesModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  typeRule: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  rulesSelected: PropTypes.array.isRequired,
}

export default AddRulesModal
