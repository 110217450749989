import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: {},
  editTruckType: false,
  truckTypeSelected: {},
}

export const {
  requestCreateTruckType,
  successCreateTruckType,
  failureCreateTruckType,
  clearCreateTruckType,
  loadTruckType,
  addTruckType,
} = createActions({
  REQUEST_CREATE_TRUCK_TYPE: (data) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    data,
  }),
  SUCCESS_CREATE_TRUCK_TYPE: (data) => ({
    data,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_CREATE_TRUCK_TYPE: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  CLEAR_CREATE_TRUCK_TYPE: () => ({
    ...defaultState,
  }),
  LOAD_TRUCK_TYPE: (data) => ({
    ...defaultState,
    editTruckType: true,
    truckTypeSelected: data,
  }),
  ADD_TRUCK_TYPE: () => ({
    ...defaultState,
    editTruckType: false,
  }),
})

const createTruckType = handleActions(
  {
    [requestCreateTruckType]: produce((draft, { payload: { fetching, data, error, success } }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successCreateTruckType]: produce((draft, { payload: { message, fetching, success, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = false
      draft.data = data
    }),
    [failureCreateTruckType]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = false
    }),
    [clearCreateTruckType]: produce((draft, { payload: { message, fetching, error, success, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
      draft.data = data
    }),
    [loadTruckType]: produce((draft, { payload: { editTruckType, truckTypeSelected } }) => {
      draft.editTruckType = editTruckType
      draft.truckTypeSelected = truckTypeSelected
    }),
    [addTruckType]: produce((draft, { payload: { editTruckType } }) => {
      draft.editTruckType = editTruckType
    }),
  },
  defaultState
)

export default createTruckType
