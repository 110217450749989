import { put, call, debounce } from 'redux-saga/effects'
import { states } from 'services'
import { requestValidateState, successValidateState, failureValidateState } from 'store/duck/validateState.duck'

function* validateStateAsync(data) {
  try {
    const response = yield call(states.validateState, data.payload.data)
    yield put(successValidateState(response))
  } catch (error) {
    yield put(failureValidateState(error))
  }
}

function* watchValidateStateAsync() {
  yield debounce(1500, requestValidateState, validateStateAsync)
}

export default watchValidateStateAsync
