import React, { useMemo } from 'react'
import IconGas from 'assets/img/local_gas_station.png'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Space from 'antd/lib/space'
import Avatar from 'antd/lib/avatar'
import Tooltip from 'antd/lib/tooltip'
import { AiOutlineUser } from 'react-icons/all'
import Typography from 'antd/lib/typography'
import moment from 'moment'
import ImgSvg from 'components/ImgSvg'

const UserNameAvatar = ({
  name,
  avatar,
  considerCustomFuelPrice,
  lastLoginDate,
  hiddenDate = true,
  flatbedFuelPrice = '',
  reeferFuelPrice = '',
  vanFuelPrice = '',
  lastUpdatedFuelPrice = '',
  defaultFuel = false,
  trucks = []
}) => {
  const { Text } = Typography
  const consider = considerCustomFuelPrice ?? false
  // const fuel = defaultFuel
  const showDate =
    lastLoginDate !== '' ? `Last login date: ${moment.utc(lastLoginDate).format('MM/DD/YYYY')}` : null

  const outdated = () => {
    const lastUpdate = moment.utc(lastUpdatedFuelPrice).format('YYYY-MM-DD')
    const currentDate = moment().format('YYYY-MM-DD')

    const sameMonth = moment(lastUpdate).isSame(currentDate, 'month')

    if (sameMonth) return false

    return moment(lastUpdate).isBefore(currentDate, 'day')
  }
  const outdatedStyle = outdated()
    ? {
      filter: 'invert(16%) sepia(54%) saturate(7131%) hue-rotate(4deg) brightness(98%) contrast(128%)',
    }
    : {}

  const defaultOutdatedStyle = { filter: "invert(42%) sepia(88%) saturate(1510%) hue-rotate(87deg) brightness(108%) contrast(115%)" }

  const displayOutdated = outdated() ? (
    <Col span={24}>
      <Text style={{ fontStyle: 'italic', fontWeight: 600, fontSize: '12px' }}>Outdated:</Text>
      <Text style={{ fontStyle: 'italic', fontSize: '12px' }}>{` ${moment
        .utc(lastUpdatedFuelPrice)
        .format('MM/YY')}`}</Text>
    </Col>
  ) : null
 

  const fuelValues = useMemo(() => {
    let Flatbed = defaultFuel ? "" : (flatbedFuelPrice ?? 0)
    let Reefer = defaultFuel ? "" : (reeferFuelPrice ?? 0)
    let Van = defaultFuel ? "" : (vanFuelPrice ?? 0)

    if (defaultFuel && trucks.length > 0) {
      trucks.forEach((item) => {
        if (item.name === "Van" && item.defaultFuel) {
          Van = item.defaultFuel
        }
        if (item.name === "Flatbed" && item.defaultFuel) {
          Flatbed = item.defaultFuel
        }
        if (item.name === "Reefer" && item.defaultFuel) {
          Reefer = item.defaultFuel
        }
      })
    }

    return {
      Flatbed,
      Reefer,
      Van
    }

  }, [trucks, defaultFuel, flatbedFuelPrice, reeferFuelPrice, vanFuelPrice])


  return (
    <Row>
      <Space>
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {avatar && <Avatar src={avatar} />}
            {!avatar && <AiOutlineUser size="2rem" />}
            <span style={{ color: '#545454', paddingLeft: '3px', fontSize: '16px' }}>{name}</span>
            {consider || defaultFuel ? (
              <Tooltip
                placement="bottom"
                title={
                  <Row style={{ maxWidth: '80px' }}>
                    {displayOutdated}
                    <Col span={24}>
                      <Text style={{ fontStyle: 'italic', fontWeight: 600, fontSize: '12px' }}>Flatbed:</Text>
                      <Text style={{ fontStyle: 'italic', fontSize: '12px' }}>{` $${fuelValues.Flatbed}`}</Text>
                    </Col>
                    <Col span={24}>
                      <Text style={{ fontStyle: 'italic', fontWeight: 600, fontSize: '12px' }}>Reefer:</Text>
                      <Text style={{ fontStyle: 'italic', fontSize: '12px' }}>{` $${fuelValues.Reefer}`}</Text>
                    </Col>
                    <Col span={24}>
                      <Text style={{ fontStyle: 'italic', fontWeight: 600, fontSize: '12px' }}>Van:</Text>
                      <Text style={{ fontStyle: 'italic', fontSize: '12px' }}>{` $${fuelValues.Van}`}</Text>
                    </Col>
                  </Row>
                }
                color="#D9D9D9"
              >
                <ImgSvg alt="" src={IconGas} style={defaultFuel ? defaultOutdatedStyle : outdatedStyle} />
              </Tooltip>
            ) : null}
          </div>
          <div>
            {!hiddenDate ? (
              <div style={{ width: '100%', fontSize: '16px', color: '#3594FF' }}>{showDate}</div>
            ) : null}
          </div>
        </div>
      </Space>
    </Row>
  )
}

export default UserNameAvatar
