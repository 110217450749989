export const stringRules = (nameField, required = true) => [
  {
    type: 'string',
    pattern: new RegExp(/^[a-zA-Z0-9#. \b]*$/),
    message: `Invalid ${nameField}.`,
  },
  {
    max: 25,
    message: 'Maximum 25 characters allowed.',
  },
  {
    required,
    message: `Please enter the ${nameField}.`,
  },
]

export const accountManagerRules = (nameField) => [
  {
    type: 'string',
    pattern: new RegExp(/^[a-zA-Z0-9#. \b]*$/),
    message: `Invalid ${nameField}.`,
  },
  {
    max: 25,
    message: 'Maximum 25 characters allowed.',
  },   
]

export const addressRules = [
  {
    max: 70,
    message: 'Maximum 70 characters allowed.',
  },
  {
    required: true,
    message: 'An address must be provided',
  },
  {
    type: 'string',
    // eslint-disable-next-line
    pattern: new RegExp(/^[a-zA-Z0-9]+[\s]*[a-zA-Z0-9.\-\,\#]+[\s]*[a-zA-Z0-9.\-\,\#]+[a-zA-Z0-9\s.\-\,\#]*$/),
    message: 'Invalid Address',
  },
]

export const nameRules = [
  {
    type: 'string',
    pattern: new RegExp(/^^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/),
    message: `Invalid name.`,
  },
  {
    max: 25,
    message: 'Maximum 25 characters allowed.',
  },
  {
    required: true,
    message: `Please enter the name.`,
  },
]

export const descriptionRules = [
  {
    max: 500,
    message: 'Maximum 500 characters allowed.',
  },
]

export const emailRules = [
  {
    type: 'email',
    message: 'Invalid email address.',
  },
  {
    max: 100,
    message: 'Maximum 100 characters allowed.',
  },
  {
    required: true,
    message: 'Please input your email.',
  },
]

export const emailRulesNoRequired = [
  {
    type: 'email',
    message: 'Invalid email address.',
  },
  {
    max: 30,
    message: 'Maximum 30 characters allowed.',
  },
]

export const phoneRulesNoRequired = [
  {
    type: 'string',
    pattern: new RegExp('^[0-9]*$'),
    message: 'Invalid phone number',
  },
  {
    max: 10,
    message: 'Maximum 10 characters allowed!',
  },
]

export const addressRulesNoRequired = [
  {
    max: 70,
    message: 'Maximum 70 characters allowed.',
  },
  {
    type: 'string',
    // eslint-disable-next-line
    pattern: new RegExp(/^[a-zA-Z0-9]+[\s]*[a-zA-Z0-9.\-\,\#]+[\s]*[a-zA-Z0-9.\-\,\#]+[a-zA-Z0-9\s.\-\,\#]*$/),
    message: 'Invalid Address',
  },
]
export const passwordNoRequired = [
  {
    min: 5,
    message: 'Password must have 5 characters or more!',
  },
  {
    required: false,
    message: 'Please input your password!',
  },
]

export const phoneRules = [
  {
    type: 'string',
    pattern: new RegExp('^[0-9]*$'),
    message: 'Invalid phone number',
  },
  {
    max: 10,
    message: 'Maximum 10 characters allowed!',
  },
  {
    required: true,
    message: 'Please enter a phone number',
  },
]

export const passwordRules = [
  {
    min: 5,
    message: 'Password must have 5 characters or more!',
  },
  {
    required: true,
    message: 'Please input your password!',
  },
]

export const resetPasswordRules = [
  {
    min: 8,
    message: 'Password must have 8 characters or more!',
  },
  {
    required: true,
    message: 'Please input your password!',
  },
]

export const requiredRules = [
  {
    required: true,
    message: 'This field is required',
  },
  {
    max: 30,
    message: 'Maximum 30 characters allowed.',
  },
]

export const longNameRule = [
  {
    required: true,
    message: 'This field is required',
  },
  {
    max: 30,
    message: 'Maximum 40 characters allowed.',
  },
]

export const requiredDescription = [
  {
    required: true,
    message: 'This field is required',
  },
  {
    max: 80,
    message: 'Maximum 80 characters allowed.',
  },
  {
    pattern: new RegExp(/^[a-zA-Z0-9#. \b]*$/),
    message: 'Only Alphanumeric characters',
  },
]

export const requiredText = [
  {
    required: true,
    message: 'This field is required',
  },
  {
    max: 25,
    message: 'Maximum 25 characters allowed.',
  },
  {
    pattern: new RegExp(/^[a-zA-Z0-9#. \b]*$/),
    message: 'Only Alphanumeric characters',
  },
]

export const requiredMarginCustomer = [
  {
    required: true,
    message: 'this field is required',
  },
]

export const requiredFlatCustomer = [
  {
    required: true,
    message: 'this field is required',
  },
]

export const requiredMargin = [
  {
    required: true,
    message: 'This field is required',
  },
  {
    max: 3,
    message: 'Maximum 3 digits number allowed',
  },
  {
    whitespace: false,
    message: 'No whitespace allowed',
  },
]

export const requiredFlat = [
  {
    required: true,
    message: 'This field is required',
  },
  {
    max: 5,
    message: 'Maximum 5 digits number allowed',
  },
  {
    whitespace: false,
    message: 'No whitespace allowed',
  },
]

export const requiredPrice = [
  {
    required: true,
    message: 'Please enter a price',
  },
  {
    max: 4,
    message: 'Maximum 4 digits number allowed',
  },
]

export const requiredSimple = [
  {
    required: true,
    message: 'This field is required',
  },
]

export const requiredWeight = [
  {
    type: 'number',
    min: 1,
    max: 99999,
    message: 'Could not be zero and maximum 5 digits number allowed',
  },
]

export const requiredQuantity = [
  {
    type: 'number',
    min: 1,
    max: 99,
    message: 'Invalid quantity',
  },
]

export const alphanumericText = [
  {
    type: 'string',
    pattern: new RegExp(/^[a-zA-Z0-9#. \b]*$/),
    message: `Invalid text.`,
  },
  {
    max: 25,
    message: 'Maximum 25 characters allowed.',
  },
]
