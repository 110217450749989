import React from 'react'
import {
  StyledContainer,
  StyledHeader,
  StyledSubHeader,
  StyledExtremeLeftHeader,
  StyledRightHeader,
  StyledTitleHeader,
  StyledContent,
} from './styles'

const ContentContainer = ({ children, title, left, right, subHeader }) => {
  return (
    <StyledContainer>
      <StyledHeader>
        <StyledTitleHeader>{title}</StyledTitleHeader>
        <StyledRightHeader>{right}</StyledRightHeader>
        <StyledExtremeLeftHeader>{left}</StyledExtremeLeftHeader>
      </StyledHeader>
      <StyledSubHeader>{subHeader}</StyledSubHeader>
      <StyledContent>{children}</StyledContent>
    </StyledContainer>
  )
}

export default ContentContainer
