import styled from 'styled-components'

export const TitleValue = styled.div`
  font-size: '14px';
  font-weight: 400;
  color: #002555;
  text-align: center;
`

export const TextValue = styled.span`
  font-size: '14px';
  font-weight: 700;
  color: #002555;
  white-space: 'nowrap';
  text-align: center;
`

export const SmallBox = styled.div`
  background-color: ${(props) => props.color};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  overflow: hidden;
`

export const ContentBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`
