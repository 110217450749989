import React, { useState, useEffect } from 'react'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import { Col, Row } from 'antd'
import colors from 'providers/theme/config/colorPallete'
import Checkbox from 'antd/lib/checkbox'
import Text from 'antd/lib/typography/Text'
import NumberInput from '../../../components/Form/NumerInput'

export default function FuelPrices({
    setDefault = (some) => some,
    setCustom = (some) => some,
    setNoPrice = (some) => some,
    DefaultValue = false,
    NoneValue = true,
    CustomValue = false}) {
    const [toggle, settoggle] = useState(false)
    const [checked, setChecked] = useState(false)
    const [defaultFuel, setdefaultFuel] = useState(false)
    const [none, setNone] = useState(NoneValue)

    useEffect(() => {
        setChecked(CustomValue)
        setdefaultFuel(DefaultValue)
        setNone(NoneValue) 
        if(!NoneValue){
            settoggle(true)
        }
    }, [DefaultValue, NoneValue, CustomValue])


    const onToggleFuelPriceSection = () => {
        settoggle((open) => !open)
    }

    const onCheck = (e) => {
        settoggle(true)

        setChecked(e.target.checked)
        setCustom(e.target.checked)

        setdefaultFuel(false)
        setDefault(false)

        setNone(false)
        setNoPrice(false)
    }
    const onDefault = (e) => {
        settoggle(true)
        setdefaultFuel(e.target.checked)
        setDefault(e.target.checked)


        setChecked(false)
        setCustom(false)

        setNone(false)
        setNoPrice(false)
    }
    const onNone = (e) => {
        settoggle(false)
        setNone(e.target.checked)
        setNoPrice(e.target.checked)

        setChecked(false)
        setCustom(false)

        setdefaultFuel(false)
        setDefault(false)

    }

    

    return (
        <>
            <Row gutter={[8, 8]} style={{ marginBottom: 10 }}>
                <Col span={24}>
                    <span style={{ color: colors.dark_blue.default, fontWeight: 700, fontSize: 16, }}>
                        Fuel Price
                    </span>
                </Col>

                <Col span={8}>
                    <Checkbox
                        checked={checked}
                        onChange={onCheck}
                    >
                        <Text style={{ color: '#545454', fontWeight: 400, fontSize: '16px' }}>Custom</Text>
                    </Checkbox>
                </Col>
                <Col span={8}>
                    <Checkbox
                        checked={defaultFuel}
                        onChange={onDefault}
                    >
                        <Text style={{ color: '#545454', fontWeight: 400, fontSize: '16px' }}>Default</Text>
                    </Checkbox>
                </Col>
                <Col span={8}>
                    <Checkbox
                        checked={none}
                        onChange={onNone}
                    >
                        <Text style={{ color: '#545454', fontWeight: 400, fontSize: '16px' }}>None</Text>
                    </Checkbox>
                </Col>
            </Row>

            <Row gutter={[26, 4]} style={{ alignItems: 'baseline' }}>
                {
                    checked && (
                        <Col
                            span={24}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                                marginBottom: checked ? 0 : 10,
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <span style={{ color: colors.dark_blue.default, fontWeight: 700, fontSize: 16 }}>
                                    Settings
                                </span>
                            </div>
                            <div>
                                {toggle ? (
                                    <MdKeyboardArrowUp
                                        fontSize="24px"
                                        style={{
                                            color: '#3594FF',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => onToggleFuelPriceSection()}
                                    />
                                ) : (
                                    <MdKeyboardArrowDown
                                        fontSize="24px"
                                        style={{
                                            color: '#3594FF',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => onToggleFuelPriceSection()}
                                    />
                                )}
                            </div>
                        </Col>
                    )
                }

                {toggle && (
                    <>
                        <Col span={24} style={{ marginBottom: 12 }}>
                            Select the equipment type you wish to modify
                        </Col>
                        <Col span={12} style={{ color: colors.dark_grey.default, fontWeight: 400, fontSize: 16 }}>
                            Flatbed
                        </Col>
                        <Col span={12}>
                            <NumberInput name="flatbedFuelPrice" prefix="$" placeholder="0.00" disabled={!checked} />
                        </Col>
                        <Col span={12} style={{ color: colors.dark_grey.default, fontWeight: 400, fontSize: 16 }}>
                            Reefer
                        </Col>
                        <Col span={12}>
                            <NumberInput name="reeferFuelPrice" prefix="$" placeholder="0.00" disabled={!checked} />
                        </Col>
                        <Col span={12} style={{ color: colors.dark_grey.default, fontWeight: 400, fontSize: 16 }}>
                            Van
                        </Col>
                        <Col span={12} style={{ marginBottom: 8 }}>
                            <NumberInput name="vanFuelPrice" prefix="$" placeholder="0.00" disabled={!checked} />
                        </Col>
                    </>
                )}
            </Row>
        </>
    )
}
