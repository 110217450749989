import React from 'react'
import ImgSvg from 'components/ImgSvg'
import IconTimer from 'assets/img/timer_24.png'
import Tooltip from 'antd/lib/tooltip'

const ExpirationDate = ({ expirationDate = '', isExpirated = false }) => {
  const outdatedStyle = isExpirated
    ? {
        filter: 'invert(16%) sepia(54%) saturate(7131%) hue-rotate(4deg) brightness(98%) contrast(128%)',
      }
    : { filter: 'invert(58%) sepia(55%) saturate(6020%) hue-rotate(198deg) brightness(107%) contrast(101%)' }

  const colorExpired = isExpirated ? '#F85359' : '#3594FF'
  const textExpired = isExpirated ? `Outdate: ${expirationDate}` : expirationDate;

  return (
    <Tooltip placement="rightBottom" title={textExpired} color={colorExpired}>
      <ImgSvg src={IconTimer} style={outdatedStyle} />
    </Tooltip>
  )
}

export default ExpirationDate
