import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  successUpdateStatus: false,
  data: {},
}

export const {
  requestUpdateStatusMultiStep,
  successUpdateStatusMultiStep,
  failureUpdateStatusMultiStep,
  clearUpdateStatusMultiStep,
} = createActions({
  REQUEST_UPDATE_STATUS_MULTI_STEP: (data) => ({
    ...defaultState,
    fetching: true,
    successUpdateStatus: false,
    data,
  }),
  SUCCESS_UPDATE_STATUS_MULTI_STEP: (data, message) => ({
    data,
    message,
    fetching: false,
    error: false,
    successUpdateStatus: true,
  }),
  FAILURE_UPDATE_STATUS_MULTI_STEP: (message) => ({
    message,
    fetching: false,
    error: true,
  }),
  CLEAR_UPDATE_STATUS_MULTI_STEP: () => ({
    message: '',
    fetching: false,
    error: false,
    successUpdateStatus: false,
  }),
})

const updateStatusMultiStep = handleActions(
  {
    [requestUpdateStatusMultiStep]: produce((draft, { payload: { message, fetching, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.successUpdateStatus = false
      draft.data = data
    }),
    [successUpdateStatusMultiStep]: produce(
      (draft, { payload: { message, fetching, successUpdateStatus, data } }) => {
        draft.message = message
        draft.fetching = fetching
        draft.successUpdateStatus = successUpdateStatus
        draft.data = data
      }
    ),
    [failureUpdateStatusMultiStep]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
    }),
    [clearUpdateStatusMultiStep]: produce(
      (draft, { payload: { message, fetching, error, successUpdateStatus } }) => {
        draft.message = message
        draft.fetching = fetching
        draft.error = error
        draft.successUpdateStatus = successUpdateStatus
      }
    ),
  },
  defaultState
)

export default updateStatusMultiStep
