import React from 'react'
import colors from 'providers/theme/config/colorPallete'

const Divider = () => (
  <div
    style={{
      width: '100%',
      height: 1,
      backgroundColor: colors.light_grey.default,
      marginBottom: 24,
      marginTop: 16,
    }}
  />
)

export default Divider
