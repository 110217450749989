import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  ruleSets: [],
  totalRecords: 0,
}

export const {
  requestGetRuleSetsHistory,
  successGetRuleSetsHistory,
  failureGetRuleSetsHistory,
  clearGetRuleSetsHistory,
} = createActions({
  REQUEST_GET_RULE_SETS_HISTORY: (params) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    params,
  }),
  SUCCESS_GET_RULE_SETS_HISTORY: (ruleSets) => ({
    ruleSets,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_RULE_SETS_HISTORY: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  CLEAR_GET_RULE_SETS_HISTORY: () => ({
    ...defaultState,
  }),
})

const getRuleSetsHistory = handleActions(
  {
    [requestGetRuleSetsHistory]: produce((draft, { payload: { fetching, data, error, success } }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successGetRuleSetsHistory]: produce(
      (draft, { payload: { message, fetching, success, ruleSets,  error } }) => {
        draft.message = message
        draft.fetching = fetching
        draft.success = success
        draft.error = error
        draft.ruleSets = ruleSets
      }
    ),
    [failureGetRuleSetsHistory]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = !error
    }),
    [clearGetRuleSetsHistory]: produce(
      (draft, { payload: { message, fetching, success, ruleSets, totalRecords, error } }) => {
        draft.message = message
        draft.fetching = fetching
        draft.success = success
        draft.error = error
        draft.ruleSets = ruleSets
        draft.totalRecords = totalRecords
      }
    ),
  },
  defaultState
)

export default getRuleSetsHistory
