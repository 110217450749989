// src\containers\dashboard\RateInformation\RateInformation.util.js


const validatedAndReturnMarkers = (markers = [], extraCoordinates = {}) => {
    const newMarkers = JSON.parse(JSON.stringify(markers));
    const originLat = markers[0]?.coordinate?.lat ?? ""
    const originLng = markers[0]?.coordinate?.lng ?? ""
    const destinationLat = markers[1]?.coordinate?.lat ?? ""
    const destinationLng = markers[1]?.coordinate?.lng ?? ""
    if (JSON.stringify(extraCoordinates) !== "{}" && (originLat === "" || originLng === "")) {
        if (extraCoordinates?.originCoordinates) {
            const updatedOrigin = { ...newMarkers[0] }
            updatedOrigin.coordinate.lat = extraCoordinates?.originCoordinates?.lat
            updatedOrigin.coordinate.lng = extraCoordinates?.originCoordinates?.lng
            newMarkers[0] = updatedOrigin
        }
    }
    if (JSON.stringify(extraCoordinates) !== "{}" && (destinationLat === "" || destinationLng === "")) {
        if (extraCoordinates?.originCoordinates) {
            const updatedDestination = { ...newMarkers[1] }
            updatedDestination.coordinate.lat = extraCoordinates?.destinationCoordinates?.lat
            updatedDestination.coordinate.lng = extraCoordinates?.destinationCoordinates?.lng
            newMarkers[1] = updatedDestination
        }
    }
    return newMarkers
}

export default validatedAndReturnMarkers