import React from 'react'
import { Switch } from 'react-router-dom'
import TabsLane from 'containers/rates/TabsLane'
import HistoryLanes from 'containers/rates/HistoryLanes'
import Reporting from 'containers/rates/Reporting'
import Tableau from 'containers/rates/Reporting/Tableau' 

const LayoutLanes = () => {
  return (
    <Switch>
      <TabsLane
        componentOne={<HistoryLanes />}
        componetTwo={<Reporting />}
        componetThree={<Tableau />} 
      />
    </Switch>
  )
}

export default LayoutLanes
