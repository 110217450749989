import { put, takeLatest, call } from 'redux-saga/effects'
import { statistics } from 'services'
import { successGetTotalStatistics, requestGetTotalStatistics, failureGetTotalStatistics } from '../duck/getTotalStatistics.duck'

function* getGetTotalStatistics(data) {
  try {
    const response = yield call(statistics.getTotalStatistics, data.payload.params)
    yield put(successGetTotalStatistics(response))
  } catch (error) {
    yield put(failureGetTotalStatistics(error))
  }
}

function* watchGetTotalStatistcsAsync() {
  yield takeLatest(requestGetTotalStatistics, getGetTotalStatistics)
}

export default watchGetTotalStatistcsAsync
