import React, { useCallback, useEffect, useMemo, useState, useLayoutEffect } from 'react'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Form from 'antd/lib/form'
import Select from 'antd/lib/select'
import DatePicker from 'antd/lib/date-picker'
import { useDispatch, useSelector } from 'react-redux'
import { BsPlusSquareFill } from 'react-icons/bs'
import { MdDateRange } from 'react-icons/md'
import { identity } from 'lodash/util'
import { keyBy } from 'lodash/collection'
import { mapValues } from 'lodash/object'
import { clearDeleteRule } from 'store/duck/deleteRule.duck'
import { requestGetRules } from 'store/duck/getRules.duck'
import { requestGetMasterRulesByParams } from 'store/duck/getMasterRulesByParams.duck'
import { requestGetMultiSteps } from 'store/duck/getMultiSteps.duck'
import { requestGetRuleSets } from 'store/duck/getRuleSets.duck'
import { clearUpdateStatusRule } from 'store/duck/updateStatusRule.duck'
import { clearUpdateRuleset } from 'store/duck/updateRuleset.duck'
import { clearDeleteBetaRules } from 'store/duck/deleteBetaRules.duck'
import { clearSwitchBetaRules } from 'store/duck/switchBetaRules.duck'
import notify from 'utils/notify'
import ButtonPrimary from 'components/Button/ButtonPrimary'
import StyledSearch from 'components/Input/Search'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import FloatLabel from 'components/Label/FloatLabel'
import colors from 'providers/theme/config/colorPallete'
import { ArrowDownIcon } from 'assets/svg'
import { clearUpdateMultiStep } from 'store/duck/updateMultiStep.duck'
import { clearCreateMultiStep } from 'store/duck/createMultiStep.duck'
import { filterTypeRules } from 'utils/filters'
import { FormItem } from 'components/Input'
import CustomSelect from 'components/CustomSelect'
import useCustomersFromUser from 'hooks/useCustomersFromUser'
import { requestGetRulesByCustomer } from 'store/duck/getRulesByCustomer.duck'
import { requestSaveSelectedCustomer } from 'store/duck/saveSelectedCustomer.duck'
import StyledItem from '../StyledItem/Index'
import StyledOptions from '../StyledOptions'
import DropDownCustomers from '../DropDownCustomers'

const FilterRules = ({
  queryParams,
  handleColumnsParams,
  onClickNewRule,
  changeStatus,
  page,
  limit,
  getMasterRules = false,
  typesId = [],
  enableEdit = true,
}) => {
  const [form] = Form.useForm()
  const { xl } = useBreakpoint()
  const dispatch = useDispatch()
  const [filter, setFilter] = useState(false)
  const [params, setParams] = useState({})
  const [ruleType, setRuleType] = useState('geographicRule')
  const { successDelete } = useSelector((state) => state.deleteRule)
  const { success: successCreate } = useSelector((state) => state.createRule)
  const { success: successUpdate } = useSelector((state) => state.updateRule)
  const { success: successUpdateStatusRule, error, message } = useSelector((state) => state.updateStatusRule)
  const { success: returnSuccess } = useSelector((state) => state.returnVersionRule)
  const { success: successUpdateRuleset } = useSelector((state) => state.updateRuleset)
  const { successUpdate: successUpdateMultiStep } = useSelector((state) => state.updateMultiStep)
  const { success: successCreateMultiStep } = useSelector((state) => state.createMultiStep)
  const { successDelete: successDeleteBetas } = useSelector((state) => state.deleteBetaRules)
  const { successSwitch } = useSelector((state) => state.SwitchBetaRules)
  const { selectedCustomer } = useSelector((state) => state.saveSelectedCustomer)
  const [dropdownData, setRequest] = useCustomersFromUser()

  const masterRuleType = React.useMemo(() => {
    return filterTypeRules(typesId, params.typeRuleId)
  }, [params, typesId])

  useEffect(() => {
    if (!enableEdit && dropdownData.length === 0 && !getMasterRules) {
      setRequest(!enableEdit)
    }
  }, [enableEdit, setRequest, dropdownData, getMasterRules])

  useEffect(() => {
    if (dropdownData.length > 0 && !params.customer && !getMasterRules) {
      if (selectedCustomer !== '') {
        setParams({ ...params, customer: [selectedCustomer] })
        form.setFieldsValue({ customer: selectedCustomer })
      } else {
        setParams({ ...params, customer: [] })
      }
    }
  }, [dropdownData, params, getMasterRules, selectedCustomer, form])

  const ruleShippingDropdown = useCallback(
    () => (
      <Col flex={3}>
        <FloatLabel label="Type" show>
          <StyledItem name="ruleShipping">
            <Select placeholder="Select" size="large">
              <StyledOptions key="outbound" value="outbound">
                Pick up
              </StyledOptions>
              <StyledOptions key="inbound" value="inbound">
                Delivery
              </StyledOptions>
            </Select>
          </StyledItem>
        </FloatLabel>
      </Col>
    ),
    []
  )
  const ruleTypes = useMemo(
    () => ({
      geographicRule: {
        defaultRuleShipping: 'outState',
        getQueryAndColumnParams: (ruleShipping) =>
          ({
            outState: {
              queryParams: queryParams.geographic.outState,
              columnParams: { type: 'geographicRule', shipping: 'outState', reference: 'state' },
            },
            outZip: {
              queryParams: queryParams.geographic.outZip,
              columnParams: { type: 'geographicRule', shipping: 'outZip', reference: 'zip' },
            },
            inState: {
              queryParams: queryParams.geographic.inState,
              columnParams: { type: 'geographicRule', shipping: 'inState', reference: 'state' },
            },
            inZip: {
              queryParams: queryParams.geographic.inZip,
              columnParams: { type: 'geographicRule', shipping: 'inZip', reference: 'zip' },
            },
            outbound: {
              queryParams: queryParams.market.outbound,
              columnParams: { type: 'marketRule', shipping: 'outbound', reference: '' },
            },
            inbound: {
              queryParams: queryParams.market.inbound,
              columnParams: { type: 'marketRule', shipping: 'inbound', reference: '' },
            },
            outRegion: {
              queryParams: queryParams.region.outRegion,
              columnParams: { type: 'regionRule', shipping: 'outRegion', reference: '' },
            },
            inRegion: {
              queryParams: queryParams.region.inRegion,
              columnParams: { type: 'regionRule', shipping: 'inRegion', reference: '' },
            },
          }[ruleShipping]),
        render: () => (
          <Col flex={4}>
            <FloatLabel label="Type" show>
              <StyledItem name="ruleShipping">
                <Select suffixIcon={<ArrowDownIcon />} placeholder="Select" size="large">
                  <StyledOptions key="outRegion" value="outRegion">
                    Pickup Region
                  </StyledOptions>
                  <StyledOptions key="outState" value="outState">
                    Pickup State
                  </StyledOptions>
                  <StyledOptions key="outbound" value="outbound">
                    Pickup Market
                  </StyledOptions>
                  <StyledOptions key="outZip" value="outZip">
                    Pickup Zip
                  </StyledOptions>
                  <StyledOptions key="inRegion" value="inRegion">
                    Delivery Region
                  </StyledOptions>
                  <StyledOptions key="inState" value="inState">
                    Delivery State
                  </StyledOptions>
                  <StyledOptions key="inbound" value="inbound">
                    Delivery Market
                  </StyledOptions>
                  <StyledOptions key="inZip" value="inZip">
                    Delivery Zip
                  </StyledOptions>
                </Select>
              </StyledItem>
            </FloatLabel>
          </Col>
        ),
      },
      lengthRule: {
        getQueryAndColumnParams: () => ({
          queryParams: queryParams.length,
          columnParams: {
            type: 'lengthRule',
            shipping: '',
            reference: '',
          },
        }),
        render: () => null,
      },
      laneScoreRule: {
        getQueryAndColumnParams: () => ({
          queryParams: queryParams.laneScore,
          columnParams: {
            type: 'laneScoreRule',
            shipping: '',
            reference: '',
          },
        }),
        render: () => null,
      },
      leadTimeRule: {
        getQueryAndColumnParams: () => ({
          queryParams: queryParams.leadTime,
          columnParams: {
            type: 'leadTimeRule',
            shipping: '',
            reference: '',
          },
        }),
        render: () => null,
      },
      truckType: {
        getQueryAndColumnParams: () => ({
          queryParams: queryParams.equipment,
          columnParams: {
            type: 'truckType',
            shipping: '',
            reference: '',
          },
        }),
        render: () => null,
      },
      simpleRule: {
        defaultRuleShipping: 'outbound',
        getQueryAndColumnParams: (ruleShipping) =>
          ({
            outbound: {
              queryParams: queryParams.simple.outbound,
              columnParams: { type: 'simpleRule', shipping: 'outbound', reference: '' },
            },
            inbound: {
              queryParams: queryParams.simple.inbound,
              columnParams: { type: 'simpleRule', shipping: 'inbound', reference: '' },
            },
          }[ruleShipping]),
        render: ruleShippingDropdown,
      },
      dateRangeRule: {
        defaultRuleShipping: 'outbound',
        getQueryAndColumnParams: (ruleShipping) =>
          ({
            outbound: {
              queryParams: queryParams.dateRange.outbound,
              columnParams: { type: 'dateRangeRule', shipping: 'outbound', reference: '' },
            },
            inbound: {
              queryParams: queryParams.dateRange.inbound,
              columnParams: { type: 'dateRangeRule', shipping: 'inbound', reference: '' },
            },
          }[ruleShipping]),
        formItems: ['dateRange'],
        render: () => (
          <>
            {ruleShippingDropdown()}
            <Col flex={3}>
              <FloatLabel label="Date Range" show>
                <StyledItem name="dateRange">
                  <DatePicker.RangePicker
                    format="MM-DD-YYYY"
                    size="large"
                    suffixIcon={<MdDateRange size="20px" color={colors.dark_blue.default} />}
                  />
                </StyledItem>
              </FloatLabel>
            </Col>
          </>
        ),
      },
      analytics: {
        defaultRuleShipping: 'laneScore',
        getQueryAndColumnParams: (ruleShipping) =>
          ({
            laneScore: {
              queryParams: queryParams.analytics.laneScore,
              columnParams: {
                type: 'laneScoreRule',
                shipping: 'laneScore',
                reference: '',
              },
            },
            outMCI: {
              queryParams: queryParams.analytics.outMCI,
              columnParams: { type: 'mciRule', shipping: 'outMCI', reference: '' },
            },
            inMCI: {
              queryParams: queryParams.analytics.inMCI,
              columnParams: { type: 'mciRule', shipping: 'inMCI', reference: '' },
            },
            outLT: {
              queryParams: queryParams.analytics.outLT,
              columnParams: { type: 'ltRatioRule', shipping: 'outLT', reference: '' },
            },
            inLT: {
              queryParams: queryParams.analytics.inLT,
              columnParams: { type: 'ltRatioRule', shipping: 'inLT', reference: '' },
            },
          }[ruleShipping]),
        render: () => (
          <Col flex={4}>
            <FloatLabel label="Type" show>
              <StyledItem name="ruleShipping">
                <Select suffixIcon={<ArrowDownIcon />} placeholder="Select" size="large">
                  <StyledOptions key="laneScore" value="laneScore">
                    UD Score
                  </StyledOptions>
                  <StyledOptions key="outMCI" value="outMCI">
                    Origin MCI
                  </StyledOptions>
                  <StyledOptions key="inMCI" value="inMCI">
                    Destination MCI
                  </StyledOptions>
                  <StyledOptions key="outLT" value="outLT">
                    Origin L/T
                  </StyledOptions>
                  <StyledOptions key="inLT" value="inLT">
                    Destination L/T
                  </StyledOptions>
                </Select>
              </StyledItem>
            </FloatLabel>
          </Col>
        ),
      },
    }),
    [
      ruleShippingDropdown,
      queryParams.geographic.outState,
      queryParams.geographic.outZip,
      queryParams.geographic.inState,
      queryParams.geographic.inZip,
      queryParams.length,
      queryParams.laneScore,
      queryParams.leadTime,
      queryParams.simple.outbound,
      queryParams.simple.inbound,
      queryParams.dateRange.outbound,
      queryParams.dateRange.inbound,
      queryParams.equipment,
      queryParams.market.outbound,
      queryParams.market.inbound,
      queryParams.region.inRegion,
      queryParams.region.outRegion,
      queryParams.analytics.laneScore,
      queryParams.analytics.outMCI,
      queryParams.analytics.inMCI,
      queryParams.analytics.outLT,
      queryParams.analytics.inLT,
    ]
  )
  useEffect(() => {
    changeStatus(params.status)
  }, [changeStatus, params])

  useEffect(() => {
    setParams((prev) => ({
      ...prev,
      page,
      limit,
    }))
  }, [page, limit])

  useEffect(() => {
    const id = params.typeRuleId
    if (id) {
      if (!getMasterRules)
        if (enableEdit && params) dispatch(requestGetRules(params))
        else dispatch(requestGetRulesByCustomer(params))
      else dispatch(requestGetMasterRulesByParams({ ...params, masterRuleType }))
    }
  }, [dispatch, params, getMasterRules, masterRuleType, enableEdit])

  const getAfterReturnVersion = useCallback(() => {
    if (!getMasterRules)
      if (enableEdit) dispatch(requestGetRules(params))
      else dispatch(requestGetRulesByCustomer(params))
    else dispatch(requestGetMasterRulesByParams({ ...params, masterRuleType }))
  }, [dispatch, params, getMasterRules, masterRuleType, enableEdit])

  useEffect(() => {
    if (
      successCreate ||
      successUpdate ||
      successUpdateStatusRule ||
      successCreateMultiStep ||
      successUpdateMultiStep ||
      successUpdateRuleset ||
      successDeleteBetas ||
      successSwitch
    ) {
      if (!getMasterRules)
        if (enableEdit) dispatch(requestGetRules(params))
        else dispatch(requestGetRulesByCustomer(params))
      else dispatch(requestGetMasterRulesByParams({ ...params, masterRuleType }))
      dispatch(clearCreateMultiStep())
      dispatch(clearUpdateMultiStep())
      dispatch(clearUpdateRuleset())
    }
    if (successDelete || successDeleteBetas) {
      const messageBeta = successDeleteBetas ? 'Beta rules' : ''
      notify('success', `${successDelete ? 'Rule' : messageBeta} has been deleted`)
      if (!getMasterRules)
        if (enableEdit) dispatch(requestGetRules(params))
        else dispatch(requestGetRulesByCustomer(params))
      else dispatch(requestGetMasterRulesByParams({ ...params, masterRuleType }))
      dispatch(requestGetMultiSteps())
      dispatch(requestGetRuleSets())
    }
    if (successDeleteBetas) {
      dispatch(clearDeleteBetaRules())
    }
    if (successDelete) {
      dispatch(clearDeleteRule())
    }
    if (successUpdateStatusRule) {
      dispatch(clearUpdateStatusRule())
      if (message !== '') notify('error', message)
    }
    if (successSwitch) {
      dispatch(clearSwitchBetaRules())
    }
    if (error) notify('error', message)
  }, [
    successCreate,
    successUpdate,
    successDelete,
    successUpdateStatusRule,
    dispatch,
    form,
    params,
    error,
    message,
    successUpdateRuleset,
    successUpdateMultiStep,
    successCreateMultiStep,
    getMasterRules,
    masterRuleType,
    successDeleteBetas,
    successSwitch,
    enableEdit,
  ])
  useLayoutEffect(() => {
    if (returnSuccess) {
      getAfterReturnVersion()
    }
  }, [getAfterReturnVersion, returnSuccess])

  const handleValuesChange = (values) => {
    const isAlpha = form.getFieldValue('isAlpha')
    const formRuleType = form.getFieldValue('ruleType')
    const { queryParams: rawQueryParams, columnParams } = ruleTypes[formRuleType].getQueryAndColumnParams(
      values.ruleType ? ruleTypes[formRuleType].defaultRuleShipping : form.getFieldValue('ruleShipping')
    )

    const newParams = {
      ...rawQueryParams,
      text: formRuleType !== 'simpleRule' ? form.getFieldValue('text') : undefined,
      textAndWeekday: formRuleType === 'simpleRule' ? form.getFieldValue('text') : undefined,
      status: form.getFieldValue('status') !== 'All' ? form.getFieldValue('status') : undefined,
      ...mapValues(keyBy(ruleTypes[formRuleType].formItems, identity), (formItem) => form.getFieldValue(formItem)),
      page,
      limit,
      isAlpha,
    }

    if (!enableEdit && !getMasterRules) {
      const customer = values?.customer ?? ''
      if (customer !== '') {
        newParams.customer = [customer]
        dispatch(requestSaveSelectedCustomer(customer))
      } else {
        newParams.customer = []
        if (selectedCustomer !== '') dispatch(requestSaveSelectedCustomer(''))
      }
    }

    setParams(newParams)
    handleColumnsParams(columnParams)
    if (values.ruleType && values.ruleType !== 'truckType') {
      form.setFieldsValue({
        ruleShipping: ruleTypes[formRuleType].defaultRuleShipping,
      })
    }
    setRuleType(formRuleType)
    setFilter(true)
  }
  useEffect(() => {
    if (filter) {
      return
    }
    const formRuleType = form.getFieldValue('ruleType')
    const { queryParams: rawQueryParams } = ruleTypes[formRuleType].getQueryAndColumnParams(
      form.getFieldValue('ruleShipping')
    )
    const isAlpha = form.getFieldValue('isAlpha')
    setParams({
      ...rawQueryParams,
      status: form.getFieldValue('status'),
      page,
      limit,
      isAlpha,
    })
  }, [filter, form, limit, page, ruleTypes])

  return (
    <Form
      layout="vertical"
      name="filter-rules-form"
      style={{ width: '100%', margin: '0px', padding: '0px' }}
      form={form}
      initialValues={{
        ruleType: 'geographicRule',
        ruleShipping: 'outState',
        status: 'Activated',
      }}
      onValuesChange={handleValuesChange}
      hideRequiredMark
    >
      <Row gutter={[24, 16]} justify="end">
        <Col flex={xl ? 5 : undefined} xs={!xl ? 24 : undefined}>
          <StyledItem name="text">
            <StyledSearch />
          </StyledItem>
        </Col>
        {getMasterRules && enableEdit ? (
          <Col flex={xl ? 3 : undefined} xs={!xl ? 24 : undefined}>
            <FloatLabel label="Alpha/Beta" show>
              <FormItem name="isAlpha">
                <CustomSelect
                  showSearch
                  allowClear
                  placeholder=""
                  keysort="label"
                  size="large"
                  options={[
                    {
                      value: true,
                      label: 'Alpha',
                    },
                    {
                      value: false,
                      label: 'Beta',
                    },
                  ]}
                />
              </FormItem>
            </FloatLabel>
          </Col>
        ) : (
          ''
        )}
        {!enableEdit && !getMasterRules && <DropDownCustomers dropdownData={dropdownData} />}

        <Col flex={3}>
          <FloatLabel label="Category" show>
            <StyledItem name="ruleType">
              <Select
                data-testid="category-rule-testid"
                suffixIcon={<ArrowDownIcon />}
                placeholder="Select"
                size="large"
              >
                <StyledOptions key="analytics" value="analytics">
                  Analytics
                </StyledOptions>
                <StyledOptions key="dateRangeRule" value="dateRangeRule">
                  Date Range
                </StyledOptions>
                <StyledOptions key="truckType" value="truckType">
                  Equipment
                </StyledOptions>
                <StyledOptions key="geographicRule" value="geographicRule">
                  Geographic
                </StyledOptions>
                <StyledOptions key="leadTimeRule" value="leadTimeRule">
                  Lead Time
                </StyledOptions>
                <StyledOptions key="lengthRule" value="lengthRule">
                  Length of haul
                </StyledOptions>
                <StyledOptions key="simpleRule" value="simpleRule">
                  Day of Week
                </StyledOptions>
              </Select>
            </StyledItem>
          </FloatLabel>
        </Col>
        {ruleTypes[ruleType].render()}
        <Col flex="auto">
          <FloatLabel label="Status" show>
            <StyledItem name="status">
              <Select suffixIcon={<ArrowDownIcon />} placeholder="Select" size="large">
                <StyledOptions key="All" value="All">
                  All
                </StyledOptions>
                <StyledOptions key="Activated" value="Activated">
                  Activated
                </StyledOptions>
                <StyledOptions key="Deactivated" value="Deactivated">
                  Blocked
                </StyledOptions>
                <StyledOptions key="Paused" value="Paused">
                  Paused
                </StyledOptions>
              </Select>
            </StyledItem>
          </FloatLabel>
        </Col>
        {enableEdit && (
          <Col flex="none">
            <Form.Item>
              <ButtonPrimary icon={<BsPlusSquareFill size="16px" />} height="40px" onClick={onClickNewRule}>
                New Rule
              </ButtonPrimary>
            </Form.Item>
          </Col>
        )}
      </Row>
    </Form>
  )
}
export default FilterRules
