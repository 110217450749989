import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import GlobalStyle from './globalStyles';
import defaultTheme from './config';

const ThemeProvider = ({ children }) => {
  return (
    <StyledThemeProvider theme={defaultTheme}>
      <GlobalStyle />
      {children}
    </StyledThemeProvider>
  );
};

export default ThemeProvider;
