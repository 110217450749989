import { put, takeLatest, call } from 'redux-saga/effects'

import { users } from 'services'

import {
  requestDeactivateUser,
  successDeactivateUser,
  failureDeactivateUser,
} from 'store/duck/deactivateUser.duck'

function* deactivateUserAsync(data) {
  try {
    const response = yield call(users.deactivateUser, data.payload.params)
    yield put(successDeactivateUser(response, 'success'))
  } catch (error) {
    yield put(failureDeactivateUser(error.response.data.message))
  }
}

function* watchDeactivateUserAsync() {
  yield takeLatest(requestDeactivateUser, deactivateUserAsync)
}

export default watchDeactivateUserAsync
