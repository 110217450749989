import { put, takeLatest, call } from 'redux-saga/effects'
import { rules } from 'services'
import { requestGetMasterRules, successGetMasterRules, failureGetMasterRules } from '../duck/getMasterRules.duck'

function* getMasterRulesAsync() {
  try {
    const response = yield call(rules.getMasterRules)
    yield put(successGetMasterRules(response))
  } catch (error) {
    yield put(failureGetMasterRules(error))
  }
}

function* watchGetMasterRulesAsync() {
  yield takeLatest(requestGetMasterRules, getMasterRulesAsync)
}

export default watchGetMasterRulesAsync
