export const EnumStatus = {
  Activated: 'Activated',
  Paused: 'Paused',
  Deactivated: 'Deactivated',
}

export const getStatus = (property, active) => {
  return EnumStatus[property] ?? (active ?? false ? 'Activated' : 'Deactivated')
}
 
