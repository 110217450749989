import { put, takeLatest, call } from 'redux-saga/effects'
import { history } from 'services'
import {
  requestGetSpecialServiceHistory,
  successGetSpecialServiceHistory,
  failureGetSpecialServiceHistory,
} from '../duck/getSpecialServiceHistory.duck'

function* getSpecialServiceHistoryAsync(data) {
  try {
    const response = yield call(history.getSpecialServiceHistory, data.payload.params)
    yield put(successGetSpecialServiceHistory(response))
  } catch (error) {
    yield put(failureGetSpecialServiceHistory(error))
  }
}

function* watchGetSpecialServiceHistoryAsync() {
  yield takeLatest(requestGetSpecialServiceHistory, getSpecialServiceHistoryAsync)
}

export default watchGetSpecialServiceHistoryAsync
