import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: '',
}

export const {
  requestGetDownloadMultipleRates,
  successGetDownloadMultipleRates,
  failureGetDownloadMultipleRates,
  clearDownloadRates,
} = createActions({
  REQUEST_GET_DOWNLOAD_MULTIPLE_RATES: (data) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    data,
  }),
  SUCCESS_GET_DOWNLOAD_MULTIPLE_RATES: (data) => ({
    data,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_DOWNLOAD_MULTIPLE_RATES: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  CLEAR_DOWNLOAD_RATES: () => ({}),
})

const getDownloadMultipleRates = handleActions(
  {
    [requestGetDownloadMultipleRates]: produce((draft, { payload: { fetching, data, error, success } }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successGetDownloadMultipleRates]: produce(
      (draft, { payload: { message, fetching, success, data, error } }) => {
        draft.message = message
        draft.fetching = fetching
        draft.success = success
        draft.error = error
        draft.data = data
      }
    ),
    [failureGetDownloadMultipleRates]: produce((draft, { payload: { message, fetching, success, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [clearDownloadRates]: produce((draft) => {
      draft.success = false
    }),
  },
  defaultState
)

export default getDownloadMultipleRates
