import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: [],
}

export const {
  requestGetGeographicReference,
  successGetGeographicReference,
  failureGetGeographicReference,
  clearGetGeographicReference,
} = createActions({
  REQUEST_GET_GEOGRAPHIC_REFERENCE: () => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
  }),
  SUCCESS_GET_GEOGRAPHIC_REFERENCE: (data) => ({
    data,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_GEOGRAPHIC_REFERENCE: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  CLEAR_GET_GEOGRAPHIC_REFERENCE: () => ({
    ...defaultState,
  }),
})

const getGeographicReference = handleActions(
  {
    [requestGetGeographicReference]: produce((draft, { payload: { fetching, error, success } }) => {
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successGetGeographicReference]: produce((draft, { payload: { message, fetching, success, data, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data
    }),
    [failureGetGeographicReference]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = !error
    }),
    [clearGetGeographicReference]: produce((draft, { payload: { message, fetching, success, data, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data
    }),
  },
  defaultState
)

export default getGeographicReference
