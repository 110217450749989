import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { requestGetAutocompleteHere } from 'store/duck/getAutocompleteHere.duck'
import { requestGetZipHere, clearGetZipHere } from 'store/duck/getZipHere.duck'
import { clearGetZipAutoComplete, requestGetZipAutoComplete } from 'store/duck/getZipAutoComplete.duck'
import { saveLane } from 'store/duck/saveQuote.duck'
import { requestGetCityFromZip } from 'store/duck/getCityFromZip.duck'

const useAutocomplete = () => {
  const {
    dataZip,
    data,
    success,
    editRate,
    zipCodes,
    dataFromZipCode,
    fetchingData,
    fetchingZipAutoComplete 
  } = useSelector((state) => ({
    success: state.getZipHere.success,
    dataZip: state.getZipHere.data, 
    data: state.getAutocompleteHere.data || [],
    fetchingData: state.getAutocompleteHere.fetching,
    editRate: state.createRate.editRate,
    zipCodes: state.getZipAutoComplete.zipCodes || [],
    dataFromZipCode: state.getCityFromZip.data,
    fetchingZipAutoComplete: state.getZipAutoComplete.fetching
  }))

  const [value, setValue] = useState(' ')
  const [dropdownData, setDropdownData] = useState(data || [])
  const [isNumberInput, setIsNumberInput] = useState(false)
  const [fetching, setfetching] = useState(false)
  const [resultData, setResultData] = useState([])
  const dispatch = useDispatch()



  useEffect(() => {
    const fetch = fetchingZipAutoComplete || fetchingData   
    setfetching(fetch)
  }, [fetchingData, fetchingZipAutoComplete, fetching])


  useEffect(() => {
    if (isNumberInput && value.length === 5) {
      dispatch(requestGetCityFromZip(value))
    } else if (value) {
      dispatch(requestGetAutocompleteHere(value))
      dispatch(clearGetZipAutoComplete())
      setResultData([])
    }
  }, [dispatch, value, isNumberInput])

  useEffect(() => {
    if (success && Object.keys(dataZip).length > 0 && !dataZip.wayPoint && !editRate) {
      dispatch(saveLane(dataZip))
      dispatch(clearGetZipHere())
    }
  }, [dataZip, dispatch, success, editRate])

  const handleAutocompleteChange = (searchText) => {
    const regex = /\d+/g
    const hasNumbers = regex.test(searchText)
    setIsNumberInput(hasNumbers)
    setValue(searchText)
  }
  const handleSelect = (selectedValue, target) => {
    if (selectedValue === 'Los Angeles, CA, United States') {
      dispatch(requestGetZipHere(selectedValue, target))
    } else dispatch(requestGetZipHere(selectedValue.replace(', United States', ''), target))
  }

  useEffect(() => {
    if (data.length > 0) {
      dispatch(requestGetZipAutoComplete(data))
    }
  }, [data, dispatch])

  useEffect(() => {
    if (zipCodes.length) {
      const mixingData = []
      data.forEach((element, index) => {
        let newObj = {}
        if (zipCodes && zipCodes.length > 0 && zipCodes[index]) {
          newObj = { ...element[index] }
          newObj.value = `${element.value} - ${zipCodes[index]}`
        }
        mixingData.push(newObj)
      });
      setResultData(mixingData)


    }
  }, [data, zipCodes, dispatch])



  useEffect(() => {
    const finalDropdownData = isNumberInput ? dataFromZipCode : resultData
    setDropdownData(finalDropdownData)
  }, [dataFromZipCode, zipCodes, isNumberInput, resultData])




  return [dropdownData, handleAutocompleteChange, handleSelect, fetching]
}

export default useAutocomplete
