import { put, takeLatest, call } from 'redux-saga/effects'
import { rules } from 'services'
import {
  requestCreateMultiStep,
  successCreateMultiStep,
  failureCreateMultiStep,
} from '../duck/createMultiStep.duck'

function* createMultiStepAsync(data) {
  try {
    const response = yield call(rules.createMultiStep, data.payload.data)
    yield put(successCreateMultiStep(response))
  } catch (error) {
    yield put(failureCreateMultiStep(error))
  }
}

function* watchCreateMultiStepAsync() {
  yield takeLatest(requestCreateMultiStep, createMultiStepAsync)
}

export default watchCreateMultiStepAsync
