import React from 'react'
import { DatePicker } from 'antd'
import { AiOutlineCalendar } from 'react-icons/ai'
import DrawerItem from 'components/Form/DrawerItem'
import ControlInput from 'components/Form/ControlInput'
import InputWithLabel from 'components/Form/InputWithLabel'
import colors from 'providers/theme/config/colorPallete'

const DateInput = ({ label, name, rules, ...props }) => (
  <InputWithLabel label={label}>
    {(hasValue) => (
      <DrawerItem name={name} rules={rules} style={{ marginBottom: 0 }}>
        <ControlInput hasValue={hasValue}>
          {({ value, onChange }) => (
            <DatePicker
              allowClear
              style={{ width: '100%' }}
              placeholder=""
              size="large"
              suffixIcon={<AiOutlineCalendar size={20} color={colors.dark_blue.default} />}
              value={value}
              onChange={onChange}
              format="MM-DD-YYYY"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
          )}
        </ControlInput>
      </DrawerItem>
    )}
  </InputWithLabel>
)

export default DateInput
