import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: {},
}

export const { requestPostLogin, successPostLogin, failurePostLogin, logout, clearPostLogin } = createActions({
  REQUEST_POST_LOGIN: (data) => ({
    ...defaultState,
    fetching: true,
    data,
  }),
  SUCCESS_POST_LOGIN: (data, message) => ({
    data,
    message,
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_POST_LOGIN: (message) => ({
    message,
    fetching: false,
    error: true,
  }),
  LOGOUT: () => ({}),
  CLEAR_POST_LOGIN: () => ({
    error: false,
  }),
})

const loginUser = handleActions(
  {
    [requestPostLogin]: produce((draft, { payload: { message, fetching, success, error, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data
    }),
    [successPostLogin]: produce((draft, { payload: { message, fetching, success, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.data = data
    }),
    [failurePostLogin]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
    }),
    [logout]: produce((draft) => {
      draft.message = ''
      draft.fetching = false
      draft.error = false
      draft.success = false
      draft.data = {}
    }),
    [clearPostLogin]: produce((draft, { payload: { error } }) => {
      draft.error = error
    }),
  },
  defaultState
)

export default loginUser
