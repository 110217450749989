import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  successDelete: false,
  itemId: '',
  data: {},
}

export const { requestDeleteRate, successDeleteRate, failureDeleteRate, clearDeleteRate } = createActions({
  REQUEST_DELETE_RATE: (itemId) => ({
    ...defaultState,
    fetching: true,
    itemId,
  }),
  SUCCESS_DELETE_RATE: (data, message) => ({
    data,
    message,
    fetching: false,
    error: false,
    successDelete: true,
  }),
  FAILURE_DELETE_RATE: (message) => ({
    message,
    fetching: false,
    error: true,
    successDelete: false,
  }),
  CLEAR_DELETE_RATE: () => ({
    ...defaultState,
  }),
})

const deleteRate = handleActions(
  {
    [requestDeleteRate]: produce((draft, { payload: { fetching, itemId } }) => {
      draft.fetching = fetching
      draft.itemId = itemId
    }),
    [successDeleteRate]: produce((draft, { payload: { message, fetching, successDelete, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.successDelete = successDelete
      draft.data = data
    }),
    [failureDeleteRate]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
    }),
    [clearDeleteRate]: produce((draft, { payload: { message, fetching, error, successDelete, data, itemId } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.successDelete = successDelete
      draft.data = data
      draft.itemId = itemId
    }),
  },
  defaultState
)

export default deleteRate
