import React from 'react'
import { Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import LayoutRoutes from './LayoutRoutes'

const Routes = () => {
  const { login } = useSelector((state) => ({ login: state.login.data.roleId.name }))

  return <>{login !== 'Basic' && <Route path="/rules" component={LayoutRoutes} />}</>
}

export default Routes
