import styled from 'styled-components'
import Button from 'antd/lib/button'

const StyledButton = styled(Button)`
  border: 2px solid #002555;
  color: #002555;
  &:hover {
    background: #002555;
    color: white;
    border-color: #002555 !important;
    span {
      color: #002555;
      font-weight: 600;
    }
  }
  svg {
    position: absolute;
    top: -1px;
    right: -1px;
  }
  span {
    position: absolute !important;
    top: 0.2rem;
    left: 2.5rem;
  }
`

export default StyledButton
