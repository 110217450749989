import { put, takeLatest, call } from 'redux-saga/effects'
import { rules } from 'services'
import { requestGetRulesetById, successGetRulesetById, failureGetRulesetById } from '../duck/getRulesetById.duck'

function* getRulesetByIdAsync(data) {
  try {
    const response = yield call(rules.getRulesetById, data.payload.id)
    yield put(successGetRulesetById(response))
  } catch (error) {
    yield put(failureGetRulesetById(error))
  }
}

function* watchGetRulesetByIdAsync() {
  yield takeLatest(requestGetRulesetById, getRulesetByIdAsync)
}

export default watchGetRulesetByIdAsync
