import Menu from 'antd/lib/menu'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import MoreDropdown from 'components/MoreDropdown'
import ModalConfirmation from 'components/ModalConfirmation'
import { MdBlock, MdDelete, MdModeEdit } from 'react-icons/md'
import { requestUpdateTruckType } from 'store/duck/updateTruckType.duck'
import { requestMessageSnackBar } from 'store/duck/messageSnackBar.duck'
import { AiOutlineCheck } from 'react-icons/ai'
import { loadTruckType } from 'store/duck/createTruckType.duck'
import { StyledCol, StyledRowMenu } from '../styles'

const sizeIcon = '20'
const paddingItem = { padding: '0px 8px 0px 8px' }

const ActionsDropdown = ({ record, openDrawer, isActive }) => {
  const dispatch = useDispatch()

  const [propsModal, setPropsModal] = useState({
    visible: false,
    message: 'Are you sure you want to delete this Equipment type?',
    // eslint-disable-next-line react/jsx-props-no-spreading
    icon: (props) => <MdDelete {...props} />,
    textButon: 'Delete',
    textSuccessfull: 'Equipment type has been deleted',
    actionConfirm: (some) => some,
  })

  const editEquipmentType = () => {
    openDrawer(true)
    dispatch(loadTruckType(record))
  }

  const changeStatus = () => {
    if (!isActive) {
      dispatch(requestUpdateTruckType({ id: record._id, active: true }))
      dispatch(requestMessageSnackBar(`Equipment type has been activated`))
    } else {
      setPropsModal({
        visible: true,
        message: `Are you sure you want to block this Equipment type?`,
        // eslint-disable-next-line react/jsx-props-no-spreading
        icon: (props) => <MdBlock {...props} />,
        textButon: 'Block',
        textSuccessfull: 'Equipment type has been blocked',
        actionConfirm: () => {
          dispatch(requestUpdateTruckType({ id: record._id, active: false }))
          dispatch(requestMessageSnackBar('Equipment type has been blocked'))

          setPropsModal({
            visible: false,
            message: ' ',
            icon: (props) => props,
            textButon: '',
            textSuccessfull: '',
            actionConfirm: (props) => props,
          })
        },
      })
    }
  }

  return (
    <MoreDropdown
      menu={
        <Menu>
          <Menu.Item style={paddingItem} onClick={editEquipmentType}>
            <StyledRowMenu>
              <StyledCol>
                <MdModeEdit size={sizeIcon} />
              </StyledCol>
              <StyledCol>
                <span>Edit Equipment type</span>
              </StyledCol>
            </StyledRowMenu>
          </Menu.Item>
          <Menu.Item style={paddingItem} onClick={() => changeStatus()}>
            {!isActive ? (
              <StyledRowMenu>
                <StyledCol>
                  <AiOutlineCheck size={sizeIcon} />
                </StyledCol>
                <StyledCol>
                  <span>Active Equipment type</span>
                </StyledCol>
              </StyledRowMenu>
            ) : (
              <StyledRowMenu>
                <StyledCol>
                  <MdBlock size={sizeIcon} />
                </StyledCol>
                <StyledCol>
                  <span>Block Equipment type</span>
                </StyledCol>
              </StyledRowMenu>
            )}
          </Menu.Item>
          <ModalConfirmation
            avoidNotify
            actionConfirm={propsModal.actionConfirm}
            textSuccessfull={propsModal.textSuccessfull}
            textButton={propsModal.textButon}
            close={() => {
              setPropsModal({
                visible: false,
                message: ' ',
                icon: (props) => props,
                textButon: '',
                textSuccessfull: '',
                actionConfirm: (props) => props,
              })
            }}
            icon={propsModal.icon}
            message={propsModal.message}
            visible={propsModal.visible}
            height="296px"
            width={350}
            centered
          />
        </Menu>
      }
    />
  )
}

const actionsDropdown = (_, openDrawer) => ({
  title: <div style={{fontSize: '16px'}}>Actions</div>,
  dataIndex: 'actions',
  key: 'actions',
  textWrap: 'word-break',
  render: (_edit, record) => <ActionsDropdown record={record} openDrawer={openDrawer} isActive={record.active} />,
  align: 'center',
  width: 80,
})

export default actionsDropdown
