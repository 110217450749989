import { put, takeLatest, call } from 'redux-saga/effects'
import { statistics } from 'services'
import { successGetStatistics, requestGetStatistics, failureGetStatistics } from '../duck/getStatistics.duck'

function* getGetStatistics(data) {
  try {
    const response = yield call(statistics.getStatistics, data.payload.params)
    yield put(successGetStatistics(response))
  } catch (error) {
    yield put(failureGetStatistics(error))
  }
}

function* watchGetAllStatistcsAsync() {
  yield takeLatest(requestGetStatistics, getGetStatistics)
}

export default watchGetAllStatistcsAsync
