import { useState, useRef, useEffect } from 'react'

const useHereMap = () => {
  const [map, setMap] = useState(null)
  const mapElement = useRef(null)

  useEffect(() => {
    if (mapElement.current && !map) {
      const { H } = window
      const platform = new H.service.Platform({ apiKey: process.env.REACT_APP_HERE_MAPS_KEY })
      const defaultLayers = platform.createDefaultLayers()

      const _map = new H.Map(mapElement.current, defaultLayers.vector.normal.map, {
        zoom: 3.9,
        center: {
          lat: 39.0,
          lng: -97.0,
        },
        pixelRatio: window.devicePixelRatio || 1,
      })
      setMap(_map)
    }
    const clearPointer = () => map && map.removeObjects(map.getObjects())

    return () => {
      clearPointer()
    }
  }, [map])

  return mapElement
}

export default useHereMap
