import { put, takeLatest, call } from 'redux-saga/effects'
import { rules } from 'services'
import {
  requestSwitchBetaRules,
  successSwitchBetaRules,
  failureSwitchBetaRules,
} from '../duck/switchBetaRules.duck'

function* SwitchBetaRulesAsync() {
  try {
    const response = yield call(rules.switchBetaRules)
    yield put(successSwitchBetaRules(response))
  } catch (error) {
    yield put(failureSwitchBetaRules(error))
  }
}

function* watchSwitchBetaRulesAsync() {
  yield takeLatest(requestSwitchBetaRules, SwitchBetaRulesAsync)
}

export default watchSwitchBetaRulesAsync
