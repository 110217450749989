import styled from 'styled-components'
import Typography from 'antd/lib/typography'
import Row from 'antd/lib/row'
import Avatar from 'antd/lib/avatar'

const { Text } = Typography

export const StyledRow = styled(Row)`
  width: 100%;
  height: 64px;
  background: white;
  margin: 0px auto;
  border-bottom: 1px solid #e2e2e2;
  position: sticky;
  top: 0;
  z-index: 999;
  cursor: pointer;
`

export const StyledWelcomeLabel = styled(Text)`
  font-size: 16px;
  font-weight: 500;
  color: #545454;
  margin-right: 15px;
`
export const TransformedAvatar = styled(Avatar)`
  transform: translateY(-5px);
`
