import React, { useState, useEffect } from 'react'
import Table from 'components/Table'
import filterDatalake from 'utils/filterDatalake'
import { merge } from 'lodash'
import Columns from './columns'

export default function DatalakeTable({ Datalake = {}, greenscreens = [] }) {
  const [data, setData] = useState([])
  const dataSource = merge([], filterDatalake(Datalake), greenscreens).slice(0, 20)
  useEffect(() => {
    const size = Object.keys(Datalake)
    if (size.length > 0) {
      if (JSON.stringify(data) !== JSON.stringify(dataSource)) setData(dataSource)
    }
  }, [Datalake, dataSource, data])

  return (
    <Table
      addHeight="-50"
      bgHeader="#F9FAFE"
      columns={Columns()}
      data={data}
      loading={false}
      rowKey="loadProNumber"
      width="100%"
      padding="4px 0px"
      pagination={false}
      showBulkActions={false}
      borderTableNone
    />
  )
}
