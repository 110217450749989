import React, { useCallback, useState, useRef, useEffect } from 'react'
import Select from 'antd/lib/select'
import { pick } from 'lodash/object'
import { debounce } from 'lodash'
import { sortBy } from 'lodash/collection'
import colors from 'providers/theme/config/colorPallete'
import InputWithLabel from 'components/Form/InputWithLabel'
import DrawerItem from 'components/Form/DrawerItem'
import ControlInput from 'components/Form/ControlInput'
import ClosablePill from 'containers/rules/Ruleset/CreateRuleset/utils/ClosablePill'
import TitleDrawer from 'components/TitleDrawer'
import { BsPlusSquareFill } from 'react-icons/bs'
import { formatCustomerAssigned, formatCustomersFromUsers } from 'utils/formatValues'
import './style.css'

export default function AssignCustomers({
  customers = [],
  resetFormValue = () => '',
  handleValues = (some) => some,
  loadCustomers = [],
}) {
  const [selectedCustomers, setSelectedCustomers] = useState([])
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [textInput, setTextInput] = useState('')
  const [openSelect, setopenSelect] = useState(false)
  const selectRef = useRef(null)
  const [position, setPosition] = useState('')
  const [isLoad, setIsLoad] = useState(false)


  useEffect(() => {
    if (loadCustomers.length > 0 && selectedCustomers.length === 0 && !isLoad) {
      setSelectedCustomers(formatCustomersFromUsers(loadCustomers))
      setIsLoad(true)
    }
  }, [loadCustomers, selectedCustomers, isLoad])

  useEffect(() => {
    handleValues(formatCustomerAssigned(selectedCustomers))
  }, [selectedCustomers, handleValues])

  const addCustomer = () => {
    if (selectedCustomer) {
      setSelectedCustomers((prev) => [...prev, selectedCustomer])
      setSelectedCustomer(null)
      resetFormValue()
    }
  }
  const searching = useCallback(
    debounce((textToSearch) => setTextInput(textToSearch), 400),
    [setTextInput]
  )
  // Drag and drop funcitonality (start)
  function onDragOver(e) {
    e.preventDefault()
    e.dataTransfer.dropEffect = 'move'
  }
  function onDragStart(e, info) {
    const element = e.target

    element.classList.add('novisible')

    e.dataTransfer.setData('id', info)
    setPosition(info)
  }

  function onDrop(e, info) {
    // const id = e.dataTransfer.getData('id')
    const getNewValue = selectedCustomers[position]
    const getPreviousValue = selectedCustomers[info]
    const newData = [...selectedCustomers]
    newData[info] = getNewValue
    newData[position] = getPreviousValue
    setSelectedCustomers(newData)
  }
  function endDrag(e) {
    const element = e.target
    element.classList.remove('novisible')
  }

  const items = selectedCustomers.map((customer, i) => {
    return (
      <div
        key={i}
        draggable="true"
        onDragOver={(e) => onDragOver(e)}
        onDragStart={(e) => onDragStart(e, i)}
        onDrop={(e) => onDrop(e, i)}
        onDragEnd={(e) => endDrag(e)}
      >
        <ClosablePill
          key={customer._id}
          text={customer.value}
          onClose={() => {
            const newSelectedCustomers = [...selectedCustomers]
            newSelectedCustomers.splice(i, 1)
            setSelectedCustomers(newSelectedCustomers)
          }}
        />
      </div>
    )
  })
  // Drag and drop funcitonality (finish)



  return (
    <>
      <InputWithLabel label="Default Customer *">
        {(hasValue) => (
          <DrawerItem
            style={{ marginBottom: 0 }}
            name="defaultCustomer"
            rules={[{ required: false }]}
          >
            <ControlInput hasValue={hasValue}>
              {({ value, onChange }) => (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Select
                    ref={selectRef}
                    allowClear
                    showSearch
                    size="large"
                    onSearch={searching}
                    options={sortBy(
                      customers
                        .map((item) => item.customer)
                        .map((customer) => ({ ...customer, value: customer.name }))
                        .map((customer) => pick(customer, ['_id', 'value']))
                        .filter((customer) => !selectedCustomers.map((item) => item._id).includes(customer._id)),
                      'value'
                    ).filter((item) => item.value.toString().toLowerCase().match(textInput))}
                    defaultActiveFirstOption={false}
                    notFoundContent={null}
                    value={value?.value}
                    onChange={(_, option) => {
                      onChange(option)
                      setopenSelect(false)
                      setSelectedCustomer(option)
                    }}
                    autoFocus={false}
                    onPressEnter={() => addCustomer()}
                    onInputKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        addCustomer()
                        setopenSelect(false)
                        if (selectRef.current) {
                          selectRef.current.blur()
                        }
                      }
                    }}
                    onFocus={() => {
                      setopenSelect(true)
                    }}
                    open={openSelect}
                    filterOption={false}
                  />
                  <BsPlusSquareFill
                    style={{
                      fontSize: '18px',
                      color: colors.light_blue.default,
                      marginTop: 12,
                      marginLeft: 18,
                      cursor: 'pointer',
                    }}
                    onClick={() => addCustomer()}
                  />
                </div>
              )}
            </ControlInput>
          </DrawerItem>
        )}
      </InputWithLabel>
      {selectedCustomers.length > 0 && (
        <div>
          <TitleDrawer title="Customers assigned" />

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 16,
              flexWrap: 'wrap',
            }}
          >
            {items}
          </div>
        </div>
      )}
    </>
  )
}
