import moment from "moment"

const returnDateRanges = (data = {}) => {
    const newObject = {}
    if (data?.createdAt) {
        if (data?.createdAt?.dateStrings) {
            [...data.createdAt.dateStrings].forEach((item, index) => {
                if (item !== "") {
                    if (index === 0) { 
                        newObject.createdAt = moment(item, 'MM DD YYYY').format('YYYY-MM-DD')
                    }else{
                        newObject.createdAtUntil = moment(item, 'MM DD YYYY').format('YYYY-MM-DD')
                    } 
                }
            })
        }
    }
    if (data?.pickupDate) {
        if (data?.pickupDate?.dateStrings) {
            [...data.pickupDate.dateStrings].forEach((item, index) => {
                if (item !== "") {
                    if (index === 0) { 
                        newObject.pickupDate = moment(item, 'MM DD YYYY').format('YYYY-MM-DD')
                    }else{
                        newObject.pickupDateUntil = moment(item, 'MM DD YYYY').format('YYYY-MM-DD')
                    } 
                }
            })
        }
    }

    return newObject
}

export default returnDateRanges