import styled from 'styled-components'

const MapContainer = styled.div`
  width: 100%;
  height: 10rem;
`
export const MapContainerLarge = styled.div`
  width: 100%;
  height: 10rem;
`
export default MapContainer
