import { put, takeLatest, call } from 'redux-saga/effects'
import { rules } from 'services/'
import {
  successGetGeographicReference,
  requestGetGeographicReference,
  failureGetGeographicReference,
} from '../duck/getGeographicReference.duck'

function* getGeographicReferenceAsync(data) {
  try {
    const response = yield call(rules.getGeographicReference,data.payload.params)   
    yield put(successGetGeographicReference(response))
  } catch (error) {
    yield put(failureGetGeographicReference(error))
  }
}

function* watchGetGeographicReferenceAsync() {
  yield takeLatest(requestGetGeographicReference, getGeographicReferenceAsync)
}

export default watchGetGeographicReferenceAsync
