import PropTypes from 'prop-types'
import PrimarySubmit from './PrimarySubmit'

PrimarySubmit.propTypes = {
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  formName: PropTypes.string,
  disable: PropTypes.bool,
}

PrimarySubmit.defaultProps = {
  label: '',
  loading: false,
  formName: '',
  disable: false,
}

export default PrimarySubmit
