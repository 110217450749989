import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: {},
  editMultistep: false,
  resetForm: false,
  ruleSelected: {},
}

export const {
  requestCreateMultiStep,
  successCreateMultiStep,
  failureCreateMultiStep,
  clearCreateMultiStep,
  loadMultiStep,
  addMultiStep,
} = createActions({
  REQUEST_CREATE_MULTI_STEP: (data) => ({
    ...defaultState,
    fetching: true,
    data,
  }),
  SUCCESS_CREATE_MULTI_STEP: (data) => ({
    data,
    message: data.message,
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_CREATE_MULTI_STEP: (message) => ({
    message,
    fetching: false,
    error: true,
  }),
  CLEAR_CREATE_MULTI_STEP: () => ({
    message: '',
    fetching: false,
    error: false,
    success: false,
  }),
  LOAD_MULTI_STEP: (data) => ({
    editMultistep: true,
    ruleSelected: data,
  }),
  ADD_MULTI_STEP: () => ({
    editMultistep: false,
  }),
})

const createMultiStep = handleActions(
  {
    [requestCreateMultiStep]: produce((draft, { payload: { message, fetching, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.data = data
    }),
    [successCreateMultiStep]: produce((draft, { payload: { message, fetching, success, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.data = data
    }),
    [failureCreateMultiStep]: produce((draft, { payload: { message, fetching } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = true
    }),
    [clearCreateMultiStep]: produce((draft, { payload: { message, fetching, error, success } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [loadMultiStep]: produce((draft, { payload: { editMultistep, ruleSelected } }) => {
      draft.editMultistep = editMultistep
      draft.ruleSelected = ruleSelected
    }),
    [addMultiStep]: produce((draft, { payload: { editMultistep } }) => {
      draft.editMultistep = editMultistep
    }),
  },
  defaultState
)

export default createMultiStep
