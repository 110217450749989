import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  multiSteps: [],
  totalRecords: 0,
  params: {},
}

export const {
  requestGetMultiStepsHistory,
  successGetMultiStepsHistory,
  failureGetMultiStepsHistory,
  clearGetMultiStepsHistory,
} = createActions({
  REQUEST_GET_MULTI_STEPS_HISTORY: (params) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    params,
  }),
  SUCCESS_GET_MULTI_STEPS_HISTORY: (multiSteps) => ({
    multiSteps,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_MULTI_STEPS_HISTORY: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  CLEAR_GET_MULTI_STEPS_HISTORY: () => ({
    ...defaultState,
  }),
})

const getMultiStepsHistory = handleActions(
  {
    [requestGetMultiStepsHistory]: produce((draft, { payload: { fetching, error, success } }) => {
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successGetMultiStepsHistory]: produce(
      (draft, { payload: { message, fetching, success, multiSteps, error } }) => {
        draft.message = message
        draft.fetching = fetching
        draft.success = success
        draft.error = error
        draft.multiSteps = multiSteps
      }
    ),
    [failureGetMultiStepsHistory]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = !error
    }),
    [clearGetMultiStepsHistory]: produce(
      (draft, { payload: { message, fetching, success, multiSteps, error } }) => {
        draft.message = message
        draft.fetching = fetching
        draft.success = success
        draft.error = error
        draft.multiSteps = multiSteps
      }
    ),
  },
  defaultState
)

export default getMultiStepsHistory
