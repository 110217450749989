import { put, takeLatest, call } from 'redux-saga/effects'
import { customers } from 'services'
import { flow } from 'lodash/util'
import {
  requestGetRulesByCustomer,
  successGetRulesByCustomer,
  failureGetRulesByCustomer,
} from '../duck/getRulesByCustomer.duck'

function* getRulesByCustomerAsync(data) {
  try {
    const response = yield call(customers.getRules, data.payload.params)
    const newData = flow([
      (responseData) => responseData,
      (responseData) =>
        data.payload.params.dateRange
          ? responseData.filter(
              (item) =>
                data.payload.params.dateRange[0].utc().startOf('day').isSameOrBefore(item.rule.dateInit, 'day') &&
                data.payload.params.dateRange[1].utc().startOf('day').isSameOrAfter(item.rule.dateEnd, 'day')
            )
          : responseData,
      (responseData) =>
        data.payload.params.textAndWeekday
          ? responseData.filter(
              (item) =>
                item.rule.day.toLowerCase().includes(data.payload.params.textAndWeekday.toLowerCase()) ||
                item.name.toLowerCase().includes(data.payload.params.textAndWeekday.toLowerCase())
            )
          : responseData,
    ])(response.data)

    yield put(
      successGetRulesByCustomer({
        ...response,
        data: newData,
        totalRules: newData.length,
      })
    )
  } catch (error) {
    yield put(failureGetRulesByCustomer(error))
  }
}

function* watchGetRulesByCustomerAsync() {
  yield takeLatest(requestGetRulesByCustomer, getRulesByCustomerAsync)
}

export default watchGetRulesByCustomerAsync
