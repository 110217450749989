import styled from 'styled-components'
import { DatePicker } from 'antd'

const InputLineDatePicker = styled(DatePicker)`
  padding: 5px;
  .ant-picker-input {
    max-height: 14px;
    border-style: solid;
    border-width: 1px;
    border-color: transparent transparent #002555 transparent;
  }
  .ant-picker-input > input {
    max-height: 14px !important;
  }
`

export default InputLineDatePicker
