import styled from 'styled-components'
import Select from 'antd/lib/select'

const StyledSelect = styled(Select)`
  padding-top: 2px;

  .ant-select-arrow {
    color: #002555;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-select-selector {
    border-radius: 4px !important;
    /* &:hover {
    background-color: #105b72c2;
    } */
  }
  .ant-select-selector span {
    padding-right: 0px !important;
  }
  .ant-select-item-option-content {
    background-color: blue;
    margin: 0px 24px 0px;
    line-height: 45px;
  }
  /* .ant-select-focused {
    background-color: green;
    &:hover {
      background-color: #105b72c2;
    }
  } */
  /* .ant-select-selection-search {
    background-color: green;
    &:hover {
      background-color: #105b72c2;
    }
  } */

  /* .ant-select-selection {
    background-color: blue;
  }
  .ant-select-item-option {
    padding: 0.5rem 12px !important;

    &:hover {
      background-color: #452380d2 !important;
      color: white !important;
    }
  } */
`

export default StyledSelect
