import React from 'react'
import { useHistory } from 'react-router-dom'
import TitleList from 'components/TitleList'
import Tabs from 'antd/lib/tabs'
import HistoryLanes from 'containers/rates/HistoryLanes'
import TabsRules from 'containers/rules/TabsRules/TabsRules'
import { StyledDiv, StyledTabs } from './styles'

export default function TabsCustomerDashboard() {
    const history = useHistory()
    const handleTabClick = (key) => {
        history.push(`/${key}`)
    }

    const masterRules = `customer/quote-history`
    const rulesList = `customer/rules`

    return (
        <>
            <StyledDiv>
                <TitleList title="Customer Dashboard" />
            </StyledDiv>
            <StyledTabs
                defaultActiveKey="history"
                onChange={handleTabClick}
                width="96%"
                tabBarStyle={{ margin: '0px 0px 0px 2%', color: '#002555', fontWeight: 600, marginBottom: 15 }}
                destroyInactiveTabPane
            >
                <Tabs.TabPane tab="Quote History" key={masterRules}>
                    <HistoryLanes addDatRate />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Rules" key={rulesList}>
                    <TabsRules displayTitle={false} enableEdit={false} />
                </Tabs.TabPane>
            </StyledTabs>
        </>
    )
}
