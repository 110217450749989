import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: {},
}

export const { requestPostRefreshToken, successPostRefreshToken, failurePostRefreshToken, clearPostRefreshToken } = createActions({
  REQUEST_POST_REFRESH_TOKEN: () => ({
    ...defaultState,
    fetching: true,
  }),
  SUCCESS_POST_REFRESH_TOKEN: () => ({ 
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_POST_REFRESH_TOKEN: (message) => ({
    message,
    fetching: false,
    error: true,
  }),
  CLEAR_POST_REFRESH_TOKEN: () => ({
    error: false,
  }), 
})

const refreshToken = handleActions(
  {
    [requestPostRefreshToken]: produce((draft, { payload: { message, fetching, success, error, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data
    }),
    [successPostRefreshToken]: produce((draft, { payload: { message, fetching, success, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.data = data
    }),
    [failurePostRefreshToken]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
    }),
    [clearPostRefreshToken]: produce((draft, { payload: { error } }) => {
      draft.error = error
    }),
  },
  defaultState
)

export default refreshToken
