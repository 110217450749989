import React from 'react'

import { CapacityNowLogo } from 'assets/img'
import { CompanyContainer,CompanyContainerImg } from './styles'

const CompanyLogo = () => {
  return (
    <CompanyContainer>
      <CompanyContainerImg alt="Ally Logo" src={CapacityNowLogo}   />
    </CompanyContainer>
  )
}

export default CompanyLogo
