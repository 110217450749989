/**
 * 
 * @param {*} a array of multisteps rules
 * @returns multi-step array without master rules
 */
function getNonMasterMultiStepRules(a = []) {
  const noMasterRules = []
  a.forEach((element) => {
    if (!element.crossMultistep) {
      noMasterRules.push(element)
    }
  })
  return noMasterRules
}

export default getNonMasterMultiStepRules
