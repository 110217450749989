import produce from 'immer'

import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: [],
}

export const {
  requestDeactivateUser,
  successDeactivateUser,
  failureDeactivateUser,
  resetDeactivateUser,
} = createActions({
  REQUEST_DEACTIVATE_USER: (params) => ({
    ...defaultState,
    fetching: true,
    params,
  }),

  SUCCESS_DEACTIVATE_USER: (data, message) => ({
    data,
    message,
    fetching: false,
    error: false,
    success: true,
  }),

  FAILURE_DEACTIVATE_USER: (message) => ({
    message,
    fetching: false,
    error: true,
  }),
  RESET_DEACTIVATE_USER: () => ({
    ...defaultState,
  }),
})

const userDeactivate = handleActions(
  {
    [requestDeactivateUser]: produce((draft, { payload: { message, fetching, success, error, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data
    }),

    [successDeactivateUser]: produce((draft, { payload: { message, fetching, success, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.data = data
    }),

    [failureDeactivateUser]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.data = {}
    }),
    [resetDeactivateUser]: produce((draft, { payload: { message, fetching, success, error, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data
    }),
  },

  defaultState
)

export default userDeactivate
