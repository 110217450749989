import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  rules: [],
  totalRules: 0,
  params: {},
}

export const { requestGetRules, successGetRules, failureGetRules, clearGetRules } = createActions({
  REQUEST_GET_RULES: (params) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    rules : [],
    params,
  }),
  SUCCESS_GET_RULES: (rules) => ({
    rules: rules.data,
    totalRules: rules.totalRecords,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_RULES: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  CLEAR_GET_RULES: () => ({
    ...defaultState,
  }),
})

const getRules = handleActions(
  {
    [requestGetRules]: produce((draft, { payload: { fetching, error, success,rules } }) => {
      draft.fetching = fetching
      draft.error = error
      draft.success = success
      draft.rules = rules
    }),
    [successGetRules]: produce((draft, { payload: { message, fetching, success, rules, totalRules, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.rules = rules
      draft.totalRules = totalRules
      draft.error = error
    }),
    [failureGetRules]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = !error
    }),
    [clearGetRules]: produce((draft, { payload: { message, fetching, success, rules, totalRules, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.rules = rules
      draft.totalRules = totalRules
      draft.error = error
    }),
  },
  defaultState
)

export default getRules
