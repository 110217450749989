import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { requestGetStates } from 'store/duck/getStates.duck'
import { requestValidateState } from 'store/duck/validateState.duck'

const useAutocomplete = () => {
  const { list, validate, stateField } = useSelector((state) => ({
    list: state.getStates.data,
    validate: state.validateState,
    stateField: state.validateState.field,
  }))
  const [value, setValue] = useState('')
  const [validStatus, setValidStatus] = useState('success')
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(requestGetStates(value))
    if (value.length > 2) dispatch(requestValidateState(value, 0))
  }, [dispatch, value])

  useEffect(() => {
    if (validate.fetching) {
      setValidStatus('validating')
    } else if (validate.success) {
      setValidStatus('success')
    } else setValidStatus('error')
  }, [dispatch, validate.fetching, validate.success])

  const handleAutocompleteChange = (searchText) => {
    setValue(searchText)
  }

  const handleStateChange = (event) => {
    setValue(event)
  }

  return [list, validStatus, stateField, handleAutocompleteChange, handleStateChange, setValidStatus]
}

export default useAutocomplete
