import { put, takeLatest, call } from 'redux-saga/effects'
import { auth } from 'services'
import { requestResetPassword, successResetPassword, failureResetPassword } from 'store/duck/resetPassword.duck'

function* resetPasswordRequestAsync(data) {
  try {
    const response = yield call(auth.resetPassword, data.payload.data)
    yield put(successResetPassword(response, 'success'))
  } catch (error) {
    yield put(failureResetPassword(error.response.data.message))
  }
}

function* watchResetPasswordAsync() {
  yield takeLatest(requestResetPassword, resetPasswordRequestAsync)
}

export default watchResetPasswordAsync
