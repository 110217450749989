import PropTypes from 'prop-types'
import LoadGeographicRule from './LoadGeographicRule'

LoadGeographicRule.propTypes = {
  handleFetchRules: PropTypes.func.isRequired,
  rules: PropTypes.object.isRequired,
  rulesetData: PropTypes.object.isRequired,
  shippingData: PropTypes.object.isRequired,
  referenceData: PropTypes.object.isRequired,
}

export default LoadGeographicRule
