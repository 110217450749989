import React from 'react'
import PriceLabel from 'components/Label/PriceLabel'
import Typography from 'antd/lib/typography'
import BoxCell from 'components/Box/BoxCell'

const { Text } = Typography

const Columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name),
    sortDirections: ['descend', 'ascend'],
    width: 130,
    ellipsis: true,
  },
  {
    title: 'Mileage Range',
    dataIndex: 'name',
    key: 'mileage',
    colSpan: 5,
    render: () => {
      return (
        <BoxCell>
          <Text>If mileage is</Text>
          <Text>between</Text>
        </BoxCell>
      )
    },
    width: 105,
    ellipsis: true,
  },
  {
    title: '',
    dataIndex: ['rule', 'mileageInit'],
    key: 'mileageInit',
    colSpan: 0,
    render: (mileageInit) => {
      return <PriceLabel>{mileageInit}</PriceLabel>
    },
    width: 40,
    ellipsis: true,
  },
  {
    title: '',
    dataIndex: 'name',
    key: 'icon',
    colSpan: 0,
    render: () => {
      return <PriceLabel>&</PriceLabel>
    },
    width: 20,
    ellipsis: true,
  },
  {
    title: '',
    dataIndex: ['rule', 'mileageEnd'],
    key: 'mileageEnd',
    colSpan: 0,
    render: (mileageEnd) => {
      return <PriceLabel>{mileageEnd}</PriceLabel>
    },
    width: 40,
    ellipsis: true,
  },
  {
    title: '',
    dataIndex: 'name',
    key: 'mileage',
    colSpan: 0,
    render: () => {
      return (
        <BoxCell>
          <Text>make these rate</Text>
          <Text>adjustments</Text>
        </BoxCell>
      )
    },
    width: 105,
    ellipsis: true,
  },
  {
    title: 'Percentage',
    dataIndex: ['rule', 'margin'],
    key: 'margin',
    render: (margin) => {
      return <PriceLabel>{margin} %</PriceLabel>
    },
    width: 75,
    ellipsis: true,
  },
  {
    title: 'Flat',
    dataIndex: ['rule', 'flat'],
    key: 'flat',
    render: (flat) => {
      return <PriceLabel>{flat}</PriceLabel>
    },
    width: 70,
    ellipsis: true,
  },
]

export default Columns
