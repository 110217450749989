import { put, takeLatest, call } from 'redux-saga/effects'
import { customers } from 'services'
import {
  requestCreateCustomer,
  successCreateCustomer,
  failureCreateCustomer,
} from 'store/duck/createCustomer.duck'

function* createCustomerRequestAsync(data) {
  try {
    const response = yield call(customers.createCustomer, data.payload.data)
    yield put(successCreateCustomer(response, 'success'))
  } catch (error) {
    yield put(failureCreateCustomer(error.response.data.message))
  }
}

function* watchCreateCustomerAsync() {
  yield takeLatest(requestCreateCustomer, createCustomerRequestAsync)
}

export default watchCreateCustomerAsync
