import React from 'react'

const RuleAdjustmentColumns = [
  {
    title: <div style={{ fontSize: '16px' }}>Rule Name</div>,
    dataIndex: 'name',
    key: 'name',
    align: 'left',
    render: (text) => <div style={{ fontSize: 16 }}>{text}</div>,
    width: 200,
  },
  {
    title: <div style={{ fontSize: '16px' }}>Rule Type</div>,
    dataIndex: 'typeRule',
    key: 'typeRule',
    render: (text) => <div style={{ fontSize: 16 }}>{text}</div>,
    width: 125,
  },
  {
    title: <div style={{ fontSize: '16px' }}>Shipping Type</div>,
    dataIndex: 'typeShipping',
    key: 'typeShipping',
    render: (text) => <div style={{ fontSize: 16 }}>{text}</div>,
    width: 125,
  },
  {
    title: <div style={{ fontSize: '16px' }}>Percentage %</div>,
    dataIndex: 'margin',
    key: 'margin',
    align: 'right',
    render: (text) => <div style={{ fontSize: 16 }}>{text}</div>,
    width: 125,
  },
  {
    title: <div style={{ fontSize: '16px' }}>Flat</div>,
    dataIndex: 'flat',
    key: 'flat',
    align: 'right',
    render: (text) => <div style={{ fontSize: 16 }}>{text}</div>,
    width: 125,
  },
]

export default RuleAdjustmentColumns
