import React from 'react'

import StyledToastContainer from 'components/Alert'
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import ThemeProvider from 'providers/theme'
import { store, persistor } from 'store'
import Routes from 'pages'
import MainLayout from './styles'
import 'react-toastify/dist/ReactToastify.css'

const App = () => {
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
         <ThemeProvider>
           <MainLayout>
             <Routes />
             <StyledToastContainer
               position="bottom-right"
               autoClose={5000}
               hideProgressBar
               newestOnTop={false}
               closeOnClick
               rtl={false}
               pauseOnFocusLoss
               draggable
               pauseOnHover
             />
           </MainLayout>
         </ThemeProvider>
       </PersistGate>
     </ReduxProvider>
  )
}

export default App
