import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: {},
}

export const {
  requestResetPassword,
  successResetPassword,
  failureResetPassword,
  clearResetPassword,
} = createActions({
  REQUEST_RESET_PASSWORD: (data) => ({
    ...defaultState,
    fetching: true,
    data,
  }),
  SUCCESS_RESET_PASSWORD: (data, message) => ({
    data,
    message,
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_RESET_PASSWORD: (message) => ({
    message,
    fetching: false,
    error: true,
  }),
  CLEAR_RESET_PASSWORD: () => ({
    ...defaultState,
  }),
})

const resetPassword = handleActions(
  {
    [requestResetPassword]: produce((draft, { payload: { message, fetching, success, error, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data
    }),
    [successResetPassword]: produce((draft, { payload: { message, fetching, success, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.data = data
    }),
    [failureResetPassword]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.data = {}
    }),
    [clearResetPassword]: produce((draft, { payload: { message, fetching, success, error, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data
    }),
  },
  defaultState
)

export default resetPassword
