import React, { useState, useEffect, useCallback } from 'react'
import moment from 'moment'
import Form from 'antd/lib/form'
import { useForm } from 'antd/lib/form/Form'
import CryptoJS from 'crypto-js'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { emailRules, passwordRules } from 'utils/inputRules'
import { requestPostLogin, clearPostLogin } from 'store/duck/login.duck'
import AuthForm from 'components/Form/AuthForm'
import ButtonPrimary from 'components/Button/ButtonPrimary'
import CompanyLogo from 'components/CompanyLogo'
import SpinLoading from 'components/SpinLoading'
// eslint-disable-next-line import/named
import InputWithLabel from 'components/Form/InputWithLabel'
import DrawerItem from 'components/Form/DrawerItem'
import { InputGeneric } from 'components/Input'
import { IoMdEye, IoMdEyeOff } from 'react-icons/io'
import notify from 'utils/notify'
import Modal from './Modal'
import { LoginContentForm, LoginContainer, Link, LinkContainer, LoginContent, ImgContainer } from './styles'

const Login = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [authForm] = useForm()

  const { success, error, data, message } = useSelector((state) => state.login)
  const [loading, setLoading] = useState(false)
  const [passwordShown, setPasswordShown] = useState(false)
  const [email, setEmail] = useState()
  const [visible, setvisible] = useState(false)
  const [isRememberMe, setIsRememberMe] = useState(false)
  const closeModal = () => setvisible(false)

  useEffect(() => {
    if (success) {
      setLoading(false)
      history.push('/dashboard')
      if (window.localStorage.getItem('expiration') === null) {
        window.localStorage.setItem('expiration', moment())
      }
    }
    if (error) {
      setvisible(true)
      setLoading(false)
      dispatch(clearPostLogin())
    }
  }, [data, error, history, success, dispatch])
 

  const onFinish = useCallback(
    (values) => {
      setLoading(true)
      const password = CryptoJS.AES.encrypt(values.password, process.env.REACT_APP_CRYPTO_KEY).toString()
      const loginData = {
        email: values.newmail || email,
        password,
      }
      if (isRememberMe) {
        localStorage.setItem('isRememberMe', JSON.stringify({ email: values.newmail, isCheck: isRememberMe }))
      } else {
        localStorage.removeItem('isRememberMe')
      }
      dispatch(requestPostLogin(loginData))
    },
    [dispatch, email, isRememberMe]
  )
 
  const onFinishFailed = useCallback(() => notify('error', 'There was an error in login form.'), [])

  const ControlInput = ({ value, onChange, hasValue, children }) => {
    useEffect(() => {
      hasValue(value)
    }, [hasValue, value])

    return children({ value, onChange })
  }

  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown)
  }

  useEffect(() => {
    const rememberMe = JSON.parse(localStorage.getItem('isRememberMe'))
    if (rememberMe) {
      setIsRememberMe(rememberMe.isCheck)
      setEmail(rememberMe.email)
      authForm.setFieldsValue({ newmail: rememberMe.email })
    }
  }, [authForm])
  

  return (
    <ImgContainer>
      <Modal visible={visible} close={closeModal} message={message} />
      <LoginContainer>
        <div style={{ display: 'flex', height: 'fit-content', justifyContent: 'center' }}>
          <CompanyLogo />
        </div>
        <LoginContent>
          <LoginContentForm>
            <AuthForm
              autoComplete="false"
              name="login-form"
              layout="vertical"
              onFinish={onFinish}
              form={authForm}
              onFinishFailed={onFinishFailed}
              hideRequiredMark
              style={{ width: '80%' }}
            >
              <div style={{ color: '#002555', fontSize: '20px', fontWeight: 'bold', marginBottom: '20px' }}>
                Log In
              </div>
             
              <InputWithLabel label="Username *">
                {(hasValue) => (
                  <DrawerItem name="newmail" value={email} rules={emailRules}>
                    <ControlInput name="newmail" value={email} hasValue={hasValue}>
                      {({ value, onChange }) => (
                        <InputGeneric
                          size="large"
                          type="email"
                          autoComplete="newemail"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    </ControlInput>
                  </DrawerItem>
                )}
              </InputWithLabel>
              <InputWithLabel label="Password *">
                {(hasValue) => (
                  <DrawerItem name="password" rules={passwordRules}>
                    <ControlInput name="password" hasValue={hasValue}>
                      {({ value, onChange }) => (
                        <InputGeneric
                          autoComplete="new-password"
                          value={value}
                          onChange={onChange}
                          size="large"
                          type={passwordShown ? 'text' : 'password'}
                          suffix={
                            passwordShown ? (
                              <IoMdEyeOff
                                onClick={togglePassword}
                                style={{ fontSize: '20px', cursor: 'pointer' }}
                              />
                            ) : (
                              <IoMdEye onClick={togglePassword} style={{ fontSize: '20px', cursor: 'pointer' }} />
                            )
                          }
                        />
                      )}
                    </ControlInput>
                  </DrawerItem>
                )}
              </InputWithLabel>
              <div style={{ display: 'flex', alignItems: 'center', gap: '5px', marginBottom: '20px' }}>
                <input
                  type="checkbox"
                  name="IsRememberMe"
                  className="checkbox"
                  checked={isRememberMe}
                  onChange={() => setIsRememberMe(!isRememberMe)}
                />
                <span style={{ color: '#545454', fontSize: '16px' }}>Remember me</span>
              </div>
              <Form.Item>
                <ButtonPrimary
                  htmlType="submit"
                  width="100%"
                  height="100%"
                  disabled={loading}
                  style={{ padding: '10px 0' }}
                >
                  {loading ? <SpinLoading /> : 'Log In'}
                </ButtonPrimary>
                <LinkContainer>
                  <Link to="/forgot-password">Forgot Password? </Link>
                </LinkContainer>
              </Form.Item>
            </AuthForm>
          </LoginContentForm>
        </LoginContent>
      </LoginContainer>
    </ImgContainer>
  )
}

export default Login
