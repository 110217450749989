import React from 'react'
import Typography from 'antd/lib/typography' 
import AnalyticColumBase from './AnalyticsColumBase'

const { Text } = Typography

const MciColumns= (dispatch, openDrawer, enableEdit = true) => {
  const laneScoreColum = {
    title: <div style={{ fontSize: '16px' }}>MCI Score</div>,
    dataIndex: ['rule', 'udMCIinit'],
    key: 'udMCIinit',
    render: (udMCIinit, rule) => {
      return (
        <Text style={{ fontSize: '16px', display: 'flex' }}>
          {udMCIinit}
          <span style={{ margin: '0px', padding: '0px', color: '#3594FF', fontWeight: '600' }}> & </span>
          {rule?.rule?.udMCIend}
        </Text>
      )
    },
    width: 100,
    ellipsis: true,
  }
  const base = [...AnalyticColumBase(dispatch, openDrawer, enableEdit)]
  base.splice(3, 0, laneScoreColum) 
  return [...base]
}

export default MciColumns
