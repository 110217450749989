import React from 'react'
import ConfirmPassword from '../ConfirmPassword'

const ResetPassword = () => {
  return (
    <ConfirmPassword
      title="Reset your password"
      description="Enter your new password to access the platform."
      passwordInputLabel="new password"
      submitButtonText="Reset Password"
    />
  )
}

export default ResetPassword
