import moment from 'moment'

const outdated = (expirationDate = '') => {
  const date = moment.utc(expirationDate).format('YYYY-MM-DD')
  const currentDate = moment().format('YYYY-MM-DD')
  const validDate = moment(date)
  if (!validDate.isValid()) return false
  if (date === currentDate) return false
  return validDate.isBefore(currentDate, 'day')
}

export default outdated
