import { put, takeLatest, call } from 'redux-saga/effects'
import { rules } from 'services'
import {
  requestReturnVersionMultistep,
  successReturnVersionMultistep,
  failureReturnVersionMultistep,
} from '../duck/returnVersionMultistep.duck'

function* returnVersionMultistepAsync(data) {
  try {
    const response = yield call(rules.returnVersionMultistep, data.payload.data)
    yield put(successReturnVersionMultistep(response))
  } catch (error) {
    yield put(failureReturnVersionMultistep(error.response.data.message))
  }
}

function* watchReturnVersionMultistepAsync() {
  yield takeLatest(requestReturnVersionMultistep, returnVersionMultistepAsync)
}

export default watchReturnVersionMultistepAsync
