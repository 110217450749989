import React from 'react'
import Typography from 'antd/lib/typography'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import { FiLogOut } from 'react-icons/fi'
import { Button } from 'antd'
import { StyleModalLogout } from './style'

const ModalLogout = ({ visible = false, logout, close }) => {
  const { Text } = Typography
  return (
    <StyleModalLogout transitionName="" footer={null} visible={visible} width={350} centered>
      <Row gutter={[16, 14]} style={{ padding: '37px 20px 20px 20px' }}>
        <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '40px',
              height: '40px',
              backgroundColor: '#3594FF',
              borderRadius: '8px',
              color: 'white',
              fontSize: '24px',
            }}
          >
            <FiLogOut />
          </div>
        </Col>
        <Col
          span={24}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#545454',
            fontWeight: '400',
            fontSize: '16px',
            textAlign: 'center',
          }}
        >
          <Text>Are you sure you want to logout the platform?</Text>
        </Col>
        <Row
          gutter={[0, 6]}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Col style={{ width: '100%' }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: '100%', borderRadius: '4px' }}
              onClick={logout}
            >
              Log Out
            </Button>
          </Col>
          <Col style={{ width: '100%' }}>
            <Button style={{ width: '100%', borderRadius: '4px', color: '#3594FF' }} onClick={close}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Row>
    </StyleModalLogout>
  )
}

export default ModalLogout
