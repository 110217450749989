import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: {},
}
export const { requestGetRuleHistory, successGetRuleHistory, failureGetRuleHistory, clearGetRuleHistory } = createActions({
  REQUEST_GET_RULE_HISTORY: (params) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    params,
  }),
  SUCCESS_GET_RULE_HISTORY: (history) => ({
    history, 
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_RULE_HISTORY: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  CLEAR_GET_RULE_HISTORY: () => ({
    error: false,
    success: false,
  }),
})
const getRuleHistory = handleActions(
  {
    [requestGetRuleHistory]: produce((draft, { payload: { fetching, error, success, params } }) => {
      draft.fetching = fetching
      draft.error = error
      draft.success = success
      draft.params = params
    }),
    [successGetRuleHistory]: produce(
      (draft, { payload: { message, fetching, success, history, totalRecords, error } }) => {
        draft.message = message
        draft.fetching = fetching
        draft.success = success
        draft.error = error
        draft.history = history
        draft.totalRecords = totalRecords
      }
    ),
    [failureGetRuleHistory]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = !error
    }),
    [clearGetRuleHistory]: produce((draft, { payload: { success, error } }) => {
      draft.success = success
      draft.error = error
    }),
  },
  defaultState
)

export default getRuleHistory
