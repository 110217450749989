import { put, takeLatest, call } from 'redux-saga/effects'
import { rates } from 'services'
import { requestPatchQuote, successPatchQuote, failurePatchQuote } from '../duck/patchQuote.duck'

function* patchQuoteAsync(data) {
  try {
    const response = yield call(rates.loadWon, data.payload.id, data.payload.load)
    yield put(successPatchQuote({ ...response, _id: data.payload.id }))
  } catch (error) {
    yield put(failurePatchQuote(error))
  }
}

function* watchPatchQuoteAsync() {
  yield takeLatest(requestPatchQuote, patchQuoteAsync)
}

export default watchPatchQuoteAsync
