import axiosManager from 'utils/axiosManager'
import Auth from './auth.services'
import Users from './users.services'
import Rules from './rules.services'
import Rates from './rates.services'
import TruckTypes from './trucktype.services'
import Customers from './customers.services'
import SpecialServices from './specialservices.services'
import Roles from './roles.services'
import Settings from './settings.services'
import Integrations from './integrations.services'
import Zipcode from './zipcode.services'
import States from './states.services'
import History from './history.service'
import Markets from './markets.services'
import Statistics from './statistics.service'

const axios = axiosManager.api()

export const auth = new Auth(axios)
export const users = new Users(axios)
export const rules = new Rules(axios)
export const rates = new Rates(axios)
export const trucktypes = new TruckTypes(axios)
export const customers = new Customers(axios)
export const settings = new Settings(axios)
export const specialServices = new SpecialServices(axios)
export const roles = new Roles(axios)
export const integrations = new Integrations(axios)
export const zipcode = new Zipcode(axios)
export const states = new States(axios)
export const history = new History(axios)
export const markets = new Markets(axios)
export const statistics = new Statistics(axios)
