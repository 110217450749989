import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: {},
}

export const {
  requestForgotPassword,
  successForgotPassword,
  failureForgotPassword,
  clearForgotPassword,
} = createActions({
  REQUEST_FORGOT_PASSWORD: (data) => ({
    ...defaultState,
    fetching: true,
    data,
  }),
  SUCCESS_FORGOT_PASSWORD: (data, message) => ({
    message,
    fetching: false,
    error: false,
    success: true,
    data,
  }),
  FAILURE_FORGOT_PASSWORD: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  CLEAR_FORGOT_PASSWORD: () => ({
    ...defaultState,
  }),
})

const forgotPassword = handleActions(
  {
    [requestForgotPassword]: produce((draft, { payload: { message, fetching, success, error, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data
    }),
    [successForgotPassword]: produce((draft, { payload: { message, fetching, success, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.data = data
    }),
    [failureForgotPassword]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.data = {}
    }),
    [clearForgotPassword]: produce((draft, { payload: { message, fetching, error, success, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
      draft.data = data
    }),
  },
  defaultState
)

export default forgotPassword
