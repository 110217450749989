import React, { useState, useEffect } from 'react'
import Form from 'antd/lib/form'
import { useDispatch, useSelector } from 'react-redux'
import { emailRules } from 'utils/inputRules'
import { requestForgotPassword, clearForgotPassword } from 'store/duck/forgotPassword.duck'
import AuthForm from 'components/Form/AuthForm'
import CompanyLogo from 'components/CompanyLogo'
import ButtonBack from 'components/Button/ButtonBack'
import SpinLoading from 'components/SpinLoading'
import ButtonPrimary from 'components/Button/ButtonPrimary'
import InputWithLabel from 'components/Form/InputWithLabel'
import DrawerItem from 'components/Form/DrawerItem'
import { InputGeneric } from 'components/Input'
import notify from 'utils/notify'
import { ImgContainer, StyledText } from '../Login/styles'
import ForgotContainer from './styles'
import ModalCheckEmail from './ModalCheckEmail'

const ForgotPassword = () => {
  const dispatch = useDispatch()

  const { success, error, message } = useSelector((state) => state.forgotPassword)
  const [loading, setLoading] = useState(false)
  const [visible, setvisible] = useState(false)
  const closeModal = () => setvisible(false)

  useEffect(() => {

    if (success) {
      setLoading(false)
      dispatch(clearForgotPassword())
    }
    if (error) {
      notify('error', 'Forgot password error!')
      setLoading(false)
      dispatch(clearForgotPassword())
    }
  }, [dispatch, error, message, success])

  const onFinish = (values) => {
    setvisible(true)
    setLoading(true)
    dispatch(requestForgotPassword(values))
  }

  const ControlInput = ({ value, onChange, hasValue, children }) => {
    useEffect(() => {
      hasValue(value)
    }, [hasValue, value])

    return children({ value, onChange })
  }

  return (
    <ImgContainer>
     <ModalCheckEmail visible={visible} close={closeModal} message="Please check your email to update your password." />
      <ForgotContainer>
        <div style={{ height: 'fit-content' }}>
          <CompanyLogo />
        </div>
        <div id="content" style={{ display: 'flex', alignItems: 'center', marginTop: '6%' }}>
          <div style={{ width: '40%', marginRight: '50px' }}>
            <StyledText>
              Timing and accuracy is vital in transportation and logistics. <strong>Ally Logistics</strong> has the
              expertise to help you manage the changing digital environment.
            </StyledText>
          </div>
          <div
            style={{
              display: 'flex',
              background: '#F9FAFE',
              padding: '50px 0px 150px 0px',
              width: '50%',
              justifyContent: 'center',
              height: 'fit-content',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <AuthForm name="login-form" layout="vertical" onFinish={onFinish} hideRequiredMark>
              <div style={{ color: '#002555', fontSize: '20px', fontWeight: 'bold' }}>Forgot your password?</div>
              <div style={{ color: '#545454', fontSize: '16px', marginBottom: '10px' }}>
                Enter your email to send you a test password and to access the system.
              </div>
              <InputWithLabel label="E-mail *">
                {(hasValue) => (
                  <DrawerItem name="email" rules={emailRules}>
                    <ControlInput hasValue={hasValue}>
                      {({ value, onChange }) => (
                        <InputGeneric size="large" type="email" value={value} onChange={onChange} />
                      )}
                    </ControlInput>
                  </DrawerItem>
                )}
              </InputWithLabel>
              <Form.Item>
                <ButtonPrimary
                  htmlType="submit"
                  width="100%"
                  height="100%"
                  disabled={loading}
                  style={{ padding: '10px 0' }}
                >
                  {loading ? <SpinLoading /> : 'Send Email'}
                </ButtonPrimary>
                <div style={{ marginTop: '4px', textAlign: 'center' }}>
                  <ButtonBack />
                </div>
              </Form.Item>
            </AuthForm>
          </div>
        </div>
      </ForgotContainer>
    </ImgContainer>
  )
}

export default ForgotPassword
