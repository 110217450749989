export default function (api) {
  return {
    getTruckTypes: () => api.get('api/truck-types'),
    getTruckTypeList: (params) =>
      api.get('api/truck-types', {
        params: {
          limit: 12,
          ...params,
        },
      }),
    getTruckTypeHistory: (id) => api.get(`api/truck-types/history/${id}?limit=100`),
    createTruckType: (data) => api.post('api/truck-types', data),
    updateTruckType: (truckData) => {
      const data = { ...truckData }
      delete data.id
      return api.put(`api/truck-types/${truckData.id}`, data)
    },
    deleteTruckType: (id) => api.delete(`api/truck-types/${id}`),
  }
}
