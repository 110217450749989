import Dashboard from './dashboardIcon.png'
import History from './historyLaneIcon.png'
import Settings from './settingsIcon.png'
import Customer from './customersIcon.png'
import Users from './usersIcon.png'
import AllyHorizontal from './allyHorizontal.png'
import AllySmall from './allyIcon.png'
import AllySmallWhite from './allyIconWhite.png'
import AddIcon from './addIcon.png'
import LoginBg from './login-bg.jpg'
import PingBlueOrigin from './p2blue.svg'
import PingLocation from './location.svg'
import PingTruck from './truck.svg'
import { ReactComponent as AllyWhite } from './allyWhite.svg'
import { ReactComponent as Paper } from './paper.svg'
import { ReactComponent as DeleteIcon } from './icon_delete.svg'
import { ReactComponent as DownloadTxt } from './txt-file.svg'
import { ReactComponent as DashboardIcon } from './icon_dashboard_gris.svg'
import { ReactComponent as IconMenu } from './normal.svg'
import { ReactComponent as ListAlt } from './list_alt_white_24dp.svg'
import { ReactComponent as User } from './person_white_24dp.svg'
import AllyLarge from './capacityNow.png'
import CapacityNowLogo from './capacity_now_logo.png'

export {
  Dashboard,
  DashboardIcon,
  IconMenu,
  History,
  Settings,
  Customer,
  Users,
  AllyHorizontal,
  AllySmall,
  AddIcon,
  LoginBg,
  AllyWhite,
  Paper,
  DeleteIcon,
  DownloadTxt,
  PingTruck,
  PingLocation,
  PingBlueOrigin,
  ListAlt,
  User,
  AllySmallWhite,
  AllyLarge,
  CapacityNowLogo
}
