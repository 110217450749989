import { put, takeLatest, call } from 'redux-saga/effects'
import { customers } from 'services/'
import { requestGetCustomers, successGetCustomers, failureGetCustomers } from '../duck/getCustomers.duck'

function* getCustomersAsync(data) {
  try {
    const response = yield call(customers.getCustomers, data.payload.params)
    yield put(successGetCustomers(response))
  } catch (error) {
    yield put(failureGetCustomers(error))
  }
}

function* watchGetCustomersAsync() {
  yield takeLatest(requestGetCustomers, getCustomersAsync)
}

export default watchGetCustomersAsync
