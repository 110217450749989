import { put, call, debounce } from 'redux-saga/effects'
import { states } from 'services'
import { requestGetStates, successGetStates, failureGetStates } from 'store/duck/getStates.duck'

function* getStatesAsync(data) {
  try {
    const response = yield call(states.getState, data.payload.data)
    yield put(successGetStates(response))
  } catch (error) {
    yield put(failureGetStates(error))
  }
}

function* watchGetStatesAsync() {
  yield debounce(1500, requestGetStates, getStatesAsync)
}

export default watchGetStatesAsync
