import styled from 'styled-components'

const BoxSnapshot = styled.div`
  background-color: ${(props) => props.color};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  .spanContainer {
    margin: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .tagSecondary {
    font-size: 14px;
    font-weight: 400;
    vertical-align: middle;
  }
  .label {
    font-size: 14px;
    font-weight: 400;
    color: #002555;
  }
  .tag {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
  }
  .tagValue {
    font-size: 14px;
    font-weight: 700;
    color: #002555;
    white-space: nowrap;
  }
  flex-flow: column nowrap;
  padding: 0.5rem 0;
  margin-bottom: 12px;
`

export const BoxSnapshotTitle = styled.div`
  font-weight: 400;
  color: ${(props) => props.color};
  font-size: 18px;
  margin-bottom: 1rem;
`

export const BoxSnaphotConent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`
export default BoxSnapshot
