/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Select from 'antd/lib/select'
import Swal from 'sweetalert2'
import { MdBlock } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { requestUserList } from 'store/duck/getUsers.duck'
import { requestGetRoles } from 'store/duck/getRoles.duck'
import { resetDeactivateUser, requestDeactivateUser } from 'store/duck/deactivateUser.duck'
import { clearActivateUser, requestActivateUser } from 'store/duck/activateUser.duck'
import Table from 'components/Table'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import StyledSearch from 'components/Input/Search'
import FloatLabel from 'components/Label/FloatLabel'
import { ArrowDownIcon } from 'assets/svg'
import StyledSelect from 'components/Input/Select'
import { Tooltip } from 'antd'
import ButtonPrimary from 'components/Button/ButtonPrimary'
import { clearMessageSnackBar } from 'store/duck/messageSnackBar.duck'
import { BsPlusSquareFill } from 'react-icons/bs'
import Form from 'antd/lib/form'
import notify from 'utils/notify'
import Columns from './utils/TableColumns'

const UserList = ({ setVisible }) => {
  const dispatch = useDispatch()
  const { xl } = useBreakpoint()
  const { Option } = Select
  const [params, setParams] = useState({ page: 1, limit: 12 })

  const { success, error, message, data } = useSelector((state) => state.getUsers)
  const userDeactivate = useSelector((state) => state.deactivateUser)

  const roles = useSelector((state) => state.getRoles.data)

  const { success: successUpdate, error: errorUpdate, message: msmUpdate } = useSelector(
    (state) => state.activateUser
  )

  const { message: messageSnackBar } = useSelector((state) => state.messageSnackBar)
  const [users, setUsers] = useState([])
  const columnsUser = Columns(dispatch)
  const [form] = Form.useForm()

  const mapNames = (usersApi) => {
    return usersApi.map(
      ({
        _id,
        avatar,
        name,
        lastName,
        phone,
        email,
        roleId,
        authApis,
        active,
        servicesErrors,
        lastLoginDate,
        datRequests,
        customers
      }) => ({
        _id,
        avatar,
        fullName: `${name} ${lastName}`,
        phone,
        email,
        roleId,
        authApis,
        active,
        servicesErrors,
        lastLoginDate,
        datRequests,
        customers
      })
    )
  }

  useEffect(() => {
    dispatch(requestGetRoles())
  }, [dispatch])

  useEffect(() => {
    dispatch(requestUserList(params))
  }, [dispatch, params])

  useEffect(() => {
    if (success) {
      setUsers(mapNames(data.data))
    }
  }, [data.data, dispatch, error, message, success])

  useEffect(() => {
    if (successUpdate && messageSnackBar !== '') {
      notify('success', messageSnackBar)
      dispatch(clearActivateUser())
      dispatch(clearMessageSnackBar())
      dispatch(requestUserList())
    }
    if (errorUpdate) {
      Swal.fire({
        icon: 'error',
        title: 'Error updating user!',
        text: msmUpdate,
      })
      dispatch(clearActivateUser())
    }
  }, [errorUpdate, msmUpdate, successUpdate, dispatch, messageSnackBar])

  useEffect(() => {
    if (userDeactivate.success) {
      notify('success', 'User deleted successfully')
      dispatch(resetDeactivateUser())
      dispatch(requestUserList())
    }

    if (userDeactivate.error) {
      notify('error', userDeactivate.message)
    }
  }, [dispatch, userDeactivate.success, userDeactivate.error, userDeactivate.message])

  const handleTableChange = useCallback((pagination) => {
    setParams((prevParams) => ({
      ...prevParams,
      page: pagination.current ?? prevParams.page,
      limit: pagination.limit ?? prevParams.limit,
    }))
  }, [])

  const handleValuesChange = (values) => {
    const active = values.active ?? params.active
    const roleId = values.role ?? params.role

    setParams({
      ...params,
      text: values.text ?? params.text,
      active: active !== 'All' ? active : undefined,
      roleId,
    })
  }

  return (
    <>
      <Form
        layout="vertical"
        name="filter-customer-list"
        style={{ width: '100%', margin: '0px', padding: '0px' }}
        form={form}
        initialValues={{
          active: 'All',
          role: 'All',
        }}
        onValuesChange={handleValuesChange}
        hideRequiredMark
      >
        <Row style={{ marginBottom: 0 }} gutter={[24, 16]} justify="end">
          <Col flex={xl ? 8 : undefined} xs={!xl ? 24 : undefined}>
            <Form.Item name="text">
              <StyledSearch />
            </Form.Item>
          </Col>
          <Col>
            <FloatLabel label="Role" show>
              <Form.Item name="role">
                <StyledSelect
                  style={{ paddingTop: 0, width: 300, paddingLeft: 0 }}
                  suffixIcon={<ArrowDownIcon />}
                  size="large"
                  placeholder="Role"
                >
                  <Option key="all-roles" value="">
                    All
                  </Option>
                  {roles &&
                    roles.map((role) => (
                      <Option key={role._id} value={role._id}>
                        {role.name}
                      </Option>
                    ))}
                </StyledSelect>
              </Form.Item>
            </FloatLabel>
          </Col>
          <Col>
            <FloatLabel label="Status" show>
              <Form.Item name="active">
                <StyledSelect
                  suffixIcon={<ArrowDownIcon />}
                  style={{ paddingTop: 0, width: 200, paddingLeft: 0 }}
                  placeholder="Status"
                  size="large"
                >
                  <Option value="">All</Option>
                  <Option value="true">ON</Option>
                  <Option value="false">OFF</Option>
                </StyledSelect>
              </Form.Item>
            </FloatLabel>
          </Col>
          <Col>
            <Tooltip title="Create User">
              <span>
                <ButtonPrimary
                  icon={<BsPlusSquareFill size="16px" />}
                  height="40px"
                  onClick={() => setVisible(true)}
                >
                  New User
                </ButtonPrimary>
              </span>
            </Tooltip>
          </Col>
        </Row>
      </Form>
      <Table
        width="100%"
        padding="4px 0px"
        columns={columnsUser}
        data={users}
        loading={!success}
        rowKey="_id"
        pagination={{ pageSize: 10, total: data.totalRecords, current: params.page }}
        onChange={handleTableChange}
        updatedPagination
        limitPerPage={50}
        rowSelection
        dispatch={dispatch}
        deleteRequest={requestDeactivateUser}
        avoidNotify
        entityNameForBulk="User"
        pauseProps={{
          label: 'Disable',
          labelSuccess: 'disabled',
          icon: (props) => <MdBlock {...props} />,
          newState: {},
        }}
        pauseRequest={(obj) => requestActivateUser({ active: false }, obj.id)}
      />
    </>
  )
}

export default UserList
