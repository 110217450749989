import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        -webkit-box-sizing: border-box; 
        -moz-box-sizing: border-box;    
        box-sizing: border-box;
        background: ${(props) => props.theme.backgroundPrimaryColor};
        font-family: Roboto, sans-serif;
    }
    * {
        box-sizing: inherit;
        margin:0;
        padding:0;
        ::-webkit-scrollbar {
            height: 5px;
            width: 5px;  
        }
        ::-webkit-scrollbar:hover{
            height: 8px;
        }
        ::-webkit-scrollbar-track {
            background-color: #f1f1f1;
        }
        ::-webkit-scrollbar-thumb {
            background-color: #e4e4e4;
            border-radius: 100px;
        } 
    }
    h1, h2, h3, h4, h5, p {
      margin: 0;  
    }
`

export default GlobalStyle
