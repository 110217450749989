import React from 'react'
import Avatar from 'antd/lib/avatar'
import { cleanUserName } from './cleanData'
import { StyledAvatar } from '../styles'

export default function AvatarItem({ userName = '' }) {
  const initials = cleanUserName(userName)
  return (
    <StyledAvatar>
      <Avatar
        shape="square"
        size="large"
        style={{
          fontSize: 'large',
          fontWeight: '400',
          backgroundColor: '#3594FF',
          borderRadius: '4px',
          width: '50px',
          height: '50px',
          lineHeight: '50px',
        }}
      >
        {initials}
      </Avatar>
    </StyledAvatar>
  )
}
