import pipe from './pipe'
import filterArrayByProps from './filterArrayByProps'

/**
 *
 * @param {*} array rules' array
 * @param {*} filter object with props to search and its values to compare
 * @param {*} ignoreMasterRules value to know if the master rules must be ignored
 * @param {*} rulesIds set of properties where the values typeRuleId, typeShippingId and typeReferenceGeographicId it will be taken
 * @returns
 */
const filterRulesToRuleset = (array = [], filter = {}, ignoreMasterRules = false, rulesIds = {}) => {
  const typeRuleId = (a = []) =>
    a.filter((item) => (item !== null ? !rulesIds.typeRuleId || item.typeRuleId._id === rulesIds.typeRuleId : ''))

  const shippingId = (rule) =>
    rule.rule.typeShippingId._id
      ? rule.rule.typeShippingId._id === rulesIds.typeShippingId
      : rule.rule.typeShippingId === rulesIds.typeShippingId

  const typeShippingId = (a = []) =>
    a.filter((rule) => (rule !== null ? !rulesIds.typeShippingId || shippingId(rule) : ''))

  const referenceGeographic = (rule) =>
    rule.rule.typeReferenceGeographicId._id
      ? rule.rule.typeReferenceGeographicId._id === rulesIds.typeReferenceGeographicId
      : rule.rule.typeReferenceGeographicId === rulesIds.typeReferenceGeographicId
      
  const typeReferenceGeographicId = (a = []) =>
    a.filter((rule) => (rule !== null ? !rulesIds.typeReferenceGeographicId || referenceGeographic(rule) : ''))

  const addMarginAndFlatDropdownOptions = (a = []) =>
    a.map((item) => ({
      ...item,
      value: item.name,
      label: `${item.name} (Percentage ${item.rule.margin}% - Flat ${item.rule.flat})`,
    }))

  if (ignoreMasterRules) {
    filter.crossRule = false
  }
  return pipe(
    typeRuleId,
    typeShippingId,
    typeReferenceGeographicId,
    addMarginAndFlatDropdownOptions
  )(filterArrayByProps(filter, array))
}

export default filterRulesToRuleset
