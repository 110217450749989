import { put, takeLatest } from 'redux-saga/effects'
import { requestSaveSelectedCustomer, successSaveSelectedCustomer, failureSaveSelectedCustomer } from 'store/duck/saveSelectedCustomer.duck'

function* saveSelectedCustomerAsync(data) {
  try {
    yield put(successSaveSelectedCustomer(data.payload.selectedCustomer))
  } catch (error) {
    yield put(failureSaveSelectedCustomer(error))
  }
}

function* watchSaveSelectedCustomerAsync() {
  yield takeLatest(requestSaveSelectedCustomer, saveSelectedCustomerAsync)
}

export default watchSaveSelectedCustomerAsync
