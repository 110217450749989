import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  modalRate:{},
  isModalRate:false,
  data: {},  
}

export const {
  requestGetModalRate,
  successGetModalRate,
  failureGetModalRate,
  resetGetModalRate,
  loadModalRate,
  unloadModalRate,
} = createActions({
  REQUEST_GET_MODAL_RATE: (data) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    data,
  }),
  SUCCESS_GET_MODAL_RATE: (data) => ({
    data,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_MODAL_RATE: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  RESET_GET_MODAL_RATE: () => ({
    ...defaultState,
  }),
  LOAD_MODAL_RATE: (data) => ({
    isModalRate: true,
    modalRate: data,
  }),
  UNLOAD_MODAL_RATE: () => ({
    isModalRate: false,
    modalRate: defaultState.modalRate,
  }),
})

const createModalRate = handleActions(
  {
    [requestGetModalRate]: produce((draft, { payload: { fetching, data, error, success } }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successGetModalRate]: produce((draft, { payload: { message, fetching, success, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = false
      draft.data = data
    }),
    [failureGetModalRate]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = false
    }),
    [resetGetModalRate]: produce((draft, { payload: { fetching, data, error, success } }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [loadModalRate]: produce((draft, { payload: { isModalRate, modalRate } }) => {
      draft.isModalRate = isModalRate
      draft.modalRate = modalRate
    }),
    [unloadModalRate]: produce((draft, { payload: { isModalRate } }) => {
      draft.isModalRate = isModalRate
    }),
  },
  defaultState
)

export default createModalRate
