import { put, takeLatest, call } from 'redux-saga/effects'
import { rules } from 'services'
import { requestCreateRuleset, successCreateRuleset, failureCreateRuleset } from '../duck/createRuleSet.duck'

function* createRuleSetAsync(data) {
  try {
    const response = yield call(rules.createRuleset, data.payload.data)
    yield put(successCreateRuleset(response))
  } catch (error) {
    yield put(failureCreateRuleset(error.response.data.message))
  }
}

function* watchCreateRuleSetAsync() {
  yield takeLatest(requestCreateRuleset, createRuleSetAsync)
}

export default watchCreateRuleSetAsync
