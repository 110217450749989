import DrawerItem from 'components/Form/DrawerItem'
import React from 'react'
import Select from 'antd/lib/select'
import SelectOption from 'components/SelectOption'
import Form from 'antd/lib/form/Form'

export default function
    ({ editRule = false }) {
    const typeRules = [
        { keyValue: "dateRangeRule", label: "Date Range" },
        { keyValue: "truckType", label: "Equipment Type" },
        { keyValue: "geographicRule", label: "Geographic" },
        { keyValue: "lengthRule", label: "Length" },
        { keyValue: "leadTimeRule", label: "Lead Time" },
        { keyValue: "simpleRule", label: "Day of Week" }
    ]
    const addAnalyticType = (keyValue = "") => {
        let newArray = []

        if (keyValue === "laneScoreRule" || keyValue === "mciRule" || keyValue === "ltRatioRule") {
            const type = { keyValue, label: "Analytics" }
            newArray.push(type)
        } else {
            newArray.push({ keyValue: "laneScoreRule", label: "Analytics" })
        }
        newArray = [...newArray, ...typeRules]
        return newArray
    }


    return (
        <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.ruleType !== currentValues.ruleType}
        >
            {({ getFieldValue }) => {
                const val = getFieldValue('ruleType') 
                return val ? (
                    <DrawerItem shouldUpdate name="ruleType" rules={[{ required: true, message: 'Please select the rule category' }]}>
                        <Select size="large" disabled={editRule}>
                            {
                                addAnalyticType(val).map((rule) => (
                                    <SelectOption key={rule.keyValue} value={rule.keyValue}>
                                        {rule.label}
                                    </SelectOption>))
                            }
                        </Select>
                    </DrawerItem>
                ) : null
            }
            }
        </Form.Item>

    )
}
