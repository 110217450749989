import PropTypes from 'prop-types'
import ButtonAdd from './ButtonAdd'

ButtonAdd.propTypes = {
  onClick: PropTypes.func,
  element: PropTypes.string.isRequired,
  size: PropTypes.string,
  children: PropTypes.string,
  btnType: PropTypes.string,
}

ButtonAdd.defaultProps = {
  onClick: () => {},
  size: 'medium',
  btnType: 'button',
  children: 'Add ',
}

export default ButtonAdd
