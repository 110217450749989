import { put, call, debounce } from 'redux-saga/effects'
import { zipcode } from 'services'
import { requestGetZipcodes, successGetZipcodes, failureGetZipcodes } from 'store/duck/getZipcodes.duck'

function* getZipcodesAsync(data) {
  try {
    const response = yield call(zipcode.getZipcodes, data.payload.data)
    yield put(successGetZipcodes(response))
  } catch (error) {
    yield put(failureGetZipcodes(error))
  }
}

function* watchGetZipcodesAsync() {
  yield debounce(1500, requestGetZipcodes, getZipcodesAsync)
}

export default watchGetZipcodesAsync
