import styled from 'styled-components'
import { Avatar } from 'antd'

const StyledAvatar = styled(Avatar)`
  border: 4px solid #fff !important ;
  box-sizing: border-box;
  margin-right: -0.5rem;
`

export const AvatarAdd = styled(Avatar)`
  border: 4px solid #fff !important ;
  box-sizing: border-box;
  margin-right: -0.5rem;
  background: #3594ff;
  margin-top: -2rem;
  transition: 0.3s;
  &:hover {
    background-color: ${(props) => props.theme.colors.dark_blue.default};
  }
  svg {
    cursor: pointer;
  }
`

export const AvatarGroup = styled(Avatar.Group)`
  .ant-avatar {
    border: 4px solid #fff !important ;
    box-sizing: border-box;
    margin-right: -0.7rem;
  }
`

export default StyledAvatar
