import Menu from 'antd/lib/menu'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import MoreDropdown from 'components/MoreDropdown'
import ModalConfirmation from 'components/ModalConfirmation'
import { requestMessageSnackBar } from 'store/duck/messageSnackBar.duck'
import { loadRule } from 'store/duck/createRule.duck'
import { requestUpdateStatusRule } from 'store/duck/updateStatusRule.duck'
import { AiOutlineCheck } from 'react-icons/ai'
import { getStatus, EnumStatus } from 'utils/getStatus'
import { MdBlock, MdDelete, MdModeEdit, MdPauseCircleFilled } from 'react-icons/md'
import ModalDeleteRules from 'components/ModalDeleteRules'
import { requestDeleteRule } from 'store/duck/deleteRule.duck'
import TextBodyModal from './TextBodyModal'
import { StyledCol, StyledRow } from './styles'

const sizeIcon = '20'
const paddingItem = { padding: '0px 8px 0px 8px' }

const ActionsDropdown = ({ record, openDrawer, status}) => {
  const validationMultiStepsRuleInfo = () => {
    if (record?.multiStepsRuleInfo) {
      return [...record?.multiStepsRuleInfo?.multistepNames]
    }
    return []
  }

  const validationRulesetsInfo = () => {
    if (record?.rulesetsInfo) {
      return [...record?.rulesetsInfo?.ruleSetsNames]
    }
    return []
  }

  const dispatch = useDispatch()
  const [visibleDelete, setVisibleDelete] = useState(false)
  const [propsModal, setPropsModal] = useState({
    visible: false,
    message: 'Are you sure you want to delete this Rule?',
    // eslint-disable-next-line react/jsx-props-no-spreading
    icon: (props) => <MdDelete {...props} />,
    textButon: 'Delete',
    textSuccessfull: 'Rule has been deleted',
    actionConfirm: (some) => some,
  })

  const loadRuleSelected = (data) => {
    dispatch(loadRule(data))
    openDrawer(true)
  }
  const openDeleteModal = () => {
    setVisibleDelete(true)
  }
  const closeDeleteModal = () => {
    return setVisibleDelete(false)
  }

  const messageModal = {
    [EnumStatus.Activated]: 'activated',
    [EnumStatus.Paused]: 'pause',
    [EnumStatus.Deactivated]: 'block',
  }
  const handleUpdateStatus = async (id = '', newStatus = '') => {
    const dataToStatus = {
      id,
      status: newStatus,
    }

    if (newStatus === 'Activated') {
      dispatch(requestMessageSnackBar(`Rule has been ${messageModal[newStatus].toLowerCase()}`))
      dispatch(requestUpdateStatusRule(dataToStatus))
      return
    }
    setPropsModal({
      visible: true,
      message: `Are you sure you want to ${messageModal[newStatus]} this Rule?`,
      // eslint-disable-next-line react/jsx-props-no-spreading
      icon: (props) => <AiOutlineCheck {...props} />,
      textButon: messageModal[newStatus].charAt(0).toUpperCase() + messageModal[newStatus].slice(1),
      textSuccessfull: `Rule has been ${newStatus.toLowerCase()}`,
      actionConfirm: () => {
        dispatch(requestMessageSnackBar(`Rule has been ${messageModal[newStatus].toLowerCase()}`))
        dispatch(requestUpdateStatusRule(dataToStatus))
        setPropsModal({
          visible: false,
          message: ' ',
          icon: (props) => props,
          textButon: '',
          textSuccessfull: '',
          actionConfirm: (props) => props,
        })
      },
    })
  }

  const assets = {
    [EnumStatus.Activated]: {
      icon: <AiOutlineCheck size={sizeIcon} />,
      text: <span>Activate Rule</span>,
      onClick: (id) => handleUpdateStatus(id, EnumStatus.Activated),
    },
    [EnumStatus.Paused]: {
      icon: <MdPauseCircleFilled size={sizeIcon} />,
      text: <span>Pause Rule</span>,
      onClick: (id) => handleUpdateStatus(id, EnumStatus.Paused),
    },
    [EnumStatus.Deactivated]: {
      icon: <MdBlock size={sizeIcon} />,
      text: <span>Block Rule</span>,
      onClick: (id) => handleUpdateStatus(id, EnumStatus.Deactivated),
    },
  }

  const displayedStatusModifiers = (id) => {
    return Object.keys(EnumStatus)
      .filter((item) => item !== status)
      .map((row, index) => (
        <Menu.Item key={index} style={paddingItem} onClick={() => assets[row].onClick(id)}>
          <StyledRow>
            <StyledCol>{assets[row].icon}</StyledCol>
            <StyledCol>
              <span>{assets[row].text}</span>
            </StyledCol>
          </StyledRow>
        </Menu.Item>
      ))
  }

  return (
    <MoreDropdown
      menu={
        <Menu>
          <Menu.Item style={paddingItem} onClick={() => loadRuleSelected(record)}>
            <StyledRow>
              <StyledCol>
                <MdModeEdit size={sizeIcon} />
              </StyledCol>
              <StyledCol>
                <span>Edit Rule</span>
              </StyledCol>
            </StyledRow>
          </Menu.Item>
          {displayedStatusModifiers(record._id)}
          <Menu.Item style={paddingItem} onClick={() => openDeleteModal()}>
            <StyledRow>
              <StyledCol>
                <MdDelete size={sizeIcon} />
              </StyledCol>
              <StyledCol>
                <span>Delete Rule</span>
              </StyledCol>
            </StyledRow>
          </Menu.Item>
          <ModalDeleteRules
            visible={visibleDelete}
            multiSteps={validationMultiStepsRuleInfo()}
            ruleSets={validationRulesetsInfo()}
            close={closeDeleteModal}
            componentBody={() => (
              <TextBodyModal
                multiQuantity={record?.multiStepsRuleInfo?.quantity ?? ''}
                ruleSetQuantity={record?.rulesetsInfo?.quantity ?? ''}
              />
            )}
            onClick={() => dispatch(requestDeleteRule(record._id))}
          />
          <ModalConfirmation
            avoidNotify
            actionConfirm={propsModal.actionConfirm}
            textSuccessfull={propsModal.textSuccessfull}
            textButton={propsModal.textButon}
            close={() => {
              setPropsModal({
                visible: false,
                message: ' ',
                icon: (props) => props,
                textButon: '',
                textSuccessfull: '',
                actionConfirm: (props) => props,
              })
            }}
            icon={propsModal.icon}
            message={propsModal.message}
            visible={propsModal.visible}
            height="296px"
            width={350}
            centered
          />
        </Menu>
      }
    />
  )
}

const actionsDropdown = (_, openDrawer, enableEdit = true) => ({
  title: <div style={{ fontSize: '16px' }}>Actions</div>,
  dataIndex: 'actions',
  key: 'actions',
  textWrap: 'word-break',
  render: (edit, record) => (
    <ActionsDropdown
      enableEdit={enableEdit}
      record={record}
      openDrawer={openDrawer}
      status={getStatus(record.status, record.active)}
    />
  ),
  width: 80,
  align: 'center',
})

export default actionsDropdown
