import React, { useMemo } from 'react'
import FloatLabel from 'components/Label/FloatLabel'
import DrawerItem from 'components/Form/DrawerItem'
import Select from 'antd/lib/select'
import SelectOption from 'components/SelectOption'
import { RULE_GEOGRAPHIC } from 'utils/constants'

export default function RuleShipping({ ruleType = "", editRule = false, value = "" }) {
    
    const ruleTypes = useMemo(() => ({
        [RULE_GEOGRAPHIC]: {
            render: () => (
                <DrawerItem name="ruleShipping">
                    <Select
                        disabled={ruleType === '' || !editRule} size="large" value={value}>
                        <SelectOption key="outRegion" value="outRegion">
                            Pickup Region
                        </SelectOption>
                        <SelectOption key="outState" value="outState">
                            Pickup State
                        </SelectOption>
                        <SelectOption key="outbound" value="outbound">
                            Pickup Market
                        </SelectOption>
                        <SelectOption key="outZip" value="outZip">
                            Pickup Zip
                        </SelectOption>
                        <SelectOption key="inRegion" value="inRegion">
                            Delivery Region
                        </SelectOption>
                        <SelectOption key="inState" value="inState">
                            Delivery State
                        </SelectOption>
                        <SelectOption key="inbound" value="inbound">
                            Delivery Market
                        </SelectOption>
                        <SelectOption key="inZip" value="inZip">
                            Delivery Zip
                        </SelectOption>
                    </Select>
                </DrawerItem>
            )
        },
        "Analytics": {
            render: () => (
                <DrawerItem name="ruleShipping">
                    <Select
                        defaultActiveFirstOption
                        disabled={ruleType === '' || !editRule} size="large" value={value}>
                        <SelectOption key="laneScore" value="laneScore">
                            UD Score
                        </SelectOption>
                        <SelectOption key="outMCI" value="outMCI">
                            Origin MCI
                        </SelectOption>
                        <SelectOption key="inMCI" value="inMCI">
                            Destination MCI
                        </SelectOption>
                        <SelectOption key="outLT" value="outLT">
                            Origin L/T
                        </SelectOption>
                        <SelectOption key="inLT" value="inLT">
                            Destination L/T
                        </SelectOption>
                    </Select>
                </DrawerItem>
            )
        }
    }), [ruleType, editRule, value])



    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
                <FloatLabel label="Type *" show>
                    {ruleTypes[ruleType].render()}
                </FloatLabel>
            </div>
        </div>
    )
}
