import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: {},
  editSpecialSer: false,
  resetForm: false,
  resetFormEdit: false,
  specialSerSelected: {},
}

export const {
  requestCreateSpecialService,
  successCreateSpecialService,
  failureCreateSpecialService,
  clearCreateSpecialService,
  loadSpecialService,
  addSpecialService,
} = createActions({
  REQUEST_CREATE_SPECIAL_SERVICE: (data) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    data,
  }),
  SUCCESS_CREATE_SPECIAL_SERVICE: (data) => ({
    data,
    message: data.message,
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_CREATE_SPECIAL_SERVICE: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  CLEAR_CREATE_SPECIAL_SERVICE: () => ({
    ...defaultState,
  }),
  LOAD_SPECIAL_SERVICE: (data) => ({
    ...defaultState,
    editSpecialSer: true,
    resetFormEdit: true,
    specialSerSelected: data,
  }),
  ADD_SPECIAL_SERVICE: () => ({
    ...defaultState,
    editSpecialSer: false,
    resetForm: true,
  }),
})

const createSpecialService = handleActions(
  {
    [requestCreateSpecialService]: produce((draft, { payload: { fetching, data, error, success } }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successCreateSpecialService]: produce((draft, { payload: { message, fetching, success, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = false
      draft.data = data
    }),
    [failureCreateSpecialService]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = false
    }),
    [clearCreateSpecialService]: produce((draft, { payload: { message, fetching, error, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = false
      draft.data = data
    }),
    [loadSpecialService]: produce((draft, { payload: { editSpecialSer, specialSerSelected, resetFormEdit } }) => {
      draft.editSpecialSer = editSpecialSer
      draft.specialSerSelected = specialSerSelected
      draft.resetFormEdit = resetFormEdit
    }),
    [addSpecialService]: produce((draft, { payload: { editSpecialSer, resetForm } }) => {
      draft.editSpecialSer = editSpecialSer
      draft.resetForm = resetForm
    }),
  },
  defaultState
)

export default createSpecialService
