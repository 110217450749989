import PropTypes from 'prop-types'
import DateLabel from './DateLabel'

DateLabel.propTypes = {
  date: PropTypes.string,
  time: PropTypes.string,
}

DateLabel.defaultProps = {
  date: '',
  time: '',
}

export default DateLabel
