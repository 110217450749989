import React from 'react'
import { getStatus } from 'utils/getStatus'
import Typography from 'antd/lib/typography'
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md'
import { AiFillStar } from 'react-icons/ai'
import { StyledBadge, StyledText } from './styles'
import actionsDropdown from './actionsDropdown'
import { CreateName } from './ShowItems'

const { Text } = Typography
const Main = ({ children }) => {
  return <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
}

const ShowIcon = (rate, rows) => {
  if (typeof rows === 'string') {
    const match = rows === rate?._id
    if (match) {
      return (
        <MdKeyboardArrowUp
          fontSize="18px"
          style={{
            color: '#3594FF',
            cursor: 'pointer',
          }}
        />
      )
    }
  }
  return <MdKeyboardArrowDown fontSize="18px" style={{ color: '#3594FF', cursor: 'pointer' }} />
}

export const getConditionalsColumn = (sentences, rate, onExpand, expandedRowKeys) => {
  if (!sentences?.length) {
    return null
  }

  return (
    <Main>
      {sentences.length > 2 ? (
        <>
          <Text
            onClick={() => onExpand(rate)}
            underline
            style={{ color: '#3594FF', cursor: 'pointer', fontSize: '16px', display: 'flex' }}
          >{`(${sentences.length}) conditionals`}</Text>
          {ShowIcon(rate, expandedRowKeys)}
        </>
      ) : (
        <div style={{ width: '100%', maxWidth: 250, fontSize: '16px', display: 'flex', flexDirection: 'column' }}>
          {sentences.map((item, index) => (
            <CreateName item={item} key={index} />
          ))}
        </div>
      )}
    </Main>
  )
}

const MultiStepColumns = (dispatch, openDrawer, onExpand, expandedRowKeys) => {
  return [
    {
      title: '',
      dataIndex: 'crossMultistep',
      render: (crossMultistep) => {
        return crossMultistep ? (
          <AiFillStar fontSize="15px" style={{ color: '#F7981C', margin: '0px 4px' }} />
        ) : null
      },
      width: 20,
      align: 'left',
    },
    {
      title: <div style={{ fontSize: '16px' }}>Name</div>,
      dataIndex: 'multistepName',
      key: 'multistepName',
      render: (multistepName) => {
        return (
          <Main>
            <StyledText style={{ fontSize: '16px', display: 'flex' }}>{multistepName}</StyledText>
          </Main>
        )
      },
      width: 150,
      align: 'left',
    },
    {
      title: <div style={{ fontSize: '16px' }}>Conditionals</div>,
      dataIndex: 'sentences',
      key: 'sentences',
      colSpan: 1,
      render: (sentences, rate) => getConditionalsColumn(sentences, rate, onExpand, expandedRowKeys),
      width: 200,
      ellipsis: true,
    },
    {
      title: <div style={{ fontSize: '16px', display: 'flex' }}>Percentage</div>,
      dataIndex: 'margin',
      key: 'margin',
      render: (margin) => {
        return (
          <Main>
            <StyledText style={{ fontSize: '16px', display: 'flex' }}>{margin}%</StyledText>
          </Main>
        )
      },
      width: 100,
      ellipsis: true,
      editable: true
    },
    {
      title: <div style={{ fontSize: '16px', display: 'flex' }}>Flat</div>,
      dataIndex: 'flat',
      key: 'flat',
      render: (flat) => {
        return (
          <Main>
            <StyledText style={{ fontSize: '16px', display: 'flex' }}>${flat}</StyledText>
          </Main>
        )
      },
      width: 100,
      ellipsis: true,
      editable: true
    },

    {
      title: <div style={{ fontSize: '16px' }}>Status</div>,
      dataIndex: 'active',
      key: ['rate', 'active'],
      render: (active, rate) => (
          <div style={{ display: 'flex' }}>
            <StyledBadge variant={getStatus(rate.status, active)} />
          </div>
      ),
      width: 75,
      align: 'left',
    },
    {
      ...actionsDropdown(dispatch, openDrawer),
    },
  ]
}

export default MultiStepColumns
