import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  history: [],
  totalRecords: 0,
}

export const {
  requestGetSpecialServiceHistory,
  successGetSpecialServiceHistory,
  failureGetSpecialServiceHistory,
  clearGetSpecialServiceHistory,
} = createActions({
  REQUEST_GET_SPECIAL_SERVICE_HISTORY: (params) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    params,
  }),
  SUCCESS_GET_SPECIAL_SERVICE_HISTORY: (specialService) => ({
    history: specialService.data,
    totalRecords: specialService.totalRecords,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_SPECIAL_SERVICE_HISTORY: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  CLEAR_GET_SPECIAL_SERVICE_HISTORY: () => ({
    ...defaultState,
  }),
})

const getSpecialServiceHistory = handleActions(
  {
    [requestGetSpecialServiceHistory]: produce((draft, { payload: { fetching, data, error, success } }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successGetSpecialServiceHistory]: produce(
      (draft, { payload: { message, fetching, success, history, totalRecords, error } }) => {
        draft.message = message
        draft.fetching = fetching
        draft.success = success
        draft.error = error
        draft.history = history
        draft.totalRecords = totalRecords
      }
    ),
    [failureGetSpecialServiceHistory]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = !error
    }),
    [clearGetSpecialServiceHistory]: produce(
      (draft, { payload: { message, fetching, success, history, totalRecords, error } }) => {
        draft.message = message
        draft.fetching = fetching
        draft.success = success
        draft.error = error
        draft.history = history
        draft.totalRecords = totalRecords
      }
    ),
  },
  defaultState
)

export default getSpecialServiceHistory
