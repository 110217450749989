import styled from 'styled-components'
import StyledModal from 'components/Modal/styles'

const StyleModal = styled(StyledModal)`
  .ant-modal-close {
    padding: 0px;
    margin: 0px;
  }
  .ant-modal-close-x {
    display: flex;
    justify-content: flex-end;
    font-size: 18px;
    color: #545454;
    padding: 7px 10px 0px 0px;
    height: 35px;
  }
`

export default StyleModal
