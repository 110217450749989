import React, { useEffect } from 'react'
import 'tableau-api'
import { createToken } from 'utils/createTokenTableau'

export default function Tableu() {
  const url = process.env.REACT_APP_TABLEAU_URL
  const user = process.env.REACT_APP_TABLEAU_USER
  const secretId = process.env.REACT_APP_APP_SECRET_ID
  const secretValue = process.env.REACT_APP_SECRET_VALUE
  const appId = process.env.REACT_APP_CONNECTED_APP_ID

  useEffect(() => {
    if (user && secretId && secretValue && appId && url) {
      const token = createToken(user, secretId, secretValue, appId)
      const tp = `
        <tableau-viz id="tableauViz" src="${url}" 
        token="${token}"
        height="1000px"
        width="100%"
        toolbar="hidden" hide-tabs>
      </tableau-viz>`

      document.getElementById('dash').innerHTML = tp
    }
  }, [user, secretId, secretValue, appId, url])

  return <div id="dash" style={{ height: 'auto', width: '100%' }} />
}
