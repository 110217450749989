import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: {},
  customerId: '',
}

export const { requestActivateCustomer, successActivateCustomer, failureActivateCustomer, clearActivateCustomer } = createActions({
  REQUEST_ACTIVATE_CUSTOMER: (data, customerId) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    data,
    customerId,
  }),
  SUCCESS_ACTIVATE_CUSTOMER: (data) => ({
    data,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_ACTIVATE_CUSTOMER: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  CLEAR_ACTIVATE_CUSTOMER: () => ({
    ...defaultState,
  }),
})

const activateCustomer = handleActions(
  {
    [requestActivateCustomer]: produce((draft, { payload: { fetching, data, error, success } }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successActivateCustomer]: produce((draft, { payload: { message, fetching, success, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = false
      draft.data = data
    }),
    [failureActivateCustomer]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = false
    }),
    [clearActivateCustomer]: produce((draft, { payload: { message, fetching, error, success } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
  },
  defaultState
)

export default activateCustomer
