import { put, takeLatest, call } from 'redux-saga/effects'
import { trucktypes } from 'services/'
import { requestGetTruckTypes, successGetTruckTypes, failureGetTruckTypes } from '../duck/getTruckTypes.duck'

function* getTruckTypesAsync() {
  try {
    const response = yield call(trucktypes.getTruckTypes)
    yield put(successGetTruckTypes(response))
  } catch (error) {
    yield put(failureGetTruckTypes(error))
  }
}

function* watchGetTruckTypesAsync() {
  yield takeLatest(requestGetTruckTypes, getTruckTypesAsync)
}

export default watchGetTruckTypesAsync
