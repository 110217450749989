import styled from 'styled-components'
import Button from 'antd/lib/button'
import { DeleteIcon } from 'assets/img'

export const StyledDeleteIcon = styled(DeleteIcon)`
  color: #6e7f8d;
  font-size: 20px;
`

export const StyledButton = styled(Button)`
  border: none;
  padding: 0px;
`
