/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import { FiPauseCircle } from 'react-icons/fi'
import Typography from 'antd/lib/typography'
import { MdDelete } from 'react-icons/md'
import ModalConfirmation from 'components/ModalConfirmation/ModalConfirmation'
import { requestMessageSnackBar } from 'store/duck/messageSnackBar.duck'
import colors from 'providers/theme/config/colorPallete'
import StyledRow from './styles'

const { Text } = Typography

const defaultPropsModal = {
  visible: false,
  message: ' ',
  icon: (props) => props,
  textButon: '',
  textSuccessfull: '',
  actionConfirm: (props) => props,
}

export default function BulkActions({
  data = [],
  dispatch,
  cleanData,
  pauseRequest,
  deleteRequest,
  avoidNotify = false,
  CustomModalDelete,
  openPauseModal,
  openDeleteModal,
  handleData,
  entityNameForBulk,
  entityNameForTextOnTopOfTableForBulk,
  padding = '',
  margin = '',
  pauseProps = {},
  deleteProps = {},
  showBulkActions = true,
  customBulkActions,
  displayComponent,
}) {
  const propsPause = {
    label: 'Pause',
    labelSuccess: 'paused',
    icon: (props) => <FiPauseCircle {...props} />,
    newState: { status: 'Paused' },
    hidden: false,
    ...pauseProps,
  }
  const propsDelete = {
    hidden: false,
    ...deleteProps,
  }

  const rowsSelecteds = data.length
  const MESSAGE_SUCCESS_PAUSED = `The ${entityNameForBulk}s has been ${propsPause.labelSuccess}!`
  const [propsModal, setPropsModal] = useState(defaultPropsModal)

  useEffect(() => {
    if (handleData !== undefined) {
      handleData(data)
    }
  }, [data, handleData])

  if (data.length === 0) {
    if (showBulkActions) {
      return (
        <Row style={{ width: '100%', margin: '0px', marginBottom: 11 }}>
          <Col span={12}>
            <div
              style={{
                //
                fontSize: '15px',
                color: colors.dark_grey.default,
                // ,
              }}
            >
              {` (Select ${entityNameForTextOnTopOfTableForBulk.toLowerCase()} for bulk update)`}
            </div>
          </Col>
          <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {displayComponent ? displayComponent() : ''}
          </Col>
        </Row>
      )
    }
    return null
  }

  const close = () => {
    setPropsModal({
      visible: false,
      message: ' ',
      icon: (props) => props,
      textButon: '',
      textSuccessfull: '',
      actionConfirm: (props) => props,
    })
  }

  const actionConfirmation = () => {
    cleanData()
    close()
  }

  const confirmPauseRules = () => {
    data.forEach((id) => {
      if (pauseRequest !== undefined) {
        const newStatus = { id, ...propsPause.newState }
        dispatch(pauseRequest(newStatus))
      }
    })
    dispatch(requestMessageSnackBar(MESSAGE_SUCCESS_PAUSED))
    actionConfirmation()
  }

  const pauseRules = () => {
    if (typeof openPauseModal === 'function') {
      openPauseModal(data)
    } else {
      setPropsModal({
        visible: true,
        message: `Are you sure you want to ${propsPause.label.toLowerCase()} these ${entityNameForBulk}s?`,
        icon: (props) => propsPause.icon(props),
        textButon: propsPause.label,
        textSuccessfull: MESSAGE_SUCCESS_PAUSED,
        actionConfirm: confirmPauseRules,
      })
    }
  }

  const confirmDeleteRules = () => {
    data.forEach((id) => {
      if (deleteRequest !== undefined) {
        dispatch(deleteRequest(id))
      }
    })

    actionConfirmation()
  }

  const deleteRules = () => {
    if (typeof openDeleteModal === 'function') {
      openDeleteModal()
    } else {
      setPropsModal({
        visible: true,
        message: `Are you sure you want to delete these ${entityNameForBulk}s?`,
        // eslint-disable-next-line react/jsx-props-no-spreading
        icon: (props) => <MdDelete {...props} />,
        textButon: 'Delete',
        textSuccessfull: `The ${entityNameForBulk}s has been deleted successfully!`,
        actionConfirm: confirmDeleteRules,
      })
    }
  }
  const style = { fontSize: '24px' }

  const BulkActionsButtons = () => (
    <>
      {!propsPause.hidden ? (
        <Col>
          <StyledRow onClick={pauseRules}>
            {propsPause.icon(style)}
            <Text underline style={{ marginLeft: '7px', color: 'inherit', fontWeight: 500, fontSize: '16px' }}>
              {propsPause.label} {entityNameForBulk}s
            </Text>
          </StyledRow>
        </Col>
      ) : null}
      {!propsDelete.hidden ? (
        <Col>
          <StyledRow onClick={deleteRules}>
            <MdDelete style={{ fontSize: '24px' }} />
            <Text underline style={{ marginLeft: '7px', color: 'inherit', fontWeight: 500, fontSize: '16px' }}>
              Delete {entityNameForBulk}s
            </Text>
          </StyledRow>
        </Col>
      ) : null}
    </>
  )

  return (
    <Row
      style={{
        margin: margin === '' ? '0px 0px 10.2px 0px' : margin,
        padding: padding === '' ? '0px' : padding,
        position: 'relative',
      }}
    >
      {typeof CustomModalDelete === 'object' && CustomModalDelete}
      <ModalConfirmation
        actionConfirm={propsModal.actionConfirm}
        textSuccessfull={propsModal.textSuccessfull}
        textButton={propsModal.textButon}
        close={close}
        icon={propsModal.icon}
        message={propsModal.message}
        visible={propsModal.visible}
        height="296px"
        width={350}
        centered
        avoidNotify={avoidNotify}
      />
      <Col
        style={{ margin: '0px 15px 0px 0px', fontSize: '15px', color: '#545454' }}
      >{`(${rowsSelecteds} ${entityNameForBulk.toLowerCase()}s selected)`}</Col>

      <Col>
        <Row gutter={16}>
          {customBulkActions ? customBulkActions(<BulkActionsButtons />, cleanData) : <BulkActionsButtons />}
        </Row>
      </Col>
    </Row>
  )
}
