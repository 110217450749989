import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  id: "",
  rate:{}
}

export const {
  requestPatchQuote,
  successPatchQuote,
  failurePatchQuote,
  savePatchQuote,
  clearPatchQuote,
} = createActions({
  REQUEST_PATCH_QUOTE: (id, load) => ({
    ...defaultState,
    fetching: true,
    load,
    id,
  }),
  SUCCESS_PATCH_QUOTE: (rate) => ({ 
    rate,
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_PATCH_QUOTE: (message) => ({
    message,
    fetching: false,
    error: true,
  }),
  CLEAR_PATCH_QUOTE: () => ({}),
})

const patchQuote = handleActions(
  {
    [requestPatchQuote]: produce((draft, { payload: { message, fetching, success, error, id  } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.id = id 
    }),
    [successPatchQuote]: produce((draft, { payload: { message, fetching, success, rate } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.rate = rate
    }),
    [failurePatchQuote]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
    }) ,
    [clearPatchQuote]: produce((draft) => {
      draft.message = defaultState.message
      draft.fetching = defaultState.fetching
      draft.error = defaultState.error
      draft.success = defaultState.success
      draft.id = defaultState.id
    }),
  },
  defaultState
)

export default patchQuote
