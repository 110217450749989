import React from 'react' 
import moment from 'moment'
import { MainDiv, StyledText } from './styles'

export default function MasterRule({ name = '', lastUseDate = '' }) {
  return (
    <div style={{ display: 'block', margin: '0px', padding: '0px' }}>
      <div style={{ width: '100%' }}>
        <MainDiv>
          <StyledText style={{fontSize: '16px'}}>{name}</StyledText>           
        </MainDiv>
      </div>
      <div style={{ width: '100%', fontSize: '16px', color: '#3594FF' }}>
        {lastUseDate !== '' ? `Last use date: ${moment.utc(lastUseDate).format('MM/DD/YYYY')}` : null}
      </div>
    </div>
  )
}
