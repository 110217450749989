import React from 'react'
import { useDispatch } from 'react-redux'
import Collapse from 'antd/lib/collapse'
import ArrowIcon from 'components/Icon/ArrowIcon'
import ButtonAdd from 'components/Button/ButtonAdd'
import Table from 'components/Table'
import columns from './utils/TableColumns'

const LoadGeographicRule = ({ handleFetchRules, rules, rulesetData, shippingData, referenceData }) => {
  const dispatch = useDispatch()

  const fetchRules = (ruleType, title, shipping, shippingId, reference, referenceId, currentRules) => {
    const ruleSelected = []
    currentRules.map((item) => {
      ruleSelected.push(item._id)
      return ruleSelected
    })
    handleFetchRules(ruleType, title, shipping, shippingId, reference, referenceId, ruleSelected)
  }

  return (
    <Collapse
      expandIcon={({ isActive }) => <ArrowIcon change={isActive ? '0deg' : '180deg'} size="1rem" />}
      defaultActiveKey={['1', '2', '3', '4']}
      ghost
    >
      <Collapse.Panel header="Inbound (State)" key="1">
        {rules.inboundState.length > 0 && (
          <Table
            columns={columns(dispatch, 'inbound', 'state', rulesetData.rulesId, rulesetData._id)}
            data={rules.inboundState}
            loading={false}
            rowKey="_id"
            width="100%"
            padding="4px 2px"
          />
        )}
        <ButtonAdd
          element={'LoadGeoIS'}
          onClick={() =>
            fetchRules(
              'RuleGeographic',
              'Geographic',
              'inbound',
              shippingData.inShipping,
              'state',
              referenceData.referenceState,
              rules.inboundState
            )
          }
        >
          New Rule
        </ButtonAdd>
      </Collapse.Panel>
      <Collapse.Panel header="Inbound (3 DigitZip)" key="2">
        {rules.inboundZip.length > 0 && (
          <Table
            columns={columns(dispatch, 'inbound', 'zip', rulesetData.rulesId, rulesetData._id)}
            data={rules.inboundZip}
            loading={false}
            rowKey="_id"
            width="100%"
            padding="4px 2px"
          />
        )}
        <ButtonAdd
          element={'LoadGeoIZ'}
          onClick={() =>
            fetchRules(
              'RuleGeographic',
              'Geographic',
              'inbound',
              shippingData.inShipping,
              'zip',
              referenceData.referenceZip,
              rules.inboundZip
            )
          }
        >
          New Rule
        </ButtonAdd>
      </Collapse.Panel>
      <Collapse.Panel header="Outbound (State)" key="3">
        {rules.outboundState.length > 0 && (
          <Table
            columns={columns(dispatch, 'outbound', 'state', rulesetData.rulesId, rulesetData._id)}
            data={rules.outboundState}
            loading={false}
            rowKey="_id"
            width="100%"
            padding="4px 2px"
          />
        )}
        <ButtonAdd
          element={'LoadGeoOS'}
          onClick={() =>
            fetchRules(
              'RuleGeographic',
              'Geographic',
              'outbound',
              shippingData.outShipping,
              'state',
              referenceData.referenceState,
              rules.outboundState
            )
          }
        >
          New Rule
        </ButtonAdd>
      </Collapse.Panel>
      <Collapse.Panel header="Outbound (3 DigitZip)" key="4">
        {rules.outboundZip.length > 0 && (
          <Table
            columns={columns(dispatch, 'outbound', 'zip', rulesetData.rulesId, rulesetData._id)}
            data={rules.outboundZip}
            loading={false}
            rowKey="_id"
            width="100%"
            padding="4px 2px"
          />
        )}
        <ButtonAdd
          element={'LoadGeoOZ'}
          onClick={() =>
            fetchRules(
              'RuleGeographic',
              'Geographic',
              'outbound',
              shippingData.outShipping,
              'zip',
              referenceData.referenceZip,
              rules.outboundZip
            )
          }
        >
          New Rule
        </ButtonAdd>
      </Collapse.Panel>
    </Collapse>
  )
}

export default LoadGeographicRule
