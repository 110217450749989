import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  successUpdate: false,
  data: {},
}

export const {
  requestUpdateMultiStep,
  successUpdateMultiStep,
  failureUpdateMultiStep,
  clearUpdateMultiStep,
} = createActions({
  REQUEST_UPDATE_MULTI_STEP: (data) => ({
    ...defaultState,
    fetching: true,
    successUpdate: false,
    data,
  }),
  SUCCESS_UPDATE_MULTI_STEP: (data, message) => ({
    data,
    message,
    fetching: false,
    error: false,
    successUpdate: true,
  }),
  FAILURE_UPDATE_MULTI_STEP: (message) => ({
    message,
    fetching: false,
    error: true,
  }),
  CLEAR_UPDATE_MULTI_STEP: () => ({
    message: '',
    fetching: false,
    error: false,
    successUpdate: false,
  }),
})

const updateMultiStep = handleActions(
  {
    [requestUpdateMultiStep]: produce((draft, { payload: { message, fetching, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.successUpdate = false
      draft.data = data
    }),
    [successUpdateMultiStep]: produce((draft, { payload: { message, fetching, successUpdate, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.successUpdate = successUpdate
      draft.data = data
    }),
    [failureUpdateMultiStep]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
    }),
    [clearUpdateMultiStep]: produce((draft, { payload: { message, fetching, error, successUpdate } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.successUpdate = successUpdate
    }),
  },
  defaultState
)

export default updateMultiStep
