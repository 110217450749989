import React from 'react'
import { StyledCol } from 'components/Text/TextPrimary'
import ButtonText from 'components/Button/TextButton'

export default function RowRule({
  name = '',
  margin = '',
  flat = '',
  symbols = true,
  setVisible = () => '',
  visible = true,
}) {
  if (!visible) {
    return <></>
  }
  return (
    <StyledCol span={24} style={{ display: 'flex', paddingLeft: '9px' }}>
      <StyledCol span={10}>
        {symbols ? (
          <ButtonText style={{ justifyContent: 'start' }} onClick={setVisible}>
            {name}
          </ButtonText>
        ) : (
          `${name}`
        )}
      </StyledCol>
      <StyledCol span={7}>{`${margin}${symbols ? '%' : ''}`}</StyledCol>
      <StyledCol span={7}>{`${symbols ? '$' : ''}${flat}`}</StyledCol>
    </StyledCol>
  )
}
