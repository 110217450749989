import styled from 'styled-components'
import Typography from 'antd/lib/typography'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Menu from 'antd/lib/menu'

const { Text } = Typography

export const MenuContainer = styled.div`
  width: 100px;
  margin: 0px;
  padding: 2px 6px;
  padding-top: 10px;
`

export const StyledCol = styled(Col)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const StyledRow = styled(Row)`
  cursor: pointer;
  margin-bottom: 8px;
`

export const StyledLabel = styled(Text)`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #545454;
  &:hover {
    color: #002555;
    font-size: 13px;
    font-weight: 600;
  }
`
export const SvgContainer = styled.div`
  svg {
    width: 20px;
    height: 20px;
  }
`

export const StyledItem = styled(Menu.Item)`
  color: #54aeff;
  margin: 5px;
  display: flex;
  &:hover {
    color: white;
    background: #002555;
    border-radius: 4px !important;
  }
  &::after,
  &:active,
  &:focus {
    background: #002555;
  }
  img {
    filter: invert(63%) sepia(77%) saturate(1980%) hue-rotate(183deg) brightness(100%) contrast(102%);
  }
`
 
