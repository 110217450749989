import { put, call, debounce } from 'redux-saga/effects'
import { zipcode } from 'services'
import {
  requestValidateZipcode,
  successValidateZipcode,
  failureValidateZipcode,
} from 'store/duck/validateZipcode.duck'

function* validateZipcodeAsync(data) {
  try {
    const response = yield call(zipcode.validateZipcode, data.payload.data)
    yield put(successValidateZipcode(response))
  } catch (error) {
    yield put(failureValidateZipcode(error))
  }
}

function* watchValidateZipcodeAsync() {
  yield debounce(1500, requestValidateZipcode, validateZipcodeAsync)
}

export default watchValidateZipcodeAsync
