import { put, takeLatest, call } from 'redux-saga/effects'
import { settings } from 'services/'
import {
  requestGetTrustPercentages,
  successGetTrustPercentages,
  failureGetTrustPercentages,
} from '../duck/getTrustPercentages.duck'

function* getTrustPercentagesAsync(data) {
  try {
    const response = yield call(settings.getTrustPercentage, data.payload.params)
    yield put(successGetTrustPercentages(response))
  } catch (error) {
    yield put(failureGetTrustPercentages(error))
  }
}

function* watchGetTrustPercentagesAsync() {
  yield takeLatest(requestGetTrustPercentages, getTrustPercentagesAsync)
}

export default watchGetTrustPercentagesAsync
