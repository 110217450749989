import CryptoJS from 'crypto-js'

export function base64url(source) {
  let encodedSource = CryptoJS.enc.Base64.stringify(source)
  encodedSource = encodedSource.replace(/=+$/, '')
  encodedSource = encodedSource.replace(/\+/g, '-')
  encodedSource = encodedSource.replace(/\//g, '_')
  return encodedSource
}

/**
 * 
 * @param {*} userid email with access to the workbook
 * @param {*} kid The Connected App Secret ID
 * @param {*} secret The Connected App Secret value
 * @param {*} iss The Connected App ID
 * @param {*} scp scopes (optional value)
 * @returns jwt token
 */
export function createToken(userid, kid, secret, iss, scp=["tableau:views:embed"]) {
  const header = {
    alg: 'HS256',
    typ: 'JWT',
    iss,
    kid,
  }
  const stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header))
  const encodedHeader = base64url(stringifiedHeader)
  const claimSet = {
    sub: userid,
    aud: 'tableau',
    nbf: Math.round(new Date().getTime() / 1000) - 100,
    jti: new Date().getTime().toString(),
    iss,
    scp,
    exp: Math.round(new Date().getTime() / 1000) + 100,
  }
  const stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(claimSet))
  const encodedData = base64url(stringifiedData)
  const token = `${encodedHeader}.${encodedData}`
  let signature = CryptoJS.HmacSHA256(token, secret)
  signature = base64url(signature)
  const signedToken = `${token}.${signature}`
  return signedToken
}
