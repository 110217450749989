import React from 'react'
import StyledButton from './styles'
import SpinLoading from '../SpinLoading'

const PrimarySubmit = ({ label, formName, loading, disable, onClick }) => {
  return (
    <StyledButton type="primary" htmlType="submit" form={formName} disabled={loading || disable} onClick={onClick}>
      {loading ? <SpinLoading /> : label}
    </StyledButton>
  )
}

export default PrimarySubmit
