import React from 'react'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import InputNumber from 'antd/lib/input-number' 
import { StyledItem, StyledLabel } from './styles'

const TrustPercentageItem = ({ itemLabel, itemName, placeholder, required, message, icon = null }) => {
  return (
    <Row gutter={10}>
      <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
        <StyledLabel>{itemLabel}</StyledLabel>
        {icon}
      </Col>
      <Col span={12}>
        <StyledItem name={itemName} rules={[{ required, message }]}>
          <InputNumber min={0} max={100} style={{ width: '100%' }} placeholder={placeholder} />
        </StyledItem>
      </Col>
    </Row>
  )
}

export default TrustPercentageItem
