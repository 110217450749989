import { all } from 'redux-saga/effects'
import watchAssignTrustPercentageAsync from './assignTrustPercentage.saga'
import activateSpecialServiceRequestAsync from './activateSpecialService.saga'
import watchGetTrustPercentagesAsync from './getTrustPercentages.saga'
import watchCreateMultiStepAsync from './createMultiStep.saga'
import watchCreateRuleSetAsync from './createRuleSet.saga'
import watchCreateCustomerAsync from './createCustomer.saga'
import watchGetRouteDataAsync from './getRouteData.saga'
import watchGetRulesAsync from './getRules.saga'
import watchGetRateAsync from './getRate.saga'
import watchGetMasterRulesAsync from './getMasterRules.saga'
import watchGetMultiStepsAsync from './getMultiSteps.saga'
import watchGetRuleSetsAsync from './getRuleSets.saga'
import watchGetRulesetByIdAsync from './getRulesetById.saga'
import watchGetAllRatesAsync from './getAllRates.saga'
import watchGetAllRulesAsync from './getAllRules.saga'
import watchGetAllCustomersAsync from './getAllCustomer.saga'
import watchGetCustomersAsync from './getCustomers.saga'
import watchGetSpecialServicesAsync from './getSpecialServices.saga'
import watchGetSpecialServicesListAsync from './getSpecialServicesList.saga'
import watchCreateSpecialServiceAsync from './createSpecialService.saga'
import watchGetTruckTypesAsync from './getTrucktypes.saga'
import watchGetTruckTypeListAsync from './getTruckTypeList.saga'
import watchTruckTypeAsync from './createTruckType.saga'
import watchCreateRatesAsync from './createRate.saga'
import watchGetUsersAsync from './getUsers.saga'
import watchLoginAsync from './login.saga'
import watchForgotPasswordAsync from './forgotPassword.saga'
import watchResetPasswordAsync from './resetPassword.saga'
import watchGetRolesAsync from './getRoles.saga'
import watchCreateUserAsync from './createUser.saga'
import watchCreateRuleAsync from './createRule.saga'
import watchGetGeographicReferenceAsync from './getGeographicReference.saga'
import watchGetRuleTypeAsync from './getRuleType.saga'
import watchGetShippingTypeAsync from './getShippingType.saga'
import watchGetSingleUserAsync from './getSingleUser.saga'
import watchEditUserAsync from './editUser.saga'
import watchGetDatHisotryAsync from './getDatHistory.saga'
import watchUpdateMultiStepAsync from './updateMultiStep.saga'
import watchUpdateSpecialServiceAsync from './updateSpecialService.saga'
import watchUpdateTruckTypeAsync from './updateTruckType.saga'
import watchUpdateTrustPercentageAsync from './updateTrustPercentage.saga'
import watchUpdateRulesetAsync from './updateRuleset.saga'
import watchUpdateRuleAsync from './updateRule.saga'
import watchAssignCustomersAsync from './assignCustomers.saga'
import watchDeleteMultiStepAsync from './deleteMultiStep.saga'
import watchDeleteSpecialServiceAsync from './deleteSpecialService.saga'
import watchDeleteRuleAsync from './deleteRule.saga'
import watchDeleteRulesetAsync from './deleteRuleset.saga'
import watchDeleteRateAsync from './deleteRate.saga'
import watchActivateUserAsync from './activateUser.saga'
import watctUserEditAdminAsync from './userEditAdmin.saga'
import watchGetAutocompleteHereAsync from './getAutocompleteHere.saga'
import watchGetCityFromZipAsync from './getCityFromZip.saga'
import watchValidateZipcodeAsync from './validateZipcode.saga'
import watchGetZipcodesAsync from './getZipcodes.saga'
import watchDeactivateUserAsync from './deactivateUser.saga'
import watchGetZipAutocompleteAsync from './getZipAutoComplete.saga'
import watchGetZipHereAsync from './getZipHere.saga'
import watchSaveQuoteAsync from './saveQuote.saga'
import watchGetCustomerAsync from './getCustomer.saga'
import watchEditCustomerAsync from './editCustomer.saga'
import watchActivateCustomerAsync from './activateCustomer.saga'
import watchDeleteCustomerAsync from './deleteCustomer.saga'
import watchGetDownloadMultipleRatesAsync from './getDownloadMultipleRates.saga'
import watchGetStatesAsync from './getStates.saga'
import watchValidateStateAsync from './validateState.saga'
import watchDefaultCustomerAsync from './defaultCustomer.saga'
import watchGetDefaultCustomerAsync from './getDefaultCustomer.saga'
import watchSendEmailAsync from './sendEmail.saga'
import watchResendInviteAsync from './resendInvite.saga'
import watchGetHistoryAsync from './getHistory.saga'
import watchGetRuleHistoryAsync from './getHistoryRule.saga'
import watchUpdateStatusMultistepAsync from './updateStatusMultiStep.saga'
import watchUpdateStatusRuleAsync from './updateStatusRule.saga'
import watchBulkDeleteRulesetAsync from './deleteBulkRuleset.saga'
import watchGetMultiStepsHistoryAsync from './getMultiStepsHistory.saga'
import watchBulkDeleteCustomerAsync from './deleteBulkCustomer.saga'
import watchGetRuleSetsHistoryAsync from './getRuleSetsHistory.saga'
import watchDeleteTruckTypeAsync from './deleteTruckType.saga'
import watchGetSpecialServiceHistoryAsync from './getSpecialServiceHistory.saga'
import watchGetTruckTypesHistoryAsync from './getTruckTypesHistory.saga'
import watchReturnVersionRuleAsync from './returnVersionRuke.saga'
import watchReturnVersionMultistepAsync from './returnVersionMultistep.saga'
import watchReturnRulesetAsync from './returnRuleset.saga'
import watchupdateBulkFuelPriceAsync from './updateBulkFuelPrice.saga'
import watchGetMarketsAsync from './getMarkets.saga'
import watchPatchQuoteAsync from './patchQuote.saga'
import watchGetAllStatistcsAsync from './getStatistics.saga'
import watchGetTotalStatistcsAsync from './getTotalStatistics.saga'
import watchSaveStopsAsync from './saveStops.saga'
import watchGetGsRateAsync from './getGsRate.saga'
import watchGetMasterRulesByParamsAsync from './getMasterRulesByParams.saga'
import watchDeleteBetaRulesAsync from './deleteBetaRules.saga'
import watchSwitchBetaRulesAsync from './switchBetaRules.saga'
import watchGetRulesByCustomerAsync from './getRulesByCustomer.saga'
import watchGetMultiStepsByCustomerAsync from './getMultiStepsByCustomer.saga'
import watchSaveSelectedCustomerAsync from './saveSelectedCustomer.saga'
import watchRefreshTokenAsync from './refreshToken.saga'

export default function* rootSaga() {
  
  yield all([
    watchAssignTrustPercentageAsync(),
    watchGetTrustPercentagesAsync(),
    watchGetRulesAsync(),
    watchGetRouteDataAsync(),
    watchGetRateAsync(),
    watchGetMasterRulesAsync(),
    watchGetMultiStepsAsync(),
    watchGetRuleSetsAsync(),
    watchGetRulesetByIdAsync(),
    watchGetCustomersAsync(),
    watchGetAllRatesAsync(),
    watchGetAllRulesAsync(),
    watchGetAllCustomersAsync(),
    watchGetSpecialServicesAsync(),
    watchGetSpecialServicesListAsync(),
    watchCreateSpecialServiceAsync(),
    watchGetTruckTypesAsync(),
    watchGetTruckTypeListAsync(),
    watchTruckTypeAsync(),
    watchCreateRatesAsync(),
    watchGetUsersAsync(),
    watchLoginAsync(),
    watchCreateMultiStepAsync(),
    watchCreateRuleSetAsync(),
    watchCreateCustomerAsync(),
    watchForgotPasswordAsync(),
    watchResetPasswordAsync(),
    watchGetRolesAsync(),
    watchCreateUserAsync(),
    watchCreateRuleAsync(),
    watchGetGeographicReferenceAsync(),
    watchGetRuleTypeAsync(),
    watchGetShippingTypeAsync(),
    watchGetSingleUserAsync(),
    watchEditUserAsync(),
    watchGetDatHisotryAsync(),
    watchUpdateMultiStepAsync(),
    watchUpdateSpecialServiceAsync(),
    watchUpdateTruckTypeAsync(),
    watchUpdateTrustPercentageAsync(),
    watchUpdateRulesetAsync(),
    watchUpdateRuleAsync(),
    watchAssignCustomersAsync(),
    watchDeleteMultiStepAsync(),
    watchDeleteSpecialServiceAsync(),
    watchDeleteTruckTypeAsync(),
    watchDeleteRuleAsync(),
    watchDeleteRulesetAsync(),
    watchDeleteRateAsync(),
    watchActivateUserAsync(),
    watctUserEditAdminAsync(),
    watchGetAutocompleteHereAsync(),
    watchValidateZipcodeAsync(),
    watchGetZipcodesAsync(),
    watchDeactivateUserAsync(),
    watchGetZipAutocompleteAsync(),
    watchGetCityFromZipAsync(),
    watchGetZipHereAsync(),
    watchSaveQuoteAsync(),
    watchGetCustomerAsync(),
    watchEditCustomerAsync(),
    watchActivateCustomerAsync(),
    watchDeleteCustomerAsync(),
    watchGetDownloadMultipleRatesAsync(),
    watchValidateStateAsync(),
    watchGetStatesAsync(),
    watchDefaultCustomerAsync(),
    watchGetDefaultCustomerAsync(),
    watchSendEmailAsync(),
    watchResendInviteAsync(),
    watchGetHistoryAsync(),
    watchGetRuleHistoryAsync(),
    watchUpdateStatusMultistepAsync(),
    watchUpdateStatusRuleAsync(),
    watchBulkDeleteRulesetAsync(),
    watchGetMultiStepsHistoryAsync(),
    watchBulkDeleteCustomerAsync(),
    watchGetRuleSetsHistoryAsync(),
    activateSpecialServiceRequestAsync(),
    watchGetSpecialServiceHistoryAsync(),
    watchGetTruckTypesHistoryAsync(),
    watchReturnVersionRuleAsync(),
    watchReturnVersionMultistepAsync(),
    watchReturnRulesetAsync(),
    watchupdateBulkFuelPriceAsync(),
    watchGetMarketsAsync(),
    watchPatchQuoteAsync(),
    watchGetAllStatistcsAsync(),
    watchGetTotalStatistcsAsync(),
    watchSaveStopsAsync(),
    watchGetGsRateAsync(),
    watchGetMasterRulesByParamsAsync(),
    watchDeleteBetaRulesAsync(),
    watchSwitchBetaRulesAsync(),
    watchGetRulesByCustomerAsync(),
    watchGetMultiStepsByCustomerAsync(),
    watchSaveSelectedCustomerAsync(),
    watchRefreshTokenAsync(),
  ])
}
