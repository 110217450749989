import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  trucktypes: [],
  totalRecords: 0,
  params: {},
}

export const { requestGetTruckTypeList, successGetTruckTypeList, failureGetTruckTypeList } = createActions({
  REQUEST_GET_TRUCK_TYPE_LIST: (params) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    params,
  }),
  SUCCESS_GET_TRUCK_TYPE_LIST: (trucktypes) => ({
    trucktypes: trucktypes.data,
    totalRecords: trucktypes.totalRecords,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_TRUCK_TYPE_LIST: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
})

const getTruckTypeList = handleActions(
  {
    [requestGetTruckTypeList]: produce((draft, { payload: { fetching, error, success } }) => {
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successGetTruckTypeList]: produce(
      (draft, { payload: { message, fetching, success, trucktypes, totalRecords, error } }) => {
        draft.message = message
        draft.fetching = fetching
        draft.success = success
        draft.error = error
        draft.trucktypes = trucktypes
        draft.totalRecords = totalRecords
      }
    ),
    [failureGetTruckTypeList]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = !error
    }),
  },
  defaultState
)

export default getTruckTypeList
