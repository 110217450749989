import styled from 'styled-components'
import Typography from 'antd/lib/typography'

const { Text } = Typography

export const Container = styled.div`
  width: 100%;
  margin: 0px;
  @media screen and (min-width: 992px) {
    padding: 1rem;
  }
`

export const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: auto;
  margin-top: 34px;
  margin-bottom: 25px;
`

export const StyledLabel = styled(Text)`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #545454;
`
