import { removeRulesNoFlatAndMarginZero } from "utils/filters"

export const getMinGraph = (dataGrap = [], extra = 0) => {
  let min = extra;
  const size = dataGrap.length;
  for (let index = 0; index < size; index += 1) {
    const element = dataGrap[index];
    if (element.y) {
      const value = Number(element.y);
      if (index === 0 && extra === 0) min = value;
      if (value < min) min = value;
    }
  }
  return min;
};

export const getMinGraphing = (data = []) => {
  const size = data.length;
  let min = 0;
  for (let index = 0; index < size; index += 1) {
    const element = data[index];
    min = getMinGraph(element?.data ?? [], min);
  }
  return min;
};

export const getMaxGraph = (dataGrap = [], extra = 0) => {
  let max = extra
  const size = dataGrap.length
  for (let index = 0; index < size; index += 1) {
    const element = dataGrap[index]
    const value = Number(element.y)
    if (index === 0 && extra === 0) max = value
    if (value > max) max = value
  }
  return max
}

export const getMaxGraphing = (data = []) => {
  const size = data.length
  let max = 0
  for (let index = 0; index < size; index += 1) {
    const element = data[index]
    max = getMaxGraph(element?.data ?? [], max)
  }
  return max
}


export const getIdsFromCustomers = (array = []) => {
  const result = []
  array.forEach(({ id }) => {
    result.push(id._id)
  })
  return result
}

const retunrAllRulesFiltered = (ruleAdjustments = [], multiSteptAdjustments = []) => {
  const clearRulesAdjustments = removeRulesNoFlatAndMarginZero(ruleAdjustments)
  const clearMultiSteps = removeRulesNoFlatAndMarginZero(multiSteptAdjustments)
  return [...clearRulesAdjustments, ...clearMultiSteps]
}

export const getTotalMarginFromRules = (ruleAdjustments = [], multiSteptAdjustments = []) => {
  const allRules = retunrAllRulesFiltered(ruleAdjustments,multiSteptAdjustments)
  const totalMargin = allRules.reduce((a, b) => {
    const a1 = a
    const b1 = b.margin ?? 0
    return a1 + b1
  }, 0)
  return totalMargin
}

export const getTotalFlatFromRules = (ruleAdjustments = [], multiSteptAdjustments = []) => {   
  const allRules = retunrAllRulesFiltered(ruleAdjustments,multiSteptAdjustments)
  const totalFlat = allRules.reduce((a, b) => {
    const a1 = a
    const b1 = b.flat ?? 0
    return a1 + b1
  }, 0)
  return totalFlat
}

export const getMilles = (milles = 0, stops = []) => {
  const total = milles ?? 0
  const useStops = stops.length > 0 ? stops[0].miles : 0
  return total === 0 && stops ? useStops : total
}



