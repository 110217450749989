import React from 'react'
import { AiFillWarning } from 'react-icons/ai'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Typography from 'antd/lib/typography'
import StyleModal from './styles'

export default function ModalError({ visible = false, close = () => {}, message = '' }) {
  const { Text } = Typography
  const onCancel = () => {
    close()
  }
  return (
    <StyleModal
      transitionName=""
      footer={null}
      visible={visible}
      height="200px"
      width={350}
      centered
      onCancel={onCancel}
    >
      <Row gutter={[16, 14]} style={{ padding: '37px 20px 20px 20px' }}>
        <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '50px',
              height: '50px',
              backgroundColor: '#3594FF',
              borderRadius: '8px',
            }}
          >
            <AiFillWarning fontSize="35px" style={{ color: '#FFFFFF' }} />
          </div>
        </Col>
        <Col
          span={24}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#545454',
            fontWeight: '400',
            fontSize: '16px',
            textAlign: 'center',
          }}
        >
          <Text>{message}</Text>
        </Col>
      </Row>
    </StyleModal>
  )
}
