import React from 'react'
import { Typography } from 'antd'
import { StyledTexTitle, StyledTextDescription, StyledStep } from '../styles'
import AvatarItem from './avatar'
import { generateTitle, toHumanDate } from './cleanData'

const { Text } = Typography

export default function StepItem({ HistoryItem = {}, category = '' }) {
  const { name, action, editedFields, updatedAt } = HistoryItem
  return (
    <StyledStep
      prefixCls="ant-steps"
      iconPrefix="ant"
      title={
        <p style={{ lineHeight: '0.5 !important' }}>
          <StyledTexTitle>{name}</StyledTexTitle>{' '}
          <StyledTextDescription style={{ lineHeight: '0.5 !important' }}>
            {generateTitle(action, editedFields, category)}
          </StyledTextDescription>
        </p>
      }
      description={<Text>{toHumanDate(updatedAt)}</Text>}
      icon={<AvatarItem userName={name} />}
    />
  )
}
