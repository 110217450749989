import PropTypes from 'prop-types'
import AddMultistep from './AddMultistep'

AddMultistep.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  multiSelected: PropTypes.array.isRequired,
}

export default AddMultistep
