import styled from 'styled-components'
import { AntdTable } from 'components/Table/styles'

const AntTableRuleSets = styled(AntdTable)`
  .ant-table-thead > tr > th {
    font-size: 16px;
  }

  .ant-table-row .ant-table-cell:first-child,
  .ant-table-thead th:first-child,
  .ant-table-expanded-row .ant-table-cell {
    padding-left: 16px;
  }

  .ant-table-expanded-row .ant-table-cell {
    height: fit-content;
  }
  .ant-table-expanded-row .ant-table-cell:hover {
    height: fit-content;
  }
`

export default AntTableRuleSets
