import { put, takeLatest, call } from 'redux-saga/effects'
import { rates } from 'services'
import { requestDeleteRate, successDeleteRate, failureDeleteRate } from '../duck/deleteRate.duck'

function* deleteRateAsync(data) {
  try {
    const response = yield call(rates.deleteRate, data.payload.itemId)
    yield put(successDeleteRate(response))
  } catch (error) {
    yield put(failureDeleteRate(error))
  }
}

function* watchDeleteRateAsync() {
  yield takeLatest(requestDeleteRate, deleteRateAsync)
}

export default watchDeleteRateAsync
