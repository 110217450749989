import React from 'react'
import { requestUpdateRuleset } from 'store/duck/updateRuleset.duck'
import Typography from 'antd/lib/typography'
import PriceLabel from 'components/Label/PriceLabel'
import DeleteIcon from 'components/Icon/DeleteIcon'
import moment from 'moment'

const { Text } = Typography

const columns = (dispatch, shippingType, rulesId, rulesetId) => {
  const handleDeleteRuleInRuleset = (recordId) => {
    const udateRules = rulesId.filter((e) => e !== recordId)
    const data = {
      id: rulesetId,
      rules: [...udateRules],
    }
    dispatch(requestUpdateRuleset(data))
  }
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
      width: 120,
      ellipsis: true,
    },
    {
      title: shippingType === 'outbound' ? 'Pick Up' : 'Delivery',
      dataIndex: 'shippingType',
      key: 'shippingType',
      render: () => {
        return <Text>{shippingType === 'outbound' ? 'Pick Up' : 'Delivery'}</Text>
      },
      width: 80,
      ellipsis: true,
    },
    {
      title: 'Description',
      dataIndex: ['rule', 'description'],
      key: 'description',
      render: (description) => {
        return <Text>{description}</Text>
      },
      width: 120,
      ellipsis: true,
    },
    {
      title: 'If Date / Range is',
      dataIndex: ['rule', 'dateInit'],
      key: 'dateInit',
      colSpan: 3,
      render: (dateInit) => {
        return <Text>{moment(dateInit).format('MM-DD-YYYY')}</Text>
      },
      width: 80,
      ellipsis: true,
    },
    {
      title: '',
      dataIndex: 'to',
      key: 'to',
      colSpan: 0,
      render: () => {
        return <PriceLabel>to</PriceLabel>
      },
      width: 20,
      ellipsis: true,
    },
    {
      title: '',
      dataIndex: ['rule', 'dateEnd'],
      key: 'dateEnd',
      colSpan: 0,
      render: (dateEnd) => {
        return <Text>{moment(dateEnd).format('MM-DD-YYYY')}</Text>
      },
      width: 80,
      ellipsis: true,
    },
    {
      title: 'Percentage',
      dataIndex: ['rule', 'margin'],
      key: 'margin',
      render: (margin) => {
        return <PriceLabel>{margin} %</PriceLabel>
      },
      width: 75,
      ellipsis: true,
    },
    {
      title: 'Flat',
      dataIndex: ['rule', 'flat'],
      key: 'flat',
      render: (flat) => {
        return <PriceLabel>{flat}</PriceLabel>
      },
      width: 75,
      ellipsis: true,
    },
    {
      title: '',
      dataIndex: 'del',
      key: 'del',
      render: (del, record) => {
        return <DeleteIcon onClick={() => handleDeleteRuleInRuleset(record._id)} />
      },
      width: 50,
      align: 'center',
    },
  ]
}

export default columns
