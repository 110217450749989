import styled from 'styled-components'
import Tabs from 'antd/lib/tabs'

const StyledTabs = styled(Tabs)`
  height: ${(props) => props.height};  
  .ant-tabs-tab {
    padding-bottom: 2px;
    &.ant-tabs-tab-active .ant-tabs-tab-btn {
      font-weight: 700;
    }
  }
`

export default StyledTabs
