import styled from 'styled-components'
import Row from 'antd/lib/row'

const StyledRow = styled(Row)`
  margin-top: 1rem;
  margin-bottom: 20px;
`

export const CustomerContainer = styled(Row)`
  width: 96%;
  margin: 10px auto 15px;
`
export default StyledRow
