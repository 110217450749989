import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  successDelete: false, 
  data: {},
}

export const { requestDeleteBetaRules, successDeleteBetaRules, failureDeleteBetaRules, clearDeleteBetaRules } =
  createActions({
    REQUEST_DELETE_BETA_RULES: () => ({
      ...defaultState,
      fetching: true,
    }),
    SUCCESS_DELETE_BETA_RULES: (message) => ({
      message,
      fetching: false,
      error: false,
      successDelete: true,
    }),
    FAILURE_DELETE_BETA_RULES: (message) => ({
      message,
      fetching: false,
      error: true,
      successDelete: false,
    }),
    CLEAR_DELETE_BETA_RULES: () => ({
      ...defaultState,
    }),
  })

const deleteBetaRules = handleActions(
  {
    [requestDeleteBetaRules]: produce((draft, { payload: { fetching } }) => {
      draft.fetching = fetching 
    }),
    [successDeleteBetaRules]: produce((draft, { payload: { message, fetching, successDelete } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.successDelete = successDelete 
    }),
    [failureDeleteBetaRules]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
    }),
    [clearDeleteBetaRules]: produce(
      (draft, { payload: { message, fetching, error, successDelete } }) => {
        draft.message = message
        draft.fetching = fetching
        draft.error = error
        draft.successDelete = successDelete 
      }
    ),
  },
  defaultState
)

export default deleteBetaRules
