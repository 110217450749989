import { put, takeLatest, call } from 'redux-saga/effects'
import { customers } from 'services'
import {
  requestDefaultCustomer,
  successDefaultCustomer,
  failureDefaultCustomer,
} from 'store/duck/defaultCustomer.duck'

function* defaultCustomerRequestAsync(data) {
  try {
    const response = yield call(customers.setDefault, data.payload.data)
    yield put(successDefaultCustomer(response, 'success'))
  } catch (error) {
    yield put(failureDefaultCustomer(error.response.data.message))
  }
}

function* watchDefaultCustomerAsync() {
  yield takeLatest(requestDefaultCustomer, defaultCustomerRequestAsync)
}

export default watchDefaultCustomerAsync
