import styled from 'styled-components'

export const StyledContainer = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  margin-top: 2rem;
  padding: 0 5rem 0 5rem;
`

export const StyledHeader = styled.div`
  display: flex;
  width: 100%;
`
export const StyledSubHeader = styled.div`
  width: 100%;
  margin-top: 1.4rem;
  height: 3rem;
`

export const StyledExtremeLeftHeader = styled.div`
  position: relative;
  right: 101%;
  top: 5px;
  svg {
    cursor: pointer;
  }
`

export const StyledRightHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
`

export const StyledTitleHeader = styled.div`
  color: #002555;
  font-weight: 700;
  font-size: 20px;
`

export const StyledContent = styled.div`
  width: 100%;
  margin-top: 1.5rem;
`
