import React from 'react'
import Tooltip from 'antd/lib/tooltip'
import { IoIosAdd } from 'react-icons/io'
import Button from './styles'

const ButtonAdd = ({ onClick, size, children, element, btnType, title }) => {
  return (
    <Tooltip title={title}>
      <span>
        <Button
          htmlType={btnType}
          size={size}
          shape="circle"
          onClick={onClick}
          icon={<IoIosAdd size="1.9rem" />}
          element={element}
        >
          {children}
        </Button>
      </span>
    </Tooltip>
  )
}

export default ButtonAdd
