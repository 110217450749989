import React from 'react'
import moment from 'moment'
import PriceLabel from 'components/Label/PriceLabel'
import DateLabel from 'components/Label/DateLabel'
import { getStatus } from 'utils/getStatus'
import { StyledBadge } from 'containers/rules/ListMultiStep/utils/styles'
import actionsDropdown from './ActionsDropdown'

export default function (dispatch, openDrawer) {

  return [
    {
      title: <div style={{fontSize: '16px'}}>Name</div>,
      dataIndex: 'name',
      key: 'name',       
      align: 'left',
      width: 130,
      ellipsis: true,
      render: (name) => {
        return <div style={{fontSize: '16px', display: 'flex'}}>{name}</div>
      },
    },
    {
      title: <div style={{fontSize: '16px'}}>Create Date</div>,
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120,
      ellipsis: true,
      align: 'left',
      render: (createdAt) => {
        return (
          <div style={{fontSize: '16px', display: 'flex'}}>
            <DateLabel date={moment(createdAt).format('MM/DD/YYYY')}   />
          </div>
        )
      },
    },
    {
      title: <div style={{fontSize: '16px'}}>Description</div>,
      dataIndex: 'description',
      key: 'description',
      align: 'left',
      width: 120,
      ellipsis: true,
      render: (description) => {
        return <div style={{fontSize: '16px', display: 'flex'}}>{description}</div>
      }, 
    },
    {
      title: <div style={{fontSize: '16px'}}>Price</div>,
      dataIndex: 'price',
      key: 'price',
      render: (price) => {
        return <PriceLabel style={{fontSize: '16px', display: 'flex'}}>$ {price}</PriceLabel>
      },       
      width: 100,
      align: 'left',
    },
    {
      title:  <div style={{fontSize: '16px'}}>Status</div>,
      dataIndex: 'active',
      key: 'active',
      render: (active, record) => { 
        return <div style={{ display: 'flex' }}><StyledBadge variant={getStatus(record.status, active)} /></div>
      },
      width: 90,
      align: 'left',
    },
    {
      ...actionsDropdown(dispatch,openDrawer),
    },
   
  ]
}
