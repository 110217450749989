import { put, takeLatest, call } from 'redux-saga/effects'
import { customers } from 'services'
import {
  requestDeleteBulkCustomer,
  successDeleteBulkCustomer,
  failureDeleteBulkCustomer,
} from '../duck/deleteBulkCustomer.duck'

function* deleteBulkCustomerAsync(data) {
  try {
    const response = yield call(customers.deleteCustomer, data.payload.itemId)
    yield put(successDeleteBulkCustomer(response))
  } catch (error) {
    yield put(failureDeleteBulkCustomer(error))
  }
}

function* watchBulkDeleteCustomerAsync() {
  yield takeLatest(requestDeleteBulkCustomer, deleteBulkCustomerAsync)
}

export default watchBulkDeleteCustomerAsync
