import React, { useEffect, useState } from 'react'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Form from 'antd/lib/form'
import Select from 'antd/lib/select'
import Checkbox from 'antd/lib/checkbox'
import Text from 'antd/lib/typography/Text'
import notify from 'utils/notify'
import jsonDiff from 'utils/jsonDiff'
import { SPECIAL_SERVICE } from 'utils/constants'
import { requiredDescription, stringRules } from 'utils/inputRules'
import { useDispatch, useSelector } from 'react-redux'
import { resetGetCustomer } from 'store/duck/getCustomer.duck'
import { requestGetSpecialServiceHistory } from 'store/duck/getSpecialServiceHistory.duck'
import { clearCreateSpecialService, requestCreateSpecialService } from 'store/duck/createSpecialService.duck'
import { clearUpdateSpecialService, requestUpdateSpecialService } from 'store/duck/updateSpecialService.duck'
import StyledTabs from 'components/Tabs'
import TitleDrawer from 'components/TitleDrawer'
import HistoryItems from 'components/HistoryItem'
import TextInput from 'components/Form/TextInput'
import DrawerItem from 'components/Form/DrawerItem'
import NumberInput from 'components/Form/NumerInput'
import PrimarySubmit from 'components/PrimarySubmit'
import SelectInput from 'components/Form/SelectInput'
import TextAreaInput from 'components/Form/TextAreaInput'
import { StyledModal } from 'containers/rules/ListMultiStep/utils/CreateMultistepRule/styles'
import { MdClose, MdAttachMoney } from 'react-icons/md'
import TMSsystems from './utils/TMSsystems'

const formName = 'create-special-service'

const defaultValues = {
  name: '',
  description: '',
  price: 1,
  isStop: false,
  stops: 1,
}

const CreateForm = ({ onClose = () => '' }) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const {
    success,
    error,
    message,
    editSpecialSer: editSpecialService,
    specialSerSelected: specialServiceSelected,
  } = useSelector((state) => state.createSpecialService)
  const {
    success: successUpdate,
    error: errorUpdate,
    message: msmUpdate,
  } = useSelector((state) => state.updateSpecialService)
  const [loading, setLoading] = useState(false)
  const [availableStops, setAvailableStops] = useState(false)
  const [mappings, setMappings] = useState([])

  const onFormSubmit = (data) => {
    setLoading(true)
    const specialServiceData = {
      name: data.name,
      description: data.description,
      price: data.price,
      stops: data.isStop ? data.stops : 0,
      mappings,
    }

    if (editSpecialService) {
      const nextData = jsonDiff(
        {
          name: specialServiceSelected.name,
          description: specialServiceSelected.description,
          price: parseInt(specialServiceSelected.price, 10),
          stops: specialServiceSelected.stops,
          mappings: specialServiceSelected.mappings ?? [],
        },
        specialServiceData
      )

      dispatch(
        requestUpdateSpecialService({
          ...nextData,
          id: specialServiceSelected._id,
        })
      )
    } else {
      dispatch(requestCreateSpecialService(specialServiceData))
    }
  }

  const handleValuesChange = (changedValues) => {
    if (changedValues.isStop !== undefined) setAvailableStops(changedValues.isStop)
  }

  useEffect(() => {
    if (editSpecialService) {
      const serviceIsStop = specialServiceSelected.stops > 0

      form.setFieldsValue({
        name: specialServiceSelected.name,
        description: specialServiceSelected.description,
        price: parseInt(specialServiceSelected.price, 10),
        stops: specialServiceSelected.stops || 1,
        isStop: serviceIsStop,
      })
      setAvailableStops(serviceIsStop)
      setMappings(specialServiceSelected.mappings ?? [])
    }
  }, [editSpecialService, form, specialServiceSelected])

  useEffect(() => {
    if (success || error) {
      if (success) {
        notify('success', 'Special service created successfully!')
      }
      if (error) {
        notify('error', `Error creating special service! ${message}`)
      }

      setLoading(false)
      dispatch(clearCreateSpecialService())
      onClose()
    }
  }, [dispatch, error, message, success, onClose])

  useEffect(() => {
    if (successUpdate || errorUpdate) {
      if (errorUpdate) {
        notify('error', `Error updating special service! ${msmUpdate}`)
      }
      if (successUpdate) {
        notify('success', 'Special service updated successfully!')
      }

      setLoading(false)
      dispatch(clearUpdateSpecialService())
      onClose()
    }
  }, [dispatch, errorUpdate, msmUpdate, successUpdate, onClose])

  return (
    <>
      <Row justify="space-between" style={{ marginBottom: '14px' }}>
        <Col>
          <Text style={{ color: '#545454', fontWeight: 400, fontSize: '16px', marginBottom: '16' }}>
            {editSpecialService
              ? 'Edit the information of this new special service.'
              : 'Add the information of this new special service'}
          </Text>
        </Col>
      </Row>

      <Form
        form={form}
        layout="vertical"
        name={formName}
        initialValues={defaultValues}
        onFinish={onFormSubmit}
        onValuesChange={handleValuesChange}
        style={{ marginTop: 6 }}
        hideRequiredMark
      >
        <Row gutter={[26, 10]}>
          <Col span={24}>
            <TextInput data-testid="Name" label="Name *" name="name" rules={stringRules('special service name')} />
          </Col>
          <Col span={24}>
            <TextAreaInput data-testid="Description" label="Description *" name="description" rules={requiredDescription} />
          </Col>
          <Col span={24}>
            <TMSsystems mappings={mappings} handleMappings={setMappings} />
          </Col>
          <Col span={24}>
            <DrawerItem name="isStop" label="" valuePropName="checked" style={{ marginBottom: 15 }}>
              <Checkbox>
                <Text style={{ color: '#545454', fontWeight: 400, fontSize: '16px' }}>
                  This service is a way point
                </Text>
              </Checkbox>
            </DrawerItem>
          </Col>
          {availableStops && (
            <Col span={12}>
              <SelectInput label="Select the way points *" name="stops" rules={[{ required: true }]}>
                <Select.Option value={1}>1</Select.Option>
                <Select.Option value={2}>2</Select.Option>
                <Select.Option value={3}>3</Select.Option>
                <Select.Option value={4}>4</Select.Option>
              </SelectInput>
            </Col>
          )}
          <Col span={12}>
            <NumberInput
              defaultValue={1}
              name="price"
              label="Price *"
              prefix={<MdAttachMoney />}
              rules={[
                { required: true, message: 'Please enter a price' },
                {
                  type: 'number',
                  max: 9999,
                  message: 'The price should not be greater than 9999',
                  transform: Number,
                },
                {
                  async validator(_, value) {
                    if (value !== '' && value < 1) {
                      throw new Error('The price should not be less than 1')
                    }
                  },
                },
              ]}
            />
          </Col>
          <Col span={24}>
            <div style={{ marginBottom: 18 }}>
              <PrimarySubmit
                label={editSpecialService ? 'Edit Special Service' : 'Save Special Service'}
                formName={formName}
                loading={loading}
              />
            </div>
          </Col>
        </Row>
      </Form>
    </>
  )
}

const CreateSpecialServiceModal = ({ visible, onClose }) => {
  const dispatch = useDispatch()
  const { editSpecialSer: editSpecialService, specialSerSelected: specialServiceSelected } = useSelector(
    (state) => state.createSpecialService
  )
  const { history } = useSelector((state) => state.getSpecialServiceHistory)

  const [activeKeyEditTab, setActiveKeyEditTab] = useState('1')

  useEffect(() => {
    if (editSpecialService && visible) {
      dispatch(requestGetSpecialServiceHistory({ id: specialServiceSelected._id }))
    }
  }, [dispatch, editSpecialService, specialServiceSelected, visible])

  return (
    <StyledModal
      width={500}
      title={<TitleDrawer title={editSpecialService ? 'Edit Special Service' : 'Create New Special Service'} />}
      visible={visible}
      bodyStyle={{ padding: editSpecialService ? '0px 25px' : '10px 25px 0px' }}
      onCancel={() => onClose()}
      footer={null}
      destroyOnClose
      afterClose={() => {
        dispatch(resetGetCustomer())
      }}
      closeIcon={<MdClose size={24} color="#000000" />}
    >
      {editSpecialService ? (
        <StyledTabs
          activeKey={activeKeyEditTab}
          onChange={(newActiveKey) => setActiveKeyEditTab(newActiveKey)}
          tabBarStyle={{ width: '100%' }}
        >
          <StyledTabs.TabPane tab="Special Services Info" key="1">
            <CreateForm onClose={() => onClose()} />
          </StyledTabs.TabPane>
          <StyledTabs.TabPane tab="History" key="2">
            <HistoryItems data={history} category={SPECIAL_SERVICE} />
          </StyledTabs.TabPane>
        </StyledTabs>
      ) : (
        <CreateForm onClose={() => onClose()} />
      )}
    </StyledModal>
  )
}

export default CreateSpecialServiceModal
