import styled from 'styled-components'
import Typography from 'antd/lib/typography'

const { Text } = Typography

export const StyledText = styled(Text)`
  color: #545454 !important;
  font-weight: 400 !important;
`
export const StyledDiv = styled.div`
  color: '#F7981C';
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const MainDiv = styled.div`
  display: flex;
  align-items: center; 
`

export const TextMaster = styled(Text)`
  font-size: 12px;
  color: #f7981c;
`
