import styled from 'styled-components'
import Select from 'antd/lib/select'

const SelectOption = styled(Select.Option)`
  background-color: white;
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 4px 11px;
  font-size: 14px;
  z-index: 200;
`

export default SelectOption
