import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: {},
  editRuleset: false,
  rulesetSelected: {},
}

export const {
  requestCreateRuleset,
  successCreateRuleset,
  failureCreateRuleset,
  clearCreateRuleset,
  loadRuleset,
  addRuleset,
} = createActions({
  REQUEST_CREATE_RULESET: (data) => ({
    ...defaultState,
    fetching: true,
    data,
  }),
  SUCCESS_CREATE_RULESET: (data, message) => ({
    data,
    message,
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_CREATE_RULESET: (message) => ({
    message,
    fetching: false,
    error: true,
  }),
  CLEAR_CREATE_RULESET: () => ({
    ...defaultState,
  }),
  LOAD_RULESET: (data) => ({
    editRuleset: true,
    rulesetSelected: data,
  }),
  ADD_RULESET: () => ({
    editRuleset: false,
  }),
})

const createRuleset = handleActions(
  {
    [requestCreateRuleset]: produce((draft, { payload: { message, fetching, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.data = data
    }),
    [successCreateRuleset]: produce((draft, { payload: { message, fetching, success, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.data = data
    }),
    [failureCreateRuleset]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
    }),
    [clearCreateRuleset]: produce((draft, { payload: { message, fetching, success, data, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.data = data
      draft.error = error
    }),
    [loadRuleset]: produce((draft, { payload: { editRuleset, rulesetSelected } }) => {
      draft.editRuleset = editRuleset
      draft.rulesetSelected = rulesetSelected
    }),
    [addRuleset]: produce((draft, { payload: { editRuleset } }) => {
      draft.editRuleset = editRuleset
    }),
  },
  defaultState
)

export default createRuleset
