import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  field: '',
  data: [],
}

export const { requestGetStates, successGetStates, failureGetStates } = createActions({
  REQUEST_GET_STATES: (data) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    data,
  }),
  SUCCESS_GET_STATES: (data) => ({
    data,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_STATES: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
})

const states = handleActions(
  {
    [requestGetStates]: produce((draft, { payload: { fetching, error, success, data } }) => {
      draft.fetching = fetching
      draft.error = error
      draft.success = success
      draft.field = data
    }),
    [successGetStates]: produce((draft, { payload: { message, fetching, success, data, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data.map((entry) => {
        return {
          label: `${entry.name}, ${entry.abbreviation}`,
          value: entry.name,
          abbreviation: entry.abbreviation,
        }
      })
    }),
    [failureGetStates]: produce((draft, { payload: { message, fetching, error, success } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
  },
  defaultState
)

export default states
