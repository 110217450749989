import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  field: '',
  data: {},
}

export const { requestValidateZipcode, successValidateZipcode, failureValidateZipcode } = createActions({
  REQUEST_VALIDATE_ZIPCODE: (data, field) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    data,
    field,
  }),
  SUCCESS_VALIDATE_ZIPCODE: (data) => ({
    data,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_VALIDATE_ZIPCODE: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
})

const zipcodes = handleActions(
  {
    [requestValidateZipcode]: produce((draft, { payload: { fetching, error, success, data, field } }) => {
      draft.fetching = fetching
      draft.error = error
      draft.success = success
      draft.field = field
      draft.data = data
    }),
    [successValidateZipcode]: produce((draft, { payload: { message, fetching, success, data, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data
    }),
    [failureValidateZipcode]: produce((draft, { payload: { message, fetching, error, success } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
  },
  defaultState
)

export default zipcodes
