import React from 'react'
import Typography from 'antd/lib/typography'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Spin from 'antd/lib/spin'
import { StyleModal, SpinAnimated } from './styles'

export default function ModalRates({ onCancel, visible = false, messageBody = '', height = 160, width = 350 }) {
  const { Text } = Typography
  return (
    <StyleModal
      transitionName=""
      footer={null}
      visible={visible}
      height={height}
      width={width}
      centered
      onCancel={onCancel}
    >
      <Row gutter={[16, 14]} style={{ padding: '37px 20px 20px 20px' }}>
        <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '40px',
              height: '40px',
              backgroundColor: '#3594FF',
              borderRadius: '8px',
            }}
          >
            <Spin indicator={<SpinAnimated />} />
          </div>
        </Col>
        <Col
          span={24}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#545454',
            fontWeight: '400',
            fontSize: '16px',
            textAlign: 'center',
          }}
        >
          <Text>{messageBody}</Text>
        </Col>
      </Row>
    </StyleModal>
  )
}
