import React, { useCallback } from 'react'
import Spin from 'antd/lib/spin'
import DrawerItem from 'components/Form/DrawerItem'
import ControlInput from 'components/Form/ControlInput'
import InputWithLabel from 'components/Form/InputWithLabel'
import { debounce } from 'lodash'
import CustomSelect from 'components/CustomSelect'

const DropdownApiInput = ({
  label,
  formProps,
  inputProps,
  loading,
  onSearch = (some) => some,
  onChange: onChangeOption,
  onPressEnter,
  disabled = false,
  avoidDebounce = false
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnSearch = useCallback(
    debounce((text) => onSearch(text), 400),
    [onSearch]
  )

  return (
    <InputWithLabel label={label} floatLabel>
      {(hasValue) => (
        <DrawerItem
          style={{ marginBottom: 0 }}
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...formProps}
        >
          <ControlInput hasValue={hasValue}>
            {({ value, onChange }) => (
              <CustomSelect
                disabled={disabled}
                allowClear
                showSearch
                size="large"
                defaultActiveFirstOption={false}
                notFoundContent={loading ? <Spin size="small" /> : null}
                onSearch={(text) => avoidDebounce ? {} :
                  debouncedOnSearch(text)
                }
                value={value?.value}
                onChange={(_, option) => {
                  onChange(option)
                  if (typeof onChangeOption !== 'undefined') {
                    onChangeOption(option)
                  }
                }}
                onInputKeyDown={({ keyCode }) => {
                  if (keyCode === 13 && onPressEnter) {
                    onPressEnter(value)
                  }
                }}
                filterOption={false}
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...inputProps}
              />
            )}
          </ControlInput>
        </DrawerItem>
      )}
    </InputWithLabel>
  )
}

export default DropdownApiInput
