import PropTypes from 'prop-types'
import TitleTextEdit from './TitleTextEdit'

TitleTextEdit.propTypes = {
  title: PropTypes.string.isRequired,
  subText: PropTypes.string,
  handleEditText: PropTypes.func.isRequired,
}

TitleTextEdit.defaultProps = {
  subText: '',
}

export default TitleTextEdit
