import React from 'react'
import { useDispatch } from 'react-redux'
import Collapse from 'antd/lib/collapse'
import ArrowIcon from 'components/Icon/ArrowIcon'
import ButtonAdd from 'components/Button/ButtonAdd'
import Table from 'components/Table'
import columns from './utils/TableColumns'

const LoadDateRangeRule = ({ handleFetchRules, rules, rulesetData, shippingData }) => {
  const dispatch = useDispatch()

  const fetchRules = (ruleType, title, shipping, shippingId, currentRules) => {
    const ruleSelected = []
    currentRules.map((item) => {
      ruleSelected.push(item._id)
      return ruleSelected
    })
    handleFetchRules(ruleType, title, shipping, shippingId, ruleSelected)
  }

  return (
    <Collapse
      expandIcon={({ isActive }) => <ArrowIcon change={isActive ? '0deg' : '180deg'} size="1rem" />}
      defaultActiveKey={['1', '2']}
      ghost
    >
      <Collapse.Panel header="Pickup" key="1">
        {rules.pickup.length > 0 && (
          <Table
            columns={columns(dispatch, 'outbound', rulesetData.rulesId, rulesetData._id)}
            data={rules.pickup}
            loading={false}
            rowKey="_id"
            width="100%"
            padding="4px 2px"
          />
        )}
        <ButtonAdd
          element={'pickupNewRule'}
          onClick={() =>
            fetchRules('RuleDateRange', 'Range Date', 'pickup', shippingData.outShipping, rules.pickup)
          }
        >
          New Rule
        </ButtonAdd>
      </Collapse.Panel>
      <Collapse.Panel header="Delivery" key="2">
        {rules.delivery.length > 0 && (
          <Table
            columns={columns(dispatch, 'inbound', rulesetData.rulesId, rulesetData._id)}
            data={rules.delivery}
            loading={false}
            rowKey="_id"
            width="100%"
            padding="4px 2px"
          />
        )}
        <ButtonAdd
          element={'dispatchNewRule'}
          onClick={() =>
            fetchRules('RuleDateRange', 'Range Date', 'delivery', shippingData.inShipping, rules.delivery)
          }
        >
          New Rule
        </ButtonAdd>
      </Collapse.Panel>
    </Collapse>
  )
}

export default LoadDateRangeRule
