import styled from 'styled-components'
import Select from 'antd/lib/select'

const StyledSelectGraphic = styled(Select)`
  padding-top: 2px;
  width: 100%;

  .ant-select-arrow {
    color: #002555;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-select-selector {
    border-radius: 4px !important;
  }
  .ant-select-selector span {
    padding-left: 13px !important;
    padding-right: 0px !important;
  }

  .ant-select-selection-item {
    color: #545454;
    font-weight: 400;
  }
`

export const ContainerGraphics = styled.div`
  border: 1px solid rgba(217, 217, 217, 0.1);
  box-shadow: rgb(217 217 217 / 20%) 0px 10px 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  padding: 1rem;
`
export const HeaderGraph = styled.div`
  display: flex;
  justify-content: space-between;
  /* display:grid;
    grid-template-columns:1fr 1fr; */
`

export default StyledSelectGraphic
