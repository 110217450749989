/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import Col from 'antd/lib/col'
import FloatLabel from 'components/Label/FloatLabel'
import { FormItem } from 'components/Input'
import CustomSelect from 'components/CustomSelect'

export default function DropDownCustomers({ dropdownData = [], label = 'Customer', name = 'customer', ...props }) {
  return (
    <Col span={4} xs={5}>
      <FloatLabel label={label} show>
        <FormItem name={name}>
          <CustomSelect
            allowClear
            showSearch
            placeholder=""
            keysort="label"
            size="large"
            options={(dropdownData ?? []).map((item) => ({ value: item.id, label: item.value }))}
            {...props}
          />
        </FormItem>
      </FloatLabel>
    </Col>
  )
}
