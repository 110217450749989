import PropTypes from 'prop-types'
import TrustPercentageItem from './TrustPercentageItem'

TrustPercentageItem.propTypes = {
  itemLabel: PropTypes.string.isRequired,
  itemName: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  message: PropTypes.string,
}

TrustPercentageItem.defaultProps = {
  placeholder: '',
  message: 'Field required.',
}

export default TrustPercentageItem
