import styled from 'styled-components'
import Row from 'antd/lib/row'

export const StyledHead = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: auto;
  margin-top: 30px;
`

export const StyledRow = styled(Row)`
  width: 90%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 10px;
`

export const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
`

export const StyledButtonSection = styled.div`
  margin: 0 auto;
  width: 90%;
  display: flex;
  justify-content: flex-end;
`