import styled from 'styled-components'
import Row from 'antd/lib/row'

const StyledRow = styled(Row)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: '#002555',
  cursor: 'pointer',
  '&:hover':{
      color:'#3594FF'
  }
})

export default StyledRow
