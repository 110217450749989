import moment from 'moment'

const rangeManager = (firstDate, secondDate) => {
  const newDateOne = moment(new Date(firstDate)).format('YYYY-MM-DD')
  const newDateTwo = moment(new Date(secondDate)).format('YYYY-MM-DD')
  const rangeDate = `${newDateOne},${newDateTwo}`
  return rangeDate
}

export const editFormat = (entry) => {
  return moment(entry, "YYYY MM DD").format("YYYY-MM-DD")
}

export default rangeManager
