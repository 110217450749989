import styled from 'styled-components'
import Button from 'antd/lib/button'

export const ButtonSecondaryWhite = styled(Button)`
  background: ${(props) => props.theme.colors.white.default};
  border: none;
  border-radius: 2px;
  padding: 6px 10px;
  margin: 0px;
  margin-top: 2rem;
  color: #002555;
  font-size: 14px;
  font-weight: 700;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  &:hover {
    color: #002555;
  }
`
export const ButtonSecondaryBlack = styled(Button)`
  background: ${(props) => props.theme.colors.secondary_1.default};
  &:hover {
    background: ${(props) => props.theme.colors.secondary_1.hover};
  }
  &:focus {
    background: ${(props) => props.theme.colors.secondary_1.focus};
  }
  border: none;
  color: white;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
`
export const ButtonSecondaryGrey = styled(Button)`
  background: ${(props) => props.theme.colors.secondary_2.default};
  &:hover {
    background: ${(props) => props.theme.colors.secondary_2.hover};
  }
  &:focus {
    background: ${(props) => props.theme.colors.secondary_2.focus};
  }
  border: none;
  color: white;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
`

export const ButtonSecondaryDefault = styled(Button)`
  background-color: white;
  border-radius: 4px;
  border: none;
  color: #3594ff;
  font-weight: 400;
  font-size: 16px;
  height: 40px;
  &:hover {
    background: white;
    color: #3594ff;
  }
  &:focus {
    background: white;
    color: #3594ff;
  }
`
