/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import { Typography } from 'antd'
import { TaskDone, Restore } from 'assets/svg'
import { useDispatch } from 'react-redux'
import Tooltip from 'antd/lib/tooltip'
import ModalConfirmation from 'components/ModalConfirmation'
import { StyledTexTitle, StyledTextDescription, StyledStep } from '../styles'
import AvatarItem from './avatar'
import { generateTitle, toHumanDate } from './cleanData'
import { ImgSvg, ImgIcon } from './style'

const { Text } = Typography

export default function StepItemRules({
  HistoryItem = {},
  category = '',
  id,
  request = (some) => some,
  large,
  enableEdit = true,
}) {
  const { name, action, editedFields, updatedAt, versionTag, [id]: ruleId, _id, revertedTo } = HistoryItem
  const dispatch = useDispatch()

  const returnVersion = () => {
    dispatch(request({ ruleId, historyId: _id }))
  }

  const currentVersion = versionTag === 'Current Version'
  const reverted = revertedTo ?? ''

  const [propsModal, setPropsModal] = useState({
    visible: false,
    message: 'Are you sure you want to restore at this Version?',
    // eslint-disable-next-line react/jsx-props-no-spreading
    icon: (props) => props,
    textButon: 'Restore',
    textSuccessfull: '',
    actionConfirm: (some) => some,
  })

  const throwModalConfiramtion = () => {
    setPropsModal({
      visible: true,
      message: 'Are you sure you want to restore at this Version?',
      // eslint-disable-next-line react/jsx-props-no-spreading
      icon: (props) => (
        <ImgIcon src={Restore} onClick={!currentVersion ? throwModalConfiramtion : null} {...props} />
      ),
      textButon: 'Restore',
      textSuccessfull: `Rule has been restored}`,
      actionConfirm: () => {
        returnVersion()
        setPropsModal({
          visible: false,
          message: ' ',
          icon: (props) => props,
          textButon: '',
          textSuccessfull: '',
          actionConfirm: (props) => props,
        })
      },
    })
  }

  return (
    <StyledStep
      prefixCls="ant-steps"
      iconPrefix="ant"
      title={<p>{versionTag ?? ''}</p>}
      subTitle={
        <div
          style={{
            display: 'flex',
          }}
        >
          <div
            style={{
              float: 'left',
              width: large ? '1200px' : '600px',
            }}
          >
            <ModalConfirmation
              avoidNotify
              actionConfirm={propsModal.actionConfirm}
              textSuccessfull={propsModal.textSuccessfull}
              textButton={propsModal.textButon}
              close={() => {
                setPropsModal({
                  visible: false,
                  message: ' ',
                  icon: (props) => props,
                  textButon: '',
                  textSuccessfull: '',
                  actionConfirm: (props) => props,
                })
              }}
              visible={propsModal.visible}
              height="296px"
              width={350}
              icon={propsModal.icon}
              message={propsModal.message}
              centered
            />
            <p style={{ lineHeight: '0.5 !important' }}>
              <StyledTexTitle>{name}</StyledTexTitle>{' '}
              <StyledTextDescription style={{ lineHeight: '0.5 !important' }}>
                {generateTitle(action, editedFields, category)}
              </StyledTextDescription>
              {reverted !== '' ? (
                <span>
                  {' '}
                  This Rule was revert to <StyledTexTitle>{revertedTo}</StyledTexTitle>
                </span>
              ) : (
                ''
              )}
            </p>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '40px',
            }}
          >
            <Tooltip>
              <Tooltip placement="rightTop" title={currentVersion ? '' : 'Return to this version'} color="#3594FF">
                <ImgSvg
                  src={TaskDone}
                  version={currentVersion.toString()}
                  onClick={!currentVersion && enableEdit ? throwModalConfiramtion : null}
                />
              </Tooltip>
            </Tooltip>
          </div>
        </div>
      }
      description={<Text>{toHumanDate(updatedAt)}</Text>}
      icon={<AvatarItem userName={name} />}
    />
  )
}
