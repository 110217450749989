import { put, takeLatest, call } from 'redux-saga/effects'
import { history } from 'services'
import {
  requestGetRuleSetsHistory,
  successGetRuleSetsHistory,
  failureGetRuleSetsHistory,
} from '../duck/getRuleSetsHistory.duck'

function* getRuleSetsHistoryAsync(data) {
  try {
    const response = yield call(history.getRulseSetsHistory, data.payload.params)
    yield put(successGetRuleSetsHistory(response))
  } catch (error) {
    yield put(failureGetRuleSetsHistory(error))
  }
}

function* watchGetRuleSetsHistoryAsync() {
  yield takeLatest(requestGetRuleSetsHistory, getRuleSetsHistoryAsync)
}

export default watchGetRuleSetsHistoryAsync
