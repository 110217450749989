import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: [],
}

export const {
  requestReturnVersionMultistep,
  successReturnVersionMultistep,
  failureReturnVersionMultistep,
  clearReturnVersionMultistep,
  clearReturnMultistep,
} = createActions({
  REQUEST_RETURN_VERSION_MULTISTEP: (data) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    data,
  }),
  SUCCESS_RETURN_VERSION_MULTISTEP: (data) => ({
    data,
    message: data.message,
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_RETURN_VERSION_MULTISTEP: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  CLEAR_RETURN_VERSION_MULTISTEP: () => ({
    error: false,
    success: false,
    message: '',
    fetching: false,
    data: [],
  }),
  CLEAR_RETURN_MULTISTEP: () => ({
    message: '',
    fetching: false,
    error: false,
    success: false,
    target: 'origin',
    routeMarkers: [],
  }),
})

const returnVersionMultistep = handleActions(
  {
    [requestReturnVersionMultistep]: produce((draft, { payload: { fetching, data, error, success } }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successReturnVersionMultistep]: produce((draft, { payload: { message, fetching, success, data, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data
    }),
    [failureReturnVersionMultistep]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = !error
    }),
    [clearReturnVersionMultistep]: produce((draft, { payload: { fetching, data, error, success, message } }) => {
      draft.data = data
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [clearReturnMultistep]: produce((draft, { payload: { message, fetching, error, success } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
  },
  defaultState
)

export default returnVersionMultistep
