import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  editLog: false,
  editIndex: 0,
  deleteIndex: 0,
  pricingLog: {},
  logSelected: {},
  totalLogs: [],
}

export const {
  requestCreatePricingLog,
  updatePricingLog,
  selectPricingLog,
  deletePricingLog,
  loadPricingLog,
} = createActions({
  REQUEST_CREATE_PRICING_LOG: (totalLogs, data) => ({
    ...defaultState,
    pricingLog: data,
    editLog: false,
    totalLogs,
  }),
  UPDATE_PRICING_LOG: (totalLogs) => ({
    totalLogs,
    editLog: false,
    pricingLog: {},
    logSelected: {},
  }),
  SELECT_PRICING_LOG: (pricingLog, editIndex) => ({
    logSelected: pricingLog,
    editLog: true,
    editIndex,
  }),
  DELETE_PRICING_LOG: (deleteIndex) => ({
    deleteIndex,
  }),
  LOAD_PRICING_LOG: (totalLogs) => ({
    ...defaultState,
    totalLogs,
  }),
})

const createPricingLog = handleActions(
  {
    [requestCreatePricingLog]: produce((draft, { payload: { totalLogs, pricingLog, editLog } }) => {
      draft.totalLogs = totalLogs.map((entry) => {
        return { ...entry, date: entry.date }
      })
      draft.pricingLog = pricingLog
      draft.editLog = editLog
    }),
    [updatePricingLog]: produce((draft, { payload: { totalLogs, editLog } }) => {
      draft.totalLogs = totalLogs
      draft.editLog = editLog
    }),
    [selectPricingLog]: produce((draft, { payload: { logSelected, editLog, editIndex } }) => {
      draft.logSelected = logSelected
      draft.editLog = editLog
      draft.editIndex = editIndex
    }),
    [deletePricingLog]: produce((draft, { payload: { deleteIndex } }) => {
      draft.totalLogs = draft.totalLogs.filter((_, index) => index !== deleteIndex)
      draft.deleteIndex = deleteIndex
    }),
    [loadPricingLog]: produce((draft, { payload: { totalLogs } }) => {
      draft.totalLogs = totalLogs
    }),
  },
  defaultState
)

export default createPricingLog
