import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: [],
  origin: '',
  destination: '',
}

export const { requestGetCityFromZip, successGetCityFromZip, failureGetCityFromZip } = createActions({
  REQUEST_GET_CITY_FROM_ZIP: (entry) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    entry,
  }),
  SUCCESS_GET_CITY_FROM_ZIP: (data) => ({
    data,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_CITY_FROM_ZIP: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
})

const getZipHere = handleActions(
  {
    [requestGetCityFromZip]: produce((draft, { payload: { fetching, data, error, success, target } }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
      draft.target = target
    }),
    [successGetCityFromZip]: produce((draft, { payload: { message, fetching, success, data, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data
    }),
    [failureGetCityFromZip]: produce((draft, { payload: { message, fetching, error, success } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
  },
  defaultState
)

export default getZipHere
