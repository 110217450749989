import React, { useState, useEffect } from 'react'
import { Col, Row } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import Form from 'antd/lib/form'
import Text from 'antd/lib/typography/Text'
import TitleDrawer from 'components/TitleDrawer'
import NumberInput from 'components/Form/NumerInput'
import PrimarySubmit from 'components/PrimarySubmit'
import { StyledModal } from 'containers/rules/ListMultiStep/utils/CreateMultistepRule/styles'
import colors from 'providers/theme/config/colorPallete'
import { requestUpdateBulkFuelPrice, resetUpdateBulkFuelPrice } from 'store/duck/updateBulkFuelPrice.duck'
import notify from 'utils/notify'

const formName = 'bulk-fuel-prices-form'

const CreateForm = ({ handleOnClose, customersToUpdate = [], cleanData }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const { message, error, success } = useSelector((state) => state.updateBulkFuelPrice)

  const onFormSubmit = () => {
    setLoading(true)
    const newCustomerInfo = form.getFieldsValue()

    Object.keys(newCustomerInfo).forEach((key) =>
      newCustomerInfo[key] === undefined ? delete newCustomerInfo[key] : {}
    )

    dispatch(
      requestUpdateBulkFuelPrice({
        customers: [...customersToUpdate],
        data: {
          ...newCustomerInfo,
        },
      })
    )
  }

  useEffect(() => {
    if (success) {
      handleOnClose()
      dispatch(resetUpdateBulkFuelPrice())
      notify('success', 'The customers have been updated')
      cleanData()
    }
    if (error) {
      setLoading(false)
      notify('error', message)
    }
  }, [dispatch, success, message, handleOnClose, error, cleanData])

  return (
    <>
      <Row style={{ marginBottom: 20 }}>
        <Col>
          <Text style={{ color: '#545454', fontWeight: 400, fontSize: '16px', marginBottom: '16' }}>
            Massively edits the fuel price of selected customers.
          </Text>
        </Col>
      </Row>
      <Form
        form={form}
        layout="vertical"
        name={formName}
        onFinish={onFormSubmit}
        style={{ marginTop: 6 }}
        hideRequiredMark
      >
        <Row gutter={[26, 10]}>
          <Col span={12} style={{ color: colors.dark_grey.default, fontWeight: 400, fontSize: 16 }}>
            Flatbed
          </Col>
          <Col span={12}>
            <NumberInput name="flatbedFuelPrice" prefix="$" placeholder="0.00" />
          </Col>
          <Col span={12} style={{ color: colors.dark_grey.default, fontWeight: 400, fontSize: 16 }}>
            Reefer
          </Col>
          <Col span={12}>
            <NumberInput name="reeferFuelPrice" prefix="$" placeholder="0.00" />
          </Col>
          <Col span={12} style={{ color: colors.dark_grey.default, fontWeight: 400, fontSize: 16 }}>
            Van
          </Col>
          <Col span={12} style={{ marginBottom: 8 }}>
            <NumberInput name="vanFuelPrice" prefix="$" placeholder="0.00" />
          </Col>
          <Col span={24}>
            <div style={{ marginBottom: 10 }}>
              <PrimarySubmit label="Update Fuel Prices" loading={loading} formName={formName} />
            </div>
          </Col>
        </Row>
      </Form>
    </>
  )
}

const BulkFuelPricesModal = ({ visible, handleOnClose, customersToUpdate = [], cleanData }) => {
  return (
    <StyledModal
      width={500}
      title={<TitleDrawer title="Fuel prices" />}
      visible={visible}
      bodyStyle={{ padding: '10px 25px 0px' }}
      onCancel={() => handleOnClose()}
      footer={null}
      destroyOnClose
    >
      <CreateForm handleOnClose={handleOnClose} customersToUpdate={customersToUpdate} cleanData={cleanData} />
    </StyledModal>
  )
}

export default BulkFuelPricesModal
