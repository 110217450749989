import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Upload from 'antd/lib/upload'
import Avatar from 'antd/lib/avatar'
import Badge from 'antd/lib/badge'
import Form from 'antd/lib/form'
import Spin from 'antd/lib/spin'
import Swal from 'sweetalert2'
import notify from 'utils/notify'
import { requestUserEditAdmin, resetUserEditAdmin } from 'store/duck/userEditAdmin.duck'
import { requestEditUser, resetEditUser } from 'store/duck/editUser.duck'
import { requestUpdateAvatar, clearUpdateAvatar } from 'store/duck/updateAvatar.duck'
import UserPhoto from 'assets/img/UserPhoto.png'
import { H1 } from 'components/Text/TextHeader'
import FloatLabel from 'components/Label/FloatLabel'
import InputMedium from 'components/Input/InputMedium'
import InputEditable from 'components/Input/InputEditable'
import PrimarySubmit from 'components/PrimarySubmit'
import { phoneRules, nameRules } from 'utils/inputRules'
import { MdPhotoCamera } from 'react-icons/md'
import Divider from 'antd/lib/divider'
import { AvatarContainer, FormItem, FormContainer } from './styles'

const UserProfileInfo = () => {
  const [loading, setLoading] = useState(true)

  const [newAvatar, setNewAvatar] = useState('')
  const [avatarFile, setAvatarFile] = useState('')
  const [avatar, setAvatar] = useState(UserPhoto)
  const [form] = Form.useForm()

  const dispatch = useDispatch()

  const editUser = useSelector((state) => state.editUser)
  const loginData = useSelector((state) => state.login.data.id)
  const { data, success, error, message } = useSelector((state) => state.userEditAdmin)

  const handlePreview = (file) => {
    const reader = new FileReader()
    reader.addEventListener(
      'load',
      () => {
        setAvatar(reader.result)
        setNewAvatar(reader.result)
      },
      false
    )
    if (file) {
      reader.readAsDataURL(file)
    }
  }

  const handleAvatarChange = (file) => {
    if (file.size > 300000) {
      Swal.fire({
        icon: 'error',
        title: 'File Upload Error!',
        text: 'Image must be smaller than 300KB!',
      })
      setAvatar(data.avatar)
      return false
    }
    handlePreview(file)
    setAvatarFile(file)
    return false
  }

  const onFormSubmit = () => {
    const formData = new FormData()
    const newUserInfo = form.getFieldsValue()
    const { authApis } = newUserInfo 
    if (avatarFile) {
      formData.set('avatar', avatarFile)
    }
    for (const property in newUserInfo) {
      if (property !== 'authApis') {
        formData.set(property, newUserInfo[property])
      }
    }
    formData.set('authApis', JSON.stringify(authApis))
    setLoading(true)
    dispatch(requestEditUser(formData, data._id))
  }

  useEffect(() => {
    return () => {
      dispatch(resetEditUser())
      dispatch(resetUserEditAdmin())
      dispatch(clearUpdateAvatar())
    }
  }, [dispatch])

  useEffect(() => {
    if (!success && !error) {
      dispatch(requestUserEditAdmin(loginData))
    }
    if (success) {
      setLoading(false)
      if (data.avatar && data.avatar !== 'undefined') {
        setAvatar(data.avatar)
      }
      form.setFieldsValue({
        name: data.name,
        lastName: data.lastName,
        phone: data.phone,
        email: data.email,
      })
      if (data.authApis) {
        form.setFieldsValue({
          authApis: {
            datUsername: data.authApis.datUsername ? data.authApis.datUsername : '',
            datPassword: data.authApis.datPassword ? data.authApis.datPassword : '',
            sonarUsername: data.authApis.sonarUsername ? data.authApis.sonarUsername : '',
            sonarPassword: data.authApis.sonarPassword ? data.authApis.sonarPassword : '',
            itsUsername: data.authApis.itsUsername ? data.authApis.itsUsername : '',
            itsPassword: data.authApis.itsPassword ? data.authApis.itsPassword : '',
          },
        })
      }
    }
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Get user Data error!',
        text: message,
      })
    }
  }, [dispatch, message, success, error, data.avatar, data, form, loginData])

  useEffect(() => {
    if (editUser.success) {
      notify('success', 'User edit successful!')
      setNewAvatar(avatar)
      dispatch(requestUpdateAvatar(newAvatar, form.getFieldValue('name'), form.getFieldValue('lastName')))
      dispatch(resetEditUser())
      dispatch(resetUserEditAdmin())
      dispatch(clearUpdateAvatar())
    }
    if (editUser.error) {
      Swal.fire({
        icon: 'error',
        title: 'User edit Error!',
        text: editUser.message,
      })
      dispatch(resetEditUser())
      dispatch(resetUserEditAdmin())
      dispatch(clearUpdateAvatar())
    }
  }, [editUser.success, editUser.error, editUser.message, dispatch, data, avatar, loginData, newAvatar, form])

  return (
    <Col span={24}>
      <Form form={form} layout="vertical" requiredMark={false}>
        <Spin spinning={loading}>
          <Row justify="space-between">
            <Col span={24} style={{ marginTop: '15px' }}>
              <Row justify="center">
                <AvatarContainer>
                  <Upload accept=".jpg,.jpeg,.png" fileList={[]} name="file" beforeUpload={handleAvatarChange}>
                    <Badge
                      offset={[0, 20]}
                      count={
                        <MdPhotoCamera
                          style={{
                            color: 'white',
                            fontSize: '31px',
                            padding: '4px',
                            borderRadius: '50%',
                            backgroundColor: '#3594FF',
                          }}
                        />
                      }
                    >
                      <Avatar size={{ xs: 60, sm: 32, md: 40, lg: 64, xl: 80, xxl: 80 }} src={avatar} />
                    </Badge>
                  </Upload>
                </AvatarContainer>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <H1 style={{ fontWeight: 700, fontSize: '16px', color: '#002555' }}>Basic Data</H1>
              </Row>
              <FormContainer>
                <Row gutter={[24, 16]}>
                  <Col span={8}>
                    <FloatLabel label="First Name * " show>
                      <FormItem rules={nameRules} name="name">
                        <InputMedium />
                      </FormItem>
                    </FloatLabel>
                  </Col>
                  <Col span={8}>
                    <FloatLabel label="Last Name * " show>
                      <FormItem rules={nameRules} name="lastName">
                        <InputMedium />
                      </FormItem>
                    </FloatLabel>
                  </Col>
                  <Col span={8}>
                    <FloatLabel label="Email * " show>
                      <FormItem name="email">
                        <InputMedium readOnly />
                      </FormItem>
                    </FloatLabel>
                  </Col>
                  <Col span={8}>
                    <FloatLabel label="Phone" show>
                      <FormItem rules={phoneRules} name="phone">
                        <InputMedium />
                      </FormItem>
                    </FloatLabel>
                  </Col>
                </Row>
              </FormContainer>
            </Col>
            <Divider style={{ marginTop: '8px' }} />
            <Col span={24}>
              <Row>
                <H1 style={{ fontWeight: 700, fontSize: '16px', color: '#002555' }}>Key Data</H1>
              </Row>
              <Row gutter={[24, 16]}>
                <Col span={8}>
                  <FormContainer>
                    <InputEditable label="DAT Username" name={['authApis', 'datUsername']} />
                  </FormContainer>
                </Col>
                <Col span={8}>
                  <FormContainer>
                    <InputEditable type="password" label="DAT Password" name={['authApis', 'datPassword']} />
                  </FormContainer>
                </Col>
                <Col span={8}>
                  <FormContainer>
                    <InputEditable label="ITS Username" name={['authApis', 'itsUsername']} />
                  </FormContainer>
                </Col>
                <Col span={8}>
                  <FormContainer>
                    <InputEditable type="password" label="ITS Password" name={['authApis', 'itsPassword']} />
                  </FormContainer>
                </Col>
                <Col span={8}>
                  <FormContainer>
                    <InputEditable label="Sonar Username" name={['authApis', 'sonarUsername']} />
                  </FormContainer>
                </Col>
                <Col span={8}>
                  <FormContainer>
                    <InputEditable type="password" label="Sonar Password" name={['authApis', 'sonarPassword']} />
                  </FormContainer>
                </Col>
              </Row>
            </Col>
            <Col span={24} style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <div style={{ textAlign: 'center', bottom: '5px', width: '60%' }}>
                <PrimarySubmit label="Edit User" htmlType="submit" onClick={onFormSubmit}/>
              </div>
            </Col>
          </Row> 
        </Spin>
      </Form>
    </Col>
  )
}

export default UserProfileInfo
