import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { MdClose } from 'react-icons/md'
import { BsEyeFill } from 'react-icons/bs'
import Table from 'components/Table'
import ButtonText from 'components/Button/TextButton'
import TitleDrawer from 'components/TitleDrawer'
import { StyledModal } from 'containers/rules/CreateNewRule/styles'
import paginate from 'utils/paginate'

import Columns from './utils/TableColumn'

const SpecialServicesModal = ({ services = [] }) => {
  const { rate: allSpecialServices } = useSelector((state) => ({
    rate: state.getRate.rate.specialServices ?? services,
  }))

  const [params, setParams] = useState({ text: '', page: 1, limit: 1 })
  const [visible, setVisible] = useState(false)

  const specialServices = paginate(allSpecialServices, params.limit, params.page)

  const handleTableChange = useCallback((pagination) => {
    setParams((prevParams) => ({
      ...prevParams,
      page: pagination.current ?? prevParams.page,
      limit: pagination.limit ?? prevParams.limit,
    }))
  }, [])

  return (
    <>
      <ButtonText onClick={() => setVisible((state) => !state)}>
        <BsEyeFill />
        <span style={{ margin: '0 3px', fontSize: '16px' }}>View All</span>
      </ButtonText>
      <StyledModal
        title={<TitleDrawer title="All Special Services Details" />}
        width={900}
        visible={visible}
        bodyStyle={{ padding: 24 }}
        onCancel={() => {
          setVisible(() => false)
        }}
        footer={null}
        closeIcon={<MdClose size={24} color="#000000" />}
      >
        <Table
          columns={Columns}
          data={specialServices}
          rowKey="_id"
          showBulkActions={false}
          updatedPagination
          width="100%"
          pagination={{ total: allSpecialServices.length ?? 0 }}
          onChange={handleTableChange}
        />
      </StyledModal>
    </>
  )
}

export default SpecialServicesModal
