import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  successSwitch: false, 
  data: {},
}

export const { requestSwitchBetaRules, successSwitchBetaRules, failureSwitchBetaRules, clearSwitchBetaRules } =
  createActions({
    REQUEST_SWITCH_BETA_RULES: () => ({
      ...defaultState,
      fetching: true,
    }),
    SUCCESS_SWITCH_BETA_RULES: (message) => ({
      message,
      fetching: false,
      error: false,
      successSwitch: true,
    }),
    FAILURE_SWITCH_BETA_RULES: (message) => ({
      message,
      fetching: false,
      error: true,
      successSwitch: false,
    }),
    CLEAR_SWITCH_BETA_RULES: () => ({
      ...defaultState,
    }),
  })

const SwitchBetaRules = handleActions(
  {
    [requestSwitchBetaRules]: produce((draft, { payload: { fetching } }) => {
      draft.fetching = fetching 
    }),
    [successSwitchBetaRules]: produce((draft, { payload: { message, fetching, successSwitch } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.successSwitch = successSwitch
    }),
    [failureSwitchBetaRules]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
    }),
    [clearSwitchBetaRules]: produce(
      (draft, { payload: { message, fetching, error, successSwitch } }) => {
        draft.message = message
        draft.fetching = fetching
        draft.error = error
        draft.successSwitch = successSwitch 
      }
    ),
  },
  defaultState
)

export default SwitchBetaRules
