import styled from 'styled-components'
import Button from 'antd/lib/button'

const ButtonQuaternary = styled(Button)`
  background: ${(props) => props.theme.colors.dark_blue.default};
  &:hover {
    background: ${(props) => props.theme.colors.bright_white.hover};
    border: 1px solid ${(props) => props.theme.colors.dark_blue.default};
    color: ${(props) => props.theme.colors.dark_blue.default};
  }
  &:focus {
    background: ${(props) => props.theme.colors.bright_white.focus};
  }
  border: 1px solid ${(props) => props.theme.colors.dark_blue.default};
  color: ${(props) => props.theme.colors.bright_white.default};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  font-size: 14px;
  line-height: 16px;
  border-radius: 4px;
`

export default ButtonQuaternary
