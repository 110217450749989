/**
 * 
 * @param {*} obj datalakeTable Object
 * @returns 
 */
const filterDatalake = (obj = {}) => {
  if (obj?.items) {
    return obj?.items
  } 
  return []
}

export default filterDatalake