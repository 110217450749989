import styled from 'styled-components'

const BoxContainer = styled.div`
  border: rgba(217, 217, 217, 0.1) 1px solid;
  box-shadow: 0px 10px 20px rgba(217, 217, 217, 0.2);
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  ${(props) =>
    props.backgroundColor
      ? `background-color: #f9fafe; ${props.bordertop ? 'border-top: 5px solid #3594FF;' : ''} `
      : 'background-color: white;'}
  ${(props) => (props.borderless ? 'padding: 1rem 0 0 0;' : 'padding: 1rem;')}
  #header {
    ${(props) => props.borderless && 'padding:0rem 1rem 0 1rem;'}
    width: 100%;
    color: #002555;
    font-size: 20px;
    font-weight: 700;
  }
  #content {
    margin-top: 1.1rem;
    ${(props) => props?.marginTop && `margin-top:${props?.marginTop};`}
    width: 100%;
    height: 100%;
  }
  .borderless {
  }
  .graph-select {
    width: 8rem !important;
  }
  .titleSecondary {
    font-size: 20px;
  }
  .title {
    font-size: 20px;
  }
`

export default BoxContainer
