export default function (api) {
  return {
    getSpecialServices: (params) =>
      api.get('api/special-services', {
        params: {
          text: params,
          stops: false,
          limit: 1000,
        },
      }),
    getSpecialServicesList: (params) =>
      api.get('api/special-services', {
        params: {
          limit: 20,
          ...params,
        },
      }),
    createSpecialService: (data) => api.post('api/special-services', data),
    updateSpecialService: (specialData) => {
      const data = { ...specialData }
      delete data.id
      return api.put(`api/special-services/${specialData.id}`, data)
    },
    deleteSpecialService: (itemId) => api.delete(`api/special-services/${itemId}`),
    editSpecialService: (specialData, specialServiceID) => api.put(`api/special-services/${specialServiceID}`, specialData),

  }
}
