import { put, takeLatest, call } from 'redux-saga/effects'
import { rules } from 'services'
import {
  requestUpdateStatusRule,
  successUpdateStatusRule,
  failureUpdateStatusRule,
} from '../duck/updateStatusRule.duck'

function* updateStatusRuleAsync(data) {
  try {
    const response = yield call(rules.updateRule, data.payload.data)
    yield put(successUpdateStatusRule(response))
  } catch (error) {
    yield put(failureUpdateStatusRule(error.response.data.message))
  }
}

function* watchUpdateStatusRuleAsync() {
  yield takeLatest(requestUpdateStatusRule, updateStatusRuleAsync)
}

export default watchUpdateStatusRuleAsync
