import { put, takeLatest, call } from 'redux-saga/effects'
import { specialServices } from 'services'
import {
  requestDeleteSpecialService,
  successDeleteSpecialService,
  failureDeleteSpecialService,
} from '../duck/deleteSpecialService.duck'

function* deleteSpecialServiceAsync(data) {
  try {
    const response = yield call(specialServices.deleteSpecialService, data.payload.itemId)
    yield put(successDeleteSpecialService(response))
  } catch (error) {
    yield put(failureDeleteSpecialService(error))
  }
}

function* watchDeleteSpecialServiceAsync() {
  yield takeLatest(requestDeleteSpecialService, deleteSpecialServiceAsync)
}

export default watchDeleteSpecialServiceAsync
