import styled from 'styled-components'
import Item from 'components/Input/FormItem'
import { TextBody2 } from 'components/Text/TextBody'

export const FormItem = styled(Item)`
  margin-bottom: 0.5rem;
`

export const AvatarContainer = styled.div`
  margin-bottom: 1rem;
`
export const FormContainer = styled.div`
  margin-top: 1.5rem;
`
export const StyledText = styled(TextBody2)`
  font-weight: 600;
`
export const NameContainer = styled.div`
  text-align: center;
`
