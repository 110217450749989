import styled from 'styled-components'
import { ToastContainer } from 'react-toastify'

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast-container {
  }
  .Toastify__toast--success {
    border-style: none;
    color: #ffffff !important;
    border-radius: 4px !important;
    background: #00c48c !important;
  }
  .Toastify__toast--error {
    border-style: none;
    color: #ffffff !important;
    border-radius: 4px !important;
    background: #F85359 !important;
  }
  .Toastify__toast-icon {
    display: none;
  }
  .Toastify__close-button--light {
    display: none;
  }
  .Toastify__toast {
  }
  .Toastify__toast-body {
    margin: 0px;
    padding: 0px 0px 0px 10px;
  }
  .Toastify__progress-bar {
  }
  .Toastify__toast-theme--colored.Toastify__toast--success {
  }
`

export default StyledToastContainer
