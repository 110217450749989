import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector, batch } from 'react-redux'
import { clearDeleteRule, requestDeleteRule } from 'store/duck/deleteRule.duck'
import { requestUpdateStatusRule } from 'store/duck/updateStatusRule.duck'
import { startCreateRule } from 'store/duck/createRule.duck'
import { clearMessageSnackBar } from 'store/duck/messageSnackBar.duck'
import ModalDeleteRules from 'components/ModalDeleteRules'
import Table from 'components/Table'
import { requestGetStates } from 'store/duck/getStates.duck'
import FilterRules from 'components/Form/FilterRules'
import CreateRuleModal from 'containers/rules/CreateNewRule'
import notify from 'utils/notify'
import { changeMarketByGeographic } from 'utils/rulesFormat'
import { requestUpdateRule, clearUpdateRule } from 'store/duck/updateRule.duck'
import { replaceActions } from 'utils/formatValues'
import TextBodyModal from './utils/TextBodyModal'
import geograpchicColumns from './utils/GeographicColumns'
import lengthColumns from './utils/LengthColumns'
import leadTimeColumns from './utils/LeadTimeColumns'
import simpleColumns from './utils/SimpleColumns'
import marketRules from './utils/MarketColumns' 
import RegionColums from "../columnsRules/RegionColumns"
import dateRangeColumns from './utils/DateRangeColumns'
import RulesContainer from '../styles'
import useGetRulesIds from './utils/useGetRulesIds'
import equipmentColumns from './utils/EquipmentColumns'
import LaneScoreColumns from '../columnsRules/LaneScoreColumns'
import MciColumns from '../columnsRules/MciColumns'
import LTRatioColumns from '../columnsRules/LTRatioColumns'

const ListRules = ({ enableEdit = true }) => {
  const dispatch = useDispatch()
  const { shippingRule, referenceRule, typesId, queryParams } = useGetRulesIds()
  const deleteRule = useSelector((state) => state.deleteRule)
  const { rules, success, totalRules, fetching: fetchingRules } = useSelector((state) => state.getRules)
  const {
    success: successGetRules,
    rules: rulesByCustomer,
    fetching,
  } = useSelector((state) => state.getRulesByCustomer)
  const {
    success: successUpdateRule,
    error: errorUpdateRule,
    message: messageUpdateRule,
  } = useSelector((state) => state.updateRule)
  const [visible, setVisible] = useState(false)
  const [rulesData, setRulesData] = useState([])
  const [status, setStatus] = useState('All')
  const [columns, setColumns] = useState([])
  const [params, setParams] = useState({ page: 1, limit: 20 })
  const [columnsParams, setColumnsParams] = useState({
    type: 'geographicRule',
    shipping: 'outState',
    reference: '',
    queryParams,
  })
  const [openDelete, setOpenDelete] = useState(false)
  const [rulesToDelete, setRulesToDelete] = useState({ rules: [], multiStep: [], ruleSets: [] })
  const { message: msgSnack } = useSelector((state) => state.messageSnackBar)
  const {
    success: successUpdate,
    error: errorUpdate,
    message: msmUpdate,
  } = useSelector((state) => state.updateStatusRule)
  const { trucktypes } = useSelector((state) => state.getTruckTypeList)

  useEffect(() => {
    dispatch(requestGetStates(''))
  }, [dispatch])

  useEffect(() => {
    if (successUpdate) {
      notify('success', msgSnack)
      dispatch(clearMessageSnackBar())
    }
    if (successUpdateRule) {
      dispatch(clearUpdateRule())
    }
    if (errorUpdate || errorUpdateRule) {
      notify('error', errorUpdate ? 'Error updating the ruleset.' : messageUpdateRule)
      dispatch(clearMessageSnackBar())
    }
  }, [
    successUpdate,
    errorUpdate,
    msmUpdate,
    dispatch,
    params,
    msgSnack,
    successUpdateRule,
    errorUpdateRule,
    messageUpdateRule,
  ])

  const showModalDelete = () => {
    setOpenDelete(true)
  }
  const closeModalDelete = () => {
    setOpenDelete(false)
  }
  const handleData = useCallback(
    (rows) => {
      setRulesToDelete({
        ruleSets: rows
          .flatMap((id) => [...rulesData.filter((rule) => rule._id === id)])
          .flatMap((rule) => [...rule?.rulesetsInfo?.ruleSetsNames]),
        multiStep: rows
          .flatMap((id) => [...rulesData.filter((rule) => rule._id === id)])
          .flatMap((rule) => [...rule?.multiStepsRuleInfo.multistepNames]),
        rules: rows,
      })
    },
    [rulesData]
  )
  const multipleDelete = () => {
    batch(() => {
      rulesToDelete.rules.map((id) => dispatch(requestDeleteRule(id)))
    })
  }
  useEffect(() => {
    if (deleteRule.successDelete) {
      closeModalDelete()
    }
    if (!deleteRule.error) return
    dispatch(clearDeleteRule())
  }, [deleteRule, dispatch])

  // TODO: refactor this useEffeect as a custom hook and use it in master and rules modules

  useEffect(() => {
    setRulesData([])
    if (success || successGetRules) {
      const getColumns = {
        geographicRule: replaceActions(
          geograpchicColumns(dispatch, columnsParams.shipping, columnsParams.reference, setVisible),
          enableEdit,
          dispatch,
          setVisible
        ),
        lengthRule: replaceActions(lengthColumns(dispatch, setVisible), enableEdit, dispatch, setVisible),
        simpleRule: replaceActions(
          simpleColumns(dispatch, columnsParams.shipping, setVisible),
          enableEdit,
          dispatch,
          setVisible
        ),
        dateRangeRule: replaceActions(
          dateRangeColumns(dispatch, columnsParams.shipping, setVisible),
          enableEdit,
          dispatch,
          setVisible
        ),
        truckType: replaceActions(
          equipmentColumns(dispatch, trucktypes, setVisible),
          enableEdit,
          dispatch,
          setVisible
        ),
        marketRule: replaceActions(
          marketRules(dispatch, columnsParams.shipping, setVisible),
          enableEdit,
          dispatch,
          setVisible
        ),
        regionRule: replaceActions(
          RegionColums(dispatch, setVisible),
          enableEdit,
          dispatch,
          setVisible
        ),
        laneScoreRule: replaceActions(LaneScoreColumns(dispatch, setVisible), enableEdit, dispatch, setVisible),
        leadTimeRule: replaceActions(leadTimeColumns(dispatch, setVisible), enableEdit, dispatch, setVisible),
        mciRule: replaceActions(MciColumns(dispatch, setVisible), enableEdit, dispatch, setVisible),
        ltRatioRule: replaceActions(LTRatioColumns(dispatch, setVisible), enableEdit, dispatch, setVisible),        
        default: []
      }
      const valueColumns = getColumns[columnsParams.type] ?? []
      setColumns(valueColumns)
      const columnEmpty = JSON.stringify(valueColumns) === "[]"
      if (columnEmpty) {
        setRulesData([])
      }
      if (enableEdit && !columnEmpty) setRulesData(rules)
      else setRulesData(rulesByCustomer)
    }
  }, [dispatch, trucktypes, columnsParams, success, rules, enableEdit, successGetRules, rulesByCustomer])

  const handleCreateRule = () => { 
    dispatch(startCreateRule())
    setVisible(true)
  }
  
  const handleTableChange = useCallback((pagination) => {
    setParams((prevParams) => ({
      ...prevParams,
      page: pagination.current ?? prevParams.page,
      limit: pagination.limit ?? prevParams.limit,
    }))
  }, [])

  const closeModal = (some) => {
    setVisible(some)
  }
 
  

  return (
    <RulesContainer>
      <CreateRuleModal
        typesId={typesId}
        shippingRule={shippingRule}
        referenceRule={referenceRule}
        visible={visible}
        onCloseModal={closeModal}
        columnsParams={changeMarketByGeographic(columnsParams)}
        enableEdit={enableEdit}
      />
      <FilterRules
        queryParams={queryParams}
        handleColumnsParams={setColumnsParams}
        onClickNewRule={handleCreateRule}
        changeStatus={(newStatus) => setStatus(newStatus)}
        page={params.page}
        limit={params.limit}
        enableEdit={enableEdit}
      />
      <Table
        request={(nextData) => dispatch(requestUpdateRule(nextData))}
        columns={columns}
        data={rulesData}
        loading={fetchingRules || fetching}
        rowKey="_id"
        width="100%"
        padding="4px 0px"
        margin="0px"
        pagination={{ total: totalRules }}
        limitPerPage={50}
        paginationOptions={['50', '100', '150', '250']}
        updatedPagination
        onChange={handleTableChange}
        rowSelection
        ruleSelected={columnsParams.type}
        status={status}
        dispatch={dispatch}
        pauseRequest={requestUpdateStatusRule}
        avoidNotify
        openDeleteModal={showModalDelete}
        handleData={handleData}
        enableEdit={enableEdit}
        CustomModalDelete={
          <ModalDeleteRules
            visible={openDelete}
            ruleSets={rulesToDelete.ruleSets}
            multiSteps={rulesToDelete.multiStep}
            close={closeModalDelete}
            componentBody={() => (
              <TextBodyModal
                multiQuantity={rulesToDelete.multiStep.length}
                ruleSetQuantity={rulesToDelete.ruleSets.length}
              />
            )}
            onClick={() => multipleDelete()}
            fetching={deleteRule.fetching}
          />
        }
        addHeight={-30}
      />
    </RulesContainer>
  )
}
export default ListRules
