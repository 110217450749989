import { put, takeLatest, call } from 'redux-saga/effects'
import { rules } from 'services'
import {
  requestUpdateMultiStep,
  successUpdateMultiStep,
  failureUpdateMultiStep,
} from '../duck/updateMultiStep.duck'

function* updateMultiStepAsync(data) {
  try {
    const response = yield call(rules.updateMultiStep, data.payload.data)
    yield put(successUpdateMultiStep(response))
  } catch (error) {
    yield put(failureUpdateMultiStep(error.response.data.message))
  }
}

function* watchUpdateMultiStepAsync() {
  yield takeLatest(requestUpdateMultiStep, updateMultiStepAsync)
}

export default watchUpdateMultiStepAsync
