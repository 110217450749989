import styled from 'styled-components'
import Button from 'antd/lib/button'
import { MdMoreVert } from 'react-icons/md'

export const StyledIcon = styled(MdMoreVert)`
  color: #3594ff;
  font-size: 1.3rem;
`

export const StyledButton = styled(Button)`
  border: none;
  padding: 0;
  background-color: transparent !important;
`
