import React from 'react'
import { getStatus } from 'utils/getStatus'
import moment from 'moment'
import DateLabel from 'components/Label/DateLabel'
import { StyledBadge } from '../styles'
import actionsDropdown from './actionDropdown'


const columns = (dispatch, openDrawer) => {
  return [
    {
      title:<div style={{fontSize: '16px'}}> Name</div>,
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 150,
      render: (name) => {
        return <div style={{fontSize: '16px', display: 'flex'}}>{name}</div>
      },
    },
    {
      title: <div style={{fontSize: '16px'}}>Create Date</div>,
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'left',
      width: 150,
      render: (createdAt) => {
        return (
          <div style={{fontSize: '16px' , display: 'flex'}}>
            <DateLabel date={moment(createdAt).format('MM/DD/YYYY')}   />
          </div>
        )
      }
    },
    {
      title: <div style={{fontSize: '16px', display: 'flex'}}>Description</div>,
      dataIndex: 'description',
      key: 'description',
      align: 'left',
      width: 150,
      render: (description) => {
        return <div style={{fontSize: '16px', display: 'flex'}}>{description}</div>
      },
    },
    {
      title:<div style={{fontSize: '16px'}}>Max Weight</div>,
      dataIndex: 'maxWeight',
      key: 'maxWeight',
      align: 'left',
      width: 150,
      render: (maxWeight) => {
        return <div style={{fontSize: '16px', display: 'flex'}}>{maxWeight}</div>
      },
    }, 
    {
      title:<div style={{fontSize: '16px'}}>Default Fuel</div>,
      dataIndex: 'defaultFuel',
      key: 'defaultFuel',
      align: 'left',
      width: 150,
      render: (defaultFuel) => {
        return <div style={{fontSize: '16px', display: 'flex'}}>{defaultFuel}</div>
      },
    },
    {
      title:  <div style={{fontSize: '16px'}}>Status</div>,
      dataIndex: 'active',
      key: 'status',
      render: (active, record) => <div style={{ display: 'flex' }}><StyledBadge variant={getStatus(record.status, active)} /></div>,
      width: 150,
      align: 'left',
    },
    {
      ...actionsDropdown(dispatch, openDrawer),
    },
  ]
}

export default columns
