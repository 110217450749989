import React, { useEffect, useState } from 'react'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import FloatLabel from 'components/Label/FloatLabel'
import { FormItem } from 'components/Input'
import CustomSelect from 'components/CustomSelect'
import { Form, DatePicker } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { requestUserList } from 'store/duck/getUsers.duck'
import { ArrowDownIcon } from 'assets/svg'
import { requestGetStatistics } from 'store/duck/getStatistics.duck'
import moment from 'moment'

const defaultValues = {
  customer: '',
  call_type: 'ALL',
}

const { RangePicker } = DatePicker

export default function FiltersGraph() {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [totalCustomer, setTotalCustomers] = useState([])

  const { users } = useSelector((state) => ({
    users: state.getUsers.data.data,
  }))
  // const timeFrame = [
  //   { label: 'Last 7 days', value: '7 days' },
  //   { label: 'Custom', value: 'custom' },
  // ]

  const [initDate, setinitDate] = useState(moment().subtract(7, 'days').format('MM/DD/YYYY'))
  const [endDate, setinendDate] = useState(moment().format('MM/DD/YYYY'))
  const formatUser = (arr = []) => arr.map((item) => ({ label: item.name, value: item._id })).sort()

  useEffect(() => {
    const filterInfo = form.getFieldsValue()

    Object.keys(filterInfo).forEach((key) => (filterInfo[key] === undefined ? delete filterInfo[key] : {}))
    const dataForm = {call_type: "USER_REQUEST"}
    for (const property in filterInfo) {
      if (filterInfo[property]) {
        dataForm[property] = filterInfo[property]
      }
    }
    dispatch(requestGetStatistics({ ...dataForm, initDate, endDate }))
  }, [form, dispatch, initDate, endDate])

  useEffect(() => {
    setTotalCustomers(formatUser(users))
  }, [users])

  const handleFinish = (data) => {
    dispatch(requestGetStatistics({...data, call_type: "USER_REQUEST"}))
  }

  return (
    <Form
      form={form}
      onFinish={handleFinish}
      initialValues={{
        ...defaultValues,
      }}
      onValuesChange={(changedValues, allValues) => {
        const newValues = { ...allValues, call_type: "USER_REQUEST" }
        Object.keys(newValues).forEach((key) => newValues[key] === undefined && delete newValues[key])
        dispatch(requestGetStatistics(newValues))
      }}
      layout="vertical"
      name="filter"
      id="filter"
    >
      <Row gutter={[8, 8]}>
        <Col span={8}>
          <FloatLabel label="User" show>
            <FormItem name="customer">
              <CustomSelect
                allowClear
                onSearch={(value) => {
                  dispatch(requestUserList({ text: value, active: true }))
                }}
                optionFilterProp="label"
                keysort="label"
                showSearch
                placeholder="User"
                suffixIcon={<ArrowDownIcon />}
                options={totalCustomer}
              />
            </FormItem>
          </FloatLabel>
        </Col>
        <Col span={8}>
          <FloatLabel label="Timeframe" show topLabel="-10px">
            <RangePicker
              format="MM-DD-YYYY"
              onChange={(dates, dateStrings) => {
                setinitDate(dateStrings[0])
                setinendDate(dateStrings[1])
              }}
              disabledDate={(current) => current.isAfter(moment())}
              defaultValue={[moment().subtract(7, 'days'), moment()]}
            />
          </FloatLabel>
        </Col>
      </Row>
    </Form>
  )
}
