import { toast } from 'react-toastify'
import { AiOutlineCheck, AiOutlineClose, AiOutlineInfoCircle } from 'react-icons/ai'
import styled from 'styled-components'
import React from 'react'

const DivAlert = styled.div`
  display: flex;
  align-items: center;
`
const DisplayAlert = ({ Icon, message }) => {
  return (
    <DivAlert>
      <Icon style={{ fontSize: '16px', marginRight: '5px' }} />
      {message}
    </DivAlert>
  )
}
const notify = (type, message) => {
  const MESSAGES = {
    success: () => toast.success(<DisplayAlert Icon={AiOutlineCheck} message={message} />),
    error: () => toast.error(<DisplayAlert Icon={AiOutlineClose} message={message} />),
    info: () => toast.info(<DisplayAlert Icon={AiOutlineInfoCircle} message={message} />),
  }

  if (message === '') {
    return null
  }

  if (MESSAGES[type]) {
    return MESSAGES[type]()
  }

  throw new Error('unrecognized type')
}

export default notify
