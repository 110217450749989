import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: {},
}

export const {
  requestUpdateBulkFuelPrice,
  successUpdateBulkFuelPrice,
  failureUpdateBulkFuelPrice,
  resetUpdateBulkFuelPrice,
} = createActions({
  REQUEST_UPDATE_BULK_FUEL_PRICE: (data) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    data,
  }),
  SUCCESS_UPDATE_BULK_FUEL_PRICE: (data) => ({
    data,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_UPDATE_BULK_FUEL_PRICE: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  RESET_UPDATE_BULK_FUEL_PRICE: () => ({
    ...defaultState,
  }),
})

const updateBulkFuelPrice = handleActions(
  {
    [requestUpdateBulkFuelPrice]: produce((draft, { payload: { fetching, data, error, success } }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successUpdateBulkFuelPrice]: produce((draft, { payload: { message, fetching, success, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = false
      draft.data = data
    }),
    [failureUpdateBulkFuelPrice]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = false
    }),
    [resetUpdateBulkFuelPrice]: produce((draft, { payload: { message, fetching, success, error, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data
    }),
  },
  defaultState
)

export default updateBulkFuelPrice
