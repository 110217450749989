import { put, takeLatest, call } from 'redux-saga/effects'
import { integrations } from 'services'
import { successGetDatHistory, requestGetDatHistory, failureGetDatHistory } from '../duck/getDatHistory.duck'

function* getGetDatHistory(data) {
  try {
    const response = yield call(integrations.getDatHistory, data.payload.data)
    yield put(successGetDatHistory(response))
  } catch (error) {
    yield put(failureGetDatHistory(error))
  }
}

function* watchGetDatHistoryAsync() {
  yield takeLatest(requestGetDatHistory, getGetDatHistory)
}

export default watchGetDatHistoryAsync
