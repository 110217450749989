import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { requestGetZipcodes } from 'store/duck/getZipcodes.duck'
import { requestValidateZipcode } from 'store/duck/validateZipcode.duck'

const useAutocomplete = () => {
  const { list, validate, zipField } = useSelector((state) => ({
    list: state.getZipcodes.data,
    validate: state.validateZipcode,
    zipField: state.validateZipcode.field,
  }))
  const [value, setValue] = useState('')
  const [validStatus, setValidStatus] = useState('success')
  const [field, setZipField] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(requestGetZipcodes(value))
    if (value.length === 3) dispatch(requestValidateZipcode(value, field))
  }, [dispatch, value, field])

  useEffect(() => {
    if (validate.fetching) {
      setValidStatus('validating')
    } else if (validate.success) {
      setValidStatus('success')
    } else setValidStatus('error')
  }, [dispatch, validate.fetching, validate.success])

  const handleAutocompleteChange = (searchText) => {
    setValue(searchText)
  }

  const handleZipChange = (event, fieldIndex, input = false) => {
    if (input) {
      setZipField(fieldIndex)
    } else {
      setZipField(fieldIndex)
      setValue(event)
    }
  }

  return [list, validStatus, zipField, handleAutocompleteChange, handleZipChange]
}

export default useAutocomplete
