import { put, takeLatest, call } from 'redux-saga/effects'
import { history } from 'services/'
import { requestGetRuleHistory, successGetRuleHistory, failureGetRuleHistory } from '../duck/getRuleHistory.duck'

function* getRuleHistoryAsync(data) {
  try {
    const response = yield call(history.getRuleHistory, data.payload.params)
    yield put(successGetRuleHistory(response))
  } catch (error) {
    yield put(failureGetRuleHistory(error))
  }
}

function* watchGetRuleHistoryAsync() {
  yield takeLatest(requestGetRuleHistory, getRuleHistoryAsync)
}

export default watchGetRuleHistoryAsync
