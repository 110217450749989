import { put, takeLatest, call } from 'redux-saga/effects'
import { rules } from 'services'
import { requestGetRuleSets, successGetRuleSets, failureGetRuleSets } from '../duck/getRuleSets.duck'

function* getRuleSetsAsync(data) {
  try {
    const response = yield call(rules.getRuleSets, data.payload.params)
    yield put(successGetRuleSets(response))
  } catch (error) {
    yield put(failureGetRuleSets(error))
  }
}

function* watchGetRuleSetsAsync() {
  yield takeLatest(requestGetRuleSets, getRuleSetsAsync)
}

export default watchGetRuleSetsAsync
