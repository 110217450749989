import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  rules: [],
  totalRules: 0,
  params: {},
}

export const { requestGetAllRules, successGetAllRules, failureGetAllRules, clearGetAllRules } = createActions({
  REQUEST_GET_ALL_RULES: (params) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    params,
  }),
  SUCCESS_GET_ALL_RULES: (rules) => ({
    rules,
    totalRules: rules.length,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_ALL_RULES: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  CLEAR_GET_ALL_RULES: () => ({
    ...defaultState,
  }),
})

const getAllRules = handleActions(
  {
    [requestGetAllRules]: produce((draft, { payload: { fetching, error, success } }) => {
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successGetAllRules]: produce((draft, { payload: { message, fetching, success, rules, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.rules = rules.map((entry) => {
        return {
          _id: entry._id,
          key: entry._id,
          value: entry.name,
          name: entry.name,
          margin: entry.margin,
          flat: entry.flat,
        }
      })
      draft.totalRules = rules.length
    }),
    [failureGetAllRules]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = !error
    }),
    [clearGetAllRules]: produce((draft, { payload: { message, fetching, success, rules, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.rules = rules
      draft.totalRules = 0
    }),
  },
  defaultState
)

export default getAllRules
