import React, { useMemo, useCallback, useState } from 'react'
import { ResponsiveLine } from '@nivo/line'
import Grid from 'antd/lib/grid'
import Radio from 'antd/lib/radio'
import { StyledContainer, ContainerGraphics, HeaderGraph } from './styles'

const { useBreakpoint } = Grid

const GraphMCI = ({ data = {} }) => {
  const screen = useBreakpoint()
  const chartColors = useMemo(
    () => ({
      predictedIntervalUpperBound: '#F6A15B',
      mciScore: '#F6A15B',
      predictedIntervalLowerBound: '#F6A15B',
    }),
    []
  )

  const labels = useMemo(
    () => ({
      predictedIntervalUpperBound: 'MCI Upper Bound',
      mciScore: 'Predicted MCI',
      predictedIntervalLowerBound: 'MCI Lower Bound',
    }),
    []
  ) 

  const allYValues = data.INBOUND.data.flatMap(item => item.data.map(point => point.y))
                     .concat(data.OUTBOUND.data.flatMap(item => item.data.map(point => point.y)))
                     .filter(y => y !== null);

  // Find the maximum and minimum y values
  const maxY = Math.max(...allYValues);
  const minY = Math.min(...allYValues);

  const MemoSlice = React.memo(({ slice }) => {
    const right = '["predictedIntervalLowerBound","mciScore","predictedIntervalUpperBound"]'
    let points = [...slice.points]
    if (slice.points.length > 1) {
      if (right === JSON.stringify(slice.points.map((item) => item.serieId))) {
        points = slice.points.reverse()
      }
    }

    return (
      <div
        style={{
          background: 'white',
          padding: '9px 12px',
          border: '1px solid #ccc',
          zIndex: '200',
        }}
      >
        <strong style={{ textJustify: 'center' }}>{slice.points[0].data.x}</strong>

        {points.map((point) => (
          <React.Fragment key={point.id}>
            <div
              style={{
                color: point.serieColor,
                padding: '3px 0',
              }}
            >
              <strong>{points.length > 1 ? labels[point.serieId] : 'MCI Score'}</strong> [{point.data.y}]
            </div>
          </React.Fragment>
        ))}
      </div>
    )
  })

  const getColor = useCallback((bar) => chartColors[bar.id], [chartColors])
  const [filter, setFilter] = useState('OUTBOUND')
  const handleFilter = useCallback((e) => setFilter(e.target.value), [])

  return (
    <ContainerGraphics>
      <HeaderGraph>
        <span style={{ fontSize: '16px', fontWeight: 700, color: '#002555' }}>Market conditions</span>
      </HeaderGraph>

      {data[filter]?.data ? (
        <>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Radio.Group size="middle" value={filter} onChange={handleFilter}>
              <Radio.Button style={{ borderRadius: '5px 0px 0px 5px' }} value="OUTBOUND">
                ORIGIN
              </Radio.Button>
              <Radio.Button style={{ borderRadius: '0px 5px 5px 0px' }} value="INBOUND">
                DESTINATION
              </Radio.Button>
            </Radio.Group>
          </div>
          <StyledContainer
            style={
              screen.xxl
                ? { display: 'flex', width: '100%', height: '350px' }
                : { display: 'flex', width: '100%', height: '350px', overflow: 'scroll hidden' }
            }
          >
            <div style={{ position: 'relative', width: 'inherit', height: 'inherit', display: 'flex' }}>
              <ResponsiveLine
                title="MCI"
                data={data[filter]?.data ?? []}
                width={600}
                curve="catmullRom"
                margin={{ top: 20, right: 0, bottom: 40, left: 40 }}
                xScale={{ type: 'point' }}
                colors={getColor}
                yScale={{
                  type: 'linear',
                  stacked: false,
                  min: minY,
                  max: maxY,
                  reverse: false,
                }}
                yFormat=" >-.2f"
                axisTop={null}
                axisRight={null}
                theme={{
                  fontSize: 8.5,
                }}
                enableGridX
                enableGridY
                pointSize={6}
                pointColor="#ffffff"
                pointBorderWidth={1}
                lineWidth={1}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabelYOffset={-12}
                enableSlices="x"
                areaOpacity={0.1}
                useMesh
                sliceTooltip={MemoSlice}
              />
            </div>
          </StyledContainer>
        </>
      ) : (
        'nothing to display'
      )}
    </ContainerGraphics>
  )
}

export default GraphMCI
