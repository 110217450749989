import { put, takeLatest, call } from 'redux-saga/effects'
import { customers } from 'services' 
import { requestGetMultiStepsByCustomer, successGetMultiStepsByCustomer, failureGetMultiStepsByCustomer } from '../duck/getMultiStepsByCustomer.duck'

function* getMultiStepsByCustomerAsync(data) {
  try {     
    const response = yield call(customers.getMultiSteps, data.payload.params)
    yield put(successGetMultiStepsByCustomer(response))
  } catch (error) {
    yield put(failureGetMultiStepsByCustomer(error))
  }
}

function* watchGetMultiStepsByCustomerAsync() {
  yield takeLatest(requestGetMultiStepsByCustomer, getMultiStepsByCustomerAsync)
}

export default watchGetMultiStepsByCustomerAsync
