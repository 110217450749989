import React from 'react'
import Text from 'antd/lib/typography/Text'
import colors from 'providers/theme/config/colorPallete'
import { TableContainer } from 'components/Table/styles'
import AntTableRuleSets from 'containers/rules/Ruleset/CreateRuleset/styles'
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdModeEdit } from 'react-icons/md'
import {
  MULTI_STEPS,
  RULE_DATE_RANGE,
  RULE_GEOGRAPHIC,
  RULE_LENGTH,
  RULE_SIMPLE,
  RULE_EQUIPMENT,
  RULE_LEAD_TIME,
} from 'utils/constants'

const CategoriesAddedTable = ({
  selectedGeographicInState = [],
  masterRulesGeographicInState = [],
  selectedGeographicInZip = [],
  masterRulesGeographicInZip = [],
  selectedGeographicOutState = [],
  masterRulesGeographicOutState = [],
  selectedGeographicOutZip = [],
  masterRulesGeographicOutZip = [],
  selectedLength = [],
  masterRulesLength = [],
  selectedDatePickup = [],
  masterRulesDatePickup = [],
  selectedDateDelivery = [],
  masterRulesDateDelivery = [],
  selectedSimplePickup = [],
  masterRulesSimplePickup = [],
  selectedSimpleDelivery = [],
  masterRulesSimpleDelivery = [],
  selectedEquipment,
  masterRulesEquipment = [],
  showMasterRules,
  selectedMultistep = [],
  masterMultistep = [],
  selectedMarketPickup = [],
  selectedMarketDelivery = [],
  expandedRowKey,
  setExpandedRowKey,
  setRuleType,
  hideActionsColumn,
  loading,
  masterRulesLeadTime = [],
  selectedLeadTime = [],
  selectedLaneScore = [],
  selectedRegionPickup = [],
  selectedRegionDelivery = [],
  selectedOriginMci = [],
  selectedDestinationMci = [],
  selectedOriginLT = [],
  selectedDestinationLT = [],
}) => {
  const masterRulesEquipmentSize = masterRulesEquipment ? masterRulesEquipment.length : 0
  const selectedEquipmentSize = selectedEquipment ? selectedEquipment.length : 0
  const sizeMasterRulesEquipment = (showMasterRules ? masterRulesEquipmentSize : 0) + selectedEquipmentSize
  return (
    <TableContainer style={{ paddingBottom: 0 }}>
      <AntTableRuleSets
        dataSource={[
          {
            key: "Analytics",
            name: 'Analytics',
            totalRules: selectedLaneScore.length + selectedOriginMci.length +
              selectedDestinationMci.length +
              selectedOriginLT.length +
              selectedDestinationLT.length
          },
          {
            key: RULE_DATE_RANGE,
            name: 'Date Range',
            totalRules:
              (showMasterRules ? masterRulesDatePickup.length + masterRulesDateDelivery.length : 0) +
              selectedDatePickup.length +
              selectedDateDelivery.length,
          },
          {
            key: RULE_GEOGRAPHIC,
            name: 'Geographic',
            totalRules:
              selectedGeographicInState.length +
              selectedGeographicInZip.length +
              selectedGeographicOutState.length +
              selectedGeographicOutZip.length +
              selectedMarketPickup.length +
              selectedMarketDelivery.length +
              selectedRegionPickup.length +
              selectedRegionDelivery.length
          },
          {
            key: RULE_LENGTH,
            name: 'Length of Haul',
            totalRules: (showMasterRules ? masterRulesLength.length : 0) + selectedLength.length,
          },
          {
            key: RULE_LEAD_TIME,
            name: 'Lead Time',
            totalRules: (showMasterRules ? masterRulesLeadTime.length : 0) + selectedLeadTime.length,
          },
          {
            key: MULTI_STEPS,
            name: 'Multi-Steps',
            totalRules: (showMasterRules ? masterMultistep.length : 0) + selectedMultistep.length,
          },
          {
            key: RULE_SIMPLE,
            name: 'Day of Week',
            totalRules:
              (showMasterRules ? masterRulesSimplePickup.length + masterRulesSimpleDelivery.length : 0) +
              selectedSimplePickup.length +
              selectedSimpleDelivery.length,
          },
          {
            key: RULE_EQUIPMENT,
            name: 'Equipment',
            totalRules: sizeMasterRulesEquipment,
          },

        ]}
        columns={[
          {
            title: 'Category',
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            width: 200,
            render: (categoryName, category) => {
              const isExpanded = expandedRowKey === category.key

              return (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Text
                    onClick={() => setExpandedRowKey(isExpanded ? null : category.key)}
                    underline
                    style={{ color: '#3594FF', cursor: 'pointer', userSelect: 'none', fontSize: 16 }}
                  >
                    {categoryName}
                  </Text>
                  {isExpanded ? (
                    <MdKeyboardArrowUp
                      fontSize="18px"
                      style={{
                        color: '#3594FF',
                        cursor: 'pointer',
                      }}
                    />
                  ) : (
                    <MdKeyboardArrowDown fontSize="18px" style={{ color: '#3594FF', cursor: 'pointer' }} />
                  )}
                </div>
              )
            },
          },
          {
            title: 'Total Rules',
            dataIndex: 'totalRules',
            key: 'totalRules',
            align: 'center',
          },
          !hideActionsColumn
            ? {
              title: 'Actions',
              dataIndex: 'key',
              key: 'actions',
              align: 'center',
              width: 100,
              render: (categoryKey) => (
                <MdModeEdit
                  size="20"
                  style={{ verticalAlign: 'middle', cursor: 'pointer' }}
                  color={colors.light_blue.default}
                  onClick={() => setRuleType(categoryKey)}
                />
              ),
            }
            : undefined,
        ].filter(Boolean)}
        expandedRowKeys={[expandedRowKey]}
        expandedRowRender={(category) => (
          <div style={{ fontSize: 16 }}>
            {{
              "Analytics": [
                {
                  name: 'UD Score:',
                  masterRules: [],
                  selectedEntities: selectedLaneScore,
                },
                {
                  name: 'Origin MCI:',
                  masterRules: [],
                  selectedEntities: selectedOriginMci,
                },
                {
                  name: 'Destination MCI:',
                  masterRules: [],
                  selectedEntities: selectedDestinationMci,
                },
                {
                  name: 'Origin L/T:',
                  masterRules: [],
                  selectedEntities: selectedOriginLT,
                },
                {
                  name: 'Destination L/T:',
                  masterRules: [],
                  selectedEntities: selectedDestinationLT,
                },

              ],
              [RULE_DATE_RANGE]: [
                {
                  name: 'Pickup:',
                  masterRules: masterRulesDatePickup,
                  selectedEntities: selectedDatePickup,
                },
                {
                  name: 'Delivery:',
                  masterRules: masterRulesDateDelivery,
                  selectedEntities: selectedDateDelivery,
                },
              ],
              [RULE_GEOGRAPHIC]: [
                {
                  name: 'Inbound (Region):',
                  masterRules: [],
                  selectedEntities: selectedRegionDelivery,
                },
                {
                  name: 'Inbound (State):',
                  masterRules: masterRulesGeographicInState,
                  selectedEntities: selectedGeographicInState,
                },
                {
                  name: 'Inbound (3 DigitZip):',
                  masterRules: masterRulesGeographicInZip,
                  selectedEntities: selectedGeographicInZip,
                },
                {
                  name: 'Inbound (Market):',
                  masterRules: [],
                  selectedEntities: selectedMarketDelivery,
                },
                {
                  name: 'Outbound (Region):',
                  masterRules: [],
                  selectedEntities: selectedRegionPickup,
                },
                {
                  name: 'Outbound (State):',
                  masterRules: masterRulesGeographicOutState,
                  selectedEntities: selectedGeographicOutState,
                },
                {
                  name: 'Outbound (3 DigitZip):',
                  masterRules: masterRulesGeographicOutZip,
                  selectedEntities: selectedGeographicOutZip,
                },
                {
                  name: 'Outbound (Market):',
                  masterRules: [],
                  selectedEntities: selectedMarketPickup,
                },

              ],
              [RULE_LENGTH]: [
                {
                  name: 'Rules:',
                  masterRules: masterRulesLength,
                  selectedEntities: selectedLength,
                },
              ],
              [RULE_LEAD_TIME]: [
                {
                  name: 'Rules:',
                  masterRules: masterRulesLeadTime,
                  selectedEntities: selectedLeadTime,
                },
              ],
              [MULTI_STEPS]: [
                {
                  name: 'Multisteps:',
                  masterRules: masterMultistep,
                  selectedEntities: selectedMultistep,
                },
              ],
              [RULE_SIMPLE]: [
                {
                  name: 'Pickup:',
                  masterRules: masterRulesSimplePickup,
                  selectedEntities: selectedSimplePickup,
                },
                {
                  name: 'Delivery:',
                  masterRules: masterRulesSimpleDelivery,
                  selectedEntities: selectedSimpleDelivery,
                },
              ],
              [RULE_EQUIPMENT]: [
                {
                  name: 'Rules:',
                  masterRules: masterRulesEquipment,
                  selectedEntities: selectedEquipment,
                },
              ],

            }[category.key].map(({ name, selectedEntities }, index) => (
              <div key={name} style={{ marginTop: index > 0 ? 8 : 0 }}>
                <Text style={{ color: colors.dark_grey.default, fontWeight: 700 }}>{name}</Text>{' '}
                {/* {showMasterRules && (
                  <span style={{ color: colors.light_blue.default }}>
                    {masterRules.map((item) => (item.name ? item.name : item.multistepName)).join(', ')}
                    {selectedEntities.length > 0 && masterRules.length > 0 && <span>, </span>}
                  </span>
                )} */}
                {selectedEntities.map((item) => item.value).join(', ')}
              </div>
            ))}
          </div>
        )}
        expandIconColumnIndex={-1}
        expandIcon={() => null}
        pagination={false}
        loading={loading}
      />
    </TableContainer>
  )
}

export default CategoriesAddedTable
