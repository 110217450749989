const fontBold = '700'
const fontRegular = '400'

const fonts = {
  header_1: {
    size: '20px',
    lineHeight: '16px',
    fontWeight: fontBold,
  },
  header_2: {
    size: '22px',
    lineHeight: '25px',
    fontWeight: fontBold,
  },
  header_3: {
    size: '40px',
    lineHeight: '47px',
    fontWeight: fontBold,
  },
  header_4: {
    size: '14px',
    lineHeight: '16px',
    fontWeight: fontRegular,
  },
  header_5: {
    size: '18px',
    lineHeight: '21px',
    fontWeight: fontRegular,
  },
  bodyText_1: {
    size: '14px',
    lineHeight: '16px',
    fontWeight: fontRegular,
  },
  bodyText_2: {
    size: '14px',
    lineHeight: '22px',
    fontWeight: fontBold,
  },
  bodyText_3: {
    size: '12px',
    lineHeight: '14px',
    fontWeight: fontRegular,
  },
  bodyText_4: {
    size: '18px',
    lineHeight: '21px',
    fontWeight: fontRegular,
  },
}

export default fonts
