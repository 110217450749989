import axios from 'axios'
// import queryString from 'querystring'

export default function (api) {
  return {
    getDatHistory: (body) => api.get('api/dat/history-rate', { params: { ...body } }),
    getAutocompleteHere: (params) => {
      return axios.get(process.env.REACT_APP_HERE_MAPS_URL_AUTOSUGGEST, {
        params: {
          q: params,
          at: '40.71455,-74.00714',
          apiKey: process.env.REACT_APP_HERE_MAPS_KEY,
          in: 'countryCode:USA',
          resultTypes: 'locality',
          limit: 6,
        },
      })
    },
    getRouteData: async (params) => {
      const response = await axios.get(
        `${process.env.REACT_APP_HERE_MAPS_ROUTE_URL}?transportMode=truck&apiKey=${process.env.REACT_APP_HERE_MAPS_KEY}&${params.routeParams}&return=summary`
      )
      return response
    },
    getZipHere: (entry) =>
      axios.get(process.env.REACT_APP_HERE_MAPS_URL, {
        params: {
          q: entry,
          country: 'USA',
          apiKey: process.env.REACT_APP_HERE_MAPS_KEY,
        },
      }),
    getAllZipHeres: async (entry) => { 
      const promiseArray = entry.map((element) =>
        axios.get(process.env.REACT_APP_HERE_MAPS_URL, {
          params: {
            q: element.searchparameter,
            country: 'USA',
            apiKey: process.env.REACT_APP_HERE_MAPS_KEY,
          },
        })
      )
      const results = await axios.all(promiseArray)
      return results
    },
    getCityFromZip: async (params) => {
      return axios.get(process.env.REACT_APP_HERE_MAPS_GEO_URL, {
        params: {
          postalCode: params,
          country: 'USA',
          apiKey: process.env.REACT_APP_HERE_MAPS_KEY,
        },
      })
    },
  }
}
