import styled from 'styled-components'
import Form from 'antd/lib/form'
import Typography from 'antd/lib/typography'

const { Text } = Typography
const { Item } = Form

export const StyledItem = styled(Item)`
  width: 100%;
  margin-bottom: 10px;
  .ant-form-item-label {
    padding: 0px;
    margin: 0px;
  }
`

export const StyledLabel = styled(Text)`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #545454;
`
