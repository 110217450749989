import React from 'react'
import { requestUpdateRuleset } from 'store/duck/updateRuleset.duck'
import Typography from 'antd/lib/typography'
import PriceLabel from 'components/Label/PriceLabel'
import BoxCell from 'components/Box/BoxCell'
import DeleteIcon from 'components/Icon/DeleteIcon'

const { Text } = Typography

const columns = (dispatch, shippingType, geographicType, rulesId, rulesetId) => {
  const handleDeleteRuleInRuleset = (recordId) => {
    const udateRules = rulesId.filter((e) => e !== recordId)
    const data = {
      id: rulesetId,
      rules: [...udateRules],
    }
    dispatch(requestUpdateRuleset(data))
  }

  return [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
      width: 130,
      ellipsis: true,
    },
    {
      title: geographicType === 'state' ? 'State' : '3 Digit Zip',
      dataIndex: 'geographic',
      key: 'geographic',
      colSpan: 3,
      render: () => {
        return <Text>{shippingType === 'outbound' ? 'For Outbound' : 'For Inbound'}</Text>
      },
      width: 100,
      ellipsis: true,
    },
    {
      title: '',
      dataIndex: ['rule', 'guide'],
      key: 'guide',
      colSpan: 0,
      render: (guide) => {
        return <PriceLabel>{guide}</PriceLabel>
      },
      width: 110,
      ellipsis: true,
    },
    {
      title: '',
      dataIndex: 'test',
      key: 'test',
      colSpan: 0,
      render: () => {
        return (
          <BoxCell>
            <Text>make these rate</Text>
            <Text>adjustments</Text>
          </BoxCell>
        )
      },
      width: 110,
      ellipsis: true,
    },
    {
      title: 'Percentage',
      dataIndex: ['rule', 'margin'],
      key: 'margin',
      render: (margin) => {
        return <PriceLabel>{margin} %</PriceLabel>
      },
      width: 75,
      ellipsis: true,
    },
    {
      title: 'Flat',
      dataIndex: ['rule', 'flat'],
      key: 'flat',
      render: (flat) => {
        return <PriceLabel>{flat}</PriceLabel>
      },
      width: 75,
      ellipsis: true,
    },
    {
      title: '',
      dataIndex: 'del',
      key: 'del',
      render: (del, record) => {
        return <DeleteIcon onClick={() => handleDeleteRuleInRuleset(record._id)} />
      },
      width: 50,
      align: 'center',
    },
  ]
}

export default columns
