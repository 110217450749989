import React from 'react'

import colors from 'providers/theme/config/colorPallete'

const MasterRulePill = ({ text }) => (
  <div
    style={{
      padding: '8px 35px',
      backgroundColor: colors.light_blue.default,
      borderRadius: 20,
    }}
  >
    <span style={{ fontSize: 16, fontWeight: 300, color: colors.white.default }}>{text}</span>
  </div>
)

export default MasterRulePill
