import React, { useState, useEffect } from 'react'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import { useDispatch, useSelector } from 'react-redux'
import { requestUpdateRuleset } from 'store/duck/updateRuleset.duck'
import Table from 'components/Table'
import ButtonPrimary from 'components/Button/ButtonPrimary'
import LengthColumns from './utils/LengthColumns'
import geographicColumns from './utils/GeographicColumns'
import rangeDateColumns from './utils/RangeDateColumns'
import simpleColumns from './utils/SimpleColumns'

const AddRuleTable = ({
  rules,
  totalRecords,
  handleTableChange,
  currentPage,
  rulesSelected,
  loading,
  typeRule,
}) => {
  const dispatch = useDispatch()
  const [columns, setColumns] = useState([])
  const [selectedRows, setSelectedRows] = useState(rulesSelected)
  const [lengthSelectedRows, setLengthSelectedRows] = useState(0)
  const { data: rulesetData } = useSelector((state) => state.getRulesetById)
  const { fetching } = useSelector((state) => state.updateRuleset)

  useEffect(() => {
    switch (typeRule.ruleTypeName) {
      case 'RuleLength':
        setColumns(LengthColumns)
        break
      case 'RuleGeographic': {
        const geographicCols = geographicColumns(typeRule.shipping, typeRule.reference)
        setColumns(geographicCols)
        break
      }
      case 'RuleSimple': {
        const simpleCols = simpleColumns(typeRule.shipping)
        setColumns(simpleCols)
        break
      }
      case 'RuleDateRange': {
        const tableColumns = rangeDateColumns(typeRule.shipping)
        setColumns(tableColumns)
        break
      }
      default:
        break
    }
  }, [typeRule])

  const handleAssignRules = () => {
    const initialSelected = [...rulesSelected]
    const initialRules = []
    rulesetData.rules.map((item) => {
      initialRules.push(item._id)
      return initialRules
    })
    initialSelected.map((ruleId) => {
      const i = initialRules.indexOf(ruleId)
      if (i !== -1) {
        initialRules.splice(i, 1)
      }
      return initialRules
    })
    const data = {
      id: rulesetData._id,
      rulesetName: rulesetData.rulesetName,
      rules: [...initialRules, ...selectedRows],
    }
    dispatch(requestUpdateRuleset(data))
  }

  const handleSelectChange = (selectedRowKeys) => {
    setLengthSelectedRows(selectedRowKeys.length)
    setSelectedRows([...selectedRowKeys])
  }

  const onRowSelected = {
    selectedRows,
    onChange: handleSelectChange,
  }

  return (
    <>
      <Table
        columns={columns}
        data={rules}
        loading={loading}
        rowSelection={onRowSelected}
        rowKey="_id"
        pagination={{ pageSize: 10, total: totalRecords, current: currentPage }}
        onChange={handleTableChange}
        width="96%"
        padding="4px 2px"
      />
      <Row justify="end">
        <Col span={6}>
          <ButtonPrimary
            width="170px"
            height="38px"
            onClick={handleAssignRules}
            disabled={fetching || lengthSelectedRows < 1}
          >
            Add Rules
          </ButtonPrimary>
        </Col>
      </Row>
    </>
  )
}

export default AddRuleTable
