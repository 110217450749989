export default function (state, action) {
  switch (action.type) {
    case 'toggle': {
      if (!!state.filter.find((element) => element === action.payload)) {
        const newEntry = state.filter.filter((index) => index !== action.payload)
        return { ...state, filter: [...newEntry] }
      } else return { ...state, filter: [...state.filter, action.payload] }
    }
    case 'all': {
      const arr = []
      action.payload.map((entry) => arr.push(entry.id))
      return { ...state, filter: arr }
    }
    default:
      return state
  }
}
