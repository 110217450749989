import React from 'react'
import Typography from 'antd/lib/typography' 
import AnalyticColumBase from './AnalyticsColumBase'

const { Text } = Typography

const LaneScoreColumns= (dispatch, openDrawer, enableEdit = true) => {
  const laneScoreColum = {
    title: <div style={{ fontSize: '16px' }}>UD Score</div>,
    dataIndex: ['rule', 'udScoreInit'],
    key: 'udScoreInit',
    render: (udScoreInit, rule) => {
      return (
        <Text style={{ fontSize: '16px', display: 'flex' }}>
          {udScoreInit}
          <span style={{ margin: '0px', padding: '0px', color: '#3594FF', fontWeight: '600' }}> & </span>
          {rule?.rule?.udScoreEnd}
        </Text>
      )
    },
    width: 100,
    ellipsis: true,
  }
  const base = [...AnalyticColumBase(dispatch, openDrawer, enableEdit)]
  base.splice(3, 0, laneScoreColum) 
  return [...base]
}

export default LaneScoreColumns
