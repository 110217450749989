import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: {},
  params: {},
}

export const { requestGetRouteData, successGetRouteData, failureGetRouteData, clearGetRouteData } = createActions({
  REQUEST_GET_ROUTE_DATA: (params) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    params,
  }),
  SUCCESS_GET_ROUTE_DATA: (data) => ({
    data,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_ROUTE_DATA: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  CLEAR_GET_ROUTE_DATA: () => ({
    ...defaultState,
  }),
})

const getRouteData = handleActions(
  {
    [requestGetRouteData]: produce((draft, { payload: { fetching, error, success, params } }) => {
      draft.fetching = fetching
      draft.error = error
      draft.success = success
      draft.params = params
    }),
    [successGetRouteData]: produce((draft, { payload: { message, fetching, success, data, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data.data
    }),
    [failureGetRouteData]: produce((draft, { payload: { message, fetching, error, success } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [clearGetRouteData]: produce((draft, { payload: { message, fetching, success, data, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data
    }),
  },
  defaultState
)

export default getRouteData
