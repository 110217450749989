import React, { useState, useEffect } from 'react'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import { useDispatch, useSelector } from 'react-redux'
import { requestAssignCustomers, clearAssignCustomers } from 'store/duck/assignCustomers.duck'
import { requestGetRulesetById } from 'store/duck/getRulesetById.duck'
import Table from 'components/Table'
import ButtonPrimary from 'components/Button/ButtonPrimary'
import Columns from './utils/TableColumns'

const CustomersRuleset = ({ customers, close, customerSelected, loading }) => {
  const dispatch = useDispatch()
  const [selectedRows, setSelectedRows] = useState(customerSelected)
  const { data } = useSelector((state) => state.getRulesetById)
  const { error, success, message } = useSelector((state) => state.assignCustomers)

  useEffect(() => {
    if (success) {
      dispatch(clearAssignCustomers())
      dispatch(requestGetRulesetById(data._id))
    }
    if (error) {
      dispatch(clearAssignCustomers())
    }
  }, [success, error, message, dispatch, data._id])

  const handleAssignCustomers = () => {
    const updateData = {
      id: data._id,
      customers: selectedRows,
    }
    dispatch(requestAssignCustomers(updateData))
  }

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: (selectedKeys) => {
      setSelectedRows(selectedKeys)
    },
    getCheckboxProps: (record) => {
      return {
        name: record.customer.name,
      }
    },
  }

  return (
    <>
      <span style={{ zIndex: '1', position: 'absolute', top: '130px', left: '94px' }}>Is Master RuleSet</span>
      <Table
        columns={Columns}
        data={customers}
        loading={loading}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        width="100%"
        rowKey={(record) => record.customer._id}
      />
      <Row justify="end" gutter={[0, 32]}>
        <Col span={4}>
          <ButtonPrimary
            width="170px"
            height="38px"
            onClick={() => {
              handleAssignCustomers()
              close(false)
            }}
          >
            Save
          </ButtonPrimary>
        </Col>
      </Row>
    </>
  )
}

export default CustomersRuleset
