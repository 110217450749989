import React from 'react'
import { InputGeneric } from 'components/Input'
import DrawerItem from 'components/Form/DrawerItem'
import ControlInput from 'components/Form/ControlInput'
import InputWithLabel from 'components/Form/InputWithLabel'

const NumberInput = ({ label, floatLabel, labelStyle, name, rules, ...props }) => (
  <InputWithLabel label={label} floatLabel={floatLabel} labelStyle={labelStyle}>
    {(hasValue) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <DrawerItem shouldUpdate style={{ marginBottom: 0 }} name={name} rules={rules}>
        <ControlInput hasValue={hasValue}>
          {({ value, onChange }) => { 
            // eslint-disable-next-line react/jsx-props-no-spreading
            return <InputGeneric type="number" size="large" value={value} onChange={onChange} {...props} />
          }}
        </ControlInput>
      </DrawerItem>
    )}
  </InputWithLabel>
)

export default NumberInput
