import React from 'react'
import FloatLabel from 'components/Label/FloatLabel'
import { FormItem } from 'components/Input'
import CustomSelect from 'components/CustomSelect'

export default function BidStatusFilter() {
    return (
        <FloatLabel label="Bid Status" show>
            <FormItem name="loadWon">
                <CustomSelect
                    showSearch
                    allowClear
                    placeholder=""
                    keysort="label"
                    size="large"
                    options={[
                        {
                            value: true,
                            label: 'WON',
                        },
                        {
                            value: false,
                            label: 'LOST',
                        },
                    ]}
                    filterOption={(input, option) => {
                        return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                />
            </FormItem>
        </FloatLabel>)
}
