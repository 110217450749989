import { put, takeLatest, call } from 'redux-saga/effects'
import { rules } from 'services'
import { requestGetMultiSteps, successGetMultiSteps, failureGetMultiSteps } from '../duck/getMultiSteps.duck'

function* getMultiStepsAsync(data) {
  try {
    const response = yield call(rules.getMultiSteps, data.payload.params)
    yield put(successGetMultiSteps(response))
  } catch (error) {
    yield put(failureGetMultiSteps(error))
  }
}

function* watchGetMultiStepsAsync() {
  yield takeLatest(requestGetMultiSteps, getMultiStepsAsync)
}

export default watchGetMultiStepsAsync
