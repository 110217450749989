import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: {},
  editCustomer: false,
  customerSelected: {},
}

export const {
  requestCreateCustomer,
  successCreateCustomer,
  failureCreateCustomer,
  resetCreateCustomer,
  loadCustomer,
  addCustomer,
  unloadCustomer,
} = createActions({
  REQUEST_CREATE_CUSTOMER: (data) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    data,
  }),
  SUCCESS_CREATE_CUSTOMER: (data) => ({
    data,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_CREATE_CUSTOMER: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  RESET_CREATE_CUSTOMER: () => ({
    ...defaultState,
  }),
  LOAD_CUSTOMER: (data) => ({
    editCustomer: true,
    customerSelected: data,
  }),
  ADD_RULESET: () => ({
    editCustomer: false,
  }),
  UNLOAD_CUSTOMER: () => ({
    editCustomer: false,
    customerSelected: defaultState.customerSelected,
  }),
})

const createCustomer = handleActions(
  {
    [requestCreateCustomer]: produce((draft, { payload: { fetching, data, error, success } }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successCreateCustomer]: produce((draft, { payload: { message, fetching, success, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = false
      draft.data = data
    }),
    [failureCreateCustomer]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = false
    }),
    [resetCreateCustomer]: produce((draft, { payload: { fetching, data, error, success } }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [loadCustomer]: produce((draft, { payload: { editCustomer, customerSelected } }) => {
      draft.editCustomer = editCustomer
      draft.customerSelected = customerSelected
    }),
    [addCustomer]: produce((draft, { payload: { editCustomer } }) => {
      draft.editCustomer = editCustomer
    }),
    [unloadCustomer]: produce((draft, { payload: { editCustomer, customerSelected } }) => {
      draft.editCustomer = editCustomer
      draft.customerSelected = customerSelected
    }),
  },
  defaultState
)

export default createCustomer
