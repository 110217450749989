import PropTypes from 'prop-types'
import AddRuleTable from './AddRuleTable'

AddRuleTable.propTypes = {
  rules: PropTypes.array.isRequired,
  totalRecords: PropTypes.number.isRequired,
  handleTableChange: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  rulesSelected: PropTypes.array.isRequired,
  typeRule: PropTypes.object.isRequired,
  loading: PropTypes.bool,
}

AddRuleTable.defaultProps = {
  loading: false,
}

export default AddRuleTable
