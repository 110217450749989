import { put, takeLatest, call } from 'redux-saga/effects'
import { rates } from 'services'
import { requestSaveQuote, successSaveQuote, failureSaveQuote } from '../duck/saveQuote.duck'

function* saveQuoteAsync(data) {
  try {
    const response = yield call(rates.createRate, data.payload.data)
    yield put(successSaveQuote(response))
  } catch (error) {
    yield put(failureSaveQuote(error))
  }
}

function* watchSaveQuoteAsync() {
  yield takeLatest(requestSaveQuote, saveQuoteAsync)
}

export default watchSaveQuoteAsync
