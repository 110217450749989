import PropTypes from 'prop-types'
import InputEditable from './InputEditable'

InputEditable.propTypes = {
  name: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
}

export default InputEditable
