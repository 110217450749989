import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  rate: [],
  rateSelected: {},
  editRate: false,
}

export const {
  requestCreateRate,
  successCreateRate,
  failureCreateRate,
  loadRateSelected,
  clearCreateRate,
} = createActions({
  REQUEST_CREATE_RATE: (rate) => ({
    ...defaultState,
    fetching: true,
    rate,
  }),
  SUCCESS_CREATE_RATE: (rate) => ({
    rate,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_CREATE_RATE: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  LOAD_RATE_SELECTED: (data) => ({
    ...defaultState,
    rateSelected: data,
    editRate: true,
  }),
  CLEAR_CREATE_RATE: () => ({
    ...defaultState,
  }),
})

const createRate = handleActions(
  {
    [requestCreateRate]: produce((draft, { payload: { fetching, rate, error, success } }) => {
      draft.rate = rate
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successCreateRate]: produce((draft, { payload: { message, fetching, success, rate, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.rate = rate
    }),
    [failureCreateRate]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = !error
    }),
    [loadRateSelected]: produce((draft, { payload: { rateSelected, editRate } }) => {
      draft.rateSelected = rateSelected
      draft.editRate = editRate
    }),
    [clearCreateRate]: produce(
      (draft, { payload: { message, fetching, success, rate, error, rateSelected, editRate } }) => {
        draft.message = message
        draft.fetching = fetching
        draft.success = success
        draft.error = error
        draft.rate = rate
        draft.rateSelected = rateSelected
        draft.editRate = editRate
      }
    ),
  },
  defaultState
)

export default createRate
