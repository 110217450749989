import styled from 'styled-components'
import Collapse from 'antd/lib/collapse'

const SytledCollapse = styled(Collapse)`
  .ant-collapse-header {
    margin-top: 8px;
    padding: 0 0 8px 0 !important;
    color: ${(props) => props.theme.colors.dark_grey.default};
    border-bottom: 1px solid #ffffff;
  }
  .ant-collapse-arrow {
    padding: 0 2px !important;
    font-size: 11px !important;
  }
  .ant-collapse-content {
    border-top: 1px solid rgba(217, 217, 217, 0.4) !important;
    margin-top: -1px;
  }
  .ant-collapse-content-box {
    padding: 12px 0 !important;
  }
`

export default SytledCollapse
