import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  customers: [],
  totalRecords: 0,
  params: {},
  singleCustomer: {},
}

export const {
  requestGetCustomers,
  successGetCustomers,
  failureGetCustomers,
  requestSingleCustomer,
} = createActions({
  REQUEST_GET_CUSTOMERS: (params) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    params,
  }),
  SUCCESS_GET_CUSTOMERS: (customers) => ({
    customers: customers.data,
    totalRecords: customers.totalRecords,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_CUSTOMERS: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  REQUEST_SINGLE_CUSTOMER: (customer) => ({
    customer,
  }),
})

const getCustomers = handleActions(
  {
    [requestGetCustomers]: produce((draft, { payload: { fetching, error, success } }) => {
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successGetCustomers]: produce(
      (draft, { payload: { message, fetching, success, customers, totalRecords, error } }) => {
        draft.message = message
        draft.fetching = fetching
        draft.success = success
        draft.error = error
        draft.customers = customers
        draft.totalRecords = totalRecords
      }
    ),
    [failureGetCustomers]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = !error
    }),
    [requestSingleCustomer]: produce((draft, { payload: { customer } }) => {
      draft.singleCustomer = customer
    }),
  },
  defaultState
)

export default getCustomers
