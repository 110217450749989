import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: [], 
  params: {},
}

export const { requestGetStatistics, successGetStatistics, failureGetStatistics } = createActions({
  REQUEST_GET_STATISTICS: (params) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    params,
  }),
  SUCCESS_GET_STATISTICS: (data) => ({
    data, 
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_STATISTICS: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
})

const getStatistics = handleActions(
  {
    [requestGetStatistics]: produce((draft, { payload: { fetching, params, error, success } }) => {
      draft.params = params
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successGetStatistics]: produce(
      (draft, { payload: { message, fetching, success, data, error } }) => {
        draft.message = message
        draft.fetching = fetching
        draft.success = success
        draft.error = error
        draft.data = data 
      }
    ),
    [failureGetStatistics]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = !error
    }),
  },
  defaultState
)

export default getStatistics
