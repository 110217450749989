import React from 'react'
import { DatePicker } from 'antd'
import DrawerItem from 'components/Form/DrawerItem'
import ControlInput from 'components/Form/ControlInput'
import InputWithLabel from 'components/Form/InputWithLabel'
import moment from 'moment'

const { RangePicker } = DatePicker

const DateRange = ({ label, name, rules, allowFutureDates = false, ...props }) => {
  const disabledDate = (current) => !allowFutureDates && current.isAfter(moment())

  return (
    <InputWithLabel label={label} floatLabel>
      {(hasValue) => (
        <DrawerItem name={name} rules={rules} style={{ marginBottom: 0, paddingTop: '2px' }}>
          <ControlInput hasValue={hasValue}>
            {({ value = [null, null], onChange }) => (
              <RangePicker
                allowEmpty={[true, true]}
                style={{ height: '40px', borderRadius: '4px !important', fontSize: '14px', marginBottom: 0 }}
                format="MM-DD-YYYY"
                onChange={(dates, dateStrings) => {
                  onChange({ dates, dateStrings })
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...(allowFutureDates ? {} : { disabledDate })}
                defaultValue={value}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          </ControlInput>
        </DrawerItem>
      )}
    </InputWithLabel>
  )
}

export default DateRange
