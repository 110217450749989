import styled from 'styled-components'
import { Button } from 'antd'

const StyledButton = styled(Button)`
  background: #3594ff;
  border: none;
  color: white;
  width: ${(props) => props.width ?? '100%'};
  height: ${(props) => props.height ?? '40px'};
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
`

export default StyledButton
