import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  trustPercentages: [],
  totalRecords: 0,
  params: {},
}

export const {
  requestGetTrustPercentages,
  successGetTrustPercentages,
  failureGetTrustPercentages,
  clearGetTrustPercentages,
} = createActions({
  REQUEST_GET_TRUST_PERCENTAGES: (params) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    params,
  }),
  SUCCESS_GET_TRUST_PERCENTAGES: (trustPercentages) => ({
    trustPercentages: trustPercentages.data,
    totalRecords: trustPercentages.totalRecords,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_TRUST_PERCENTAGES: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  CLEAR_GET_TRUST_PERCENTAGES: () => ({
    error: false,
    success: false,
  }),
})

const getTrustPercentages = handleActions(
  {
    [requestGetTrustPercentages]: produce((draft, { payload: { fetching, error, success, params } }) => {
      draft.fetching = fetching
      draft.error = error
      draft.success = success
      draft.params = params
    }),
    [successGetTrustPercentages]: produce(
      (draft, { payload: { message, fetching, success, trustPercentages, totalRecords, error } }) => {
        draft.message = message
        draft.fetching = fetching
        draft.success = success
        draft.error = error
        draft.trustPercentages = trustPercentages
        draft.totalRecords = totalRecords
      }
    ),
    [failureGetTrustPercentages]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = !error
    }),
    [clearGetTrustPercentages]: produce((draft, { payload: { success, error } }) => {
      draft.success = success
      draft.error = error
    }),
  },
  defaultState
)

export default getTrustPercentages
