import { put, takeLatest, call } from 'redux-saga/effects'
import { rules } from 'services'
import {
  requestUpdateRule,
  successUpdateRule,
  failureUpdateRule,
} from '../duck/updateRule.duck'

function* updateRuleAsync(data) {
  try {
    const response = yield call(rules.updateRule, data.payload.data)
    yield put(successUpdateRule(response))
  } catch (error) {
    yield put(failureUpdateRule(error.response.data.message))
  }
}

function* watchUpdateRuleAsync() {
  yield takeLatest(requestUpdateRule, updateRuleAsync)
}

export default watchUpdateRuleAsync
