export default function (api) {
  return {
    getAllTrustPercentage: () => api.get('api/confidence-percentages'),
    getTrustPercentage: (params) =>
      api.get('api/confidence-percentages', {
        params: {
          limit: 20,
          ...params,
        },
      }),
    assignTrustPercentage: (data) => api.post('api/confidence-percentages', data),
    updateTrustPercentage: (percentageData) =>
      api.put(`api/confidence-percentages/${percentageData.id}`, percentageData),
  }
}
