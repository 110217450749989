import PropTypes from 'prop-types'
import LoadDateRangeRule from './LoadDateRangeRule'

LoadDateRangeRule.propTypes = {
  handleFetchRules: PropTypes.func.isRequired,
  rules: PropTypes.object.isRequired,
  rulesetData: PropTypes.object.isRequired,
  shippingData: PropTypes.object.isRequired,
}

export default LoadDateRangeRule
