import styled from 'styled-components'
import Typography from 'antd/lib/typography'

const { Text } = Typography

export const TitleContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  margin-bottom: ${(props) => props.mgBottom};
`

export const StyledTitle = styled(Text)`
  font-family: 'Roboto', sans-serif;
  font-size: 32px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #002555;
`
