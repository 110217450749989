import React from 'react'
import PropTypes from 'prop-types'
import ContentContainer from './ContentContainer'

ContentContainer.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]),
  left: PropTypes.node,
  right: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.string.isRequired]),
  subHeader: PropTypes.node,
}

ContentContainer.defaultProps = {
  subHeader: <div />,
  left: <div />,
}

export default ContentContainer
