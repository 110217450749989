import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  selectedCustomer: "", 
}

export const { requestSaveSelectedCustomer, successSaveSelectedCustomer, failureSaveSelectedCustomer, clearSaveSelectedCustomer } = createActions({
  REQUEST_SAVE_SELECTED_CUSTOMER: (selectedCustomer) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    selectedCustomer,
  }),
  SUCCESS_SAVE_SELECTED_CUSTOMER: (selectedCustomer) => ({
    selectedCustomer,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_SAVE_SELECTED_CUSTOMER: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  CLEAR_SAVE_SELECTED_CUSTOMER: () => ({
    ...defaultState,
  }),
})

const saveSelectedCustomer = handleActions(
  {
    [requestSaveSelectedCustomer]: produce((draft, { payload: { fetching, error, success, selectedCustomer } }) => {
      draft.fetching = fetching
      draft.error = error
      draft.success = success
      draft.selectedCustomer = selectedCustomer
    }),
    [successSaveSelectedCustomer]: produce((draft, { payload: { message, fetching, success, selectedCustomer, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.selectedCustomer = selectedCustomer
    }),
    [failureSaveSelectedCustomer]: produce((draft, { payload: { message, fetching, error, success } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [clearSaveSelectedCustomer]: produce((draft, { payload: { message, fetching, error, success, selectedCustomer } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
      draft.selectedCustomer = selectedCustomer
    }),
  },
  defaultState
)

export default saveSelectedCustomer
