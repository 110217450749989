import { put, takeLatest, call } from 'redux-saga/effects'
import { specialServices } from 'services'
import {
  requestActivateSpecialService,
  successActivateSpecialService,
  failureActivateSpecialService,
} from 'store/duck/activateSpecialService.duck'

function* activateSpecialServiceRequestAsync(data) {
  try {
    const response = yield call(specialServices.editSpecialService, data.payload.data, data.payload.specialServiceId)
    yield put(successActivateSpecialService(response, 'success'))
  } catch (error) {
    yield put(failureActivateSpecialService(error.response.data.message))
  }
}

function* watchActivateSpecialServiceAsync() {
  yield takeLatest(requestActivateSpecialService, activateSpecialServiceRequestAsync)
}

export default watchActivateSpecialServiceAsync