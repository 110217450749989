import React from 'react'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import NumberInput from 'components/Form/NumerInput'
import { StyledText } from '../styles'

const renderLeadTimeRule = (enableEdit = true) => {
  return (
    <Row gutter={10}>
      <Col span={24} style={{ marginBottom: 10 }}>
        <StyledText>If lead time is between</StyledText>
      </Col>
      <Col span={12}>
        <NumberInput
          disabled={!enableEdit}
          name="minLeadTime"
          rules={[
            { required: true, message: 'Please enter a value' },
            {
              type: 'number',  
              transform: Number,
            },
          ]}
        />
      </Col>
      <Col span={12}>
        <NumberInput
          disabled={!enableEdit}
          name="maxLeadTime"
          rules={[
            { required: true, message: 'Please enter a value' },
            {
              type: 'number',
              max: 100,
              message: 'The value should not be more than 100',
              transform: Number,
            },
          ]}
        />
      </Col>
    </Row>
  )
}
export default renderLeadTimeRule
