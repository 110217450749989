import { put, takeLatest, call } from 'redux-saga/effects'
import { rules } from 'services'
import {
  requestDeleteBulkRuleset,
  successDeleteBulkRuleset,
  failureDeleteBulkRuleset,
} from '../duck/deleteBulkRuleset.duck'

function* deleteBulkRulesetAsync(data) {
  try {
    const response = yield call(rules.deleteRuleset, data.payload.itemId)
    yield put(successDeleteBulkRuleset(response))
  } catch (error) {
    yield put(failureDeleteBulkRuleset(error))
  }
}

function* watchBulkDeleteRulesetAsync() {
  yield takeLatest(requestDeleteBulkRuleset, deleteBulkRulesetAsync)
}

export default watchBulkDeleteRulesetAsync
