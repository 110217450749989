export const compareValues = (key, order = 'asc') => (elemA, elemB) => {
  if (!Object.prototype.hasOwnProperty.call(elemA, key) || !Object.prototype.hasOwnProperty.call(elemB, key))
    return 0
  const comparison = elemA[key]?.localeCompare(elemB[key])
  return order === 'desc' ? comparison * -1 : comparison
}

export const AlphabethicSort = (data=[],key="") => {
  return [...data].sort(compareValues(key))
}

 