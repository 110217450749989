import React from 'react'
import { TitleContainer, StyledTitle } from './styles'

const AuthTitle = ({ marginBotton }) => {
  return (
    <TitleContainer mgBottom={marginBotton}>
      <StyledTitle>Welcome to</StyledTitle>
      <StyledTitle><b>The Ally Logistics Pricing Engine</b></StyledTitle>
    </TitleContainer>
  )
}


export default AuthTitle
