import { put, takeLatest, call } from 'redux-saga/effects'
import { rules } from 'services/'
import { parseRulesType } from 'utils/parseResponse'
import { requestGetRuleType, successGetRuleType, failureGetRuleType } from '../duck/getRuleType.duck'

function* getRuleTypeAsync() {
  try {
    const response = yield call(rules.getRuleTypes) 
    const typesId = parseRulesType(response)
    yield put(successGetRuleType(response, typesId))
  } catch (error) {
    yield put(failureGetRuleType(error))
  }
}

function* watchGetRuleTypeAsync() {
  yield takeLatest(requestGetRuleType, getRuleTypeAsync)
}

export default watchGetRuleTypeAsync
