import React, { useEffect, useState } from 'react'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import FloatLabel from 'components/Label/FloatLabel'
import {
  FormItem,
  //  InputDatePicker
} from 'components/Input'
import CustomSelect from 'components/CustomSelect'
import { Form, DatePicker } from 'antd'
// import { AiTwotoneCalendar } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { formatCustomer } from 'utils/formatValues'
import { requestGetAllCustomer } from 'store/duck/getAllCustomer.duck'
import { ArrowDownIcon } from 'assets/svg'
import { requestGetTotalStatistics } from 'store/duck/getTotalStatistics.duck'
import moment from 'moment'

const defaultValues = {
  customer: '',
}

const { RangePicker } = DatePicker

export default function FilterTable() {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [totalCustomer, setTotalCustomers] = useState([])

  const { customers } = useSelector((state) => ({
    customers: state.getAllCustomer.data,
  }))

  const [initDate, setinitDate] = useState(moment().subtract(7, 'days').format('MM/DD/YYYY'))
  const [endDate, setinendDate] = useState(moment().format('MM/DD/YYYY'))

  useEffect(() => {
    const filterInfo = form.getFieldsValue()
    Object.keys(filterInfo).forEach((key) => (filterInfo[key] === undefined ? delete filterInfo[key] : {}))
    const dataForm = {}
    for (const property in filterInfo) {
      if (filterInfo[property]) {
        dataForm[property] = filterInfo[property]
      }
    }
    dispatch(requestGetTotalStatistics({ ...dataForm, initDate, endDate }))
  }, [form, dispatch, initDate, endDate])

  useEffect(() => {
    setTotalCustomers(formatCustomer(customers))
  }, [customers])

  const handleFinish = (data) => {
    dispatch(requestGetTotalStatistics(data))
  }

  return (
    <Form
      form={form}
      onFinish={handleFinish}
      initialValues={{
        ...defaultValues,
      }}
      onValuesChange={(changedValues, allValues) => {
        const newValues = { ...allValues }
        Object.keys(newValues).forEach((key) => newValues[key] === undefined && delete newValues[key])
        dispatch(requestGetTotalStatistics(newValues))
      }}
      layout="vertical"
      name="filter"
      id="filter"
    >
      <Row gutter={[16, 8]}>
        <Col span={8}>
          <FloatLabel label="Customer" show>
            <FormItem name="customer">
              <CustomSelect
                allowClear
                onSearch={(value) => {
                  dispatch(requestGetAllCustomer({ text: value, active: true }))
                }}
                optionFilterProp="label"
                keysort="label"
                showSearch
                placeholder="Customer"
                suffixIcon={<ArrowDownIcon />}
                options={totalCustomer}
              />
            </FormItem>
          </FloatLabel>
        </Col>
        <Col span={8}>
          <FloatLabel label="Timeframe" show topLabel="-10px">
            <RangePicker
              format="MM-DD-YYYY"
              onChange={(dates, dateStrings) => {
                setinitDate(dateStrings[0])
                setinendDate(dateStrings[1]) 
              }}
              disabledDate={(current) => current.isAfter(moment())}
              defaultValue={[moment().subtract(7, 'days'), moment()]}
            />
          </FloatLabel>
        </Col>
      </Row>
    </Form>
  )
}
