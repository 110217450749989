import React from 'react'
import ConfirmPassword from '../ConfirmPassword'

const ActiveAccount = () => {
  return (
    <ConfirmPassword
      title="Create a password"
      passwordInputLabel="password"
      submitButtonText="Activate Account"
    />
  )
}

export default ActiveAccount
