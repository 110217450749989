import styled from 'styled-components'
import Row from 'antd/lib/row'

export const ErrorContainer = styled(Row)`
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  background-color: #FFF;
  top: 0;
  left: 0;
  position: fixed; 
  padding-top: 30px;
` 

export const ErrorWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
`
