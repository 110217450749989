import produce from 'immer'
import { createActions, handleActions } from 'redux-actions' 

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  gsRate: [],
  params: {},
}

export const { requestGetGsRate, successGetGsRate, failureGetGsRate, clearGetGsRate, filterGraphics, setGraphics } =
  createActions({
    REQUEST_GET_GS_RATE: (params) => ({
      ...defaultState,
      fetching: true,
      error: false,
      success: false,
      params,
    }),
    SUCCESS_GET_GS_RATE: (gsRate) => ({
      gsRate,
      message: '',
      fetching: false,
      error: false,
      success: true,
    }),
    FAILURE_GET_GS_RATE: (message) => ({
      message,
      fetching: false,
      error: true,
      success: false,
    }),
    CLEAR_GET_GS_RATE: () => ({
      ...defaultState,
    }),
    FILTER_GRAPHICS: (filter) => ({
      filter,
    }),
    SET_GRAPHICS: () => ({}),
  })

const getGsRate = handleActions(
  {
    [requestGetGsRate]: produce((draft, { payload: { fetching, error, success } }) => {
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successGetGsRate]: produce((draft, { payload: { message, fetching, success, gsRate, error } }) => { 
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.gsRate = gsRate
    }),
    [failureGetGsRate]: produce((draft, { payload: { message, fetching, error, success } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [clearGetGsRate]: produce((draft, { payload: { message, fetching, success, gsRate, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.gsRate = gsRate 
    }), 
    
  },
  defaultState
)

export default getGsRate
