/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React from 'react'
import StyledSelect from 'components/Input/Select'
import { AlphabethicSort } from 'utils/AlphabeticSort'

/**
 *
 * @param {*} props All props what it can receive Select from Ant-design
 * @param {*} disablesort Boolean value to avoid the default Alphabethic Sort
 * @param {*} keysort string to indicate a diferent key to evaluate the Alphabethic Sort
 * @returns
 */

export default function CustomSelect(props) {
  const key = props.keysort ?? 'value'
  const disablesort = props.disablesort || 'false'

  const options = React.useMemo(() => AlphabethicSort(props?.options, key), [props, key])
  const newProps = { ...props }
  if (disablesort === 'false' && props?.options) {
    newProps.options = options
  }
  if (props.Custom) {
    const { Custom } = props
    return <Custom {...newProps} />
  }
  return <StyledSelect {...newProps} />
}
