import React from 'react'
import Select from 'antd/lib/select'
import DrawerItem from 'components/Form/DrawerItem'
import ControlInput from 'components/Form/ControlInput'
import InputWithLabel from 'components/Form/InputWithLabel'

const SelectInput = ({ label, name, rules, children }) => (
  <InputWithLabel label={label}>
    {(hasValue) => (
      <DrawerItem name={name} rules={rules} style={{ marginBottom: 0 }} shouldUpdate>
        <ControlInput hasValue={hasValue}>
          {({ value, onChange }) => (
            <Select size="large" value={value} onChange={onChange}>
              {children}
            </Select>
          )}
        </ControlInput>
      </DrawerItem>
    )}
  </InputWithLabel>
)

export default SelectInput
