import React, { useEffect } from 'react'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col' 
import notification from 'antd/lib/notification'
import { useSelector } from 'react-redux'
import CreateRate from 'containers/rates/CreateRate'
import DashboardTab from 'containers/dashboard/DashboardTab'
import CustomerRate from 'containers/rates/CustomerRate'
import CarrierCost from 'containers/dashboard/CarrierCost'
import { Container } from './styles'
 

const Dashboard = () => { 
  const { success, rate,role } = useSelector((state) => ({
    success: state.getRate.success,
    rate: state.getRate.rate,
    role: state.login.data.roleId ? state.login.data.roleId.name : '',
  }))

  useEffect(() => {
    if (!success) return
    rate.notifications.forEach((entry, key) => {
      notification.error({
        message: 'Third Party Integration error',
        description: entry.message,
        placement: 'topRight',
        duration: 5,
        key,
      })
    })
  }, [rate, success])


  return (
    <Container style={{ paddingTop: '0px' }}>
      <Row gutter={[16, 16, 16]}>
        <Col md={{ span: 24 }} xl={{ span: 24 }} xxl={{ span: 24 }}>
          <Col span={24}>
            <CreateRate />
          </Col>
        </Col>
        <Col md={{ span: 16 }} xl={{ span: 16 }} xxl={{ span: 16 }} style={role === "Lite" ? { display: "none" } : {}}>
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <DashboardTab />
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 7 }}>
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <CustomerRate />
            </Col>
            <Col span={24} style={role === "Lite" ? { display: "none" } : {}}>
              <CarrierCost />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default Dashboard
