export default function (api) {
  return {
    getStatistics: async (params) =>
      api.get('api/statistics/dat-calls', {
        params: {
          ...params,
        },
      }),
    getTotalStatistics: async (params) =>
      api.get('api/statistics/dat-total-calls', {
        params: {
          ...params,
        },
      }),
  }
}
