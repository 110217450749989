import { put, takeLatest, call } from 'redux-saga/effects'
import { users } from 'services'
import { requestActivateUser, successActivateUser, failureActivateUser } from 'store/duck/activateUser.duck'

function* activateUserRequestAsync(data) {
  try {
    const response = yield call(users.editUser, data.payload.data, data.payload.userId)
    yield put(successActivateUser(response, 'success'))
  } catch (error) {
    yield put(failureActivateUser(error.response.data.message))
  }
}

function* watchActivateUserAsync() {
  yield takeLatest(requestActivateUser, activateUserRequestAsync)
}

export default watchActivateUserAsync
