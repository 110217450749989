import React from 'react'
import { useSelector } from 'react-redux'
import Graph from 'containers/rates/Graph'
import Empty from 'antd/lib/empty'
import Spin from 'antd/lib/spin'
import { formatStatistics } from 'utils/formatGraph'
import moment from 'moment'
import { ContainerGraphics } from './styles' 

export default function GraphReporting() {
  const { data, success, fetching, params } = useSelector((state) => state.getStatistics)

  const DisplayGraph = ({ dataSet = [] }) => {
    const formatData = formatStatistics(dataSet, params?.call_type ?? 'Report')  
    if (dataSet.length > 0) {
      return (
        <ContainerGraphics>
          <Graph
            margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
            customLeft={{
              format: (value) => {
                const result = value - Math.floor(value) !== 0
                return !result && value
              },
            }}
            data={formatData}
            success
            width={1000}
            yScale={{
              type: 'linear',
              stacked: false,
              min: 0,
              max: 'auto',
              reverse: false,
            }} 
            axisBottom={{
            format: (value) => moment(value).format('MMM DD'),
          }}
          />
        </ContainerGraphics>
      )
    }
    return (
      <ContainerGraphics>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </ContainerGraphics>
    )
  }

  const LoadGraph = ({ datas = [], fetch = false }) => {
    if (fetch) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '200px' }}>
          <Spin size="large" />
        </div>
      )
    }
    return <DisplayGraph dataSet={datas} />
  }

  return (
    <div>
      {!success && !fetching ? (
        <div>
          <p>Empty section, please, enter the data in the filter.</p>
        </div>
      ) : (
        <LoadGraph datas={data} fetch={fetching} />
      )}
    </div>
  )
}
