import createSagaMiddleware from 'redux-saga'
import { createStore,compose, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import LogRocket from 'logrocket'
import storage from 'redux-persist/lib/storage'
import { logger } from 'redux-logger'
import { enhancer as withReduxEnhancer } from 'addon-redux'
import reducers from './duck'
import rootSaga from './saga'

const sagaMiddleware = createSagaMiddleware()
const persistConfig = {
  key: 'login',
  storage,
  whitelist: ['login'],
}

const persistedReducer = persistReducer(persistConfig, reducers)
const applyMiddlewareOptions = [sagaMiddleware, LogRocket.reduxMiddleware()]
if (process.env.NODE_ENV === `development`) applyMiddlewareOptions.push(logger)
const enhancers = [withReduxEnhancer, applyMiddleware(...applyMiddlewareOptions)]; 
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(persistedReducer, composeEnhancers(...enhancers))
sagaMiddleware.run(rootSaga)
const persistor = persistStore(store)

export { store, persistor }
