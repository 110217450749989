import styled from 'styled-components'
import { Checkbox, Form, Upload } from 'antd'
import Typography from 'antd/lib/typography'
import colors from 'providers/theme/config/colorPallete'
import Input from 'antd/lib/input'

const { Text } = Typography

export const BodyLabel = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  color: rgb(110, 127, 141);
`

export const StyledItem = styled(Form.Item)`
  width: 100%;
  margin-bottom: 10px;
  .ant-form-item-label {
    padding: 0px;
    margin: 0px;
  }
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 400;
    color: rgb(110, 127, 141);
  }
`

export const StyledUpload = styled(Upload)`
  .ant-upload.ant-upload-select-picture-card {
    margin: auto;
    background-color: transparent;
    border: none;
    float: none;
  }
`

export const InputPassword = styled(Input.Password)`
  width: ${(props) => (props.width ? props.width : '100%')};
  .ant-input-prefix,
  .ant-input-suffix {
    color: #002555;
    font-weight: 700;
    font-size: 14px;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const BlueCheckbox = styled(Checkbox)`
  .ant-checkbox {
    outline: 2px solid ${colors.dark_blue.default};
    border-radius: 2px;
  }

  .ant-checkbox::after,
  .ant-checkbox-checked::after {
    border: 1px solid ${colors.dark_blue.default};
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${colors.dark_blue.default};
  }
`
