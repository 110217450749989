import { put, takeLatest, call } from 'redux-saga/effects'

import { customers } from 'services'

import {
  requestDeactivateCustomer,
  successDeactivateCustomer,
  failureDeactivateCustomer,
} from 'store/duck/deleteCustomer.duck'

function* deactivateCustomerAsync(data) {
  try {
    const response = yield call(customers.deleteCustomer, data.payload.params)
    yield put(successDeactivateCustomer(response, 'success'))
  } catch (error) {
    yield put(failureDeactivateCustomer(error.response.data.message))
  }
}

function* watchDeactivateCustomerAsync() {
  yield takeLatest(requestDeactivateCustomer, deactivateCustomerAsync)
}

export default watchDeactivateCustomerAsync
