import styled from 'styled-components'
import InputGeneric from '../InputGeneric'

const InputMedium = styled(InputGeneric)`
  height: 40px;
  border-radius: 4px;
  :read-only { 
    cursor: not-allowed;
    color: #A1A1A1;
    border-color: #dedcdc;
    :focus{
      box-shadow: none;
    }
  }
  input {
    line-height: 16px;
    font-size: 16px;
    ::placeholder {
      color: #545454;
      font-size: 16px;
      line-height: 16px;
    }
  }
`
export default InputMedium
