import PropTypes from 'prop-types'
import UserNameAvatar from './UserNameAvatar'

UserNameAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  avatar: PropTypes.string,
}
UserNameAvatar.defaultProps = {
  avatar: '',
}

export default UserNameAvatar
