import React from 'react'
import TextPrimary from 'components/Text/TextPrimary'

const Columns = [
  {
    title: 'Special Service Name',
    dataIndex: 'name',
    key: 'name',
    align: 'left',
    width: 200,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    align: 'left',
    width: 200,
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    render: (price) => <TextPrimary>${price}</TextPrimary>,
    width: 50,
  },
]

export default Columns
