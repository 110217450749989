import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  image: '',
  name: '',
  lastName: '',
}

export const { requestUpdateAvatar, clearUpdateAvatar } = createActions({
  REQUEST_UPDATE_AVATAR: (image, name, lastName) => ({
    image,
    name,
    lastName,
  }),
  CLEAR_UPDATE_AVATAR: () => ({
    ...defaultState
  })
})

const updateAvatar = handleActions(
  {
    [requestUpdateAvatar]: produce((draft, { payload: { image, name, lastName } }) => {
      draft.image = image
      draft.name = name
      draft.lastName = lastName
    }),
    [clearUpdateAvatar]: produce((draft, { payload: { image, name, lastName } }) => {
      draft.image = image
      draft.name = name
      draft.lastName = lastName
    }),
  },
  defaultState
)

export default updateAvatar
