import React, { useMemo, useCallback } from 'react'
import Spin from 'antd/lib/spin'
import Grid from 'antd/lib/grid'
import { ResponsiveLine } from '@nivo/line'
import moment from 'moment'
import StyledContainer from './styles'

const { useBreakpoint } = Grid

const Graph = ({
  data = [],
  success = false,
  width = 530,
  displayFormat = true,
  customLeft = '',
  margin = {},
  areaBaselineValue = 0,
  ...props
}) => {
  const screen = useBreakpoint()
  const marginValue = JSON.stringify(margin) !== '{}' ? margin : { top: 10, right: 0, bottom: 40, left: 40 }

  const chartColors = useMemo(
    () => ({
      Dat: '#002555',
      Report: '#F6A15B',
      DAT_SPOT: '#002555',
      DAT_SPOT_HISTORY: '#002555',
      Internal: '#3594FF',
      ALL: '#002555',
    }),
    []
  )
  const getColor = useCallback((bar) => chartColors[bar.id], [chartColors])
  const customY =
    customLeft === ''
      ? {
        format: (value) => `$ ${value}`,
      }
      : customLeft
  return (
    <StyledContainer
      style={
        screen.xxl
          ? { display: 'flex', width: '100%', height: '350px' }
          : { display: 'flex', width: '100%', height: '350px', overflow: 'scroll hidden' }
      }
    >
      {success ? (
        <div
          style={{
            position: 'relative',
            width: 'inherit',
            height: 'inherit',
            display: 'flex',
            margin: 0,
            padding: 0,
          }}
        >
          <ResponsiveLine
            data={data}
            width={width}
            margin={marginValue}
            xScale={{ type: 'point' }}
            colors={getColor}
            axisLeft={displayFormat ? customY : {}}
            yScale={{
              type: 'linear',
              stacked: false,
              min: 'auto',
              max: 'auto',
              reverse: false,
            }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            theme={{
              fontSize: 8.5,
            }}
            enableGridX
            enableGridY
            pointSize={6}
            pointColor="#ffffff"
            pointBorderWidth={1}
            lineWidth={1}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            enableSlices="x"
            areaBaselineValue={areaBaselineValue}
            areaOpacity={0.1}
            useMesh
            enableArea
            sliceTooltip={({ slice }) => {
              return (
                <div
                  style={{
                    background: 'white',
                    padding: '9px 12px',
                    border: '1px solid #ccc',
                    zIndex: '200',
                  }}
                >
                  {slice.points.map((point) => {
                    const date = moment(point.data?.x)
                    const formatValue = point.serieId === 'Dat' ? date.format('MMM YY') : date.format('DD-MMM-YY')
                    return (
                      <React.Fragment key={point.id}>
                        <strong style={{ textJustify: 'center' }}>{formatValue}</strong>
                        {/* <React.Fragment key={point.id}> */}
                        <div
                          style={{
                            color: point.serieColor,
                            padding: '3px 0',
                          }}
                        >
                          <strong>{point.serieId} Total</strong> [{point.data.y}]
                        </div>
                        {/* </React.Fragment> */}
                      </React.Fragment>
                    )
                  })}
                </div>
              )
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />
        </div>
      ) : (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <Spin />
        </div>
      )}
    </StyledContainer>
  )
}

export default Graph
