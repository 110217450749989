import { put, takeLatest, call } from 'redux-saga/effects'
import { rates } from 'services'
import { successGetAllRates, requestGetAllRates, failureGetAllRates } from '../duck/getAllRates.duck'

function* getGetAllRates(data) {
  try {
    const response = yield call(rates.getAllRates, data.payload.params)
    yield put(successGetAllRates(response))
  } catch (error) {
    yield put(failureGetAllRates(error))
  }
}

function* watchGetAllRatesAsync() {
  yield takeLatest(requestGetAllRates, getGetAllRates)
}

export default watchGetAllRatesAsync
