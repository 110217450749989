import styled from 'styled-components'
import { Spin } from 'antd'

const StyledLoading = styled(Spin)`
  margin: auto;
  margin: 1px 10px;
  text-align: center;
  font-size: 24;

  .ant-spin-dot.ant-spin-dot-spin {
    vertical-align: middle;
  }
`

export default StyledLoading
