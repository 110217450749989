import React from 'react'
import BoxSnapshot, { BoxSnapshotTitle, BoxSnaphotConent } from 'components/Box/BoxSnapshot'
import { daysLTRatio } from 'utils/parseResponse'
import Popover from 'antd/lib/popover'
import Divider from 'antd/lib/divider'
import colors from './constants'

export default function CardLTRatio({ ltrOrigin = [], ltrDestination = [] }) {
  const ltRatio = daysLTRatio(ltrOrigin, ltrDestination)

  return (
    <Popover
      title={
        <p
          style={{
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          L/T Ratio Review
        </p>
      }
      content={
        <div>
          <p>
            <strong>Last 8 days </strong>
          </p>
          <br />
          <p>
            <strong>Origin:</strong> {ltRatio[8].origin}
          </p>
          <br />
          <p>
            <strong>Destination:</strong> {ltRatio[8].destination}
          </p>
          <Divider style={{ margin: '12px 0', background: 'rgba(217, 217, 217, 0.4)' }} />
          <p>
            <strong>Last 30 days </strong>
          </p>
          <br />
          <p>
            <strong>Origin:</strong> {ltRatio[30].origin}
          </p>
          <br />
          <p>
            <strong>Destination:</strong> {ltRatio[30].destination}
          </p>
        </div>
      }
      placement="right"
    >
      <BoxSnapshot color={colors[10]}>
        <BoxSnapshotTitle color={colors[8]}>L/T Ratio</BoxSnapshotTitle>
        <BoxSnaphotConent style={{ display: 'flex', justifyContent: 'center' }}>
          <div className="spanContainer">
            <span className="label">Origin</span>
            <span className="tagValue">{ltRatio[1].origin}</span>
          </div>
          <div className="spanContainer ">
            <span className="label">Destination</span>
            <span className="tagValue">{ltRatio[1].destination}</span>
          </div>
        </BoxSnaphotConent>
      </BoxSnapshot>
    </Popover>
  )
}
