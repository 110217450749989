import PropTypes from 'prop-types'
import AuthTitle from './AuthTitle'

AuthTitle.propTypes = {
  marginBotton: PropTypes.string,
}

AuthTitle.defaultProps = {
  marginBotton: '3rem',
}

export default AuthTitle
