import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: {},
  userId: '',
}

export const { requestEditUser, successEditUser, failureEditUser, resetEditUser } = createActions({
  REQUEST_EDIT_USER: (data, userId) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    data,
    userId,
  }),
  SUCCESS_EDIT_USER: (data) => ({
    data,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_EDIT_USER: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  RESET_EDIT_USER: () => ({
    ...defaultState,
  }),
})

const editUser = handleActions(
  {
    [requestEditUser]: produce((draft, { payload: { fetching, data, error, success } }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successEditUser]: produce((draft, { payload: { message, fetching, success, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = false
      draft.data = data
    }),
    [failureEditUser]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = false
    }),
    [resetEditUser]: produce((draft, { payload: { message, fetching, success, error, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = error
      draft.data = data
    }),
  },
  defaultState
)

export default editUser
