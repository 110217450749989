import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Typography } from 'antd'
import paginate from 'utils/paginate'
import Modal from 'components/Modal'
import Table from 'components/Table'
import { removeRulesNoFlatAndMarginZero } from 'utils/filters'

import ListMultiStep from 'containers/rules/ListMultiStep'
import RuleAdjustmentColumns from './utils/TableColumns'

const { Title } = Typography

const RulesModal = ({
  ruleAdjustment = [],
  multiStepAdjustment = [],
  viewRate = false,
  visible = false,
  onClose = () => '',
}) => {
  const returnRules = (state) => state?.getRate?.rate?.ruleAdjustment ?? []
  const returnMultiStep = (state) => state?.getRate?.rate?.multiStepAdjustment ?? []
  const { ruleAdjustments, multiSteptAdjustments: multiStepsWithoutIds } = useSelector((state) => ({
    ruleAdjustments: viewRate ? ruleAdjustment : returnRules(state),
    multiSteptAdjustments: viewRate ? multiStepAdjustment : returnMultiStep(state),
  }))
  const clearRulesAdjustments = removeRulesNoFlatAndMarginZero(ruleAdjustments)
  const clearMultiSteps = removeRulesNoFlatAndMarginZero(multiStepsWithoutIds)
  const multiStepAdjustments = useMemo(
    () => clearMultiSteps.map((props) => ({ ...props })),
    [clearMultiSteps]
  )

  const [rulePagination, setRulePagination] = useState({ page: 1, limit: 3 })

  const handleRuleChange = useCallback((pagination) => {
    setRulePagination((prevParams) => ({
      page: pagination.current ?? prevParams.page,
      limit: pagination.limit ?? prevParams.limit,
    }))
  }, [])


  return (
    <>
      <Modal
        title={
          <h1
            style={{
              marginBottom: '5px',
              fontWeight: '700',
              fontSize: '16px',
              color: '#002555',
            }}
          >
            All Rules
          </h1>
        }
        visible={visible}
        padding="0 24px 24px"
        onCancel={onClose}
      >
        {ruleAdjustment.length > 0 ? (
          <>
            <Title style={{ fontWeight: '700', fontSize: '16px', color: '#002555', marginTop: 20, marginBottom: 10 }}>
              Rules Adjustment Details
            </Title>
            <Table
              columns={RuleAdjustmentColumns}
              data={paginate(clearRulesAdjustments, rulePagination.limit, rulePagination.page)}
              rowKey="id"
              width="100%"
              height="230px"
              padding="4px 2px"
              pagination={{ total: clearRulesAdjustments.length }}
              updatedPagination
              limitPerPage={5}
              showBulkActions={false}
              onChange={handleRuleChange}
              addHeight={-400}
            />
          </>
        ) : null}

        <Title
          level={4}
          style={{ color: '#002555', fontWeight: '700', fontSize: '16px', marginTop: 20, marginBottom: 10 }}
        >
          Multi Step Adjustments
        </Title>
        <ListMultiStep
          displayFilter={false}
          rowSelection={false} 
          showBulkActions={false}
          removeColumns={['active', "actions"]}
          dataset={multiStepAdjustments.map((item) => (
            {
              _id: item._id,
              multistepName: item.multiStepName,
              margin: item.margin,
              flat: item.flat,
              sentences: item.sentences,
              _id2: item._id
            }
          ))}
        />        
      </Modal>
    </>
  )
}

export default RulesModal
