export default function (state, action) {
  switch (action.type) {
    case 'loading':
      return { ...state, loading: action.loading }
    case 'active-submit':
      return {
        ...state,
        btnSubmit: action.btnSubmit,
        percentageWarning: action.percentageWarning,
        total: action.total,
      }
    case 'percentage-warning':
      return { ...state, percentageWarning: action.percentageWarning }
    case 'total-percentage':
      return {
        ...state,
        btnSubmit: action.btnSubmit,
        percentageWarning: action.percentageWarning,
        total: action.total,
      }
    case 'updating':
      return { ...state, btnSubmit: action.btnSubmit, total: action.total, isUpdating: action.isUpdating }
    default:
      return state
  }
}
