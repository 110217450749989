import { put, takeLatest, call } from 'redux-saga/effects'
import { trucktypes } from 'services/'
import { requestGetTruckTypesHistory, successGetTruckTypesHistory, failureGetTruckTypesHistory } from '../duck/getTruckTypeHistory.duck'

function* getTruckTypesHistoryAsync(data) {
  try {
    const response = yield call(trucktypes.getTruckTypeHistory,data.payload.id)
    yield put(successGetTruckTypesHistory(response))
  } catch (error) {
    yield put(failureGetTruckTypesHistory(error))
  }
}

function* watchGetTruckTypesHistoryAsync() {
  yield takeLatest(requestGetTruckTypesHistory, getTruckTypesHistoryAsync)
}

export default watchGetTruckTypesHistoryAsync
