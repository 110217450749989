import styled from 'styled-components'
import Badge from 'antd/lib/badge'

export const TrustPercentageContainer = styled.div`
  width: 96%;
  margin: 10px auto 15px;
`

const stylesByStatus = {
  Activated: {
    count: 'Activated',
    backgroundColor: '#00C48C',
    color: '#FFFFFF',
  },
  Deactivated: {
    count: 'Blocked',
    backgroundColor: '#F85359',
    color: '#FFFFFF',
  },
  Paused: {
    count: 'Paused',
    backgroundColor: '#F7981C',
    color: '#FFFFFF',
  },
}

export const StyledBadge = styled(Badge).attrs((props) => ({
  count: stylesByStatus[props.variant].count,
  style: {
    backgroundColor: stylesByStatus[props.variant].backgroundColor,
    color: stylesByStatus[props.variant].color,
    fontSize: 16,
  },
}))``
